import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTripLanguage } from "../../context/TripLanguageContext";
import { VisibleSwitch } from "../shared/switch/VisibleSwitch";
import TripLanguages from "./details/TripLanguages";

const TripPlusContainer = ({
  children,
  handleContinue,
  handleBack,
  isFirstStep,
  isLastStep,
  loadingSubmit,
  isEdit,
}) => {
  const { t } = useTranslation();
  const { selectedLanguage, setSelectedLanguage } = useTripLanguage();

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const eventLanguages = watch("event_language_infos", []);
  const defaultLanguage = watch("default_language");

  const handleChangeLanguage = (_, newValue) => {
    setSelectedLanguage(newValue);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-end"
        alignItems="center"
        mb={eventLanguages && eventLanguages.length > 0 ? 0 : 5}
        sx={{
          paddingInline: { xs: 0, sm: 2, md: 5, lg: 10 },
        }}
      >
        <Grid item xs={10}>
          <TripLanguages />
        </Grid>
        <Grid
          item
          xs={2}
          container
          sx={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >
          <Typography>{t("VISIBLE_TRIP")}</Typography>
          <VisibleSwitch
            checked={watch("is_visible") || false}
            handleChange={(e) => setValue("is_visible", e.target.checked)}
          />
        </Grid>
        {isEdit && (
          <Button
            type="submit"
            className="oniria-btn"
            variant="contained"
            disabled={loadingSubmit}
            sx={{ height: "fit-content", borderRadius: 3 }}
          >
            {loadingSubmit ? t("SAVING") : t("SAVE")}
          </Button>
        )}
      </Grid>
      {/* Language tabs */}
      {eventLanguages && eventLanguages.length > 0 ? (
        <Grid item xs={12} container justifyContent="center" mb={3}>
          <Tabs
            value={selectedLanguage}
            onChange={handleChangeLanguage}
            TabIndicatorProps={{
              style: { backgroundColor: "var(--secondary-color)" },
            }}
            sx={{ mb: 2, color: "var(--primary-color)" }}
          >
            <Tab
              label={defaultLanguage}
              value={defaultLanguage}
              sx={{
                "&.Mui-selected": {
                  color: "var(--secondary-color)",
                },
                padding: 0,
              }}
            />
            {eventLanguages.map((language) => (
              <Tab
                key={language.language}
                label={language.language}
                value={language.language}
                sx={{
                  "&.Mui-selected": {
                    color: "var(--secondary-color)",
                  },
                  padding: 0,
                }}
              />
            ))}
          </Tabs>
        </Grid>
      ) : null}
      <Box
        sx={{
          paddingInline: { xs: 0, sm: 2, md: 5, lg: 10 },
        }}
      >
        {children}
        <Grid container mt={4}>
          <Grid item xs={12}>
            {errors && Object.keys(errors).length > 0 && (
              <Box
                sx={{
                  backgroundColor: "#ffe6e6",
                  padding: 2,
                  borderRadius: 2,
                  marginBottom: 2,
                }}
              >
                <Typography sx={{ color: "var(--oniria-red)", fontWeight: "bold" }}>
                  {t("CHECK_FIELDS")}
                </Typography>
                <ul style={{ color: "var(--oniria-red)", paddingLeft: "20px" }}>
                  {Object.entries(errors).map(([field, error]) => (
                    <li key={field}>
                      {t(field.toUpperCase())}: {t(error.message)}
                      {t(error?.root?.message)}
                    </li>
                  ))}
                </ul>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} container justifyContent="center" gap={2}>
            {!isFirstStep && (
              <Button onClick={handleBack} className="oniria-btn-cancel" variant="contained">
                {t("BACK")}
              </Button>
            )}
            {isLastStep ? (
              <Button
                type="submit"
                className="oniria-btn"
                variant="contained"
                disabled={loadingSubmit}
              >
                {loadingSubmit ? t("SAVING") : t("SAVE")}
              </Button>
            ) : (
              <Button className="oniria-btn" variant="contained" onClick={handleContinue}>
                {t("CONTINUE")}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TripPlusContainer;
