import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getB2bSalesAgents } from "../../../services/b2bServices";

export function useQueryB2bSalesAgent(filters, page, perPage) {
  return useQuery(
    [RQ_KEY.B2B_SALES_AGENT, filters, page, perPage],
    () => getB2bSalesAgents(filters, page, perPage),
    {
      refetchOnWindowFocus: false,
    }
  );
}
