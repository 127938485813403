import { ExpandMore } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  eventLanguageErrors,
  eventLanguageInfo,
  extraLanguageInfo,
  ticketLanguageInfo,
} from "../../../classes/tripClass";
import { AVAILABLE_LANGUAGES } from "../../../constants/variables";
import InfoToolTip from "../../shared/InfoToolTip";
import { focusColor } from "../../shared/textFieldStyle";

const EventLanguages = ({ formController }) => {
  const { t } = useTranslation();
  const {
    formData: { details: { event_language_infos = [], default_language } = {} } = {},
    setFormData,
    setFormParamsErrors,
  } = formController;

  const handleChangeDefaultLanguage = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,
      details: {
        ...prev.details,
        default_language: value,
      },
    }));
  };

  const handleChangeCheckBox = (e, value, checkboxIndex) => {
    const isChecked = e.target.checked;

    const newLanguage = {
      ...eventLanguageInfo,
      language: value,
    };

    const newTicketLanguageInfo = {
      ...ticketLanguageInfo,
      language: value,
    };

    const newExtraLanguageInfo = {
      ...extraLanguageInfo,
      language: value,
    };

    const updateLanguageInfo = (items, key, languageInfo) =>
      items.map((item) => ({
        ...item,
        [`${key}_language_infos`]: isChecked
          ? [...item[`${key}_language_infos`], languageInfo]
          : item[`${key}_language_infos`].filter((lang) => lang.language !== languageInfo.language),
      }));

    setFormData((prev) => ({
      ...prev,
      details: {
        ...prev.details,
        event_language_infos: isChecked
          ? [...prev.details?.event_language_infos, newLanguage]
          : prev.details?.event_language_infos?.filter((lang) => lang.language !== value),
      },
      tickets: updateLanguageInfo(prev.tickets, "ticket", newTicketLanguageInfo),
      extras: updateLanguageInfo(prev.extras, "extra", newExtraLanguageInfo),
    }));

    setFormParamsErrors((prev) => ({
      ...prev,
      details: {
        ...prev.details,
        event_language_infos: isChecked
          ? [...prev.details?.event_language_infos, { ...eventLanguageErrors }]
          : prev.details?.event_language_infos?.filter((_, index) => index !== checkboxIndex),
      },
    }));
  };

  const isLanguageChecked = (language) => {
    return event_language_infos?.some((lang) => lang.language === language);
  };

  return (
    <Grid container justifyContent="center" mt={2} paddingInline={"8px"}>
      <Grid item xs={11} md={8}>
        <Accordion sx={{ width: "100%" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container alignItems="center">
              <Typography>{t("EVENT_LANGUAGES")}</Typography>
              <InfoToolTip text={t("EVENT_LANGUAGES_TOOLTIP")} />
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container sx={{ mb: 2 }}>
              <FormControl fullWidth sx={focusColor}>
                <InputLabel>{t("DEFAULT_LANGUAGE")}</InputLabel>
                <Select
                  label={t("DEFAULT_LANGUAGE")}
                  value={default_language}
                  onChange={handleChangeDefaultLanguage}
                >
                  {AVAILABLE_LANGUAGES.map((language) => (
                    <MenuItem key={language.id} value={language.id}>
                      {t(language.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Accordion
              sx={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid var(--grey-cancelled)",
                boxShadow: "none",
                marginTop: 2,
                width: "100%",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid container alignItems="center">
                  <Typography>{t("TRIP_LANGUAGES_AVAILABLE")}</Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {AVAILABLE_LANGUAGES.filter((language) => language.id !== default_language).map(
                  (step, checkboxIndex) => (
                    <Grid item xs={12} key={checkboxIndex} container alignItems="center">
                      <Checkbox
                        className="checkbox-oniria"
                        checked={isLanguageChecked(step.id)}
                        onChange={(e) => handleChangeCheckBox(e, step.id, checkboxIndex)}
                      />
                      <Typography>{t(step.name)}</Typography>
                    </Grid>
                  )
                )}
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default EventLanguages;
