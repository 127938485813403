import { Close } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { dateWithoutTZ, parseFloatOrNull } from "../../../constants/utils";
import { COMMISSION_TYPES } from "../../../constants/variables";
import { editB2bAgreements } from "../../../services/b2bServices";
import { focusColor } from "../../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";

export const AcceptAgreementDialog = ({ isOpen, onClose, agreement }) => {
  const { t } = useTranslation();
  const { premises_id = [] } = useSelector((state) => state.userInfo);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [data, setData] = useState({
    premise_operator_id: premises_id[0] || "",
    premise_agent_id: "",
    event_id: "",
    commission_fixed: null,
    commission_percentage: null,
    commission_type: "",
  });

  const handleAccept = async () => {
    try {
      setLoadingSubmit(true);
      const body = {
        ...data,
        commission_fixed: parseFloatOrNull(data.commission_fixed),
        commission_percentage: parseFloatOrNull(data.commission_percentage),
        approved_at: dateWithoutTZ(new Date().toISOString()),
      };
      delete body.commission_type;
      // Update commission
      if (agreement) {
        await editB2bAgreements(agreement.id, body);
        toastMessageSuccess(t("ESPECIAL_COMMISSION_UPDATED_SUCCESS"));
      }
      setData({
        premise_operator_id: "",
        premise_agent_id: "",
        event_id: "",
        commission_fixed: null,
        commission_percentage: null,
        commission_type: "",
      });
      onClose();
    } catch (error) {
      const errorMessage = error.response?.data?.message || t("ERROR");
      toastMessageError(errorMessage);
    } finally {
      setLoadingSubmit(false);
    }
  };

  useEffect(() => {
    if (!agreement) return;

    setData((prevData) => ({
      premise_operator_id:
        agreement.premise_operator_id || premises_id?.[0] || prevData.premise_operator_id || "",
      premise_agent_id: agreement.premise_agent_id ?? prevData.premise_agent_id ?? "",
      event_id: agreement.event_id ?? prevData.event_id ?? "",
      commission_fixed: agreement.commission_fixed ?? prevData.commission_fixed ?? "",
      commission_percentage:
        agreement.commission_percentage ?? prevData.commission_percentage ?? "",
      commission_type: agreement.commission_fixed ? "fixed" : "percentage",
    }));
  }, [agreement]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography sx={{ fontWeight: "bolder", color: "var(--green-success)" }}>
          {t("ACCEPT_REQUEST")}
        </Typography>
        <Typography variant="body2">{t("ACCEPT_REQUEST_DESCRIPTION")}</Typography>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "var(--secondary-color)",
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* COMMISSION */}
        <Typography>{t("COMMISSION_FOR_TRIP")}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputLabel>{t("TYPE")}</InputLabel>
            <FormControl fullWidth sx={focusColor}>
              <Select
                value={data.commission_type}
                onChange={(e) => setData({ ...data, commission_type: e.target.value })} //TODO
                placeholder={t("BASE_PRICE_O_PERCENTAGE")}
              >
                {COMMISSION_TYPES.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {t(type.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {data.commission_type === "fixed" && (
            <Grid item xs={12} sm={6}>
              <InputLabel>{t("BASE_PRICE")}</InputLabel>
              <TextField
                value={data.commission_fixed ?? ""}
                onChange={(e) => setData({ ...data, commission_fixed: e.target.value })}
                placeholder={"€"}
                sx={focusColor}
                type="number"
                fullWidth
                inputProps={{
                  min: 0,
                }}
              />
            </Grid>
          )}
          {data.commission_type === "percentage" && (
            <Grid item xs={12} sm={6}>
              <InputLabel>{t("PERCENTAGE")}</InputLabel>
              <TextField
                value={data.commission_percentage ?? ""}
                onChange={(e) => setData({ ...data, commission_percentage: e.target.value })}
                placeholder={"%"}
                sx={focusColor}
                type="number"
                fullWidth
                inputProps={{
                  min: 0,
                  max: 100,
                }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mb: 2 }}
      >
        <Button
          type="submit"
          className="oniria-border-btn"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
          disabled={loadingSubmit}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={handleAccept}
          disabled={loadingSubmit}
        >
          {loadingSubmit ? t("SAVING") : t("SAVE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
