import { sendPdfInBase64 } from "../components/shared/FormsValidator";
import { parseFloatOrNull } from "../constants/utils";

export const prepareTripAgentToSend = async (data) => {
  if (!data) return null;

  const clonedData = structuredClone(data);

  const { id, agreement = {} } = clonedData;

  const pdfBase64 = agreement.pdf ? await sendPdfInBase64(agreement.pdf) : null;

  return {
    ...clonedData,
    id: id ?? undefined,
    agreement: {
      ...agreement,
      pdf: pdfBase64,
      commission_fixed: parseFloatOrNull(agreement.commission_fixed),
      commission_percentage: parseFloatOrNull(agreement.commission_percentage),
    },
  };
};
