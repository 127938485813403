import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { PAYMENT_OPTIONS } from "../../../constants/variables";
import { useQueryAssignDomains } from "../../../hooks/queries/useQueryAssignDomains";
import { useQueryCountries } from "../../../hooks/queries/utils/useQueryCountries";
import {
  showSplitPayment,
  uniquePaymentsNumbers,
} from "../../../pages/events/createAssistant/assistantUtils";
import { getUsersAffiliate } from "../../../services/usersServices";
import { focusColor } from "../../shared/textFieldStyle";

export const AssistantInfo = ({
  formData,
  formErrors,
  addClicked,
  setFormData,
  setFormErrors,
  eventData,
  doublePayment,
  setDoublePayment,
  setShowNewUser,
  showNewUser,
  ticketsExtrasData,
  selectedPayment,
  setSelectedPayment,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  // Users
  const { data: usersData = [] } = useQuery(
    [RQ_KEY.USERS_FOR_EVENT, search],
    () => getUsersAffiliate("", "", search),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: domainCategories } = useQueryAssignDomains();
  const { data: countries = [] } = useQueryCountries(showNewUser);

  const handleEmailChange = (_, newValue) => {
    setFormData({ ...formData, user_id: newValue ? newValue.user_id : "" });
    if (newValue) {
      setFormErrors({ ...formErrors, user_id: "" });
    }
  };

  const handleInputChange = (_, newValue) => {
    setSearch(newValue);
  };

  const handleTypeAssistant = (event) => {
    const selectedAssistant = event.target.value;
    //Errors
    if (selectedAssistant !== "") {
      setFormErrors({ ...formErrors, payment_type: "" });
    }
    setFormData({
      ...formData,
      payment_type: selectedAssistant,
    });
  };

  const handleDomain = (event) => {
    const selectedDomain = event.target.value;
    setFormData({
      ...formData,
      domain_name: selectedDomain,
    });
  };

  const handleUserInputChange = (field, value) => {
    let errorMessage = "";

    if (field === "name" || field === "surname") {
      if (!value.trim()) {
        errorMessage = t("REQUIRED_FIELD");
      }
    } else if (field === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        errorMessage = t("INCORRECT_EMAIL");
      }
    } else if (field === "phone") {
      if (!/^\d+$/.test(value)) {
        errorMessage = t("INVALID_PHONE");
      }
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      form_user_data: { ...prevErrors.form_user_data, [field]: errorMessage },
    }));

    setFormData((prev) => ({
      ...prev,
      form_user_data: { ...prev.form_user_data, [field]: value },
    }));
  };

  const payments = uniquePaymentsNumbers(
    eventData.event_payments,
    eventData.start_date,
    ticketsExtrasData
  );

  console.log("selectedPayment", formData.payment_type);

  useEffect(() => {
    if (domainCategories && domainCategories.length === 1) {
      handleDomain({ target: { value: domainCategories[0].name } });
    }
  }, [domainCategories]);

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={2}
      item
      xs={12}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        mb: 2,
      }}
    >
      <Grid item xs={12}>
        <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>{t("GUEST_DATA")}</InputLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={showNewUser}
              onChange={(e) => setShowNewUser(e.target.checked)}
              className="checkbox-oniria"
            />
          }
          label={t("ADD_NEW_USER")}
        />
        {showNewUser ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label={t("USER_NAME")}
                value={formData.form_user_data.name}
                onChange={(e) => handleUserInputChange("name", e.target.value)}
                sx={focusColor}
                error={!!formErrors.form_user_data?.name}
                helperText={formErrors.form_user_data?.name}
                required={showNewUser}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label={t("SURNAME")}
                value={formData.form_user_data.surname}
                onChange={(e) => handleUserInputChange("surname", e.target.value)}
                sx={focusColor}
                error={!!formErrors.form_user_data?.surname}
                helperText={formErrors.form_user_data?.surname}
                required={showNewUser}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label={t("EMAIL")}
                value={formData.form_user_data.email}
                onChange={(e) => handleUserInputChange("email", e.target.value)}
                sx={focusColor}
                error={!!formErrors.form_user_data?.email}
                helperText={formErrors.form_user_data?.email}
                required={showNewUser}
              />
            </Grid>

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" sx={focusColor}>
                  <InputLabel id="demo-select-small-label">{t("PHONE_CODE")}</InputLabel>
                  <Select
                    required={showNewUser}
                    label={t("PHONE_CODE")}
                    value={formData.form_user_data.phone_code}
                    onChange={(e) => handleUserInputChange("phone_code", e.target.value)}
                    renderValue={(selected) => (
                      <Grid container sx={{ display: "flex", flexDirection: "row" }}>
                        <img
                          style={{
                            height: "20px",
                            width: "35px",
                            objectFit: "cover",
                            marginRight: 10,
                          }}
                          alt="Flag"
                          src={`/media/flags/${selected.split("-")[0]}.svg`}
                        />
                        <Typography> +{selected.split("-")[1]}</Typography>
                      </Grid>
                    )}
                  >
                    {countries.map((country) => (
                      <MenuItem
                        key={country.country_code}
                        value={country.country_code.toLowerCase() + "-" + country.phone_code}
                      >
                        <img
                          style={{
                            height: "20px",
                            width: "35px",
                            objectFit: "cover",
                            marginRight: 10,
                          }}
                          alt="Flag"
                          src={`/media/flags/${country.country_code.toLowerCase()}.svg`}
                        />
                        +{country.phone_code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={t("PHONE")}
                  value={formData.form_user_data.phone}
                  onChange={(e) => handleUserInputChange("phone", e.target.value)}
                  sx={focusColor}
                  error={!!formErrors.form_user_data?.phone}
                  helperText={formErrors.form_user_data?.phone}
                  required={showNewUser}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <FormControl
            fullWidth
            variant="outlined"
            sx={focusColor}
            required
            error={formErrors.user_id !== "" && addClicked}
          >
            <Autocomplete
              id="combo-box-demo"
              options={usersData}
              getOptionLabel={(option) => option.user_email || ""}
              onChange={handleEmailChange}
              onInputChange={handleInputChange}
              inputValue={search}
              value={usersData?.find((user) => user.user_id === formData.user_id) || null}
              isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("EMAIL")}
                  variant="outlined"
                  sx={focusColor}
                  required
                  error={formErrors.user_id !== "" && addClicked}
                />
              )}
            />
          </FormControl>
        )}
        <FormControl
          fullWidth
          variant="outlined"
          sx={[focusColor, { mt: 2 }]}
          required
          error={formErrors.payment_type !== "" && addClicked}
        >
          <InputLabel id="demo-select-small-label">{t("TYPE_ASSISTANT")}</InputLabel>
          <Select
            label={t("TYPE_ASSISTANT")}
            value={formData.payment_type}
            onChange={handleTypeAssistant}
          >
            <MenuItem value={PAYMENT_OPTIONS.OFFICE_CASH}>{t("OFFICE_CASH")}</MenuItem>
            <MenuItem value={PAYMENT_OPTIONS.OFFICE_GUEST}>{t("OFFICE_GUEST")}</MenuItem>
            <MenuItem value={PAYMENT_OPTIONS.OFFICE_CARD}>{t("OFFICE_CARD")}</MenuItem>
            <MenuItem value={PAYMENT_OPTIONS.OFFICE_TRANSFER_BANK}>
              {t("OFFICE_TRANSFER_BANK")}
            </MenuItem>
          </Select>
        </FormControl>
        {domainCategories?.length > 1 ? (
          <FormControl
            fullWidth
            variant="outlined"
            sx={[focusColor, { mt: 2 }]}
            required
            error={formErrors.domain_name !== "" && addClicked}
          >
            <InputLabel id="demo-select-small-label">{t("DOMAIN")}</InputLabel>
            <Select label={t("DOMAIN")} value={formData.domain_name} onChange={handleDomain}>
              {domainCategories?.map((domain, index) => (
                <MenuItem key={`domain-${index}`} value={domain.name}>
                  {domain.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        {showSplitPayment(eventData, formData?.payment_type) && (
          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <Checkbox
                checked={doublePayment}
                onChange={(e) => {
                  setDoublePayment(e.target.checked);
                  if (!e.target.checked) {
                    setSelectedPayment("");
                  }
                }}
                name="doublePayment"
                className="checkbox-oniria"
              />
            }
            label={t("SPLIT_PAYMENT")}
          />
        )}
        {doublePayment && payments?.length > 0 ? (
          <Box>
            <FormControl fullWidth sx={focusColor}>
              <InputLabel>{t("SELECT_NUMBER_PAYMENTS")}</InputLabel>
              <Select
                value={selectedPayment}
                onChange={(e) => setSelectedPayment(e.target.value)}
                sx={focusColor}
                label={t("SELECT_NUMBER_PAYMENTS")}
              >
                {payments.map((payment, index) => (
                  <MenuItem key={`payment-${index}`} value={payment}>
                    {payment}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ) : null}
      </Grid>
    </Grid>
  );
};
