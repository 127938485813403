import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { ABECEDARY } from "../../../constants/variables";
import AssignedHotelCard from "../assignToTrip/AssignedHotelCard";

export const HotelsAssignZone = ({ itineraries = [], handleRemove }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Accordion sx={{ boxShadow: "none", "&:before": { display: "none" } }} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>{t("HOSTING")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {itineraries.map((itinerary, itineraryIndex) => (
              <Grid key={`droppable-${itineraryIndex}`} item xs={12}>
                <Typography sx={{ mb: 2 }}>
                  {ABECEDARY[itineraryIndex]}: {itinerary.title}
                </Typography>
                <Droppable
                  droppableId={`hotel-${itinerary.id || itineraryIndex}`}
                  direction="horizontal"
                >
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        mb: 1,
                        backgroundColor: snapshot.isDraggingOver ? "#e0e0e0" : "#fff",
                        borderRadius: 2,
                        padding: 2,
                      }}
                    >
                      {itinerary.hotel_ids?.map((hotelId) => (
                        <AssignedHotelCard
                          key={hotelId}
                          hotelId={hotelId}
                          handleClose={() => handleRemove(hotelId, itinerary.id || itineraryIndex)}
                        />
                      ))}
                      <Box
                        sx={{
                          border: "1px dashed",
                          width: "100%",
                          textAlign: "center",
                          padding: 2,
                          borderRadius: 2,
                        }}
                      >
                        <Typography variant="caption">{t("DRAG_HOSTING_HERE")}</Typography>
                      </Box>
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
};
