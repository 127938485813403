import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getB2bAgreements } from "../../../services/b2bServices";

export const useQueryB2bAgreements = (page, perPage, filters) => {
  return useQuery(
    [RQ_KEY.TRIPS_B2B, filters, page, perPage],
    () => getB2bAgreements(filters, page, perPage),
    {
      refetchOnWindowFocus: false,
    }
  );
};
