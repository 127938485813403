import CloseIcon from "@mui/icons-material/Close";
import { Card, CardContent, CardMedia, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import useQueryExtraDetail from "../../../hooks/queries/extras/useQueryExtraDetail";
import { TripExtraDialog } from "../../tripPlus/extras/TripExtraDialog";
import { EditOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const AssignedActivityCard = ({ extraId, handleClose, extraPosition, isSimple = false }) => {
  const { t } = useTranslation();

  const [openEditExtra, setOpenEditExtra] = useState(false);

  const { data: extra = {} } = useQueryExtraDetail(extraId);
  const { name = "", images = "" } = extra;

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          width: isSimple ? "fit-content" : "100%",
          borderRadius: 2,
          position: "relative",
        }}
      >
        <Grid container spacing={1} padding={1}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", position: "relative" }}
          >
            {!isSimple && (
              <IconButton
                onClick={handleClose}
                size="small"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: 1,
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </Grid>

          {images && (
            <Grid item xs={isSimple ? 6 : 3}>
              <CardMedia
                component="img"
                height="70"
                image={images || ""}
                alt={name || "extra image"}
                sx={{ objectFit: "cover", width: "100%", borderRadius: 2 }}
              />
            </Grid>
          )}

          <Grid item xs={isSimple ? 5 : 8}>
            <CardContent
              sx={{
                padding: 0,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body2"
                component="div"
                noWrap
                sx={{ fontWeight: 700, fontSize: "0.875rem", textWrap: "wrap" }}
              >
                {name}
              </Typography>
              {!isSimple && (
                <Tooltip title={t("EDIT_EXTRA")}>
                  <IconButton onClick={() => setOpenEditExtra(true)}>
                    <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
                  </IconButton>
                </Tooltip>
              )}
            </CardContent>
          </Grid>
        </Grid>
      </Card>
      <TripExtraDialog
        isOpen={openEditExtra}
        onClose={() => setOpenEditExtra(false)}
        extraIndex={extraPosition}
      />
    </>
  );
};
