import { SERVICE } from "../constants/api";
import { http } from "../constants/axios";

export async function serviceSignin(email, password) {
  try {
    return await http.post(`${SERVICE.LOGIN}`, {
      email,
      password,
    });
    // Realizar acciones con la respuesta exitosa (data)
  } catch (error) {
    // Capturar y manejar el error
    return error;
    // Realizar acciones adicionales según sea necesario
  }
}

export async function serviceCreateAccount(body) {
  const { data } = await http.post(`${SERVICE.CREATE_ACCOUNT}`, body);
  return data;
}

export async function getUserById(id) {
  const { data } = await http.get(`${SERVICE.USER}${id}`);
  return data;
}

export async function getUserByToken() {
  const { data } = await http.get(`${SERVICE.USER}user`);
  return data;
}

export async function emailExists(email) {
  //users/user-exists/user1@yopmail.com
  const { data } = await http.get(`${SERVICE.USER}user-exists/${email}`);
  return data;
}

export async function resetPassword(body) {
  const { data } = await http.post(`${SERVICE.USER}reset-password`, body);
  return data;
}

export async function forgotPassword(email) {
  const { data } = await http.post(`${SERVICE.USER}forgot-password`, email);
  return data;
}

export async function validateUserPassword(id, body) {
  const { data } = await http.put(`${SERVICE.USER}verify/${id}`, body);
  return data;
}

export async function validateUserApp(id) {
  const { data } = await http.put(`${SERVICE.USER}verify/${id}`);
  return data;
}
