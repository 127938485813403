import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getMatchingDomainCategoryNames } from "../constants/eventsUtils";
import { RQ_KEY } from "../constants/query";
import { PAGINATION_STYLES } from "../constants/styles";
import { useDomain } from "../context/DomainContext";
import { useQueryAssignDomains } from "../hooks/queries/useQueryAssignDomains";
import useDebounce from "../hooks/useDebouncing";
import usePagination from "../hooks/usePagination";
import useUserRoles from "../hooks/useUserRoles";
import { getAllRrppbyEvent, setRrppsEvent } from "../services/rrppServices";
import { Loading } from "./shared/Loading";
import SelectPerPage from "./shared/SelectPerPage";
import { focusColor } from "./shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "./shared/toastMessage";

function TablaRPP({ eventId, eventCategories }) {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const { page, perPage, changePage, changePerPage } = usePagination();
  const { selectedDomain } = useDomain();
  const { data: domainCategories } = useQueryAssignDomains();

  const { userCanEdit } = useUserRoles();

  const domainId = domainCategories?.find((domain) => domain.name === selectedDomain)?.id || null;

  // Domain names with matching event categories
  const matchingDomainNames =
    getMatchingDomainCategoryNames(domainCategories, eventCategories).join(";") || "";

  // Get all users
  const { refetch, isLoading, isRefetching } = useQuery(
    [RQ_KEY.RRPP_BY_EVENT_ID, page, search, eventId, matchingDomainNames, domainId],
    () => getAllRrppbyEvent(eventId, page, search, matchingDomainNames, domainId),
    {
      onSuccess: (data) => {
        setTotal(data.total || 0);
        setUsers(data.data || []);
        setSelectedUsers(data.data?.filter((rrpp) => rrpp?.is_active).map((rrpp) => rrpp?.id));
      },
      keepPreviousData: true,
      retry: false,
    }
  );

  // Select users to be in the event
  const handleSelectionChange = (event, id) => {
    setSelectedUsers((prevSelection) =>
      event.target.checked
        ? [...prevSelection, id]
        : prevSelection?.filter((selectedId) => selectedId !== id)
    );
  };

  // Save changes
  const handleSaveUsers = async () => {
    setIsSending(true);
    try {
      const usersToSend = selectedUsers.map((id) => ({ id, is_active: true }));
      await setRrppsEvent(eventId, usersToSend);
      toastMessageSuccess(t("EDIT_SUCCESS"));
    } catch (error) {
      console.error("Error al actualizar rrpps del evento:", error);
      toastMessageError(error?.response.data?.error || t("ERROR"));
    } finally {
      setIsSending(false);
      refetch();
    }
  };

  // Search logic
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const debounceSearch = useDebounce(handleSearchChange, 300);

  const totalPages = Math.ceil(total / perPage);

  return (
    <div style={{ width: "100%" }}>
      <Grid container justifyContent="space-between">
        <Grid item sx={{ margin: "20px" }}>
          <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
          <TextField
            label={t("SEARCH")}
            size="small"
            onChange={(event) => debounceSearch(event)}
            sx={[focusColor, { width: "200px" }]}
          />
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end" alignItems="center">
          <Grid item>
            <SelectPerPage text={t("USERS")} change={changePerPage} value={perPage} />
          </Grid>
          <Pagination count={totalPages} page={page} onChange={changePage} sx={PAGINATION_STYLES} />
        </Grid>
      </Grid>
      {isLoading || isRefetching ? (
        <Loading />
      ) : users.length === 0 ? (
        <Typography variant="h6" sx={{ textAlign: "center", marginTop: "20px" }}>
          {t("NO_USERS_FOUND")}
        </Typography>
      ) : (
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("NAME")}</TableCell>
                <TableCell>{t("SURNAME")}</TableCell>
                <TableCell>{t("EMAIL")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.surname}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="is_in_event"
                          checked={selectedUsers.includes(row.id)}
                          onChange={(event) => handleSelectionChange(event, row.id)}
                          className="checkbox-oniria"
                          disabled={!userCanEdit}
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Pagination count={totalPages} page={page} onChange={changePage} sx={PAGINATION_STYLES} />
      </div>
      {userCanEdit && (
        <div style={{ marginTop: "20px", textAlign: "center", marginBottom: "120px" }}>
          <Button
            type="button"
            onClick={handleSaveUsers}
            fullWidth
            variant="contained"
            className="oniria-btn"
            sx={{ m: 2, fontSize: "20px", borderRadius: 3, width: "270px" }}
            disabled={isSending}
          >
            {isSending ? t("SAVING") : t("SAVE_CHANGES")}
          </Button>
        </div>
      )}
    </div>
  );
}

export default TablaRPP;
