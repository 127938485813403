import { Typography } from "@mui/material";

export const FormFieldHeader = ({ title, description, titleStyle = {}, descriptionStyle = {} }) => {
  return (
    <>
      <Typography variant="subtitle1" fontWeight="800" sx={titleStyle}>
        {title}
      </Typography>
      <Typography variant="body1" sx={descriptionStyle}>
        {description}
      </Typography>
    </>
  );
};
