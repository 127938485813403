import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import bcrypt from "bcryptjs";
import * as Jwt from "jose";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import AboutUs from "../components/login/AboutUs.jsx";
import Faqs from "../components/login/Faqs.jsx";
import { toastMessageError } from "../components/shared/toastMessage";
import { setHttpBearerToken } from "../constants/axios";
import { ROUTES } from "../constants/routes.js";
import { SALT } from "../constants/salt";
import { secret } from "../constants/secret";
import { LOGOS } from "../constants/variables.jsx";
import { getUserByToken, serviceSignin } from "../services/authenticationServices";
import { getCanPublishTrips } from "../services/usersServices.jsx";
import { userData, userInfo, userToken } from "../store/reducers/userInfoReducer";

const Login = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [valueTab, setValueTab] = useState(0);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // SETEO LA TAB EN LA QUE ESTOY
  const handleChangeTab = (_, value) => {
    setValueTab(value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(!validateEmail(value));
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
  };
  const validateEmail = (value) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(value);
  };
  const isFormValid = () => {
    if (!email || emailError || !password) {
      return false;
    }
    return true;
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData(e.currentTarget);
      const rememberCheck = data.get("remember");

      const hashedPassword = await bcrypt.hash(data.get("password"), SALT);

      let response = await serviceSignin(data.get("email"), hashedPassword);

      if (response.status === 200) {
        response = response.data;
        setHttpBearerToken(response.token);
        // CHECK USER CAN PUBLISH TRIPS
        let canPublishTrips = await getCanPublishTrips();

        var decoded = await Jwt.jwtVerify(response.token, secret);

        // CHECK USER HAS ROLES
        if (decoded.payload.roles.length === 0) {
          toastMessageError(t("ERROR_USER"));
          return;
        }

        dispatch(
          userToken({
            token: response.token,
          })
        );

        dispatch(
          userInfo({
            id: decoded.payload.id,
            email: decoded.payload.email,
            roles: decoded.payload.roles,
            trips: canPublishTrips,
            organization_id: decoded.payload.organization_id,
            grouping_id: decoded.payload.grouping_id,
            premises_id: decoded.payload.premises_id,
          })
        );
        decoded.payload.trips = canPublishTrips;

        const userResponse = await getUserByToken();
        const userLogin = userResponse;
        dispatch(userData({ userData: userLogin }));

        if (rememberCheck != null) {
          localStorage.setItem("token", response.token);
          const userDataInfoJson = JSON.stringify(decoded.payload);
          localStorage.setItem("userInfo", userDataInfoJson);
          const userDataJson = JSON.stringify(userResponse);
          localStorage.setItem("userData", userDataJson);
        } else {
          sessionStorage.setItem("token", response.token);
          const userDataInfoJson = JSON.stringify(decoded.payload);
          sessionStorage.setItem("userInfo", userDataInfoJson);
          const userDataJson = JSON.stringify(userResponse);
          sessionStorage.setItem("userData", userDataJson);
        }
        navigate("/");
      } else {
        toastMessageError(t("ERROR_PASSWORD"));
        navigate(ROUTES.LOGIN);
      }
    } catch (error) {
      console.log("Error: ", error);
      toastMessageError(t("ERROR"));
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          mt: 0,
          mb: 0,
          backgroundColor: "#333",
          color: "white",
          minHeight: "100vh",
          backgroundImage: 'url("/media/background_login.webp")',
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            backgroundColor: "#f0f0f060",
          }}
        >
          <Box
            component="img"
            alt="Logo"
            src={LOGOS.PRINCIPAL_WHITE}
            sx={{ height: 150, filter: "drop-shadow(5px 5px 5px rgba(0, 0, 0, 1))" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#333333ab",
            color: "white",
            minHeight: "100vh",
            p: 3,
          }}
        >
          <AppBar position="static" sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
            <Tabs
              value={valueTab}
              onChange={handleChangeTab}
              textColor="inherit"
              variant="fullWidth"
              indicatorColor="white"
            >
              <Tab label="Home" />
              <Tab label="About Us" />
              <Tab label="Contact" />
              <Tab label="FAQs" />
            </Tabs>
          </AppBar>
          <Box
            sx={{
              mt: 2,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {valueTab === 0 && (
              <Box
                component={Paper}
                elevation={3}
                sx={{
                  py: 3,
                  px: 5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border: "1px solid #E4E4E4",
                  borderRadius: "15px",
                  backgroundColor: "white",
                  color: "black",
                  mt: 3,
                }}
              >
                <Typography className="oniria-colorText" variant="h6">
                  {t("SIGN_IN_YOUR_ACCOUNT")}
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1, mb: 1 }} onSubmit={handleLogin}>
                  <InputLabel sx={{ fontSize: "14px", color: "black" }}>{t("EMAIL")}</InputLabel>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={handleEmailChange}
                    error={emailError}
                    helperText={emailError && t("INCORRECT_EMAIL")}
                  />
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{ fontSize: "14px", color: "black", mb: 1 }}
                  >
                    {t("PASSWORD")}
                  </InputLabel>
                  <FormControl fullWidth variant="outlined" sx={{ mb: 1 }}>
                    <OutlinedInput
                      id="password"
                      name="password"
                      value={password}
                      onChange={handlePasswordChange}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff sx={{ color: "var(--secondary-color)" }} />
                            ) : (
                              <Visibility sx={{ color: "var(--secondary-color)" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="remember"
                        value="remember"
                        sx={{
                          color: "var(--secondary-color)",
                          "&.Mui-checked": {
                            color: "var(--secondary-color)",
                          },
                        }}
                      />
                    }
                    label={t("SESSION_STARTED")}
                  />
                  <Button
                    disabled={!isFormValid()}
                    className="oniria-btn"
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, px: 6, fontSize: "14px", borderRadius: 3 }}
                  >
                    {t("LOGIN")}
                  </Button>
                  <Link
                    onClick={() => navigate(ROUTES.FORGOT_PASSWORD)}
                    underline="none"
                    color="inherit"
                    sx={{
                      fontSize: "16px",
                      "&:hover": { color: "var(--primary-color)" },
                      display: "flex",
                      justifyContent: "flex-end",
                      cursor: "pointer",
                    }}
                  >
                    {t("FORGOT_PASS")}
                  </Link>
                </Box>
                {/* <Button
                  onClick={() => navigate(ROUTES.CREATE_ACCOUNT)}
                  className="oniria-btn"
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{ mt: 3, mb: 2, px: 6, fontSize: "14px", borderRadius: 3 }}
                >
                  {t("CREATE_ACCOUNT")}
                </Button> */}
              </Box>
            )}
            {valueTab === 1 && <AboutUs />}
            {valueTab === 3 && <Faqs />}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
