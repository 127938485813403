import { Grid, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTripLanguage } from "../../../context/TripLanguageContext";
import { focusColor } from "../../shared/textFieldStyle";
import { FormFieldHeader } from "../FormFieldHeader";

const TripConfiguration = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, disabled },
  } = useFormContext();
  const { getFieldName } = useTripLanguage();

  const subtitleField = getFieldName("subtitle");
  const shortDescriptionField = getFieldName("short_description");

  return (
    <Grid item xs={12} container spacing={2}>
      {/* SKU */}
      <Grid item xs={12}>
        <FormFieldHeader title={t("OPTIONAL_SKU")} description={t("SKU_INFO")} />
        <TextField
          placeholder={t("SKU")}
          {...register("sku")}
          error={!!errors?.sku}
          helperText={t(errors?.sku?.message)}
          fullWidth
          sx={focusColor}
          disabled={disabled}
        />
      </Grid>
      {/* SUBTITLE TRIP */}
      <Grid item xs={12}>
        <FormFieldHeader title={t("OPTIONAL_SUBTITLE")} description={t("SUBTITLE_DESCRIPTION")} />
        <TextField
          key={subtitleField}
          {...register(subtitleField)}
          error={!!errors?.[subtitleField]}
          helperText={t(errors?.[subtitleField]?.message)}
          fullWidth
          sx={focusColor}
          placeholder={t("SUBTITLE")}
        />
      </Grid>
      {/* SHORT DESCRIPTION */}
      <Grid item xs={12}>
        <FormFieldHeader
          title={t("OPTIONAL_SHORT_DESCRIPTION")}
          description={t("SHORT_DESCRIPTION_DESCRIPTION")}
        />
        <TextField
          key={shortDescriptionField}
          {...register(shortDescriptionField)}
          error={!!errors?.[shortDescriptionField]}
          helperText={t(errors?.[shortDescriptionField]?.message)}
          fullWidth
          sx={focusColor}
          placeholder={t("SHORT_DESCRIPTION_TEXT")}
        />
      </Grid>
      {/* Event URL */}
      <Grid item xs={12}>
        <FormFieldHeader title={t("OPTIONAL_EVENT_URL")} description={t("EVENT_URL_INFO")} />
        <TextField
          placeholder={t("URL")}
          {...register("seo_name")}
          error={!!errors?.seo_name}
          helperText={t(errors?.seo_name?.message)}
          fullWidth
          sx={focusColor}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default TripConfiguration;
