import { Edit, FileDownloadOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PAGINATION_STYLES } from "../../../constants/styles";
import { formatCurrency } from "../../../constants/utils";
import { ROLES } from "../../../constants/variables";
import { useQueryB2bSalesAgent } from "../../../hooks/queries/b2b/useQueryB2bSalesAgent";
import usePagination from "../../../hooks/usePagination";
import InfoToolTip from "../../shared/InfoToolTip";
import { Loading } from "../../shared/Loading";
import { AgreementFormDialog } from "../dialogs/AgreementFormDialog";

export const AgentsAgreements = ({ userType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [agreementFormDialog, setAgreementFormDialog] = useState({
    isOpen: false,
    agent: null,
  });

  const initialPerPage = 5;
  const { page, changePage, perPage } = usePagination(initialPerPage);

  const { data, isLoading, refetch, isRefetching } = useQueryB2bSalesAgent(
    { hasCommission: true },
    page,
    perPage
  );
  const { data: agents = [], amount: total = 0 } = data || {};

  const handleSeeCatalog = (id) => {
    navigate(`/b2b/catalog/${id}`);
  };

  const numberPages = Math.ceil(parseFloat(total) / parseFloat(perPage));

  return (
    <>
      <Typography variant="body2" fontWeight="bold">
        {t("AGREEMENTS_WITH_AGENTS")}
      </Typography>
      {isLoading || isRefetching ? (
        <Loading />
      ) : agents && agents.length > 0 ? (
        <>
          {numberPages > 1 ? (
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
              <Pagination
                count={numberPages}
                page={page}
                onChange={changePage}
                sx={PAGINATION_STYLES}
                size="small"
              />
            </Box>
          ) : null}
          <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
            <Table sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                  <TableCell align="center">{t("AGENCIE")}</TableCell>
                  <TableCell align="center">{t("COMMISSION_WORD")}</TableCell>
                  <TableCell align="center">{t("CATALOG_ACCESS")}</TableCell>
                  <TableCell align="center">{t("CONTRACT")}</TableCell>
                  <TableCell align="center">{t("CONTACT")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agents?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">
                      {row.commission_percentage && row.commission_percentage !== "0"
                        ? `${row.commission_percentage}%`
                        : row.commission_fixed
                        ? formatCurrency(row.commission_fixed)
                        : null}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => handleSeeCatalog(row.id)}
                        variant="text"
                        className="underline-button"
                      >
                        {t("SEE_CATALOG")}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      {row.pdf ? (
                        <IconButton component="a" href={row.pdf} download target="_blank">
                          <FileDownloadOutlined sx={{ color: "var(--oniria-gold)" }} />
                        </IconButton>
                      ) : null}
                      <InfoToolTip text={t("EDIT")}>
                        <IconButton
                          onClick={() => setAgreementFormDialog({ isOpen: true, agent: row })}
                        >
                          <Edit sx={{ color: "var(--oniria-gold)" }} />
                        </IconButton>
                      </InfoToolTip>
                    </TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography variant="body2">{t("NO_DATA_AVAILABLE")}</Typography>
      )}
      <AgreementFormDialog
        isOpen={agreementFormDialog.isOpen}
        onClose={() => setAgreementFormDialog({ isOpen: false, agent: null })}
        agent={agreementFormDialog.agent}
        refetch={refetch}
        canEdit={userType === ROLES.B2B_OPERATOR}
      />
    </>
  );
};
