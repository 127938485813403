import { Close } from "@mui/icons-material";
import { Avatar, Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import useQueryTourLeaderDetails from "../../../hooks/queries/tourLeaders/useQueryTourLeaderDetails";

const AssignedTourLeaderCard = ({ tourLeaderId, handleDelete }) => {
  const { data: leader } = useQueryTourLeaderDetails(tourLeaderId, () => {});

  const { name, avatar_url } = leader || {};

  return (
    <Card sx={{ maxWidth: 345, position: "relative" }}>
      <IconButton
        onClick={() => {
          handleDelete(tourLeaderId);
        }}
        sx={{
          position: "absolute",
          top: 2,
          right: 2,
        }}
      >
        <Close />
      </IconButton>

      <CardContent>
        <Grid container gap={2} alignItems="center">
          <Avatar alt={name} src={avatar_url} sx={{ width: 50, height: 50 }} />
          <Typography variant="h6" component="div" sx={{ marginTop: 1 }}>
            {name}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AssignedTourLeaderCard;
