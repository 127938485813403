import { Box, Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toastMessageError } from "../../shared/toastMessage";

const ButtonsForms = (props) => {
  const { t } = useTranslation();
  const formController = props.formController;

  const sendClicked = props.sendButtonClicked;
  const clicked = true;
  const [isLoading, setIsLoading] = useState(false);

  const handleNextClick = async () => {
    sendClicked(clicked);
    if (props.allFieldsOk(props.errors)) {
      if (!formController.isLastStep()) {
        formController.next();
        sendClicked(false);
      } else {
        setIsLoading(true);
        try {
          await formController.finish();
        } catch (error) {
          toastMessageError(error?.response?.data?.error ?? t("ERROR"));
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <>
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="50px">
          <CircularProgress sx={{ color: "var(--secondary-color)" }} />
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        {!formController.isFirstStep() && (
          <Button
            type="submit"
            className="oniria-btn"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              fontSize: "20px",
              borderRadius: 3,
            }}
            onClick={formController.back}
          >
            {t("BACK")}
          </Button>
        )}
        <Button
          type="submit"
          disabled={isLoading}
          className="oniria-btn"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, fontSize: "20px", borderRadius: 3 }}
          onClick={handleNextClick}
        >
          {formController.isLastStep() ? "Enviar" : t("NEXT")}
        </Button>
      </Box>
    </>
  );
};

export default ButtonsForms;
