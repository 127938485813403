import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQueryCities } from "../../../hooks/queries/utils/useQueryCities";
import { useQueryCountries } from "../../../hooks/queries/utils/useQueryCountries";
import { useQueryRegions } from "../../../hooks/queries/utils/useQueryRegions";
import { DEFAULT_TRIP_AGENT, tripAgent } from "../../../schemas/tripAgent";
import { createTripAgent } from "../../../services/b2bServices";
import { prepareTripAgentToSend } from "../../../utils/tripAgent";
import ColorInput from "../../shared/ColorInput";
import { handleSelectImageReactHook } from "../../shared/FormsValidator";
import ImageUpload from "../../shared/ImageUpload";
import InfoToolTip from "../../shared/InfoToolTip";
import { focusColor } from "../../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";
import { AgentAddressFields } from "./formComponents/AgentAddressFields";
import { AgentAdminFields } from "./formComponents/AgentAdminFields";
import { AgentBillingFields } from "./formComponents/AgentBillingFields";
import { AgentContactFields } from "./formComponents/AgentContactFields";
import { AgentContractFields } from "./formComponents/AgentContractFields";
import { AgentFiscalAddressFields } from "./formComponents/AgentFiscalAddressFields";

export const TripAgentForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { premises_id = [] } = useSelector((state) => state.userInfo);

  const methods = useForm({
    resolver: zodResolver(tripAgent),
    mode: "onBlur",
    defaultValues: {
      ...DEFAULT_TRIP_AGENT,
    },
  });

  const {
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = methods;

  const countryCode = watch("country");
  const regionCode = watch("state");
  const { data: countries = [] } = useQueryCountries();
  const { data: regions = [] } = useQueryRegions(countryCode);
  const { data: cities = [] } = useQueryCities(regionCode);

  const logoUrl = watch("styles.logo_url");
  const principalColor = watch("styles.principal_color") || "#ffffff";
  const textColor = watch("styles.text_color") || "#000000";

  const onSubmit = async (data) => {
    setLoadingSubmit(true);
    try {
      const agent = await prepareTripAgentToSend(data);
      await createTripAgent(agent);
      toastMessageSuccess(t("TRIP_AGENT_CREATED_SUCCESS"));
      setTimeout(() => {
        navigate(-1);
      }, 1500);
    } catch (error) {
      const errorMessage = error.response?.data?.message || t("ERROR");
      toastMessageError(errorMessage);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (premises_id.length > 0) {
      setValue("agreement.premise_operator_id", premises_id[0]);
    }
  }, [premises_id]);

  return (
    <FormProvider {...methods}>
      <Box mt={2} mb={4} sx={{ paddingX: sm ? "0" : "40px" }}>
        <InfoToolTip text={t("GO_BACK")}>
          <IconButton onClick={handleGoBack}>
            <ArrowBack />
          </IconButton>
        </InfoToolTip>
        <Typography variant="h6">{t("CREATE_TRIP_AGENT")}</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("AGENT_NAME")}
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    required
                    sx={focusColor}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("DESCRIPTION")}
                    multiline
                    rows={3}
                    fullWidth
                    error={!!errors.description}
                    helperText={errors.description?.message}
                    sx={focusColor}
                  />
                )}
              />
            </Grid>
            {/* Address */}
            <AgentAddressFields countries={countries} cities={cities} regions={regions} />
            <Grid item xs={12} mt={2}>
              <Divider sx={{ width: "100%" }} />
            </Grid>
            {/* Contact */}
            <AgentContactFields />
            <Grid item xs={12} mt={2}>
              <Divider sx={{ width: "100%" }} />
            </Grid>
            {/*  Billing data */}
            <AgentBillingFields />
            {/* Fiscal Address */}
            <AgentFiscalAddressFields countries={countries} cities={cities} regions={regions} />
            {/* Logo */}
            <Grid item xs={12} container spacing={2} mt={2}>
              <Typography variant="body2" sx={{ width: "100%", ml: 2 }}>
                {t("IMAGE_LOGO")}
              </Typography>
              <Grid item xs={12}>
                <ImageUpload
                  onImageSelect={(e) => handleSelectImageReactHook(e, "styles.logo_url", setValue)}
                  url={logoUrl}
                />
              </Grid>
            </Grid>
            {/* Styles */}
            <Grid item xs={12} container spacing={2}>
              <Typography variant="body1" sx={{ fontWeight: "bold", width: "100%", ml: 2 }}>
                {t("PRINCIPAL_STYLES")}
              </Typography>
              <Typography variant="body2" sx={{ width: "100%", ml: 2 }}>
                {t("COLORS_AGENT_DESCRIPTION")}
              </Typography>
              <Grid item xs={12}>
                <Typography variant="body2" fontWeight="bold">
                  {t("PRINCIPAL_COLOR")}
                </Typography>
                <ColorInput
                  value={principalColor}
                  onChange={(e) => setValue("styles.principal_color", e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" fontWeight="bold">
                  {t("TEXT_COLOR")}
                </Typography>
                <ColorInput
                  value={textColor}
                  onChange={(e) => setValue("styles.text_color", e.target.value)}
                />
                <Typography variant="body2" fontWeight="bold">
                  {t("PREVIEW")}
                </Typography>
                <Grid container sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: principalColor,
                      color: textColor,
                      borderRadius: 2,
                      width: 90,
                      "&:hover": {
                        backgroundColor: principalColor,
                        filter: "brightness(90%) !important",
                      },
                    }}
                  >
                    {t("BUY")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* Admin */}
            <AgentAdminFields />
            {/* Contract */}
            <AgentContractFields />
            {/* Action buttons */}
            <Grid item xs={12} container justifyContent="center" gap={2}>
              <Button
                onClick={handleGoBack}
                className="oniria-border-btn"
                size="medium"
                sx={{ borderRadius: 2 }}
                disabled={loadingSubmit}
              >
                {t("CANCEL")}
              </Button>
              <Button
                type="submit"
                className="oniria-btn"
                size="medium"
                sx={{ borderRadius: 2, color: "var(--white)" }}
                disabled={loadingSubmit}
              >
                {loadingSubmit ? t("SAVING") : t("SAVE")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </FormProvider>
  );
};
