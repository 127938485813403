import { DeleteOutline, EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ASSIGNED_TAB } from "../../utils/hotels";
import { getTransportIconById } from "../../constants/utils";

const TransportsGrid = ({ data, handleEdit, handleSee, handleOpenDelete, selectedTab }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} mb={2}>
        {data?.map((transport) => (
          <Grid item xs={12} sm={6} md={4} key={transport.id}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                borderRadius: "20px",
                backgroundColor: transport.is_active === 0 ? "var(--grey-cancelled)" : "white",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1, padding: 1, gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {getTransportIconById(transport.type)}
                  <Typography>{t(transport.type?.toUpperCase())}</Typography>
                </Box>
                <CardContent sx={{ flex: 1, paddingLeft: 2 }}>
                  <Typography variant="h6" component="div">
                    {transport.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mb={1}>
                    {transport.sku}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {transport.places}: {t("ASSIGNED_PLACES")}
                  </Typography>
                </CardContent>
              </Box>
              <CardActions disableSpacing sx={{ justifyContent: "center", gap: 2 }}>
                {selectedTab === ASSIGNED_TAB && (
                  <Tooltip title={t("SEE_EVENTS")}>
                    <IconButton onClick={() => handleSee(transport.id)}>
                      <RemoveRedEyeOutlined sx={{ color: "var(--oniria-gold)" }} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={t("EDIT_TRANSPORT")}>
                  <IconButton onClick={() => handleEdit(transport.id)}>
                    <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("DELETE_TRANSPORT")}>
                  <IconButton onClick={() => handleOpenDelete(transport.id)}>
                    <DeleteOutline sx={{ color: "var(--oniria-gold)" }} />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TransportsGrid;
