import { VisibilityOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PAYMENT_METHODS } from "../../../constants/orders";
import { PAGINATION_STYLES } from "../../../constants/styles";
import { formatCurrency, formatDate } from "../../../constants/utils";
import { useQueryEventB2bSalesAgent } from "../../../hooks/queries/b2b/useQueryB2bEventSalesAgent";
import useDebounce from "../../../hooks/useDebouncing";
import usePagination from "../../../hooks/usePagination";
import { Loading } from "../../shared/Loading";
import { focusColor } from "../../shared/textFieldStyle";
import SearchFilter from "../filters/filter/SearchFilter";

export const AgentSales = ({
  agent,
  setOpenSettleAllDialog,
  setShowDetailOrder,
  setOpenPayCommissionDialog,
  eventId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { page, perPage, changePage } = usePagination(5);
  const [filters, setFilters] = useState({
    search: "",
    payment_type: null,
    fromDate: null,
    toDate: null,
    order: { asc: [], desc: ["created_at"] },
  });

  const { name = "", domain_name = "" } = agent;

  const { data, isLoading, isRefetching } = useQueryEventB2bSalesAgent(
    eventId,
    domain_name,
    page,
    perPage,
    filters
  );
  const { data: sales = [], total = 0 } = data || {};
  console.log("eventB2bSales data", data);
  console.log("el agent", agent);

  const isSalePayed = (saleId, settled_payments) => {
    return settled_payments?.some((payment) => payment.ticket_id === saleId);
  };

  const getSaleStatus = () => {
    const salesLength = sales?.length || 0;
    const settlePaymentsLength = agent.settled_payments?.length || 0;

    if (salesLength === 0) return "";

    if (settlePaymentsLength === 0) {
      return { label: t("PENDING"), color: "var(--oniria-red)" };
    } else if (salesLength === settlePaymentsLength) {
      return { label: t("PAID"), color: "var(--green-success)" };
    } else if (salesLength > settlePaymentsLength) {
      return { label: t("IN_PROCESS"), color: "var(--orange-error)" };
    }
  };

  const whoIsTheCollector = (payment_type) => {
    if (!payment_type) return "";
    if (payment_type.includes("office")) {
      return "agent";
    } else {
      return "operator";
    }
  };

  const handleSearchChange = (event) => {
    const search = event.target.value;
    setFilters((prev) => ({ ...prev, search: search }));
  };
  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleChange = (e, name) => {
    setFilters((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const totalPages = Math.ceil(total / perPage);

  return (
    <Grid
      key={`agent-${domain_name}`}
      component={Paper}
      elevation={3}
      sx={{ mt: 2, borderRadius: "10px", marginBottom: "20px" }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "var(--grey-cancelled)",
          padding: 2,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <Grid item xs={12} sm={4}>
          <Typography>
            {t("SELLER")}: {name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography>
            {t("SOLD_PLACES")}: {total || 0}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} container flexDirection="row" gap={2} alignItems="center">
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography>{t("STATUS")}:</Typography>
            <Typography sx={{ color: getSaleStatus(agent)?.color }}>
              {getSaleStatus(agent)?.label}
            </Typography>
          </Box>
          {getSaleStatus(agent)?.label !== t("PAID") && (
            <Button
              className="oniria-btn"
              sx={{ width: "fit-content", color: "var(--white)", textTransform: "none" }}
              size="small"
              onClick={() =>
                setOpenSettleAllDialog({
                  open: true,
                  agent,
                })
              }
            >
              {t("SETTLE_ALL")}
            </Button>
          )}
        </Grid>
      </Grid>
      {/* Filters */}
      <Grid container sx={{ padding: 2, justifyContent: "space-between" }}>
        <Grid item xs={12} sm={6} container>
          <Grid item>
            <SearchFilter onSearchChange={debounceSearch} />
          </Grid>
          {/* Payment Type */}
          <Grid item>
            <FormControl sx={[focusColor, { minWidth: 160 }]} size="small">
              <InputLabel id="demo-simple-select-label">{t("PAYMENT_TYPE")}</InputLabel>
              <Select
                value={filters.payment_type}
                label={t("ALL")}
                onChange={handleChange}
                name="payment_type"
                sx={{ fontSize: "12px", borderRadius: 3 }}
              >
                <MenuItem value="">{t("ALL")}</MenuItem>
                {PAYMENT_METHODS.map((method) => (
                  <MenuItem key={method.id} value={method.id}>
                    {t(method.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item>
          <Pagination count={totalPages} page={page} onChange={changePage} sx={PAGINATION_STYLES} />
        </Grid>
      </Grid>

      {isLoading || isRefetching ? (
        <Loading />
      ) : total > 0 ? (
        <Grid sx={{ padding: 2 }}>
          <TableContainer component={Paper} elevation={3} sx={{ borderRadius: "10px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t("USER_NAME")}</TableCell>
                  <TableCell align="center">{t("TICKET_NAME")}</TableCell>
                  <TableCell align="center">{t("PAYMENT_TYPE")}</TableCell>
                  <TableCell align="center">{t("COLLECTOR")}</TableCell>
                  <TableCell align="center">{t("RESERVE_DATE")}</TableCell>
                  <TableCell align="center">{t("NET_PRICE")}</TableCell>
                  <TableCell align="center">{t("MARGIN")}</TableCell>
                  <TableCell align="center">{t("STATUS")}</TableCell>
                  <TableCell align="center">{t("SEE_DETAILS")}</TableCell>
                  <TableCell align="center">{t("PAY")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sales?.map((row, rowIndex) => (
                  <TableRow
                    key={`${row.name}-${rowIndex}`}
                    sx={{
                      backgroundColor:
                        whoIsTheCollector(row.payment_type) === "operator"
                          ? "var(--blue-background)"
                          : "var(--yellow-background)",
                    }}
                  >
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={() => navigate(`/users/${row.user_id}`)}
                    >
                      {row.user_name}
                    </TableCell>
                    <TableCell align="center">{row.ticket_name}</TableCell>
                    <TableCell align="center">{t(row.payment_type?.toUpperCase())}</TableCell>
                    <TableCell align="center">
                      {t(whoIsTheCollector(row.payment_type)?.toUpperCase())}
                    </TableCell>
                    <TableCell align="center">{formatDate(row.created_at)}</TableCell>
                    <TableCell align="center">{formatCurrency(row.amount)}</TableCell>
                    <TableCell align="center">
                      {agent.commission_percentage
                        ? `${agent.commission_percentage}%`
                        : agent.commission_fixed
                        ? `${agent.commission_fixed} €`
                        : null}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: isSalePayed(row.ticket_id, agent.settled_payments)
                          ? "var(--green-success)"
                          : "var(--oniria-red)",
                      }}
                    >
                      {isSalePayed(row.ticket_id, agent.settled_payments)
                        ? whoIsTheCollector(row.payment_type) === "operator"
                          ? t("REALIZED_PAYMENT")
                          : t("REALIZED_CHARGED")
                        : whoIsTheCollector(row.payment_type) === "operator"
                        ? t("PENDING_PAYMENT")
                        : t("PENDING_CHARGED")}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => setShowDetailOrder({ order_id: row.order_id, open: true })}
                      >
                        <VisibilityOutlined sx={{ color: "var(--oniria-gold)" }} />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      {isSalePayed(row.ticket_id, agent.settled_payments) ? (
                        <Typography variant="body2">{t("SETTLED")}</Typography>
                      ) : (
                        <Button
                          variant="text"
                          className="underline-button"
                          onClick={() =>
                            setOpenPayCommissionDialog({
                              open: true,
                              event_id: eventId,
                              premise_agent_id: agent.id,
                              payment_type: row.payment_type,
                              net_product: row.amount,
                              margin: row.commission,
                              ticket_id: row.ticket_id,
                            })
                          }
                        >
                          {t("SETTLE")}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Typography variant="caption" sx={{ color: "#000" }}>
          {t("NO_DATA_AVAILABLE")}
        </Typography>
      )}
    </Grid>
  );
};
