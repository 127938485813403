import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getEventsRequests } from "../../../services/utilsServices";

export function useQueryEventsRequests(page, perPage, filters) {
  return useQuery(
    [RQ_KEY.EVENTS_REQUESTS, filters, page, perPage],
    () => getEventsRequests(page, perPage, filters),
    {
      refetchOnWindowFocus: false,
    }
  );
}
