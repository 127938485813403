import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  getOrderAmount,
  getOrderCommission,
  getOrderPremiseCommission,
  getOrderTotalAmount,
} from "../../../constants/orders";
import { formatCurrency, formatDate } from "../../../constants/utils";
import { renderExtrasOrderDetail, renderExtrasPrice } from "./IndividualCells";

export const OrderPaymentInfo = ({ order }) => {
  const { t } = useTranslation();

  const {
    tickets = [],
    extras = [],
    order_type,
    amount,
    payed_at,
    first_payment_payed_at,
    payments = [],
  } = order || {};

  const finalExtrasPrice = useMemo(() => {
    return extras.reduce((acc, extra) => acc + parseFloat(extra.extra_final_payed_price || 0), 0);
  }, [extras]);

  const hasPendingPayment = useMemo(
    () => first_payment_payed_at && !payed_at,
    [first_payment_payed_at, payed_at]
  );

  return (
    <Card sx={{ borderRadius: 3, height: "100%" }}>
      <CardHeader title={t("PAYMENTS")} sx={{ backgroundColor: "var(--grey-background)" }} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <TableContainer sx={{ padding: "0px 5px" }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ borderBottom: "2px solid lightgrey" }}>
                      <TableCell>{t("ORDER")}</TableCell>
                      <TableCell></TableCell>
                      <TableCell>{t("DISCOUNT")}</TableCell>
                      <TableCell>{t("PAYMENT_DATE")}</TableCell>
                      <TableCell>{t("PRICE")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tickets.map((ticket, index) => {
                      const extrasPrice = finalExtrasPrice;

                      const discounts = [
                        { key: "EARLY_PAYMENT_DISCOUNT", value: ticket?.early_payment_discount },
                        { key: "INTERNATIONAL_DISCOUNT", value: ticket?.international_discount },
                        { key: "GROUP_DISCOUNT", value: ticket?.group_discount },
                        {
                          key: "PROMOTIONAL_CODE_DISCOUNT",
                          value: ticket?.promotional_code_discount,
                        },
                      ].filter((discount) => discount.value);
                      return (
                        <Fragment key={index}>
                          <TableRow>
                            <TableCell colSpan={4}>{ticket.ticket_name}</TableCell>
                            <TableCell>{ticket.ticket_price}</TableCell>
                          </TableRow>

                          {discounts.map((discount, i) => (
                            <TableRow key={i}>
                              <TableCell colSpan={2} />
                              <TableCell>{t(discount.key)}</TableCell>
                              <TableCell />
                              <TableCell>{"- " + discount.value}</TableCell>
                            </TableRow>
                          ))}

                          {ticket.extras.length > 0 && (
                            <TableRow>
                              <TableCell />
                              <TableCell>
                                <ListItem sx={{ pl: 0 }}>
                                  <ListItemText
                                    secondary={renderExtrasOrderDetail(ticket.extras)}
                                  />
                                </ListItem>
                              </TableCell>
                              <TableCell colSpan={2} />
                              <TableCell>
                                <ListItem sx={{ pl: 0 }}>
                                  <ListItemText secondary={renderExtrasPrice(ticket.extras)} />
                                </ListItem>
                              </TableCell>
                            </TableRow>
                          )}

                          {tickets.length > 1 && (
                            <TableRow sx={{ borderBottom: "1px solid lightgrey" }}>
                              <TableCell sx={{ fontWeight: 600 }} colSpan={4}>
                                {t("TOTAL_TICKET_PRICE")}
                              </TableCell>
                              <TableCell>
                                {parseInt(ticket.final_payed_price || 0) + extrasPrice}€
                              </TableCell>
                            </TableRow>
                          )}
                        </Fragment>
                      );
                    })}
                    {extras.map((extra, index) => (
                      <TableRow key={index}>
                        <TableCell />
                        <TableCell sx={{ fontWeight: "bolder", textWrap: "nowrap" }} colSpan={3}>
                          {extra.extra_name}
                        </TableCell>
                        <TableCell sx={{ textWrap: "nowrap" }}>
                          {extra.extra_final_payed_price} €
                        </TableCell>
                      </TableRow>
                    ))}
                    {order_type === "card" && (
                      <TableRow>
                        <TableCell colSpan={4}>{t("MEMBER_CARD")}</TableCell>
                        <TableCell>{amount}€</TableCell>
                      </TableRow>
                    )}
                    {/* Resume */}
                    {payments.length > 0 &&
                      payments
                        ?.filter((payment) => Boolean(payment.payed_at))
                        .sort((a, b) => Number(a.position) - Number(b.position))
                        .map((payment, index) => (
                          <TableRow key={payment.id ?? `payment-${payment.position}`}>
                            <TableCell colSpan={3} sx={{ border: "none" }} />
                            <TableCell
                              sx={{ fontWeight: "bolder", textWrap: "nowrap", border: "none" }}
                            >
                              {t("PAYMENT")} {index + 1} - {formatDate(payment.payed_at)}
                            </TableCell>
                            <TableCell sx={{ textWrap: "nowrap", border: "none" }}>
                              {formatCurrency(payment.amount)}
                            </TableCell>
                          </TableRow>
                        ))}

                    <TableRow sx={{ borderTop: "2px solid lightgrey" }}>
                      <TableCell colSpan={3} sx={{ border: "none" }} />
                      <TableCell sx={{ fontWeight: "bolder", textWrap: "nowrap", border: "none" }}>
                        {t("TOTAL_ORDER")}
                      </TableCell>
                      <TableCell sx={{ textWrap: "nowrap", border: "none" }}>
                        {formatCurrency(getOrderAmount(order))}
                      </TableCell>
                    </TableRow>
                    {/* Commissions */}
                    <TableRow>
                      <TableCell colSpan={3} sx={{ border: "none" }} />
                      <TableCell sx={{ border: "none" }}>
                        {t("COMMISION_SERVICE")}(oniria)
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        {formatCurrency(getOrderCommission(order))}
                      </TableCell>
                    </TableRow>
                    {getOrderPremiseCommission(order) > 0 ? (
                      <TableRow>
                        <TableCell colSpan={3} sx={{ border: "none" }} />
                        <TableCell sx={{ border: "none" }}>
                          {t("PREMISE_COMMISSIONS")}({t("PREMISE")})
                        </TableCell>
                        <TableCell sx={{ border: "none" }}>
                          {formatCurrency(getOrderPremiseCommission(order))}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    <TableRow>
                      <TableCell colSpan={3} sx={{ border: "none" }} />
                      <TableCell
                        sx={{ fontWeight: "bolder", textTransform: "uppercase", border: "none" }}
                      >
                        {t("TOTAL")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: hasPendingPayment ? "" : "bolder",
                          textWrap: "nowrap",
                          border: "none",
                        }}
                      >
                        {formatCurrency(getOrderTotalAmount(order))}
                      </TableCell>
                    </TableRow>
                    {/* End Resume */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
