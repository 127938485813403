import { Box, Grid, Typography } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { AssignedExtraCard } from "../assignToTrip/AssignedExtraCard";

export const ExtrasAssignZone = ({ tripInfo = {}, handleRemove, type }) => {
  const { t } = useTranslation();

  const { extras = [] } = tripInfo;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Droppable droppableId={`extra-insurance`} direction="horizontal">
          {(provided, snapshot) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mb: 1,
                backgroundColor: snapshot.isDraggingOver ? "#e0e0e0" : "#fff",
                borderRadius: 2,
                padding: 2,
              }}
            >
              {extras
                ?.filter((extra) => extra?.tags?.includes(type) || !extra?.tags)
                ?.map((extra, index) => (
                  <AssignedExtraCard
                    key={`extra-${extra.id || index}`}
                    extra={extra}
                    handleClose={(e) => handleRemove(e, extra.position)}
                  />
                ))}
              <Box
                sx={{
                  border: "1px dashed",
                  width: "100%",
                  textAlign: "center",
                  padding: 2,
                  borderRadius: 2,
                }}
              >
                <Typography variant="caption">{t("DRAG_INSURANCE_HERE")}</Typography>
              </Box>
              {provided.placeholder}{" "}
            </Box>
          )}
        </Droppable>
      </Grid>
    </Grid>
  );
};
