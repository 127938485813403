import { ArrowForward, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { ABECEDARY } from "../../../constants/variables";
import AssignedTransportCard from "../assignToTrip/AssignedTransportCard";

export const TransportsAssignZone = ({ itineraries = [], handleRemove }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Accordion sx={{ boxShadow: "none", "&:before": { display: "none" } }} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>{t("TRANSPORTS")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {itineraries.map((itinerary, itineraryIndex) => {
              if (itineraryIndex < itineraries.length - 1) {
                const nextItinerary = itineraries[itineraryIndex + 1];
                return (
                  <Grid key={`droppable-${itinerary.id || itineraryIndex}`} item xs={12}>
                    <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
                      <Typography sx={{ mb: 2 }}>
                        {ABECEDARY[itineraryIndex]}: {itinerary.title}
                      </Typography>
                      <ArrowForward />
                      <Typography sx={{ mb: 2 }}>
                        {ABECEDARY[itineraryIndex + 1]}:{nextItinerary.title}
                      </Typography>
                    </Box>

                    <Droppable
                      droppableId={`${itinerary.id || itineraryIndex}+${
                        nextItinerary.id || itineraryIndex + 1
                      }`}
                      direction="horizontal"
                    >
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            mb: 1,
                            backgroundColor: snapshot.isDraggingOver ? "#e0e0e0" : "#fff",
                            borderRadius: 2,
                            padding: 2,
                          }}
                        >
                          {itinerary.transport_ids
                            ?.filter((transportId) =>
                              nextItinerary.transport_ids?.includes(transportId)
                            )
                            .map((transportId) => (
                              <AssignedTransportCard
                                key={`transport-card-${transportId}-${
                                  itinerary.id || itineraryIndex
                                }`}
                                transportId={transportId}
                                handleClose={() =>
                                  handleRemove(
                                    transportId,
                                    itinerary.id || itineraryIndex,
                                    nextItinerary.id || itineraryIndex + 1
                                  )
                                }
                              />
                            ))}
                          <Box
                            sx={{
                              border: "1px dashed",
                              width: "100%",
                              textAlign: "center",
                              padding: 2,
                              borderRadius: 2,
                            }}
                          >
                            <Typography variant="caption">{t("DRAG_TRANSPORT_HERE")}</Typography>
                          </Box>
                          {provided.placeholder}{" "}
                        </Box>
                      )}
                    </Droppable>
                  </Grid>
                );
              }
              return null;
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
};
