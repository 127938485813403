import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const FormHeader = ({
  title,
  description,
  children,
  isAccordion = false,
  backgroundColor = "var(--grey-background)",
  ticketPassedDate = false,
}) => {
  const { t } = useTranslation();

  return isAccordion ? (
    <Grid container spacing={2} pb={2} mb={6}>
      <Accordion
        component={Paper}
        elevation={3}
        sx={{
          width: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize="large" />}
          sx={{
            backgroundColor: backgroundColor,
            width: "100%",
            paddingTop: 1,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "95%" }}>
            <Box>
              <Typography variant="h5" fontWeight="bolder" mb={1}>
                {title}
              </Typography>
              <Typography variant="body1">{description}</Typography>
            </Box>
            {ticketPassedDate && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "var(--oniria-red)",
                    border: "2px solid var(--oniria-red)",
                    padding: "2px 4px",
                    borderRadius: "4px",
                    fontWeight: "700",
                    textTransform: "uppercase",
                  }}
                >
                  {t("OLD_DATE")}
                </Typography>
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container padding={2} spacing={2}>
            {children}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  ) : (
    <Grid component={Paper} container spacing={2} borderRadius={3} pb={2} mb={6}>
      <Grid
        sx={{
          mb: 3,
          backgroundColor: backgroundColor,
          width: "100%",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          padding: 3,
        }}
      >
        <Typography variant="h5" fontWeight="bolder" mb={1}>
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Grid>
      <Grid container padding={2} spacing={2}>
        {children}
      </Grid>
    </Grid>
  );
};
