import { DialogTitle, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import HotelForm from "../HotelForm";
import { CloseDialogButton } from "../../shared/CloseDialogButton";
import { useTranslation } from "react-i18next";

function CreateHotelDialog({ isOpen, onClose }) {
  const { t } = useTranslation();

  const handleGoBack = () => {
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <CloseDialogButton onClose={onClose} />
      <DialogTitle sx={{ fontWeight: "bold", backgroundColor: "var(--grey-background)" }}>
        {t("CREATE_HOTEL")}
      </DialogTitle>
      <Grid container sx={{ padding: 2 }}>
        <HotelForm goBack={handleGoBack} isFromDialog />
      </Grid>
    </Dialog>
  );
}

export default CreateHotelDialog;
