import { Warning } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatCurrency, formatDate, sanitizeBoolean } from "../../../constants/utils";

export const B2BCatalogCard = ({ trip, isClientView }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    end_date,
    id,
    multi_date,
    name,
    premise_name,
    principal_url,
    start_date,
    days = 0,
    pvp,
    agreements,
    approved_agreements,
  } = trip;

  const handleSee = () => {
    navigate(`/b2b/catalog-trip/${id}?agreements=${agreements}`);
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
        height: "90%",
        m: 2,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        borderRadius: 3,
      }}
    >
      {/* B2B TRIANGLE */}
      {!isClientView && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            borderLeft: "100px solid",
            borderLeftColor:
              sanitizeBoolean(agreements) && sanitizeBoolean(approved_agreements)
                ? "var(--green-success)"
                : sanitizeBoolean(agreements) && !sanitizeBoolean(approved_agreements)
                ? "var(--orange-parcial)"
                : "var(--secondary-color)",
            borderBottom: "85px solid transparent",
            zIndex: 1,
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              top: "25px",
              left: "-105px",
              color: "var(--white)",
              transform: "rotate(-40deg)",
              fontWeight: 400,
              textTransform: "uppercase",
            }}
          >
            {sanitizeBoolean(agreements) && sanitizeBoolean(approved_agreements)
              ? t("AVAILABLE")
              : sanitizeBoolean(agreements) && !sanitizeBoolean(approved_agreements)
              ? t("PENDING")
              : t("CONTACT_VERB")}
          </Typography>
        </Box>
      )}
      <CardMedia component="img" height="140" image={principal_url} alt={name} />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6">{name}</Typography>
        {!isClientView && (
          <Typography variant="body2" mb={1}>
            {premise_name}
          </Typography>
        )}
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {sanitizeBoolean(multi_date) ? (
            <>
              {t("MULTI_DATE")} {days > 0 ? `, ${days} ${t("DAYS")}` : ""}
            </>
          ) : (
            <>
              {formatDate(start_date)} - {formatDate(end_date)}
            </>
          )}
        </Typography>
        <Typography variant="h6" sx={{ textTransform: "initial" }}>
          {t("Pvp")} {formatCurrency(pvp)}
        </Typography>
        <Box sx={{ display: "flex", mt: 1 }}>
          {!sanitizeBoolean(agreements) && !isClientView && (
            <>
              <Warning color="error" />
              <Typography variant="body2" color="error">
                {t("NEED_AGENCIE_CONTACT")}
              </Typography>
            </>
          )}
        </Box>
      </CardContent>
      <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
        <Button
          className="oniria-border-btn"
          sx={{ color: "var(--white)", width: "90%", textTransform: "capitalize" }}
          size="small"
          onClick={handleSee}
        >
          {t("SEE_DETAILS")}
        </Button>
      </Box>
    </Card>
  );
};
