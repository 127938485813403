import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack } from "@mui/icons-material";
import { Button, Divider, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import InfoToolTip from "../../components/shared/InfoToolTip";
import { ROUTES } from "../../constants/routes";
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from "../../constants/variables";
import useQueryHotelDetails from "../../hooks/queries/hotels/useQueryHotelDetails";
import useQueryTourPointsList from "../../hooks/queries/tourPoints/useQueryTourPointsList";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import { defaultHotelLanguageInfo, hotelSchema } from "../../schemas/hotels";
import { assignHotelToDomains, createHotel, editHotel } from "../../services/hotelServices";
import { prepareHotelToReceive, prepareHotelToSend } from "../../utils/hotels";
import LanguageSelect from "../shared/languages/LanguageSelect";
import LanguageTabs from "../shared/languages/LanguageTabs";
import { Loading } from "../shared/Loading";
import PdfUpload from "../shared/PdfUpload";
import SelectHookForm from "../shared/SelectHookForm";
import { focusColor } from "../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import TextEditor from "../textEditor/TextEditor";
import HotelExtras from "./hotelExtras/HotelExtras";
import HotelImages from "./HotelImages";
import HotelRooms from "./hotelRooms/HotelRooms";
import HotelRss from "./HotelRss";
import { StyledAccordion } from "../shared/StyledAccordion";
import { FormFieldHeader } from "../tripPlus/FormFieldHeader";

// TODO: Galeria y preview

const HotelForm = ({ isForTrip, goBack, isFromDialog }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [languageTab, setLanguageTab] = useState(DEFAULT_LANGUAGE);

  const { data: tourPoints = [] } = useQueryTourPointsList();

  const orderTourPoints = tourPoints.sort((a, b) => a.name.localeCompare(b.name));

  const formMethods = useForm({
    resolver: zodResolver(hotelSchema),
    mode: "onBlur",
    defaultValues: {
      default_language: DEFAULT_LANGUAGE,
    },
  });

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = formMethods;

  const handleSetFormData = async (data) => {
    const newData = await prepareHotelToReceive(data);
    reset(newData);
  };
  const { isLoading, isRefetching } = useQueryHotelDetails(id, handleSetFormData, isForTrip);

  const handlePremisesSuccess = (data) => {
    if (data.length === 1) {
      setValue("owner_premise_id", data[0].id);
    }
  };

  const handleDomainsSuccess = (data) => {
    if (data.length === 1) {
      setValue("domains_ids", [data[0].domain_id]);
    }
  };

  const { data: premises = [] } = useQueryPremisesList("", "", handlePremisesSuccess);
  const { data: domains = [] } = useQueryDomains(handleDomainsSuccess);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setLoadingSubmit(true);
    try {
      const bodyToSend = await prepareHotelToSend(data, isForTrip);
      if (id) {
        // EDIT HOTEL
        await editHotel(id, bodyToSend, isForTrip);
        if (bodyToSend.domains_ids && bodyToSend.domains_ids.length > 0) {
          await assignHotelToDomains(id, bodyToSend.domains_ids, isForTrip);
        }
      } else {
        // NEW HOTEL
        const response = await createHotel(bodyToSend, isForTrip);
        if (response.id && bodyToSend.domains_ids && bodyToSend.domains_ids.length > 0) {
          await assignHotelToDomains(response.id, bodyToSend.domains_ids, isForTrip);
        }
      }
      setTimeout(() => {
        handleGoBack();
      }, 1500);
      toastMessageSuccess(t("CREATED_SUCCESSFULLY"));
    } catch (e) {
      console.log("Error al crear hotel", e);
      toastMessageError(e?.response?.data?.error || t("ERROR"));
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onError = (errors) => {
    console.log("Errores formulario hotel", errors);
  };

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigate(ROUTES.HOTELS);
    }
  };

  const handleChangeDescription = (data) => {
    setValue("description", data);
  };

  const handleChangeCheckbox = (isChecked, value) => {
    const newLanguage = JSON.parse(JSON.stringify(defaultHotelLanguageInfo));
    const languages = getValues("hotel_language_infos") || [];
    if (isChecked) {
      newLanguage.language = value;
      setValue("hotel_language_infos", [...languages, newLanguage]);
    } else {
      setValue(
        "hotel_language_infos",
        languages.filter((language) => language?.language !== value)
      );
    }
  };

  const handleChangePdf = (value) => {
    setValue("pdf_info", value);
  };

  const defaultLanguage = watch("default_language");
  const description = watch("description");
  const hotelTranslations = watch("hotel_language_infos") || [];
  const translationIndex = hotelTranslations.findIndex((tour) => tour.language === languageTab);

  const handleChangeLanguageDescription = (data) => {
    setValue(`hotel_language_infos[${translationIndex}].description`, data);
  };
  const handleChangePdfLanguage = (value) => {
    setValue(`hotel_language_infos[${translationIndex}].pdf_info`, value);
  };

  useEffect(() => {
    if (defaultLanguage) {
      setLanguageTab(defaultLanguage);
    }
  }, [defaultLanguage]);

  return (
    <Grid mb={5} mt={isFromDialog ? 0 : 7}>
      {!isFromDialog && (
        <>
          <IconButton onClick={handleGoBack}>
            <ArrowBack />
          </IconButton>
          <Grid container mb={5} mt={2}>
            <Typography variant="h5">
              {!!id ? t("EDIT") : t("CREATE")} {t("HOSTING_INDIVIDUAL")}{" "}
              {isForTrip ? t("FOR_TRIP") : ""}
            </Typography>
          </Grid>
        </>
      )}
      {isLoading || isRefetching ? (
        <Loading />
      ) : (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <h3>{t("TOURPOINT_MULTILANG")}</h3>
            <p>{t("SELECT_LANGUAGES_INFO")}</p>
            <Grid container spacing={4}>
              <Grid item xs={12} mt={1}>
                <Grid mb={2}>
                  <SelectHookForm
                    name="default_language"
                    control={control}
                    label={t("DEFAULT_LANGUAGE")}
                    required
                    error={errors?.default_language}
                    defaultValue={""}
                    options={AVAILABLE_LANGUAGES.map((lang) => (
                      <MenuItem key={lang.id} value={lang.id}>
                        {t(lang.name)}
                      </MenuItem>
                    ))}
                  />
                </Grid>
                <LanguageSelect
                  translatedFields={watch("hotel_language_infos")}
                  handleChangeCheckbox={handleChangeCheckbox}
                  defaultLanguage={defaultLanguage}
                />
                {hotelTranslations.length > 0 ? (
                  <LanguageTabs
                    languagesInfo={hotelTranslations}
                    selectedLanguage={languageTab}
                    setSelectedLanguage={setLanguageTab}
                    defaultLanguage={defaultLanguage}
                  />
                ) : null}
              </Grid>

              {/* PRINCIPAL INFO */}
              <Grid item xs={12}>
                <FormFieldHeader
                  title={t("DESTINATION")}
                  description={t("TOUR_POINT_DESCRIPTION")}
                />
                <SelectHookForm
                  name={"tour_point_id"}
                  control={control}
                  label={t("TOUR_POINT")}
                  error={errors.tour_point_id}
                  options={[{ id: null, name: t("NONE") }, ...orderTourPoints].map((point) => (
                    <MenuItem key={point.id} value={point.id}>
                      {point.name}
                    </MenuItem>
                  ))}
                />
              </Grid>
              {/* NAME */}
              <Grid item xs={12}>
                <FormFieldHeader title={t("HOTEL_NAME")} />
                {languageTab === defaultLanguage ? (
                  <TextField
                    {...register("name")}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    required
                    fullWidth
                    sx={focusColor}
                    placeholder={t("NAME")}
                  />
                ) : (
                  <>
                    <TextField
                      {...register(`hotel_language_infos[${translationIndex}].name`)}
                      error={!!errors.hotel_language_infos?.[translationIndex]?.name}
                      helperText={errors.hotel_language_infos?.[translationIndex]?.name?.message}
                      fullWidth
                      sx={focusColor}
                      placeholder={t("NAME")}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ width: "100%" }} />
              </Grid>
              {domains && domains.length === 1 ? null : (
                <Grid item xs={12}>
                  <FormFieldHeader title={t("DOMAINS")} />
                  <SelectHookForm
                    name={"domains_ids"}
                    control={control}
                    placeholder={t("DOMAINS")}
                    required={true}
                    error={errors.domains_ids}
                    multiple
                    defaultValue={[]}
                    options={domains.map((domain) => (
                      <MenuItem key={domain.domain_id} value={domain.domain_id}>
                        {domain.domain_name}
                      </MenuItem>
                    ))}
                  />
                </Grid>
              )}
              {premises && premises.length === 1 ? null : (
                <Grid item xs={12}>
                  <FormFieldHeader title={t("PREMISE")} />
                  <SelectHookForm
                    name={"owner_premise_id"}
                    control={control}
                    placeholder={t("PREMISE")}
                    required={true}
                    error={errors.owner_premise_id}
                    options={premises.map((premise) => (
                      <MenuItem key={premise.id} value={premise.id}>
                        {premise.name}
                      </MenuItem>
                    ))}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <StyledAccordion title={t("ADVANCED_OPTIONS")}>
                  <Grid container>
                    <HotelImages />
                    {/* SKU */}
                    <Grid item xs={12} mt={2}>
                      <Typography>
                        <Typography>{t("SKU_OPTIONAL")}</Typography>
                      </Typography>
                      <TextField
                        placeholder={t("SKU")}
                        {...register("sku")}
                        required
                        error={!!errors.sku}
                        helperText={errors.sku?.message}
                        fullWidth
                        sx={focusColor}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormFieldHeader
                        title={t("SHORT_DESCRIPTION_OPTIONAL")}
                        titleStyle={{ fontWeight: "0" }}
                        description={t("SHORT_DESCRIPTION_HOSTING")}
                        descriptionStyle={{ fontSize: "0.85rem" }}
                      />
                      {languageTab === defaultLanguage ? (
                        <TextField
                          placeholder={t("SHORT_DESCRIPTION_TEXT")}
                          {...register("short_description")}
                          error={!!errors.short_description}
                          helperText={errors.short_description?.message}
                          fullWidth
                          sx={focusColor}
                        />
                      ) : (
                        <>
                          <TextField
                            {...register(
                              `hotel_language_infos[${translationIndex}].short_description`
                            )}
                            error={
                              !!errors.hotel_language_infos?.[translationIndex]?.short_description
                            }
                            helperText={
                              errors.hotel_language_infos?.[translationIndex]?.short_description
                                ?.message
                            }
                            fullWidth
                            sx={focusColor}
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>{t("DESCRIPTION_OPTIONAL")}</Typography>
                      {languageTab === defaultLanguage ? (
                        <TextEditor
                          data={description}
                          id={`hotel_description_${id}`}
                          setData={handleChangeDescription}
                        />
                      ) : (
                        <>
                          <TextEditor
                            data={hotelTranslations[translationIndex]?.description}
                            id={`hotel_description_${id}_${translationIndex}`}
                            setData={handleChangeLanguageDescription}
                          />
                        </>
                      )}
                    </Grid>
                    {/* DIRECCION */}
                    <Grid item xs={12}>
                      <Typography>{t("ADDRESS_OPTIONAL")}</Typography>
                      <TextField
                        placeholder={t("ADDRESS")}
                        {...register("address")}
                        error={!!errors.address}
                        helperText={errors.address?.message}
                        fullWidth
                        sx={focusColor}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ mb: 2, mt: 2 }}>
                        {t("HOTEL_STARS")}
                        <InfoToolTip text={t("HOTEL_RATING")} />
                      </Typography>
                      <TextField
                        placeholder={t("HOTEL_STARS")}
                        {...register("stars")}
                        error={!!errors.stars}
                        helperText={errors.address?.stars}
                        fullWidth
                        sx={focusColor}
                        type="number"
                        inputProps={{ min: 0, max: 5 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ mb: 2, mt: 2 }}>
                        {t("PDF_INFORMATIVE")}
                        <InfoToolTip text={t("HOTEL_PDF_INFO")} />
                      </Typography>
                      {languageTab === defaultLanguage ? (
                        <PdfUpload value={watch("pdf_info")} onChange={handleChangePdf} />
                      ) : (
                        <>
                          <PdfUpload
                            value={watch(`hotel_language_infos[${translationIndex}].pdf_info`)}
                            onChange={handleChangePdfLanguage}
                          />
                        </>
                      )}
                    </Grid>
                    <HotelRss />
                  </Grid>
                </StyledAccordion>
              </Grid>

              {isForTrip && (
                <>
                  <HotelRooms />
                  <HotelExtras />
                </>
              )}
              <Grid item xs={12} container justifyContent="center" gap={2}>
                <Button
                  onClick={handleGoBack}
                  className="oniria-btn-cancel"
                  variant="contained"
                  size="medium"
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  {t("CANCEL")}
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  className="oniria-btn"
                  disabled={loadingSubmit}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                  onClick={(e) => onSubmit(getValues(), e)}
                >
                  {loadingSubmit ? t("SAVING") : t("SAVE")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
    </Grid>
  );
};

export default HotelForm;
