import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { RQ_KEY } from "../constants/query";
import { getAllAssistants } from "../services/eventsServices";

export const useDownloadAssistants = (eventId, selectedDomain, filters, enableDownload) => {
  const { t } = useTranslation();

  const { data: { data: assistants = [] } = {}, isSuccess } = useQuery(
    [RQ_KEY.ASSISTANTS_EVENT_ID, eventId, selectedDomain, filters],
    () => getAllAssistants(eventId, selectedDomain, "", "", filters),
    {
      refetchOnWindowFocus: false,
      enabled: enableDownload,
    }
  );

  // START PDF WITH ESSENTIAL INFORMATION
  const headerPdf = [
    t("NAME"),
    t("SURNAME"),
    t("CUSTOMER_PHONECODE"),
    t("CUSTOMER_PHONE"),
    t("EMAIL"),
    t("DOMAIN"),
    t("TICKET"),
    t("EXTRAS"),
    t("STATUS"),
  ];

  const isOrderPayed = (assistant) => {
    if (assistant.payments && assistant.payments.length > 0) {
      return assistant.payments.every((payment) => !!payment.payed_at);
    }
    return !!assistant.payed_at;
  };

  const tableDataPdf = assistants
    ?.filter((item) => !item.refunded_at)
    ?.map((item) => [
      item.client_name ?? item.buyer_name ?? "",
      item.client_surname ?? item.buyer_surname ?? "",
      item.customer_phone_code ?? item.client_phone_code ?? "",
      item.customer_phone ?? item.client_phone ?? "",
      item.client_email ? item.client_email : "",
      item.bought_in_domain ?? "",
      item.ticket_name ?? "",
      item.extras
        .map((extra) => {
          return extra.extra_name + "\n";
        })
        .join("") ?? "",
      !isOrderPayed(item) ? t("PENDING_PAYMENT") : "",
    ]);

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [headerPdf],
      body: tableDataPdf,
      styles: { fontSize: 10 },
      theme: "grid",
      margin: { top: 30 },
    });
    doc.save("assistants.pdf");
  };

  // CSV WITH ALL INFORMATION
  const tableHeadersCsv = [
    t("NAME"),
    t("SURNAME"),
    t("BIRTHDAY"),
    t("CUSTOMER_PHONECODE"),
    t("CUSTOMER_PHONE"),
    t("NUMBER_ORDER"),
    t("DOMAIN"),
    t("TICKET_ID"),
    t("TICKET_NAME"),
    t("TICKET_PRICE"),
    t("CREATED_AT"),
    t("CONSUMED_AT"),
    t("PAYMENT_TYPE"),
    t("CUSTOMER_EMAIL"),
    t("CUSTOMER_DNI_TYPE"),
    t("CUSTOMER_DNI"),
    t("CUSTOMER_DNI_EXPIRATION"),
    t("CUSTOMER_NATIONALITY"),
    t("CUSTOMER_GENDER"),
    t("AFFILIATED_STATUS"),
    t("ORDER_TICKET_ID"),
    t("EMAIL"),
    t("PHONE_CODE"),
    t("PHONE"),
    t("NATIONALITY"),
    t("GENDER"),
    t("DOCUMENT_TYPE"),
    t("NIE"),
    t("EXPIRATION_NIE"),
    t("EXPEDITION_NIE"),
    t("TICKET_PRICE"),
  ];

  const numberExtras = assistants.map((item) => item.extras.length);
  const numberExtrasMax = Math.max(...numberExtras);
  for (let i = 0; i < numberExtrasMax; i++) {
    const num = i + 1;
    tableHeadersCsv.push("Extra" + num + ": " + t("EXTRA_NAME"));
    tableHeadersCsv.push("Extra" + num + ": " + t("PRICE"));
    tableHeadersCsv.push("Extra" + num + ": " + t("CONSUMED_AT"));
    tableHeadersCsv.push("Extra" + num + ": " + t("ID"));
  }

  const tableDataCsv = assistants
    ?.filter((item) => !item.refunded_at)
    ?.map((item) => {
      const row = [
        item.client_name ?? item.buyer_name,
        item.client_surname ?? item.buyer_surname,
        item.client_birth_date ? new Date(item.client_birth_date).toLocaleDateString() : "",
        item.customer_phone_code,
        item.customer_phone,
        item.order_id,
        item.bought_in_domain,
        item.ticket_id,
        item.ticket_name,
        item.ticket_price,
        item.created_at ? new Date(item.created_at).toLocaleDateString() : "",
        item.consumed_at ? new Date(item.consumed_at).toLocaleDateString() : "",
        item.payment_type
          ? t(item.payment_type.toUpperCase())
          : !item.payed_at
          ? t("PENDING_SECOND_PAYMENT")
          : "",
        item.customer_email,
        item.customer_dni_type,
        item.customer_dni,
        item.customer_dni_expiration
          ? new Date(item.customer_dni_expiration).toLocaleDateString()
          : "",
        item.customer_nationality,
        item.customer_gender,
        item.affiliated_status,
        item.order_ticket_id,
        item.client_email,
        item.client_phone_code,
        item.client_phone,
        item.client_nationality,
        item.client_gender,
        item.client_document_type,
        item.client_document_id,
        item.client_document_expiry_date
          ? new Date(item.client_document_expiry_date).toLocaleDateString()
          : "",
        item.client_document_expedition_date
          ? new Date(item.client_document_expedition_date).toLocaleDateString()
          : "",
        item.final_payed_price,
      ];
      item.extras.forEach((extra) => {
        row.push(extra.extra_name);
        row.push(extra.extra_price);
        row.push(
          extra.extra_consumed_at
            ? new Date(extra.extra_consumed_at).toLocaleDateString()
            : "No consumido"
        );
        row.push(extra.extra_id);
      });
      return row;
    });

  return { generatePDF, tableHeadersCsv, tableDataCsv, isSuccess };
};
