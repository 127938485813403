import { Box } from "@mui/material";
import { Fragment, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useQueryTourPointsList from "../../hooks/queries/tourPoints/useQueryTourPointsList";
import AssignedTransportCard from "../shared/assignToTrip/AssignedTransportCard";
import CustomDialog from "../shared/CustomDialog";
import { FormHeader } from "../shared/FormHeader";
import AddTourLeaders from "./components/configuration/AddTourLeaders";
import TripItinerary from "./components/configuration/routePoints/TripItinerary";
import TripBlocks from "./moreInformation/TripBlocks";
import TripMoreInfo from "./moreInformation/TripMoreInfo";

export const TripCustomization = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const [openDelete, setOpenDelete] = useState({
    isOpen: false,
    itineraryIndex: null,
  });

  const { data: tourPoints = [], refetch: refetchTourPoints } = useQueryTourPointsList();

  const handleOpenDelete = (e, itineraryIndex) => {
    e.stopPropagation();
    setOpenDelete({ isOpen: true, itineraryIndex });
  };

  const itineraries = watch("itineraries", []);

  const handleRemoveItinerary = () => {
    const updatedItineraries = itineraries
      ?.filter((_, idx) => idx !== openDelete.itineraryIndex)
      ?.map((iti, index) => ({ ...iti, position: index }));
    setValue("itineraries", updatedItineraries);
    setOpenDelete({ isOpen: false, itineraryIndex: null });
  };

  return (
    <>
      <FormHeader
        title={t("CUSTOMIZE_PRODUCT_OPTIONAL")}
        description={t("CUSTOMIZE_PRODUCT_OPTIONAL_DESCRIPTION")}
      >
        <Box sx={{ paddingInline: 2, width: "100%" }}>
          <DragDropContext>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%" }}
                >
                  {itineraries.map((itinerary, index) => (
                    <Fragment key={`trip-itinerary-${index}`}>
                      <TripItinerary
                        key={`trip-itinerary-${index}`}
                        index={index}
                        handleOpenDelete={handleOpenDelete}
                        tourPoints={tourPoints}
                        refetchTourPoints={refetchTourPoints}
                        isSimpleView={false}
                      />
                      {index < itineraries.length - 1 && (
                        <Box
                          sx={{
                            borderLeft: "1px dashed",
                            borderColor: "var(--field-grey)",
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {itinerary?.transport_ids && itinerary?.transport_ids?.length > 0
                            ? itinerary.transport_ids
                                ?.filter(
                                  (id) => !itineraries?.[index - 1]?.transport_ids?.includes(id)
                                )
                                .map((transportId) => (
                                  <AssignedTransportCard
                                    key={transportId}
                                    transportId={transportId}
                                    isSimple
                                  />
                                ))
                            : null}
                        </Box>
                      )}
                    </Fragment>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </FormHeader>
      {/* Included options */}
      <FormHeader
        title={t("INCLUDED_NOT_INCLUDED_OPTIONS")}
        description={t("INCLUDED_NOT_INCLUDED_OPTIONS_DESCRIPTION")}
        isAccordion
      >
        <TripMoreInfo />
      </FormHeader>
      <FormHeader
        title={t("INFORMATION_BLOCKS")}
        description={t("INFORMATION_BLOCKS_DESCRIPTION")}
        isAccordion
      >
        <TripBlocks />
      </FormHeader>
      <FormHeader title={t("TOUR_LEADERS")} description={t("TOUR_LEADERS_DESCRIPTION")} isAccordion>
        <AddTourLeaders />
      </FormHeader>

      <CustomDialog
        isOpen={openDelete.isOpen}
        onClose={() => setOpenDelete(false)}
        title={t("DELETE_ITINERARY")}
        content={t("DELETE_ITINERARY_CONFIRM")}
        onAccept={handleRemoveItinerary}
      />
    </>
  );
};
