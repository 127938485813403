import { Divider, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormHeader } from "../../shared/FormHeader";
import { CustomSwitch } from "../../shared/switch/CustomSwitch";
import AddHosting from "./configuration/AddHosting";
import AddTransports from "./configuration/AddTransports";
import TripItineraries from "./configuration/routePoints/TripItineraries";

const TripComponents = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const [tripComponents, setTripComponents] = useState({
    hasHosting: null,
    hasTransports: null,
  });

  const handleChangeTripComponents = (type, isChecked) => {
    setTripComponents((prev) => ({ ...prev, [type]: isChecked }));
  };

  const itineraries = watch("itineraries", []);

  const transportsDisabled = itineraries.length < 2;

  useEffect(() => {
    if (!itineraries) return;
    setTripComponents({
      hasHosting: itineraries.some((itinerary) => itinerary?.hotel_ids?.length > 0),
      hasTransports: itineraries.some((itinerary) => itinerary?.transport_ids?.length > 0),
    });
  }, []);

  return (
    <>
      {/* Itineraries */}
      <FormHeader title={t("COMPONENTS")} description={t("TRIP_COMPONENTS_DESCRIPTION")}>
        <TripItineraries />
      </FormHeader>

      {/* Optional components */}
      <Grid component={Paper} container spacing={2} borderRadius={3} pb={2} mb={6}>
        <Grid
          sx={{
            mb: 3,
            backgroundColor: "var(--grey-background)",
            width: "100%",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            padding: 3,
          }}
        >
          <Typography variant="h5" fontWeight="bolder" mb={1}>
            {t("OPTIONAL_COMPONENTS")}
          </Typography>
          <Typography variant="body1">{t("OPTIONAL_COMPONENTS_DESCRIPTION")}</Typography>
        </Grid>
        <Grid container padding={2}>
          <Typography variant="subtitle1" fontWeight="800" mr={2}>
            {t("TRANSPORTS")}
          </Typography>
          <CustomSwitch
            checked={tripComponents.hasTransports || false}
            handleChange={(e) => handleChangeTripComponents("hasTransports", e.target.checked)}
            disabled={transportsDisabled}
          />
          <Typography variant="body1" width="100%">
            {t("TRANSPORTS_DESCRIPTION")}
          </Typography>
          {tripComponents.hasTransports && (
            <Grid item xs={12}>
              <AddTransports />
            </Grid>
          )}
        </Grid>
        <Grid container padding={2}>
          <Divider sx={{ width: "100%" }} />
        </Grid>

        <Grid container padding={2}>
          <Typography variant="subtitle1" fontWeight="800" mr={2}>
            {t("HOSTING")}
          </Typography>
          <CustomSwitch
            checked={tripComponents.hasHosting || false}
            handleChange={(e) => handleChangeTripComponents("hasHosting", e.target.checked)}
          />
          <Typography variant="body1" width="100%">
            {t("HOSTING_DESCRIPTION")}
          </Typography>
          {tripComponents.hasHosting && (
            <Grid item xs={12}>
              <AddHosting />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TripComponents;
