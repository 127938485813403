import { Add } from "@mui/icons-material";
import { Box, Button, Grid, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PAGINATION_STYLES } from "../../../../constants/styles";
import { ASSIGN_TRIP_TYPES } from "../../../../constants/variables";
import useQueryTourLeaders from "../../../../hooks/queries/tourLeaders/useQueryTourLeaders";
import useDebounce from "../../../../hooks/useDebouncing";
import usePagination from "../../../../hooks/usePagination";
import SearchFilter from "../../../events/filters/filter/SearchFilter";
import AssignComponent from "../../../shared/AssignComponent";
import CreateTourLeaderDialog from "../../../tourLeaders/createDialog/CreateTourLeaderDialog";

const AddTourLeaders = () => {
  const { t } = useTranslation();
  const { getValues, setValue, watch } = useFormContext();
  const { page, changePage } = usePagination();
  const [search, setSearch] = useState("");
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const tripInfo = getValues();
  const tourLeaderIds = watch("tour_leader_ids");

  const { data, refetch } = useQueryTourLeaders(page, 3, {
    search,
  });
  const tourLeaders = data?.data || [];
  const totalPages = Math.ceil((data?.total || 0) / 3);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleAddTourLeader = async (tourLeaderId, _) => {
    const newTourLeaders = getValues("tour_leader_ids") || [];
    if (!newTourLeaders?.includes(tourLeaderId) && !!tourLeaderId) {
      newTourLeaders.push(tourLeaderId);
    }
    setValue("tour_leader_ids", newTourLeaders);
    refetch();
  };

  const handleDeleteTourLeader = (itemId, _) => {
    const newTourLeaders = getValues("tour_leader_ids");
    if (newTourLeaders?.includes(itemId)) {
      newTourLeaders.splice(newTourLeaders.indexOf(itemId), 1);
    }
    setValue("tour_leader_ids", newTourLeaders);
  };

  useEffect(() => {
    if (tourLeaderIds && tourLeaderIds.length > 0) {
      setValue("tour_leader_ids", tourLeaderIds);
    }
  }, [tourLeaderIds]);

  return (
    <>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <Grid container spacing={2} sx={{ width: "45%", justifyContent: "start" }}>
          <Grid item xs={12} sm={6}>
            <SearchFilter onSearchChange={debounceSearch} />
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="flex-end">
            <Button
              className="oniria-border-btn"
              onClick={() => setOpenCreateDialog(true)}
              startIcon={<Add />}
              size="small"
              sx={{ borderRadius: 3 }}
            >
              {t("CREATE_TOUR_LEADER")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="flex-end">
            <Pagination
              count={totalPages}
              page={page}
              onChange={changePage}
              sx={PAGINATION_STYLES}
            />
          </Grid>
        </Grid>
      </Box>
      <AssignComponent
        tripInfo={tripInfo}
        itemsToAssign={tourLeaders}
        handleAssign={handleAddTourLeader}
        handleRemove={handleDeleteTourLeader}
        type={ASSIGN_TRIP_TYPES.TOUR_LEADERS}
      />

      <CreateTourLeaderDialog
        isOpen={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
      />
    </>
  );
};

export default AddTourLeaders;
