import CloseIcon from "@mui/icons-material/Close";
import { Card, CardContent, CardMedia, Grid, IconButton, Typography } from "@mui/material";
import { getRoutePointName } from "../../../constants/utils";
import useQueryHotelDetails from "../../../hooks/queries/hotels/useQueryHotelDetails";
import useQueryTourPointsList from "../../../hooks/queries/tourPoints/useQueryTourPointsList";

const AssignedHotelCard = ({ hotelId, handleClose, isSimple }) => {
  const { data: tourPoints = [] } = useQueryTourPointsList();
  const { data: hotel } = useQueryHotelDetails(hotelId, () => {}, true);

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          width: isSimple ? "fit-content" : "100%",
          borderRadius: 2,
          position: "relative",
        }}
      >
        <Grid container spacing={1} padding={1}>
          {!isSimple && (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end", position: "relative" }}
            >
              <IconButton
                onClick={handleClose}
                size="small"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: 1,
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          )}

          <Grid item xs={isSimple ? 6 : 3}>
            <CardMedia
              component="img"
              height={isSimple ? "50" : "70"}
              image={hotel?.img_url}
              alt={hotel?.name || "Hotel Image"}
              sx={{
                objectFit: "cover",
                width: isSimple ? 50 : "100%",
                borderRadius: 2,
              }}
            />
          </Grid>

          <Grid item xs={isSimple ? 5 : 8}>
            <CardContent sx={{ padding: 0 }}>
              <Typography
                variant="body2"
                component="div"
                noWrap
                sx={{ fontWeight: 700, fontSize: "0.875rem" }}
              >
                {hotel?.name || "Hotel Name"}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: "0.75rem" }}>
                {getRoutePointName(hotel?.tour_point_id, tourPoints)}
              </Typography>
            </CardContent>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={1}
            sx={{ justifyContent: "center", alignItems: "center" }}
          ></Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AssignedHotelCard;
