import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button, Chip, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  isOrderFailed,
  isOrderStatus,
  isOrderTimeout,
  isOrderTotalPayed,
  orderStatusColors,
} from "../../../constants/orders";
import { formatCurrency } from "../../../constants/utils";
import { getOrderDetail } from "../../../services/eventsServices";
import { Loading } from "../../shared/Loading";
import CompletePaymentDialog from "../CompletePaymentDialog";
import { OrderPaymentInfo } from "./OrderPaymentInfo";
import { OrderSummary } from "./OrderSummary";
import { OrderTripDetail } from "./OrderTripDetail";
import { OrderUserDetail } from "./OrderUserDetail";

const OrderDetail = (props) => {
  const { t } = useTranslation();
  const orderId = props.order;

  const [orderDetail, setOrderDetail] = useState({});
  const [openSecondPayment, setOpenSecondPayment] = useState(false);

  const handleQuerySuccess = (data) => {
    setOrderDetail(data);
  };
  const { isLoading, error, refetch } = useQuery("order", () => getOrderDetail(orderId), {
    onSuccess: handleQuerySuccess,
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <Loading />;
  } else if (error) {
    return <Typography>{t("ERROR_LOADING_DATA")}</Typography>;
  }
  return (
    <>
      <Grid container sx={{ display: "flex", flexDirection: "row", mb: 3, mt: 2 }}>
        <Link
          className="oniria-colorText"
          underline="none"
          onClick={props.onBack}
          style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
        >
          <ChevronLeftIcon style={{ marginRight: "4px" }} />
          <span style={{ verticalAlign: "middle" }}>
            {props.billing ? t("LIST_BILLS") : props.refunds ? t("REFUNDS_LIST") : t("LIST_ORDERS")}
          </span>
        </Link>
      </Grid>
      <Grid container rowGap={2} mb={10}>
        {/* REFUND */}
        {orderDetail?.refund_status ? (
          <Typography color="error" fontWeight="bold">
            {t("REFUND_STATUS")} {t(orderDetail.refund_status.toUpperCase())}
            {orderDetail.refund_amount ? ` (${formatCurrency(orderDetail.refund_amount)})` : ""}
          </Typography>
        ) : (
          <Grid container columnSpacing={2} alignItems="center" justifyContent="flex-end">
            <Grid item mt={1}>
              <Chip
                label={`${t("STATUS")}: ${
                  isOrderTotalPayed(orderDetail)
                    ? t("ORDER_PAYED_SUCCESSFULLY")
                    : isOrderTimeout(orderDetail)
                    ? t("ORDER_TIMEOUT")
                    : isOrderFailed(orderDetail)
                    ? t("ORDER_FAILED")
                    : t("PENDING_PAYMENT")
                }`}
                sx={{
                  backgroundColor: orderStatusColors?.[isOrderStatus(orderDetail)],
                  color: "white",
                  fontWeight: "bold",
                }}
              />
            </Grid>
            {!isOrderTotalPayed(orderDetail) &&
              !isOrderTimeout(orderDetail) &&
              !isOrderFailed(orderDetail) && (
                <Grid item mt={1}>
                  <Button
                    variant="contained"
                    className="oniria-btn"
                    size="small"
                    sx={{ fontSize: "12px", borderRadius: 3 }}
                    onClick={() => setOpenSecondPayment(true)}
                  >
                    {t("COMPLETE_PAYMENT")}
                  </Button>
                </Grid>
              )}
          </Grid>
        )}
        {/* Order Resume */}
        <OrderSummary order={orderDetail} />
        {/* Trip Details */}
        <OrderTripDetail order={orderDetail} />
        {/* Travelers Details */}
        <OrderUserDetail order={orderDetail} />
        {/* Order payments */}
        <Grid item xs={12}>
          <OrderPaymentInfo order={orderDetail} />
        </Grid>
      </Grid>
      <CompletePaymentDialog
        isOpen={openSecondPayment}
        onClose={() => setOpenSecondPayment(false)}
        order={orderDetail}
        refetch={refetch}
      />
    </>
  );
};

export default OrderDetail;
