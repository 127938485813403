import { SERVICE } from "../constants/api";
import { http } from "../constants/axios";

export async function getCardsStatistics(affiliatedCardId, month) {
  const params = new URLSearchParams();

  if (affiliatedCardId) {
    params.append("affiliated_card_id", affiliatedCardId);
  }
  if (month) {
    params.append("month", month);
  }

  const { data } = await http.get(`${SERVICE.CARDS}statistics?${params.toString()}`);
  return data;
}

export async function getCardsByUser(userId) {
  const { data } = await http.get(`${SERVICE.CARDS}user/${userId}`);
  return data;
}
