import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { ticket, ticketErrors, ticketLanguageInfo } from "../../../../classes/tripClass";
import { deleteTicket } from "../../../../services/eventsServices";
import ButtonsForms from "../../../account/forms/ButtonsForms";
import { allFieldsOk } from "../../../shared/FormsValidator";
import Ticket from "./Ticket";
import CustomDialog from "../../../shared/CustomDialog";
import { toastMessageError } from "../../../shared/toastMessage";

const Tickets = ({ formController, buttonClicked, setButtonClicked, eventType, isDisabled }) => {
  const { t } = useTranslation();
  const {
    formData: {
      tickets = [],
      details: {
        event_language_infos,
        event_categories,
        multi_date,
        has_double_payment,
        default_language,
      },
      id,
    },
    setFormData,
    setFormErrorsTicketsAndExtras,
    validation: { tickets: ticketsErrors },
  } = formController;

  const [numberTickets, setNumberTickets] = useState(tickets?.length || 0);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    index: null,
  });

  const setErrors = (ticketsErrors) => {
    setFormErrorsTicketsAndExtras("tickets", ticketsErrors);
  };

  const handleOpenConfirm = (index) => {
    setConfirmDialog({ open: true, index });
  };

  const handleCloseConfirm = () => {
    setConfirmDialog({ open: false, index: null });
  };

  const handleConfirmDelete = async () => {
    const index = confirmDialog.index;
    if (tickets[index].id) {
      try {
        await deleteTicket(tickets[index].id);
      } catch (error) {
        const errorMessage = error?.response?.data?.error || t("ERROR");
        toastMessageError(errorMessage);
        handleCloseConfirm();
        return;
      }
    }
    const updatedTickets = tickets.filter((_, ticketIndex) => ticketIndex !== index);
    setFormData((prev) => ({
      ...prev,
      tickets: updatedTickets,
    }));
    const updatedTicketsErrors = ticketsErrors.filter((_, ticketIndex) => ticketIndex !== index);
    setErrors(updatedTicketsErrors);
    setNumberTickets((prev) => prev - 1);
    handleCloseConfirm();
  };

  const addTicket = () => {
    // Prevenir duplicados o agregar durante carga
    if (isDisabled || (tickets.some((t) => !t.id) && !!id)) return;

    const newIdioms = event_language_infos.map((language) => ({
      ...ticketLanguageInfo,
      language: language.language,
    }));

    const newTicket = {
      ...ticket,
      position: numberTickets,
      event_id: id || "",
      ticket_language_infos: [...newIdioms],
    };

    setFormData((prev) => ({
      ...prev,
      tickets: [...prev.tickets, newTicket],
    }));

    const newTicketErrors = JSON.parse(JSON.stringify(ticketErrors));
    setErrors([...ticketsErrors, newTicketErrors]);
    setNumberTickets((prev) => prev + 1);
  };

  const checkTicketField = (
    key,
    e,
    regex,
    errorMessage,
    canBeEmpty,
    index,
    parameter = "value"
  ) => {
    //
    const newValue = e.target[parameter];
    let errorValue = "";
    //
    if (newValue === "" && !canBeEmpty) {
      errorValue = "empty";
    } else if (
      parseFloat(newValue) >= parseFloat(tickets[index].price) &&
      key === "early_payment_discount"
    ) {
      errorValue = t("HIGHER_DISCOUNT_ERROR");
    } else if (
      parseFloat(newValue) > parseFloat(tickets[index].price) &&
      key === "international_discount"
    ) {
      errorValue = t("HIGHER_DISCOUNT_ERROR");
    } else if (key === "initial_stock") {
      if (
        parseInt(tickets[index].stock) +
          (parseInt(newValue) - parseInt(tickets[index].initial_stock)) <
        0
      ) {
        errorValue = t("STOCK_ERROR");
      }
    } else if (
      parseFloat(newValue) > parseFloat(tickets[index].price) &&
      key === "promotional_code_discount"
    ) {
      errorValue = t("HIGHER_DISCOUNT_ERROR");
    } else if (regex.test(newValue)) {
      errorValue = "";
    } else {
      errorValue = errorMessage;
    }
    //
    const errorsToModify = JSON.parse(JSON.stringify(ticketsErrors));
    const currentTicketErrors = errorsToModify[index];
    currentTicketErrors[key] = errorValue;
    //
    setErrors(errorsToModify);
    //
    // formParams(classForm, key, e)
    const updatedTickets = JSON.parse(JSON.stringify(tickets));
    updatedTickets[index][key] = newValue;
    setFormData((prev) => ({
      ...prev,
      tickets: updatedTickets,
    }));
  };

  const checkTicketDiscountField = (key, ticketIndex, discountIndex, e, errorMessage, regex) => {
    const newValue = e.target.value;
    //
    const limit_tickets_order = parseInt(formController.formData.details.limit_tickets_order);
    // TODO: Chequeo de errores
    let errorValue = "";
    if (newValue === "") {
      errorValue = "empty";
    } else if (
      key === "min_size" &&
      newValue > tickets[ticketIndex].group_discounts[discountIndex].max_size &&
      tickets[ticketIndex].group_discounts[discountIndex].max_size !== ""
    ) {
      errorValue = t("MIN_SIZE_ERROR");
    } else if (
      key === "min_size" &&
      discountIndex !== 0 &&
      parseInt(newValue) !==
        parseInt(tickets[ticketIndex].group_discounts[discountIndex - 1].max_size) + 1
    ) {
      errorValue = t("HOLES_DISCOUNT_ERROR");
    } else if (key === "max_size" && newValue > limit_tickets_order) {
      errorValue = t("MAX_USERS_ERROR");
    } else if (
      key === "discount" &&
      parseFloat(newValue) >= parseFloat(tickets[ticketIndex].price)
    ) {
      errorValue = t("HIGHER_DISCOUNT_ERROR");
    } else if (regex.test(newValue)) {
      errorValue = "";
    } else {
      errorValue = errorMessage;
    }
    const errorsToModify = JSON.parse(JSON.stringify(ticketsErrors));
    const currentTicketErrors = errorsToModify[ticketIndex];
    currentTicketErrors.group_discounts[discountIndex][key] = errorValue;
    //
    setErrors(errorsToModify);
    //
    const updatedTickets = JSON.parse(JSON.stringify(tickets));

    updatedTickets[ticketIndex].group_discounts[discountIndex][key] = newValue;
    setFormData((prev) => ({
      ...prev,
      tickets: updatedTickets,
    }));
  };

  const checkPromoCodeDiscount = (
    key,
    ticketIndex,
    promoIndex,
    e,
    errorMessage,
    regex,
    isActive
  ) => {
    const newValue = e.target.value;
    //
    let errorValue = "";
    if (newValue === "" && key !== "is_active") {
      errorValue = "empty";
    } else if (
      key === "discount" &&
      parseFloat(newValue) > parseFloat(tickets[ticketIndex].price)
    ) {
      errorValue = t("HIGHER_DISCOUNT_ERROR");
    } else if (regex.test(newValue)) {
      errorValue = "";
    } else {
      errorValue = errorMessage;
    }
    const errorsToModify = JSON.parse(JSON.stringify(ticketsErrors));
    const currentTicketErrors = errorsToModify[ticketIndex];
    currentTicketErrors.promotional_code_discounts[promoIndex][key] = errorValue;
    //
    setErrors(errorsToModify);
    //
    const updatedTickets = JSON.parse(JSON.stringify(tickets));
    if (key === "is_active") {
      updatedTickets[ticketIndex].promotional_code_discounts[promoIndex][key] = isActive;
    } else {
      updatedTickets[ticketIndex].promotional_code_discounts[promoIndex][key] = newValue;
    }
    setFormData((prev) => ({
      ...prev,
      tickets: updatedTickets,
    }));
  };

  const allTicketsOk = () => {
    if (numberTickets <= 0) {
      return false;
    }
    for (const ticketErrors of ticketsErrors) {
      for (const key in ticketErrors) {
        const error = ticketErrors[key];
        if (Array.isArray(error) || typeof error === "object") {
          if (!allFieldsOk(error)) {
            return false;
          }
        } else if (error !== "") {
          return false;
        }
      }
    }
    return true;
  };
  // to set next button form clicked
  const catchButtonClicked = (value) => {
    setButtonClicked(value);
  };

  // Drag and Drop
  const handleSetTicketPositions = ({ destination, source }) => {
    if (!destination) return;
    const newTickets = [...tickets];
    const [removed] = newTickets.splice(source.index, 1);
    newTickets.splice(destination.index, 0, removed);

    const updatedTickets = newTickets.map((ticket, index) => ({
      ...ticket,
      position: index,
    }));

    setFormData((prev) => ({
      ...prev,
      tickets: updatedTickets,
    }));

    const newTicketErrors = [...ticketsErrors];
    const [removedError] = newTicketErrors.splice(source.index, 1);
    newTicketErrors.splice(destination.index, 0, removedError);
    setErrors(newTicketErrors);
  };

  return (
    <Grid
      container
      sx={{
        mt: 5,
        mb: 20,
        px: 1,
        color: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid item xs={11} md={8}>
        <Box
          component={Paper}
          elevation={3}
          sx={{
            py: 2,
            px: 3,
            border: "1px solid #E4E4E4",
            borderRadius: "15px",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={11}
              md={5.5}
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <Typography>
                {eventType === "trip" ? t("TOTAL_CAPACITY_TRIP") : t("TOTAL_CAPACITY_EVENT")}
              </Typography>
              <Typography>{formController.formData.details.total_capacity}</Typography>
            </Grid>
            <Grid
              item
              xs={11}
              md={5.5}
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <Typography>{t("LIMIT_TICKETS_ORDER")}</Typography>
              <Typography>{formController.formData.details.limit_tickets_order}</Typography>
            </Grid>
          </Grid>
          <DragDropContext onDragEnd={handleSetTicketPositions}>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {tickets
                    .sort((a, b) => a.position - b.position)
                    .map((ticket, index) => {
                      return (
                        <Ticket
                          key={`ticket-${ticket?.id || index}`}
                          ticket={ticket}
                          formController={formController}
                          isDisabled={isDisabled}
                          errors={ticketsErrors}
                          setErrors={setErrors}
                          checkTicketField={checkTicketField}
                          index={index}
                          setFormData={setFormData}
                          tickets={tickets}
                          checkTicketDiscountField={checkTicketDiscountField}
                          removeTicket={() => handleOpenConfirm(index)}
                          catchButtonClicked={buttonClicked}
                          checkPromoCodeDiscount={checkPromoCodeDiscount}
                          event_language_infos={event_language_infos}
                          event_categories={event_categories}
                          multi_date={multi_date}
                          has_double_payment={has_double_payment}
                          defaultLanguage={default_language}
                        />
                      );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              sx={{
                color: "var(--secondary-color)",
                borderColor: "var(--secondary-color)",
                "&:hover": {
                  color: "var(--secondary-color)",
                  borderColor: "var(--secondary-color)",
                  backgroundColor: "white",
                },
              }}
              onClick={addTicket}
              disabled={!allFieldsOk(ticketsErrors) || isDisabled}
            >
              {t("ADD_NEW_TICKET")}
            </Button>
          </Grid>
          {tickets.length === 0 && buttonClicked && (
            <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("MINIMUM_TICKET")}</Typography>
          )}
          {!allTicketsOk && buttonClicked && (
            <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("REQUIRED_FIELD")}</Typography>
          )}
          {!isDisabled && (
            <ButtonsForms
              formController={formController}
              allFieldsOk={allTicketsOk}
              errors={ticketsErrors}
              sendButtonClicked={catchButtonClicked}
            />
          )}
        </Box>
      </Grid>
      <CustomDialog
        isOpen={confirmDialog.open}
        onClose={handleCloseConfirm}
        title={t("CONFIRM_DELETE_TICKET")}
        content={t("")}
        onAccept={handleConfirmDelete}
      />
    </Grid>
  );
};

export default Tickets;
