import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  endsWithNumber,
  generateNextUrl,
  sanitizeBoolean,
  transformNameToUrl,
} from "../../../constants/utils";
import { useQueryAssignDomains } from "../../../hooks/queries/useQueryAssignDomains";
import { useQueryPremisesList } from "../../../hooks/queries/useQueryPremisesList";
import { checkAvailableUrl } from "../../../services/eventsServices";
import ButtonsForms from "../../account/forms/ButtonsForms";
import {
  allFieldsOk,
  checkField,
  checkFieldValue,
  handleImageSelect,
} from "../../shared/FormsValidator";
import ImageUpload from "../../shared/ImageUpload";
import InfoToolTip from "../../shared/InfoToolTip";
import MapContent from "../../shared/Map/LeafletMap";
import { focusColor } from "../../shared/textFieldStyle";
import { toastMessageError } from "../../shared/toastMessage";
import DoublePaymentInfo from "../DoublePaymentInfo";
import CategorySelect from "./CategoriesSelect";
import EventDates from "./EventDates";
import EventDescription from "./languageComponents/EventDescription";
import EventName from "./languageComponents/EventName";
import EventOutfit from "./languageComponents/EventOutfit";
import EventShortDescription from "./languageComponents/EventShortDescription";
import EventSubtitle from "./languageComponents/EventSubtitle";
import TripMetrics from "./TripMetrics";

const Details = ({
  formController,
  editEvent,
  buttonClicked,
  setButtonClicked,
  typeTrip,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const [isUrlValid, setIsUrlValid] = useState(editEvent);
  const [isSkuValid, setIsSkuValid] = useState(editEvent);

  const {
    formData: { details: trip = {} } = {},
    formParams,
    setFormErrors,
    setFormData,
    setFormParamsNotEvent,
    validation: { details: detailsErrors },
    setEventLanguageInfoError,
  } = formController;

  const isMultiDates = sanitizeBoolean(trip?.multi_date);

  const [premiseFilter, setPremiseFilter] = useState("");

  // to set next button form clicked
  const catchButtonClicked = (value) => {
    setButtonClicked(value);
  };

  const { data: premises } = useQueryPremisesList();

  const { data: domainCategories } = useQueryAssignDomains();

  const menuItems = premises?.map((premise) => (
    <MenuItem key={premise.id} value={premise.id}>
      {premise.name}
    </MenuItem>
  ));
  const handleChangeFilterPremise = (event) => {
    setPremiseFilter(event.target.value);
    checkField(
      "details",
      "premise_id",
      event,
      /.*/,
      t("CHOOSE_OPTION"),
      false,
      formParams,
      setFormErrors
    );
  };

  const checkDateField = (
    classForm,
    key,
    e,
    regex,
    errorMessage,
    canBeEmpty,
    formParams,
    setErrors
  ) => {
    //
    let errorValue = "";
    //
    const now = new Date();
    const dateToCheck = new Date(e.target.value);
    //console.log("Checking date field: ", key, now, dateToCheck)
    //
    if (dateToCheck.getDay < now.getDay) {
      errorValue = t("PREVIOUS_DATE_ERROR");
    }
    // Checkeos propios de fechas
    switch (key) {
      case "end_date":
        const startDate = new Date(trip.start_date);
        if (dateToCheck < startDate) errorValue = t("DATE_START_ERROR");
        break;
      case "end_sale_date":
        const endDate1 = new Date(trip.end_date);
        const startSaleDate = new Date(trip.start_sale_date);
        if (dateToCheck > endDate1 && !isMultiDates) errorValue = t("DATE_END_ERROR");
        if (dateToCheck < startSaleDate) errorValue = t("DATE_SALE_ERROR");
        break;
      case "end_of_publication_date":
        const endDate2 = new Date(trip.end_date);
        const startPublicationDate = new Date(trip.publication_date);
        if (dateToCheck > endDate2 && !isMultiDates) errorValue = t("DATE_END_ERROR");
        if (dateToCheck < startPublicationDate) errorValue = t("PUBLICATION_DATE_ERROR");
        break;
      case "second_payment_start_date":
        const startSaleDates = new Date(trip.start_sale_date);
        if (dateToCheck < startSaleDates) errorValue = t("SECOND_DATE_START_ERROR");
        break;
      case "second_payment_end_date":
        const startSecondDate = new Date(trip.second_payment_start_date);
        if (dateToCheck < startSecondDate) errorValue = t("SECOND_DATE_END_ERROR");
        break;
      default:
        break;
    }
    // Checkeos normales de campo
    setErrors(classForm, key, errorValue);
    formParams(classForm, key, e);
  };

  //check hour and put in end_sale_date_time and end_of_publication_date_time automatically
  const checkHourField = (
    classForm,
    key,
    e,
    regex,
    errorMessage,
    canBeEmpty,
    formParams,
    setErrors,
    parameter = "value"
  ) => {
    let errorValue = "";
    const newValue = e.target[parameter];
    if (newValue === "" && !canBeEmpty) {
      errorValue = "empty";
    } else if (regex.test(newValue)) {
      errorValue = "";
    } else {
      errorValue = errorMessage;
    }
    setErrors(classForm, key, errorValue);
    formParams(classForm, key, e, parameter);
  };

  const handleIncrement = () => {
    trip.min_age = trip.min_age + 1;
  };

  const handleDecrement = () => {
    trip.min_age > 0 ? (trip.min_age = trip.min_age - 1) : (trip.min_age = 0);
  };

  //Set premise_name when edit event
  useEffect(() => {
    if (trip.premise_id !== "" && premises?.length > 0) {
      setPremiseFilter(trip.premise_id);
    }
  }, [trip.premise_id, premises]);

  //check si la sede seleccionada es internacional
  const isInternational = () => {
    const premiseFound = premises?.find((premise) => premise.id === premiseFilter);
    return premiseFound ? premiseFound.is_active === 1 : false;
  };

  const handleChangeLatLong = (lat, lng) => {
    checkFieldValue(
      "details",
      "latitude",
      lat,
      /^[-0-9]+(\.[-0-9]+)?$/,
      t("INCORRECT"),
      true,
      setFormParamsNotEvent,
      setFormErrors
    );
    checkFieldValue(
      "details",
      "longitude",
      lng,
      /^[-0-9]+(\.[-0-9]+)?$/,
      t("INCORRECT"),
      true,
      setFormParamsNotEvent,
      setFormErrors
    );
  };

  const tryUrlWithNumber = (url) => {
    const checkUrl = (currentUrl) => {
      checkAvailableUrl(currentUrl).then((isUrlAvailable) => {
        if (isUrlAvailable) {
          checkFieldValue(
            "details",
            "seo_name",
            currentUrl,
            /^[a-z0-9-]{1,255}$/,
            t("SEO_ERROR"),
            false,
            setFormParamsNotEvent,
            setFormErrors
          );
        } else {
          const nextUrl = generateNextUrl(currentUrl);
          checkUrl(nextUrl);
        }
      });
    };
    const initialUrl = endsWithNumber(url) ? url : `${url}-01`;
    checkUrl(initialUrl);
  };

  const handleSetUrl = async (e) => {
    if (!isUrlValid || !trip.seo_name) {
      const newUrl = transformNameToUrl(e.target.value);
      if (newUrl) {
        const isUrlAvailable = await checkAvailableUrl(newUrl || "");
        if (isUrlAvailable) {
          setIsUrlValid(true);
          checkFieldValue(
            "details",
            "seo_name",
            newUrl,
            /^[a-z0-9-]{1,255}$/,
            t("SEO_ERROR"),
            false,
            setFormParamsNotEvent,
            setFormErrors
          );
        } else {
          tryUrlWithNumber(newUrl);
          setFormErrors("details", "seo_name", t("URL_NOT_AVAILABLE"));
          setIsUrlValid(false);
        }
      }
    }
  };

  const checkSku = async () => {
    if (!isSkuValid || !trip.sku) {
      const isSkuAvailable = await checkAvailableUrl(trip.sku || "");
      if (isSkuAvailable) {
        setIsSkuValid(true);
        checkFieldValue(
          "details",
          "sku",
          trip.sku,
          /^[^\s]{1,50}$/,
          t("MAX_50"),
          false,
          setFormParamsNotEvent,
          setFormErrors
        );
      } else {
        setFormErrors("details", "sku", t("SKU_NOT_AVAILABLE"));
        toastMessageError(t("SKU_NOT_AVAILABLE"));
        setIsSkuValid(false);
      }
    }
  };

  return (
    <Grid
      container
      sx={{
        mt: 2,
        mb: 20,
        px: 1,
        color: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid item xs={11} md={8}>
        <Box
          component={Paper}
          elevation={3}
          sx={{
            py: 2,
            px: 3,
            border: "1px solid #E4E4E4",
            borderRadius: "15px",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid item xs={11}>
                <EventName
                  typeTrip={typeTrip}
                  trip={trip}
                  formParams={formParams}
                  setFormErrors={setFormErrors}
                  detailsErrors={detailsErrors}
                  isDisabled={isDisabled}
                  buttonClicked={buttonClicked}
                  setFormData={setFormData}
                  setEventLanguageInfoError={setEventLanguageInfoError}
                  handleSetUrl={handleSetUrl}
                />
                <EventSubtitle
                  trip={trip}
                  formParams={formParams}
                  setFormErrors={setFormErrors}
                  detailsErrors={detailsErrors}
                  isDisabled={isDisabled}
                  buttonClicked={buttonClicked}
                  setFormData={setFormData}
                  setEventLanguageInfoError={setEventLanguageInfoError}
                />
                <TextField
                  onChange={(e) => {
                    checkField(
                      "details",
                      "sku",
                      e,
                      /^[^\s]{1,50}$/,
                      t("MAX_50"),
                      false,
                      formParams,
                      setFormErrors
                    );
                    checkSku();
                  }}
                  margin="normal"
                  required
                  fullWidth
                  value={trip.sku || ""}
                  id="sku"
                  name="sku"
                  autoComplete="sku"
                  autoFocus
                  label={t("SKU")}
                  error={
                    (detailsErrors.sku !== "" && detailsErrors.sku !== "empty") ||
                    (detailsErrors.sku === "empty" && buttonClicked)
                  }
                  helperText={detailsErrors.sku !== "empty" ? detailsErrors.sku : ""}
                  disabled={isDisabled}
                  sx={[focusColor, { mb: 1 }]}
                />
                <TextField
                  onChange={(e) => {
                    checkField(
                      "details",
                      "seo_name",
                      e,
                      /^[a-z0-9-]{1,255}$/,
                      t("SEO_ERROR"),
                      false,
                      formParams,
                      setFormErrors
                    );
                    setIsUrlValid(false);
                  }}
                  onBlur={handleSetUrl}
                  margin="normal"
                  required
                  fullWidth
                  value={trip.seo_name || ""}
                  id="seo_name"
                  name="seo_name"
                  autoComplete="seo_name"
                  autoFocus
                  label={t("EVENT_URL")}
                  error={
                    (detailsErrors.seo_name !== "" && detailsErrors.seo_name !== "empty") ||
                    (detailsErrors.seo_name === "empty" && buttonClicked)
                  }
                  helperText={detailsErrors.seo_name !== "empty" ? detailsErrors.seo_name : ""}
                  disabled={isDisabled}
                  sx={[focusColor, { mb: 3 }]}
                />
                <FormControl
                  fullWidth
                  sx={[focusColor, { mb: 2 }]}
                  error={
                    (detailsErrors.premise_id === "empty" && buttonClicked) ||
                    (detailsErrors.premise_id !== "" && detailsErrors.premise_id !== "empty")
                  }
                >
                  <InputLabel id="demo-select-small-label">{t("PREMISE_ORGANIZER")}</InputLabel>
                  <Select
                    value={premiseFilter}
                    onChange={handleChangeFilterPremise}
                    sx={{ fontSize: "16px" }}
                    label={t("PREMISE_ORGANIZER")}
                  >
                    {menuItems}
                  </Select>
                </FormControl>
                {/* CATEGORIES */}
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  <div
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      display: "flex",
                    }}
                  >
                    {t("CATEGORIES_DOMAINS")}
                    <InfoToolTip text={t("CATEGORIES_DESCRIPTION")} />
                  </div>
                </InputLabel>
                <CategorySelect
                  trip={trip}
                  domainCategories={domainCategories}
                  setFormData={setFormData}
                  buttonClicked={buttonClicked}
                  detailsErrors={detailsErrors}
                />
                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                    <div
                      style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        display: "flex",
                      }}
                    >
                      {t("SHORT_DESCRIPTION_TEXT")}
                      <InfoToolTip text={t("EVENT_CARD_SHORT_DESCRIPTION")} />
                    </div>
                  </InputLabel>
                  <EventShortDescription
                    trip={trip}
                    formParams={formParams}
                    setFormErrors={setFormErrors}
                    detailsErrors={detailsErrors}
                    isDisabled={isDisabled}
                    buttonClicked={buttonClicked}
                    setFormData={setFormData}
                    setEventLanguageInfoError={setEventLanguageInfoError}
                  />
                </Grid>
                <EventDescription
                  typeTrip={typeTrip}
                  trip={trip}
                  setFormErrors={setFormErrors}
                  detailsErrors={detailsErrors}
                  buttonClicked={buttonClicked}
                  setFormData={setFormData}
                  setFormParamsNotEvent={setFormParamsNotEvent}
                  setEventLanguageInfoError={setEventLanguageInfoError}
                />
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Grid item xs={12} lg={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black", mb: 1, pb: 1 }}>
                      <div
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          display: "flex",
                        }}
                      >
                        {typeTrip ? t("TRIP_PRINCIPAL_IMAGE") : t("EVENT_PRINCIPAL_IMAGE")}
                        <InfoToolTip
                          text={typeTrip ? t("MAIN_TRIP_IMAGE") : t("MAIN_EVENT_IMAGE")}
                        />
                      </div>
                    </InputLabel>
                    <ImageUpload
                      url={trip.principal_url}
                      selectedImagesInForm={trip.principal_url}
                      onImageSelect={(url) =>
                        handleImageSelect(
                          "details",
                          url,
                          "principal_url",
                          setFormParamsNotEvent,
                          setFormErrors
                        )
                      }
                      error={detailsErrors.principal_url !== "" && buttonClicked}
                      editEvent={editEvent}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel
                      sx={{
                        fontSize: "18px",
                        color: "black",
                        paddingBottom: 2,
                        ml: 2,
                      }}
                    >
                      {typeTrip ? t("TRIP_IMAGES_HEADER") : t("EVENT_IMAGES")}
                      <p
                        sx={{
                          fontSize: "9px",
                        }}
                      >
                        {typeTrip ? "Format 21:9" : "Format 1:1"}
                      </p>
                    </InputLabel>
                    <ImageUpload
                      url={trip.slider_urls}
                      selectedImagesInForm={trip.slider_urls}
                      onImageSelect={(url) =>
                        handleImageSelect(
                          "details",
                          url,
                          "slider_urls",
                          setFormParamsNotEvent,
                          setFormErrors
                        )
                      }
                      multi={typeTrip ? true : false}
                      editEvent={editEvent}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("LOCATION_ADDRESS")}
                    <InfoToolTip text={t("INFO_MAP_EVENT")} />
                  </InputLabel>
                  <TextField
                    onChange={(e) =>
                      checkField(
                        "details",
                        "location",
                        e,
                        /^(?!.*[|\\#·$~%&¬()^[\]+*{}_]).{1,250}$/,
                        t("MAX_250"),
                        false,
                        formParams,
                        setFormErrors
                      )
                    }
                    value={trip.location}
                    margin="normal"
                    required
                    fullWidth
                    id="location"
                    name="location"
                    label={t("LOCATION")}
                    autoComplete="location"
                    error={
                      (detailsErrors.location !== "" && detailsErrors.location !== "empty") ||
                      (detailsErrors.location === "empty" && buttonClicked)
                    }
                    helperText={detailsErrors.location !== "empty" ? detailsErrors.location : ""}
                    disabled={isDisabled}
                    sx={focusColor}
                  />
                  <Grid sx={{ height: "250px", width: "100%" }}>
                    <MapContent
                      marker
                      handleChangeLatLong={handleChangeLatLong}
                      isDisabled={isDisabled}
                      lat={trip.latitude}
                      lng={trip.longitude}
                    />
                  </Grid>
                </Grid>
                <EventDates
                  checkDateField={checkDateField}
                  formParams={formParams}
                  setFormErrors={setFormErrors}
                  trip={trip}
                  detailsErrors={detailsErrors}
                  buttonClicked={buttonClicked}
                  isDisabled={isDisabled}
                  checkField={checkField}
                  checkHourField={checkHourField}
                />
                <Grid container columnSpacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2, mt: 1.8 }}>
                      {t("MIN_AGE")}
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkField(
                          "details",
                          "min_age",
                          e,
                          /^(?:100|[1-9][0-9]?|0)$/,
                          t("INCORRECT"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      value={trip.min_age}
                      margin="normal"
                      type="number"
                      required
                      fullWidth
                      id="min_age"
                      name="min_age"
                      label={t("AGE")}
                      autoComplete="min_age"
                      error={
                        (detailsErrors.min_age !== "" && detailsErrors.min_age !== "empty") ||
                        (detailsErrors.min_age === "empty" && buttonClicked)
                      }
                      helperText={detailsErrors.min_age !== "empty" ? detailsErrors.min_age : ""}
                      disabled={isDisabled}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleIncrement} edge="end" />
                            <IconButton onClick={handleDecrement} edge="end" />
                          </InputAdornment>
                        ),
                      }}
                      sx={focusColor}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel
                      sx={{
                        fontSize: "18px",
                        color: "black",
                        mb: 2,
                        mt: 1.8,
                        whiteSpace: "unset",
                      }}
                    >
                      {t("LIMIT_TICKETS_ORDER")}
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkField(
                          "details",
                          "limit_tickets_order",
                          e,
                          /^[1-9]\d*$/,
                          t("INCORRECT"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      value={trip.limit_tickets_order}
                      margin="normal"
                      type="number"
                      required
                      fullWidth
                      id="limit_tickets_order"
                      name="limit_tickets_order"
                      label={t("LIMIT_TICKETS_ORDER")}
                      autoComplete="limit_tickets_order"
                      error={
                        (detailsErrors.limit_tickets_order !== "" &&
                          detailsErrors.limit_tickets_order !== "empty") ||
                        (detailsErrors.limit_tickets_order === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.limit_tickets_order !== "empty"
                          ? detailsErrors.limit_tickets_order
                          : ""
                      }
                      disabled={isDisabled}
                      sx={focusColor}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Grid>
                </Grid>
                <EventOutfit
                  trip={trip}
                  formParams={formParams}
                  setFormErrors={setFormErrors}
                  detailsErrors={detailsErrors}
                  isDisabled={isDisabled}
                  buttonClicked={buttonClicked}
                  setFormData={setFormData}
                  setEventLanguageInfoError={setEventLanguageInfoError}
                />
                {typeTrip && (
                  <TripMetrics
                    trip={trip}
                    formParams={formParams}
                    setFormErrors={setFormErrors}
                    detailsErrors={detailsErrors}
                    buttonClicked={buttonClicked}
                    isDisabled={isDisabled}
                  />
                )}
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_visible"
                        checked={trip.is_visible}
                        onChange={(e) =>
                          checkField(
                            "details",
                            "is_visible",
                            e,
                            /^(true|false)$/,
                            t("CHOOSE_OPTION"),
                            true,
                            formParams,
                            setFormErrors,
                            "checked"
                          )
                        }
                        disabled={isDisabled}
                        value={trip.is_visible}
                        sx={{
                          color: "var(--secondary-color)",
                          "&.Mui-checked": {
                            color: "var(--secondary-color)",
                          },
                        }}
                      />
                    }
                    label={typeTrip ? t("VISIBLE_TRIP") : t("VISIBLE_EVENT")}
                  />
                  <InfoToolTip text={t("VISIBLE_EVENT_INFO")} />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="autovalidation"
                        checked={trip.autovalidation}
                        onChange={(e) =>
                          checkField(
                            "details",
                            "autovalidation",
                            e,
                            /^(true|false)$/,
                            t("CHOOSE_OPTION"),
                            true,
                            formParams,
                            setFormErrors,
                            "checked"
                          )
                        }
                        disabled={isDisabled}
                        value={trip.autovalidation}
                        sx={{
                          color: "var(--secondary-color)",
                          "&.Mui-checked": {
                            color: "var(--secondary-color)",
                          },
                        }}
                      />
                    }
                    label={t("SELFT_VALIDATION")}
                  />
                  <InfoToolTip text={t("SELFT_VALIDATION_INFO")} />
                </Grid>
                {isInternational() && (
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="is_international"
                          checked={trip.is_international}
                          onChange={(e) =>
                            checkField(
                              "details",
                              "is_international",
                              e,
                              /^(true|false)$/,
                              t("CHOOSE_OPTION"),
                              true,
                              formParams,
                              setFormErrors,
                              "checked"
                            )
                          }
                          disabled={isDisabled}
                          value={trip.is_international}
                          sx={{
                            color: "var(--secondary-color)",
                            "&.Mui-checked": {
                              color: "var(--secondary-color)",
                            },
                          }}
                        />
                      }
                      label={t("IS_INTERNATIONAL")}
                    />
                    <InfoToolTip text={t("IS_INTERNATIONAL_INFO")} />
                  </Grid>
                )}
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="has_double_payment"
                        checked={
                          trip.has_double_payment === 1 ||
                          trip.has_double_payment === "1" ||
                          trip.has_double_payment === true
                        }
                        onChange={(e) =>
                          checkField(
                            "details",
                            "has_double_payment",
                            e,
                            /^(true|false)$/,
                            t("CHOOSE_OPTION"),
                            true,
                            formParams,
                            setFormErrors,
                            "checked"
                          )
                        }
                        disabled={isDisabled}
                        value={trip.has_double_payment}
                        sx={{
                          color: "var(--secondary-color)",
                          "&.Mui-checked": { color: "var(--secondary-color)" },
                        }}
                      />
                    }
                    label={t("IS_DOUBLE_PAYMENT")}
                  />
                  <Typography>*{t("IS_DOUBLE_PAYMENT_INFO")}</Typography>
                </Grid>
                {trip.has_double_payment && (
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <DoublePaymentInfo
                      isDisabled={isDisabled}
                      data={trip}
                      checkField={checkField}
                      checkDateField={checkDateField}
                      formParams={formParams}
                      setFormErrors={setFormErrors}
                      detailsErrors={detailsErrors}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
          {!allFieldsOk(detailsErrors) && buttonClicked && (
            <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("REQUIRED_FIELD")}</Typography>
          )}
          {!isDisabled && (
            <ButtonsForms
              formController={formController}
              allFieldsOk={allFieldsOk}
              errors={detailsErrors}
              sendButtonClicked={catchButtonClicked}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Details;
