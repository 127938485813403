import { Add } from "@mui/icons-material";
import { Box, Button, FormControl, Grid, MenuItem, Pagination, Select } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PAGINATION_STYLES } from "../../../../constants/styles";
import { ASSIGN_TRIP_TYPES } from "../../../../constants/variables";
import useQueryTransports from "../../../../hooks/queries/transports/useQueryTransports";
import useQueryTripsTransports from "../../../../hooks/queries/transports/useQueryTripsTransports";
import useDebounce from "../../../../hooks/useDebouncing";
import usePagination from "../../../../hooks/usePagination";
import { createTransport, getTransportDetails } from "../../../../services/transportServices";
import { ASSIGNED_TAB, TEMPLATES_TAB } from "../../../../utils/hotels";
import { prepareTransportToSend } from "../../../../utils/transports";
import SearchFilter from "../../../events/filters/filter/SearchFilter";
import AssignComponent from "../../../shared/AssignComponent";
import { focusColor } from "../../../shared/textFieldStyle";
import { toastMessageError } from "../../../shared/toastMessage";
import CreateTransportDialog from "../../../transports/createDialog/CreateTransportDialog";

const transportTypes = [
  { value: TEMPLATES_TAB, label: "NEW" },
  { value: ASSIGNED_TAB, label: "JUST_ASSIGNED" },
];

const AddTransports = () => {
  const { t } = useTranslation();

  const { getValues, setValue } = useFormContext();
  const { page, changePage } = usePagination();
  const [search, setSearch] = useState("");
  const [transportType, setTransportType] = useState(TEMPLATES_TAB);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const tripInfo = getValues();

  const { data: templateData } = useQueryTransports(
    page,
    6,
    {
      search,
      isActive: true,
    },
    transportType,
    () => {}
  );

  const { data: assignedData } = useQueryTripsTransports(
    page,
    3,
    { search },
    transportType,
    () => {}
  );

  const transports = transportType === TEMPLATES_TAB ? templateData?.data : assignedData?.data;
  const totalPages = Math.ceil(
    (Number(templateData?.total || 0) || Number(assignedData?.total || 0)) / 3
  );

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleAssignTransport = async (transportId, itineraryId) => {
    try {
      // 1 - Create a transport with the info of the template
      const transportInfo = await getTransportDetails(transportId, false);
      transportInfo.id = null;
      const transportToSend = await prepareTransportToSend(transportInfo);
      const newHotel = await createTransport(transportToSend, true);
      // 2 - Add the transport to the itinerary
      const firstItinerary = itineraryId.split("+")[0];
      const secondItinerary = itineraryId.split("+")[1];
      addTransportToItinerary(newHotel.id, firstItinerary, secondItinerary);
    } catch (e) {
      toastMessageError(e?.response?.data?.error || t("ERROR"));
    }
  };

  const addTransportToItinerary = (transportId, itineraryId, secondItineraryId) => {
    const prevItineraries = getValues("itineraries");
    const newItineraries = prevItineraries.map((itinerary, index) => {
      if (
        itineraryId.length > 4 ? itinerary.id?.includes(itineraryId) : index === Number(itineraryId)
      ) {
        if (!itinerary.transport_ids) {
          itinerary.transport_ids = [transportId];
        } else {
          itinerary.transport_ids = [...itinerary?.transport_ids, transportId];
        }
      }
      if (
        secondItineraryId.length > 4
          ? itinerary.id?.includes(secondItineraryId)
          : index === Number(secondItineraryId)
      ) {
        if (!itinerary.transport_ids) {
          itinerary.transport_ids = [transportId];
        } else {
          itinerary.transport_ids = [...itinerary?.transport_ids, transportId];
        }
      }
      return itinerary;
    });
    setValue("itineraries", newItineraries);
  };

  const handleRemoveTransport = (itemId, itineraryId, secondItineraryId) => {
    const prevItineraries = getValues("itineraries");
    const newItineraries = prevItineraries.map((itinerary, index) => {
      if (
        itineraryId.length > 4 ? itinerary.id?.includes(itineraryId) : index === Number(itineraryId)
      ) {
        if (itinerary.transport_ids) {
          itinerary.transport_ids = itinerary?.transport_ids.filter(
            (transportId) => transportId !== itemId
          );
        }
      }
      if (
        secondItineraryId.length > 4
          ? itinerary.id?.includes(secondItineraryId)
          : index === Number(secondItineraryId)
      ) {
        if (itinerary.transport_ids) {
          itinerary.transport_ids = itinerary?.transport_ids.filter(
            (transportId) => transportId !== itemId
          );
        }
      }
      return itinerary;
    });

    setValue("itineraries", newItineraries);
  };

  const handleCreateTransport = () => {
    setOpenCreateDialog(true);
  };

  return (
    <>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <Grid container spacing={2} sx={{ width: "45%", justifyContent: "start" }}>
          <Grid item xs={12}>
            <SearchFilter onSearchChange={debounceSearch} />
          </Grid>
          <Grid item xs={12} sm={6} sx={focusColor}>
            <FormControl fullWidth size="small">
              <Select
                value={transportType}
                onChange={(e) => setTransportType(e.target.value)}
                fullWidth
              >
                {transportTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {t(type.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="flex-end">
            <Button
              className="oniria-border-btn"
              onClick={() => setOpenCreateDialog(true)}
              startIcon={<Add />}
              size="small"
              sx={{ borderRadius: 3 }}
            >
              {t("CREATE_TRANSPORT")}
            </Button>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <Pagination
              count={totalPages}
              page={page}
              onChange={changePage}
              sx={PAGINATION_STYLES}
            />
          </Grid>
        </Grid>
      </Box>
      <AssignComponent
        tripInfo={tripInfo}
        itemsToAssign={transports}
        handleAssign={handleAssignTransport}
        handleRemove={handleRemoveTransport}
        type={ASSIGN_TRIP_TYPES.TRANSPORTS}
        handleCreateNew={handleCreateTransport}
      />

      <CreateTransportDialog isOpen={openCreateDialog} onClose={() => setOpenCreateDialog(false)} />
    </>
  );
};

export default AddTransports;
