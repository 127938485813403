import { DeleteOutline, EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PAGINATION_STYLES } from "../../../constants/styles";
import { formatCurrency } from "../../../constants/utils";
import { B2B_AGREEMENT_STATUS, B2B_APP_URL, ROLES } from "../../../constants/variables";
import { useQueryB2bAgreements } from "../../../hooks/queries/b2b/useQueryB2bAgreements";
import usePagination from "../../../hooks/usePagination";
import { deleteB2bAgreement } from "../../../services/b2bServices";
import CustomDialog from "../../shared/CustomDialog";
import InfoToolTip from "../../shared/InfoToolTip";
import { Loading } from "../../shared/Loading";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";
import SetCommissionDialog from "../dialogs/SetCommissionDialog";

export const EspecialCommission = ({ userType, doRefetch, setDoRefetch }) => {
  const { t } = useTranslation();
  const [openDelete, setOpenDelete] = useState({
    isOpen: false,
    agreement: null,
  });
  const [openCommission, setOpenCommission] = useState({
    isOpen: false,
    agreement: null,
  });

  const initialPerPage = 10;
  const { page, perPage, changePage } = usePagination(initialPerPage);
  const { data, isLoading, isRefetching, refetch } = useQueryB2bAgreements(page, perPage, {
    status: B2B_AGREEMENT_STATUS.ACCEPTED,
  });
  const { amount: total = 0, data: trips = [] } = data || {};

  const handleDelete = async () => {
    try {
      const {
        id,
        premise_operator_id,
        premise_agent_id,
        event_id,
        commission_fixed,
        commission_percentage,
      } = openDelete.agreement;

      const body = {
        premise_operator_id,
        premise_agent_id,
        event_id,
        commission_fixed,
        commission_percentage,
      };

      await deleteB2bAgreement(id, body);
      const message = t("ESPECIAL_COMMISSION_DELETED_SUCCESS");
      toastMessageSuccess(message);
      refetch();
      setOpenDelete({ isOpen: false, id: null });
    } catch (error) {
      const errorMessage = error?.response?.data?.message || t("ERROR");
      toastMessageError(errorMessage);
    }
  };

  const numberPages = Math.ceil(parseFloat(total) / parseFloat(perPage));

  const handleShowClient = (eventId) => {
    window.open(`${B2B_APP_URL}events/${eventId}`, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    if (doRefetch) {
      refetch();
      setDoRefetch(false);
    }
  }, [doRefetch]);

  return (
    <Box mb={3}>
      <Typography variant="body2" fontWeight="bold" mt={2}>
        {t("ESPECIAL_COMMISSIONS")}
      </Typography>
      {userType === ROLES.B2B_OPERATOR ? (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <Button
            className="oniria-border-btn"
            size="small"
            sx={{ textTransform: "capitalize" }}
            onClick={() => setOpenCommission({ isOpen: true, agreement: null })}
          >
            {t("ADD_ESPECIAL_COMMISSION")}
          </Button>
        </Box>
      ) : null}
      {isLoading || isRefetching ? (
        <Loading />
      ) : (trips && trips.length > 0) ? (
        <>
          {numberPages > 1 ? (
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
              <Pagination
                count={numberPages}
                page={page}
                onChange={changePage}
                sx={PAGINATION_STYLES}
                size="small"
              />
            </Box>
          ) : null}
          <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
            <Table sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                  <TableCell align="center">{t("TRIP_NAME")}</TableCell>
                  <TableCell align="center">{t("AGENT")}</TableCell>
                  <TableCell align="center">{t("ESPECIAL_COMMISSION")}</TableCell>
                  <TableCell align="center">{t("ACTION")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trips?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{row.event_name}</TableCell>
                    <TableCell align="center">
                      {row.premise_agent_name || row.premise_operator_name}
                    </TableCell>
                    <TableCell align="center">
                      {row.commission_percentage && row.commission_percentage !== "0"
                        ? `${row.commission_percentage}%`
                        : row.commission_fixed
                        ? formatCurrency(row.commission_fixed)
                        : null}
                    </TableCell>
                    {userType === ROLES.B2B_OPERATOR ? (
                      <TableCell align="center">
                        <InfoToolTip text={t("EDIT")}>
                          <IconButton
                            onClick={() => setOpenCommission({ isOpen: true, agreement: row })}
                          >
                            <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
                          </IconButton>
                        </InfoToolTip>
                        <InfoToolTip text={t("DELETE")}>
                          <IconButton
                            onClick={() => setOpenDelete({ isOpen: true, agreement: row })}
                          >
                            <DeleteOutline sx={{ color: "var(--oniria-gold)" }} />
                          </IconButton>
                        </InfoToolTip>
                      </TableCell>
                    ) : (
                      <TableCell align="center">
                        <IconButton
                          onClick={() => handleShowClient(row.event_id)}
                          sx={{ color: "var(--oniria-gold)" }}
                        >
                          <RemoveRedEyeOutlined sx={{ color: "var(--oniria-gold)" }} />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography variant="body2">{t("NO_DATA_AVAILABLE")}</Typography>
      )}

      <CustomDialog
        isOpen={openDelete.isOpen}
        onClose={() => setOpenDelete({ isOpen: false, agreement: null })}
        title={t("CONFIRM_DELETE")}
        content={t("CONFIRM_DELETE_SPONSOR_DESCRIPTION")}
        onAccept={handleDelete}
      />
      <SetCommissionDialog
        isOpen={openCommission.isOpen}
        onClose={() => {
          setOpenCommission({ isOpen: false, agreement: null });
          refetch();
        }}
        agreement={openCommission.agreement}
      />
    </Box>
  );
};
