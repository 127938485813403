import { FileDownload } from "@mui/icons-material";
import { Box, Button, Grid, Pagination, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PAGINATION_STYLES } from "../../constants/styles";
import { formatCurrency } from "../../constants/utils";
import { B2B_STATUSES_OPTIONS } from "../../constants/variables";
import { useQueryB2bSales } from "../../hooks/queries/b2b/useQueryB2bSales";
import { useQueryB2bSalesAgent } from "../../hooks/queries/b2b/useQueryB2bSalesAgent";
import { useQueryAllEventsList } from "../../hooks/queries/events/useQueryAllEventsList";
import useDebounce from "../../hooks/useDebouncing";
import { useDownloadB2bSales } from "../../hooks/useDownloadB2bSales";
import usePagination from "../../hooks/usePagination";
import { AutoCompleteEventSelect } from "../events/AutoCompleteEventSelect";
import SearchFilter from "../events/filters/filter/SearchFilter";
import SelectFilter from "../events/filters/filter/SelectFilter";
import OrderDetail from "../events/orderDetails/OrderDetail";
import { AgentsIcon } from "../icons/AgentsIcon";
import { OrderIcon } from "../icons/OrderIcon";
import { Loading } from "../shared/Loading";
import SelectPerPage from "../shared/SelectPerPage";
import { B2BSalesTable } from "./sales/B2BSalesTable";
import { SalesCard } from "./SalesCard";

const B2BSales = () => {
  const { t } = useTranslation();
  const csvRef = useRef(null);
  const { premises_id = [] } = useSelector((state) => state.userInfo);
  const userPremise = premises_id?.length === 1 ? premises_id[0] : null;

  const [filters, setFilters] = useState({
    search: "",
    premiseId: "all",
    product: "all",
    status: "all",
    month: "all",
  });
  const [enabledDownload, setEnabledDownload] = useState(false);
  const [showDetailOrder, setShowDetailOrder] = useState({
    order_id: null,
    open: false,
  });

  const { page, perPage, changePage, changePerPage } = usePagination();

  // Selects options
  const { data: events = [] } = useQueryAllEventsList(userPremise);
  const { data: agents = [] } = useQueryB2bSalesAgent({}, "", "");
  const agentOptions = agents?.map((agent) => ({
    id: agent.premise_id,
    name: `${agent.name}, ${agent.surname}`,
  }));
  const statusOptions = B2B_STATUSES_OPTIONS.map((status) => ({
    id: status.value,
    name: t(status.label),
  }));

  // Sales Info
  const {
    data = {},
    isLoading,
    isRefetching,
    refetch,
  } = useQueryB2bSales(page, perPage, filters, true);
  const { data: sales = [], total = 0, totalSales = {}, totalAgents = 0 } = data;

  const totalPrice = Object.keys(totalSales).reduce((acc, key) => {
    return acc + parseFloat(totalSales[key] || 0);
  }, 0);

  const numberPages = Math.ceil(parseFloat(total) / parseFloat(perPage));

  // SEARCH FILTER
  const handleSearchChange = (e) => {
    setFilters({
      ...filters,
      search: e.target.value,
    });
  };
  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleFilterChange = (value, key) => {
    setFilters({ ...filters, [key]: value });
  };

  // Download sales in csv
  const { tableHeadersCsv, tableDataCsv, isSuccess } = useDownloadB2bSales(
    filters,
    enabledDownload
  );

  return showDetailOrder.open ? (
    <OrderDetail
      order={showDetailOrder.order_id}
      transactionId={""}
      firstPaymentTransactionId={""}
      onBack={() => setShowDetailOrder({ open: false, order_id: null })}
    />
  ) : isLoading ? (
    <Loading />
  ) : (
    <Box mt={2} width="100%">
      <Typography variant="body2">{t("B2B_SALES_DESCRIPTION")}</Typography>
      <Box sx={{ display: "flex", mt: 2, gap: 2, flexWrap: "wrap" }}>
        <SalesCard
          title={t("TOTAL_SALES_B2B")}
          subtitle={t("IN_THIS_MONTH")}
          amount={formatCurrency(totalPrice)}
          //percentage={-7}
          iconUrl={"/media/homeIcons/dollar.svg"}
          color="#d7fade"
        />
        <SalesCard
          title={t("TOTAL_AGENTS_B2B")}
          amount={totalAgents}
          icon={<AgentsIcon width={25} />}
          color="#E7F4D9"
        />
        <SalesCard
          title={t("TOTAL_ORDERS")}
          subtitle={t("IN_THIS_MONTH")}
          amount={total}
          //percentage={20}
          icon={<OrderIcon width={25} />}
          color="#FDD2DE"
        />
      </Box>
      <Box
        sx={{ mb: 2, mt: 2, display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}
      >
        <Box display="flex" gap={2} alignItems="center">
          <SearchFilter onSearchChange={debounceSearch} />
          <AutoCompleteEventSelect
            events={events}
            selectedEventId={filters.product}
            onEventSelect={(eventId) => handleFilterChange(eventId, "product")}
          />
          <SelectFilter
            value={filters.status}
            onChange={(value) => handleFilterChange(value, "status")}
            options={[{ id: "all", name: t("STATUS") }, ...statusOptions]}
          />
          <SelectFilter
            value={filters.premiseId}
            onChange={(value) => handleFilterChange(value, "premiseId")}
            options={[{ id: "all", name: t("AGENT") }, ...agentOptions]}
          />
        </Box>

        <Button
          className="oniria-border-btn"
          size="small"
          sx={{ textTransform: "capitalize", padding: "0 10px" }}
          startIcon={<FileDownload />}
          onClick={() => setEnabledDownload(true)}
        >
          {t("DOWNLOAD")}
        </Button>
        {enabledDownload && isSuccess && (
          <>
            <CSVLink
              data={tableDataCsv}
              filename="b2b_sales.csv"
              target="_blank"
              separator=";"
              headers={tableHeadersCsv}
              className="csv-link"
              ref={csvRef}
              style={{ display: "none" }}
            />
            {setTimeout(() => {
              if (csvRef.current) {
                csvRef.current.link.click();
                setEnabledDownload(false);
              }
            }, 100)}
          </>
        )}
      </Box>

      {total > 0 && (
        <Grid
          item
          xs={11}
          sm={12}
          container
          justifyContent={{ xs: "center", sm: "space-between" }}
          alignItems={"center"}
          pb={2}
          gap={{ xs: 2, sm: 0 }}
        >
          <Typography>
            {t("TOTAL_B2B_SALES")}: {total}
          </Typography>
          {numberPages >= 1 && (
            <Grid item xs={12} md={8} container justifyContent="flex-end">
              <Grid item>
                <SelectPerPage text={t("SALES")} change={changePerPage} value={perPage} />
              </Grid>
              <Pagination
                count={numberPages}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </Grid>
      )}
      {isRefetching ? (
        <Loading />
      ) : sales && sales.length > 0 ? (
        <B2BSalesTable
          sales={sales}
          setShowDetailOrder={setShowDetailOrder}
          refetchSales={refetch}
        />
      ) : (
        <Typography>{t("NO_DATA_AVAILABLE")}</Typography>
      )}
      {numberPages >= 1 && (
        <Grid container justifyContent="center" mb={2}>
          <Pagination
            count={numberPages}
            mr={3}
            sx={PAGINATION_STYLES}
            page={page}
            onChange={changePage}
          />
        </Grid>
      )}
    </Box>
  );
};

export default B2BSales;
