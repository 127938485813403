import { SERVICE } from "../constants/api";
import { http } from "../constants/axios";
import { changeEmptyStringNull, getCurrentDateTime } from "../constants/utils";

export async function getUsersAffiliate(
  page = 1,
  perPage = 10,
  search,
  selectedStatus,
  selectedDomain,
  order
) {
  const params = new URLSearchParams();
  params.append("page", page);
  params.append("perPage", perPage);
  if (search && search !== "") params.append("search", search);
  if (selectedStatus && selectedStatus !== "all") params.append("status", selectedStatus);
  if (selectedDomain && selectedDomain !== "all") params.append("domain_id", selectedDomain);
  if (order && order.asc && order.asc.length > 0) {
    params.append("asc", order.asc.join(","));
  }
  if (order && order.desc && order.desc.length > 0) {
    params.append("desc", order.desc.join(","));
  }

  const { data } = await http.get(`${SERVICE.USERS_AFFILIATE}?${params}`);
  return data;
}

export async function createUser(body) {
  const bodyToSend = changeEmptyStringNull(body);
  const { data } = await http.post(`${SERVICE.CREATE_USER}`, bodyToSend);
  return data;
}
export async function putUser(id, body) {
  const { data } = await http.put(`${SERVICE.EDIT_USER}${id}`, body);
  return data;
}
export async function getPendingUsers() {
  const { data } = await http.get(`${SERVICE.PENDING_USERS}`);
  return data;
}

export async function validatePendingUser(id, body) {
  const { data } = await http.put(`${SERVICE.VALIDATE_USER}${id}`);
  return data;
}

export async function rejectPendingUser(id, body) {
  const { data } = await http.put(`${SERVICE.REJECT_USER}${id}`, body);
  return data;
}
export async function getUsersPremise(id) {
  const { data } = await http.get(`${SERVICE.USERS_PREMISE}${id}`);
  return data;
}

// Card
export async function buyAffiliatedCard(body) {
  const { data } = await http.post(`${SERVICE.ORDERS_CARD}`, body);
  return data;
}

// Grouping name
export async function getGroupingName() {
  const { data } = await http.get(`${SERVICE.USER}grouping`);
  return data;
}

export async function getCanPublishTrips() {
  try {
    const { data } = await http.get(`${SERVICE.USER}can-publish-trips`);
    return data;
  } catch (error) {
    console.error("Error fetching can-publish-trips:", error);
    return false;
  }
}

export async function verifyUop(id, body) {
  const { data } = await http.put(`${SERVICE.USER}verify-uop/${id}`, body);
  return data;
}

export async function confirmWelcomePack(id) {
  const body = {
    date: getCurrentDateTime(),
  };
  const { data } = await http.put(`${SERVICE.USER}welcome-pack-date/${id}`, body);
  return data;
}
