import { Add } from "@mui/icons-material";
import { Box, Button, Grid, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PAGINATION_STYLES } from "../../../constants/styles";
import { ASSIGN_TRIP_TYPES, EXTRAS_TYPES } from "../../../constants/variables";
import useQueryExtras from "../../../hooks/queries/extras/useQueryExtras";
import useDebounce from "../../../hooks/useDebouncing";
import usePagination from "../../../hooks/usePagination";
import { getExtraById } from "../../../services/extrasServices";
import SearchFilter from "../../events/filters/filter/SearchFilter";
import { ExtraFormDialog } from "../../extras/ExtraFormDialog";
import AssignComponent from "../../shared/AssignComponent";
import { CustomSwitch } from "../../shared/switch/CustomSwitch";
import { toastMessageError } from "../../shared/toastMessage";
import { FormFieldHeader } from "../FormFieldHeader";
import { TripExtraDialog } from "./TripExtraDialog";

export const TripExtrasActivities = ({ handleRemoveExtra }) => {
  const { t } = useTranslation();
  const [showExtras, setShowExtras] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openExtraDialog, setOpenExtraDialog] = useState({
    isOpen: false,
    extraIndex: null,
  });

  const { getValues, setValue } = useFormContext();
  const tripInfo = getValues();

  // Extra templates
  const [filters, setFilters] = useState({
    search: "",
    tags: EXTRAS_TYPES.ACTIVITIES,
    desc: ["created_at"],
  });
  const { page, changePage, perPage } = usePagination(4);

  const { data, refetch } = useQueryExtras(page, perPage, filters);
  const { amount = 0, data: extrasTemplates = [] } = data || {};
  const debounceSearch = useDebounce(
    (event) => setFilters({ ...filters, search: event.target.value }),
    300
  );
  const totalPages = Math.ceil(amount / perPage);

  // Assign extra to the itinerary
  const handleAssignExtra = async (extraTemplateId, itinerary) => {
    try {
      const extraTemplate = await getExtraById(extraTemplateId);
      const extras = getValues("extras") || [];
      const eventId = getValues("id") || "";

      const newExtra = {
        name: extraTemplate.name || "",
        description: extraTemplate.description || "",
        description_design: extraTemplate.description_design || "",
        extra_language_infos: extraTemplate.extra_template_language_infos || [],
        extra_template_id: extraTemplate.id,
        short_description: extraTemplate.short_description || "",
        images: extraTemplate.images || "",
        position: extras.length || 0,
        price: 0,
        tags: extraTemplate.tags,
        event_id: eventId,
        only_international: extraTemplate.only_international || false,
        stock_by_ticket_max: extraTemplate.stock_by_ticket_max || 0,
        is_visible: true,
      };

      setValue("extras", [...extras, newExtra]);

      const itineraryId = itinerary?.split("-")[1];
      // Assign extra to the itinerary
      const itineraries = getValues("itineraries") || [];
      const newItineraries = itineraries.map((itinerary) => {
        if (
          itineraryId.length > 4
            ? itinerary.id?.includes(itineraryId)
            : Number(itinerary.position) === Number(itineraryId)
        ) {
          itinerary.extra_ids = [...(itinerary?.extra_ids || []), extraTemplateId];
        }
        return itinerary;
      });
      setValue("itineraries", newItineraries);

      //Open dialog to edit the new extra
      setOpenExtraDialog({ isOpen: true, extraIndex: extras.length });
    } catch (e) {
      toastMessageError(e?.response?.data?.error || t("ERROR"));
    }
  };

  useEffect(() => {
    if (tripInfo?.extras && tripInfo.extras.length > 0) {
      const open = tripInfo.extras.some((extra) => extra.tags?.includes(EXTRAS_TYPES.ACTIVITIES));
      setShowExtras(open);
    }
  }, []);

  return (
    <Grid item xs={12}>
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <Box>
          <FormFieldHeader
            title={t("ACTIVITIES")}
            description={t("EXTRA_ACTIVITIES_DESCRIPTION")}
          />
        </Box>
        <CustomSwitch
          checked={showExtras}
          handleChange={() => {
            setShowExtras(!showExtras);
            if (showExtras) refetch();
          }}
        />
      </Grid>
      {showExtras && (
        <Grid container>
          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <Grid container spacing={2} sx={{ width: "45%", justifyContent: "start" }}>
              <Grid item xs={12} sm={6}>
                <SearchFilter onSearchChange={debounceSearch} />
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent="flex-end">
                <Button
                  className="oniria-border-btn"
                  onClick={() => setOpenCreateDialog(true)}
                  startIcon={<Add />}
                  size="small"
                  sx={{ borderRadius: 3 }}
                >
                  {t("CREATE_ACTIVITY")}
                </Button>
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end">
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={changePage}
                  sx={PAGINATION_STYLES}
                />
              </Grid>
            </Grid>
          </Box>

          <Grid item xs={12} container>
            <AssignComponent
              tripInfo={tripInfo}
              itemsToAssign={extrasTemplates}
              handleAssign={handleAssignExtra}
              handleRemove={handleRemoveExtra}
              type={ASSIGN_TRIP_TYPES.ACTIVITY}
            />
          </Grid>
        </Grid>
      )}
      <ExtraFormDialog
        isOpen={openCreateDialog}
        onClose={() => {
          setOpenCreateDialog(false);
          refetch();
        }}
        extraType={EXTRAS_TYPES.ACTIVITIES}
      />
      <TripExtraDialog
        isOpen={openExtraDialog.isOpen}
        onClose={() => setOpenExtraDialog({ isOpen: false, extraIndex: null })}
        extraIndex={openExtraDialog.extraIndex}
      />
    </Grid>
  );
};
