import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryCountries } from "../../../hooks/queries/utils/useQueryCountries";
import { AssistantTextField } from "./AssistantTextField";
import { focusColor } from "../../shared/textFieldStyle";
import { USER_DOCUMENT_TYPES } from "../../../constants/variables";

export const AssistantData = ({
  formData,
  setFormErrors,
  setFormData,
  ticket,
  index,
  formErrors,
  addClicked,
  handleFieldChange,
}) => {
  const { t } = useTranslation();
  const [showGrid, setShowGrid] = useState(false);
  const [selectedCountryNationality, setSelectedCountryNationality] = useState("");
  const [selectedDocument, setSelectedDocument] = useState("");

  const { data: countries = [] } = useQueryCountries();

  const handleCheckboxChange = (event) => {
    setShowGrid(event.target.checked);
  };

  const handleChangeCountryNationality = (event, ticketId) => {
    setSelectedCountryNationality(event.target.value);
    formData.tickets[ticketId]["client_nationality"] = event.target.value
      ?.split("-")[0]
      .toUpperCase();

    if (event.target.value !== "") {
      setFormErrors((prev) => ({
        ...prev,
        prev: (prev.tickets[ticketId].client_nationality = ""),
      }));
    }
  };
  const handleCheckboxUserGender = (event, ticketIndex) => {
    const genderValue = event.target.value;
    let updatedTickets = JSON.parse(JSON.stringify(formData.tickets));
    updatedTickets[ticketIndex]["client_gender"] = genderValue;

    setFormData({
      ...formData,
      tickets: updatedTickets,
    });
  };
  const handleChangeDocument = (event, ticketId) => {
    setSelectedDocument(event.target.value);
    formData.tickets[ticketId]["client_document_type"] = event.target.value;

    if (event.target.value !== "") {
      setFormErrors((prev) => ({
        ...prev,
        prev: (prev.tickets[ticketId].client_document_type = ""),
      }));
    }
  };

  return (
    <>
      <Typography>{t("ASSISTANT_DATA")}</Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={showGrid}
            onChange={handleCheckboxChange}
            className="checkbox-oniria"
          />
        }
        label={t("INTRODUCE_CLIENT_DATA")}
      />
      {showGrid && (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Grid item xs={12} md={6}>
            <AssistantTextField
              label={t("NAME")}
              fieldName="client_name"
              value={ticket.client_name}
              ticketIndex={index}
              type="text"
              errorProperty={formErrors.tickets[index].client_name}
              errorText={t("MAX_50")}
              regex={/^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{0,50}$/}
              handleFieldChange={handleFieldChange}
              addClicked={addClicked}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AssistantTextField
              label={t("SURNAME")}
              fieldName="client_surname"
              value={ticket.client_surname}
              ticketIndex={index}
              type="text"
              errorProperty={formErrors.tickets[index].client_surname}
              errorText={t("MAX_50")}
              regex={/^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{0,50}$/}
              handleFieldChange={handleFieldChange}
              addClicked={addClicked}
            />
          </Grid>
          <Grid item xs={12}>
            <AssistantTextField
              label={t("EMAIL")}
              fieldName="client_email"
              value={ticket.client_email}
              ticketIndex={index}
              type="text"
              errorProperty={formErrors.tickets[index].client_email}
              errorText={t("INCORRECT")}
              regex={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
              handleFieldChange={handleFieldChange}
              addClicked={addClicked}
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <InputLabel>{t("BORN_AT")}</InputLabel>
            <AssistantTextField
              label=""
              fieldName="client_birth_date"
              value={ticket.client_birth_date}
              ticketIndex={index}
              type="date"
              errorProperty={formErrors.tickets[index].client_birth_date}
              errorText={t("INCORRECT")}
              regex={null}
              handleFieldChange={handleFieldChange}
              addClicked={addClicked}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ mb: 2 }}>{t("NATIONALITY")}</InputLabel>
            <FormControl
              fullWidth
              variant="outlined"
              sx={focusColor}
              error={formErrors.tickets[index].client_nationality === "empty" && addClicked}
            >
              <InputLabel id="demo-select-small-label">{t("NATIONALITY")}</InputLabel>
              <Select
                label={t("NATIONALITY")}
                value={selectedCountryNationality ? selectedCountryNationality : ""}
                onChange={(e) => handleChangeCountryNationality(e, index)}
                renderValue={(selected) => (
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <img
                      style={{
                        height: "20px",
                        width: "35px",
                        objectFit: "cover",
                        marginRight: 10,
                      }}
                      alt="Flag"
                      src={`/media/flags/${selected.split("-")[0]}.svg`}
                    />
                    <Typography>{selected.split("-")[1]}</Typography>
                  </Grid>
                )}
              >
                {countries?.map((country, index) => (
                  <MenuItem
                    key={`${index}-${country.country_code}`}
                    value={country.country_code.toLowerCase() + "-" + country.official_name_en}
                  >
                    <img
                      style={{
                        height: "20px",
                        width: "35px",
                        objectFit: "cover",
                        marginRight: 10,
                      }}
                      alt="Flag"
                      src={`/media/flags/${country.country_code.toLowerCase()}.svg`}
                    />
                    {country.official_name_en}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} mt={1}>
            <InputLabel>{t("IDENTIFY")}</InputLabel>
            <Grid container sx={{ mt: 2, mb: 2 }}>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="genre"
                      checked={formData.tickets[index].client_gender === "woman"}
                      onChange={(e) => handleCheckboxUserGender(e, index)}
                      value="woman"
                      className="checkbox-oniria"
                    />
                  }
                  label={t("WOMAN")}
                />
              </Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="genre"
                      checked={formData.tickets[index].client_gender === "man"}
                      onChange={(e) => handleCheckboxUserGender(e, index)}
                      value="man"
                      className="checkbox-oniria"
                    />
                  }
                  label={t("MAN")}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="genre"
                      checked={formData.tickets[index].client_gender === "other"}
                      onChange={(e) => handleCheckboxUserGender(e, index)}
                      value="other"
                      className="checkbox-oniria"
                    />
                  }
                  label={t("OTHER")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t("DOCUMENT")}</InputLabel>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              sx={focusColor}
              error={formErrors.tickets[index].client_document_type === "empty" && addClicked}
            >
              <InputLabel id="demo-select-small-label">{t("TYPE")}</InputLabel>
              <Select
                label={t("TYPE")}
                value={
                  formData.tickets[index].client_document_type
                    ? formData.tickets[index].client_document_type
                    : selectedDocument
                }
                onChange={(e) => handleChangeDocument(e, index)}
              >
                {USER_DOCUMENT_TYPES.map((document, index) => (
                  <MenuItem key={`document-${index}`} value={document}>
                    {t(document)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8} sx={{ mt: -1 }}>
            <AssistantTextField
              label={t("IDENTIFICATION_NUMBER")}
              fieldName="client_document_id"
              value={formData.client_document_id}
              ticketIndex={index}
              type="text"
              errorProperty={formErrors.tickets[index].client_document_id}
              errorText={t("INCORRECT")}
              regex={/^(?:[0-9XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]|[A-Z0-9]{3,20})$/i}
              handleFieldChange={handleFieldChange}
              addClicked={addClicked}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t("EXPIRY_DATE_CARD")}</InputLabel>
            <AssistantTextField
              label=""
              fieldName="client_document_expiry_date"
              value={formData.client_document_expiry_date}
              ticketIndex={index}
              type="date"
              errorProperty={formErrors.tickets[index].client_document_expiry_date}
              errorText={t("INCORRECT")}
              regex={null}
              handleFieldChange={handleFieldChange}
              addClicked={addClicked}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
