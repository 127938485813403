import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createAdminErrors, createAdminUser } from "../../classes/userClass";
import Header from "../../components/Header";
import LegalPerson from "../../components/account/LegalPerson";
import { transformObjectWithUrls } from "../../components/shared/FormsValidator";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { ROUTES } from "../../constants/routes";
import { changeEmptyStringNull, changeRolesToIds } from "../../constants/utils";
import { createAdmin } from "../../services/adminsServices";
import { givePermissions } from "../../services/domainsServices";

const CreateAdmin = ({ isAdminSimple }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(createAdminUser)));
  const [formParamsErrors, setFormParamsErrors] = useState(
    JSON.parse(JSON.stringify(createAdminErrors))
  );
  const [selectedDomains, setSelectedDomains] = useState([]);

  const breadcrumbs = [
    {
      name: t("ADMINISTRATOR_MANAGER"),
      link: "/admins",
    },
    {
      name: t("CREATE_ADMIN"),
      link: "/create_admin",
    },
  ];

  const setFormParams = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    object[field] = e.target[atribute];
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrors = (objectName, field, value) => {
    let object = formParamsErrors[objectName];
    object[field] = value;
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const handleSubmit = async () => {
    try {
      let adminToSend = await transformObjectWithUrls(formData.user);
      // Es premise_ids como lo gestiona
      adminToSend.premises_ids = adminToSend.premises_id;

      // Change roles name to roles id
      adminToSend.roles = changeRolesToIds(adminToSend.roles);

      const adminWithNull = changeEmptyStringNull(adminToSend);
      const response = await createAdmin(adminWithNull);

      if (response !== undefined) {
        if (isAdminSimple) {
          for (const domain of selectedDomains) {
            const body = {
              user_id: response.id,
              domain_id: domain,
              can_edit: false,
            };
            await givePermissions(body);
          }
        }
        toastMessageSuccess(t("EDIT_SUCCESS"));
        setTimeout(() => {
          navigate(ROUTES.ADMINS);
        }, 1500);
      }
    } catch (error) {
      toastMessageError(error.response?.data?.error || t("EDIT_ERROR"));
    }
  };

  const formController = {
    formData,
    formParams: setFormParams,
    validation: formParamsErrors,
    setFormErrors,
    isFirstStep: () => true,
    isLastStep: () => true,
    finish: handleSubmit,
    setFormParamsNotEvent,
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_CREATE_ADMIN")} />
      <LegalPerson
        formController={formController}
        isAdminSimple={isAdminSimple}
        editPremises
        selectedDomains={selectedDomains}
        setSelectedDomains={setSelectedDomains}
      />
    </>
  );
};

export default CreateAdmin;
