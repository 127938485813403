import { z } from "zod";
import { DEFAULT_LANGUAGE, EVENTS_TYPE } from "../constants/variables";

export const blockSchema = z
  .object({
    id: z.string().nullable().optional(),
    event_id: z.string().nullable().optional(),
    title: z.string(),
    subtitle: z.string().nullable().optional(),
    description: z.any(),
    images_urls: z.any(),
    position: z.union([z.string(), z.number()]).nullable().optional(),
    language: z.string().nullable().optional(),
  })
  .nullable()
  .optional();

export const groupDiscountSchema = z
  .object({
    id: z.string().nullable().optional(),
    ticket_id: z.string().nullable().optional(),
    min_size: z.union([z.string(), z.number()]).nullable().optional(),
    max_size: z.union([z.string(), z.number()]).nullable().optional(),
    discount: z.union([z.string(), z.number()]).nullable().optional(),
  })
  .nullable()
  .optional();

export const promotionalCodeDiscountSchema = z
  .object({
    id: z.string().nullable().optional(),
    ticket_id: z.string().nullable().optional(),
    code: z.string(),
    discount: z.union([z.string(), z.number()]).nullable().optional(),
    is_active: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
  })
  .nullable()
  .optional();

export const ticketLanguageInfoSchema = z
  .object({
    id: z.string().nullable().optional(),
    ticket_id: z.string().nullable().optional(),
    language: z.string(),
    name: z.string(),
    description: z.any(),
    tickets_policy: z.string().nullable().optional(),
  })
  .nullable()
  .optional();

export const ticketSchema = z.object({
  created_at: z.string().nullable().optional(),
  default_language: z.string().nullable().optional().default(DEFAULT_LANGUAGE),
  description: z.any(),
  domain_names: z.array(z.string()).nullable().optional(),
  early_payment_discount_date: z.string().nullable().optional(),
  early_payment_discount_date_time: z.string().nullable().optional(),
  early_payment_discount: z.union([z.string(), z.number()]).nullable().optional(),
  end_date: z.string().nullable().optional(),
  event_id: z.string().nullable().optional(),
  group_discounts: z.array(groupDiscountSchema).nullable().optional(),
  id: z.string().nullable().optional(),
  initial_stock: z.union([
    z.string().min(0, "REQUIRED_ERROR"),
    z.number().min(0, "REQUIRED_ERROR"),
  ]),
  international_discount: z.union([z.string(), z.number()]).nullable().optional(),
  is_visible: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
  iva: z.union([z.string().min(0, "REQUIRED_ERROR"), z.number().min(0, "REQUIRED_ERROR")]),
  last_modified_at: z.string().nullable().optional(),
  name: z.string().min(1, "REQUIRED_ERROR"),
  only_in_app: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
  only_international: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
  position: z.union([z.string(), z.number()]).nullable().optional(),
  price: z.union([z.string().min(1, "REQUIRED_ERROR"), z.number().min(0, "REQUIRED_ERROR")]),
  promotional_code_discounts: z.array(promotionalCodeDiscountSchema).nullable().optional(),
  start_date: z.string().nullable().optional(),
  ticket_language_infos: z.array(ticketLanguageInfoSchema).nullable().optional(),
  tickets_policy: z.string().nullable().optional(),
  // ONLY FOR FRONTEND USE
  start_date_time: z.string().nullable().optional(),
  end_date_time: z.string().nullable().optional(),
  second_payment_end_date_time: z.string().nullable().optional(),
  // DEPRECATED
  promotional_code_discount: z.union([z.string(), z.number()]).nullable().optional(),
  promotional_code: z.string().nullable().optional(),
  second_payment_end_date: z.string().nullable().optional(), //-> ver como queda con n payments
  // DEPRECATED
});

export const itineraryLanguageSchema = z.object({
  id: z.string().nullable().optional(),
  itinerary_id: z.string().nullable().optional(),
  language: z.string().nullable().optional(),
  title: z.string().nullable().optional(),
  subtitle: z.string().nullable().optional(),
  description: z.any(),
});

export const itinerarySchema = z
  .object({
    id: z.string().nullable().optional(),
    event_id: z.string().nullable().optional(),
    title: z.string().min(1, "REQUIRED_ERROR"),
    description: z.any(),
    position: z.union([z.string(), z.number()]).nullable().optional(),
    language: z.string().nullable().optional(),
    nights: z.union([z.string(), z.number()]).nullable().optional(),
    tour_point_id: z.string().nullable().optional(),
    transport_ids: z.array(z.string()).nullable().optional(),
    hotel_ids: z.array(z.string()).nullable().optional(),
    extra_ids: z.array(z.string()).nullable().optional(),
    itinerary_language_infos: z.array(itineraryLanguageSchema).optional().nullable(),
    // DEPRECATED
    subtitle: z.string().nullable().optional(),
    start_point: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
    end_point: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
  })
  .nullable()
  .optional();

export const extraLanguageSchema = z
  .object({
    id: z.string().nullable().optional(),
    extra_id: z.string().nullable().optional(),
    language: z.string(),
    name: z.string(),
    short_description: z.string().nullable().optional(),
    description: z.any(),
    description_design: z.any(),
  })
  .nullable()
  .optional();

export const extraSchema = z
  .object({
    id: z.string().nullable().optional(),
    name: z.string().min(1, "REQUIRED_ERROR"),
    event_id: z.string().nullable().optional(),
    tickets_ids: z.array(z.string()).nullable().optional(),
    short_description: z.string().nullable().optional(),
    description: z.any(),
    description_design: z.any(),
    images: z.any(),
    stock_by_ticket_max: z.union([z.string(), z.number()]).optional().nullable(),
    initial_stock: z
      .union([z.string().min(1, "REQUIRED_ERROR"), z.number().min(0, "REQUIRED_ERROR")])
      .default(0),
    price: z.union([z.string().min(1, "REQUIRED_ERROR"), z.number().min(0, "REQUIRED_ERROR")]),
    is_visible: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
    international_discount: z.union([z.string(), z.number()]).nullable().optional(),
    only_international: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
    last_modified_at: z.string().nullable().optional(),
    created_at: z.string().nullable().optional(),
    times_can_be_consumed: z.union([z.string(), z.number()]).nullable().optional(),
    default_language: z.string().nullable().optional().default(DEFAULT_LANGUAGE),
    position: z.union([z.string(), z.number()]).nullable().optional(),
    no_after_sales: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
    extra_template_id: z.string().nullable().optional(),
    domain_names: z.array(z.string()).nullable().optional(),
    extra_language_infos: z.array(extraLanguageSchema).nullable().optional(),
    tags: z.string().nullable().optional(),
    hotel_id: z.string().nullable().optional(),
  })
  .nullable()
  .optional();

export const eventCategorySchema = z
  .object({
    id: z.string().nullable().optional(),
    event_id: z.string().nullable().optional(),
    category_id: z.string().nullable().optional(),
    category_name: z.string().nullable().optional(),
    subcategory_id: z.string().nullable().optional(),
    subcategory_name: z.string().nullable().optional(),
  })
  .nullable()
  .optional();

export const eventLanguageInfoSchema = z
  .object({
    id: z.string().nullable().optional(),
    event_id: z.string().nullable().optional(),
    language: z.string(),
    name: z.string(),
    subtitle: z.string().nullable().optional(),
    description: z.any(),
    short_description: z.string().nullable().optional(),
    outfit: z.string().nullable().optional(),
    not_included_options: z.any(),
    additional_info: z.string().nullable().optional(),
  })
  .nullable()
  .optional();

export const paymentSchema = z
  .array(
    z.object({
      id: z.string().nullable().optional(),
      event_id: z.string().nullable().optional(),
      position: z.union([z.string(), z.number()]).nullable().optional(),
      percentage: z
        .union([z.string(), z.number()])
        .transform((val) => Number(val))
        .refine((val) => val >= 0 && val <= 100, {
          message: "PERCENTAGE_ERROR_100",
        }),
      number_of_payments: z
        .union([z.string(), z.number()])
        .transform((val) => Number(val))
        .nullable()
        .optional(),
      days_before_start: z.union([z.string(), z.number()]).nullable().optional(),
    })
  )
  .nullable()
  .optional()
  .refine(
    (payments) => {
      if (!payments || payments.length === 0) return true;

      const groupedPayments = payments.reduce((acc, payment) => {
        const groupKey = payment.number_of_payments;
        if (!groupKey) return acc;

        if (!acc[groupKey]) acc[groupKey] = 0;
        acc[groupKey] += Number(payment.percentage) || 0;

        return acc;
      }, {});

      return Object.values(groupedPayments).every((total) => total === 100);
    },
    { message: "ERROR_SUM_PERCENTAGE" }
  );

export const tripSchema = z.object({
  additional_info: z.any(),
  autovalidation: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
  blocks: z.array(blockSchema).nullable().optional(),
  city_id: z.string().nullable().optional(),
  default_language: z.string().nullable().optional().default("ES"),
  description: z.any(), //
  end_date_time: z.string().nullable().optional(),
  end_date: z.string().nullable().optional(),
  end_of_publication_date_time: z.string().nullable().optional(),
  end_of_publication_date: z.string().nullable().optional(),
  end_sale_date_time: z.string().nullable().optional(),
  end_sale_date: z.string().nullable().optional(),
  event_categories: z.array(eventCategorySchema).nullable().optional(), //
  event_language_infos: z.array(eventLanguageInfoSchema).nullable().optional(),
  event_tag: z.string().nullable().optional(),
  event_type: z.string().default(EVENTS_TYPE.TRIP_COMPOSITE),
  extras: z.array(extraSchema).optional().nullable(),
  grouping_id: z.string().nullable().optional(),
  header_url: z.string().nullable().optional(),
  id: z.string().nullable().optional(),
  image_url: z.any(),
  included_options: z.any(),
  is_active: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
  is_international: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
  is_visible: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(), //
  itineraries: z.array(itinerarySchema).nullable().optional(), //
  last_modified_at: z.string().nullable().optional(),
  latitude: z.union([z.string(), z.number()]).nullable().optional(),
  limit_tickets_order: z.union([
    z.string().min(1, "REQUIRED_ERROR"),
    z.number().min(0, "REQUIRED_ERROR"),
  ]), //
  location: z.string().nullable().optional(),
  longitude: z.union([z.string(), z.number()]).nullable().optional(),
  multi_date: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(), //
  name: z.string().min(1, "REQUIRED_ERROR"), //
  not_included_options: z.any(),
  outfit: z.string().nullable().optional(),
  passport_required: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
  pensions_type: z.string().nullable().optional(),
  premise_id: z.string().min(1, "REQUIRED_ERROR"), //
  principal_url: z.any(), //
  publication_date_time: z.string().nullable().optional(),
  publication_date: z.string().nullable().optional(),
  rooms_type: z.string().nullable().optional(),
  seo_name: z.string().nullable().optional(), //
  short_description: z.string().max(80, "MAX_80").nullable().optional(), //
  sku: z.string().nullable().optional(), //
  slider_urls: z.any(), //
  start_date_time: z.string().nullable().optional(),
  start_date: z.string().nullable().optional(),
  start_sale_date_time: z.string().nullable().optional(),
  start_sale_date: z.string().nullable().optional(),
  subtitle: z.string().nullable().optional(), //
  tickets: z.array(ticketSchema).min(1, "REQUIRED_TICKET_ERROR"),
  total_capacity: z.union([z.string(), z.number()]).nullable().optional(),
  tour_leader_ids: z.array(z.string()).nullable().optional(),
  video_url: z.string().nullable().optional(),
  without_night: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
  parent_event_id: z.string().nullable().optional(), //
  pvp: z.union([z.string(), z.number()]).nullable().optional(), //
  event_payments: paymentSchema,
  app_contact_form: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
  // B2B fields
  is_b2b: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
  is_catalogue_visible: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),
  agents_that_can_see_ids: z.array(z.string()).nullable().optional(),
  // OLD DOUBLE PAYMENT METHOD
  second_payment_end_date_time: z.string().nullable().optional(),
  second_payment_end_date: z.string().nullable().optional(),
  first_payment_percentage: z.union([z.string(), z.number()]).nullable().optional(),
  has_double_payment: z.union([z.string(), z.number(), z.boolean()]).nullable().optional(),

  // ONLY FOR FRONTEND
  dates: z
    .array(
      z
        .object({
          start_date: z.string().min(1, "REQUIRED_ERROR"),
          start_date_time: z.string().min(1, "REQUIRED_ERROR"),
          end_date: z.string().min(1, "REQUIRED_ERROR"),
          end_date_time: z.string().min(1, "REQUIRED_ERROR"),
        })
        .optional()
    )
    .min(1, { message: "REQUIRED_ERROR" }),

  // DEPRECATED
  min_age: z.union([z.string(), z.number()]).nullable().optional(),
  rating: z.union([z.string(), z.number()]).nullable().optional(),
  nights: z.union([z.string(), z.number()]).nullable().optional(),
  countries: z.union([z.string(), z.number()]).nullable().optional(),
  days: z.union([z.string(), z.number()]).nullable().optional(),
  cities: z.union([z.string(), z.number()]).nullable().optional(),
  // DEPRECATED
});

export const DEFAULT_TICKET = {
  name: "",
  description: "",
  price: "",
  tickets_policy: "",
  is_visible: false,
  international_discount: "",
  early_payment_discount: "",
  early_payment_discount_date: "",
  early_payment_discount_date_time: "",
  group_discounts: [],
  initial_stock: "",
  promotional_code: "",
  promotional_code_discount: "",
  promotional_code_discounts: [],
  iva: "",
  only_in_app: "",
  ticket_language_infos: [],
  position: "",
  domain_names: [],
  start_date: "",
  end_date: "",
  only_international: "",
  second_payment_end_date: "",
};

export const DEFAULT_EXTRA = {
  name: "",
  description: "",
  description_design: "",
  short_description: "",
  images: "",
  price: 0,
  is_visible: false,
  only_international: false,
  stock: "",
  stock_by_ticket_max: 0,
  initial_stock: "",
  times_can_be_consumed: "",
  extra_language_infos: [],
  no_after_sales: "",
  extra_template_id: "",
  domain_names: [],
};

export const TRIP_STEPS_VALIDATION = [
  [
    "name",
    "premise_id",
    "event_categories",
    "principal_url",
    "slider_urls",
    "description",
    "sku",
    "subtitle",
    "short_description",
    "event_url",
    "seo_name",
    "dates",
  ],
  ["itineraries"],
  ["pvp", "limit_tickets_order", "tickets"],
  ["extras"],
  ["itineraries"],
  [],
];

export const DEFAULT_EVENT_LANGUAGE_INFO = {
  id: "",
  event_id: "",
  language: "",
  name: "",
  subtitle: "",
  description: "",
  short_description: "",
  outfit: "",
  additional_info: "",
  not_included_options: "",
};

export const DEFAULT_TICKET_LANGUAGE_INFO = {
  id: "",
  ticket_id: "",
  language: "",
  name: "",
  description: "",
  tickets_policy: "",
};

export const DEFAULT_EXTRA_LANGUAGE_INFO = {
  id: "",
  extra_id: "",
  language: "",
  name: "",
  short_description: "",
  description: "",
  description_design: "",
};

export const DEFAULT_ITINERARY_LANGUAGE = {
  id: "",
  itinerary_id: "",
  language: "",
  title: "",
  description: "",
};

export const DEFAULT_TRIP = {
  default_language: DEFAULT_LANGUAGE,
  agents_that_can_see_ids: [],
  event_payments: [],
  dates: [
    {
      start_date: "",
      start_date_time: "",
      end_date: "",
      end_date_time: "",
    },
  ],
};
