import { SERVICE } from "../constants/api";
import { http } from "../constants/axios";

export async function createRefund(body) {
  const { data } = await http.post(`${SERVICE.REFUNDS}`, body);
  return data;
}

export async function getRefunds(search, page, perPage, order, status, premiseId, eventId) {
  let params = new URLSearchParams();

  if (search) params.append("search", search);
  if (page) params.append("page", page);
  if (perPage) params.append("perpage", perPage);
  if (order.asc) params.append("asc", order.asc);
  if (order.desc) params.append("desc", order.desc);
  if (status) params.append("refundstatus", status);
  if (premiseId) params.append("premiseid", premiseId);
  if (eventId) params.append("eventid", eventId);

  const { data } = await http.get(`${SERVICE.REFUNDS}?${params.toString()}`);
  return data;
}

export async function getRefundID(id) {
  const { data } = await http.get(`${SERVICE.REFUNDS}/${id}`);
  return data;
}

export async function applyRefund(id, body) {
  const { data } = await http.put(`${SERVICE.REFUNDS}/${id}`, body);
  return data;
}
