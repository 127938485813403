import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getB2bEventAgentSales } from "../../../services/b2bServices";

export function useQueryEventB2bSalesAgent(eventId, userDomain, page, perPage, filters) {
  return useQuery(
    [RQ_KEY.B2B_SALES, eventId, userDomain, page, perPage, filters],
    () => getB2bEventAgentSales(eventId, userDomain, page, perPage, filters),
    {
      refetchOnWindowFocus: false,
      enabled: !!eventId,
    }
  );
}
