import { Button, Card, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getOrderFirstPaymentDate, isMultiplePayments } from "../../../constants/orders";
import { formatDate, formatHours } from "../../../constants/utils";

export const OrderSummary = ({ order }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    order_id,
    name,
    surname,
    email,
    phone_code,
    phone,
    payed_at,
    transaction_id,
    payment_type,
    first_payment_type,
    first_payment_transaction_id,
    first_payment_payed_at,
    buyer_id,
    tickets = [],
  } = order || {};

  return (
    <Card sx={{ borderRadius: 3, width: "100%" }}>
      <CardHeader
        title={t("ORDER_SUMMARY")}
        sx={{
          backgroundColor: "var(--grey-background)",
        }}
      />
      <CardContent>
        <Grid container spacing={2}>
          {/* Buyer */}
          <Grid item xs={12} container gap={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("BUYER")}
              </Typography>
            </Grid>
            <Grid item paddingLeft={2}>
              <Typography variant="body2" fontWeight="600">
                {t("NAME_SURNAME")}
              </Typography>
              <Typography variant="body2" mb={2}>
                {name}, {surname}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" fontWeight="600">
                {t("EMAIL")}
              </Typography>
              <Typography variant="body2" mb={2}>
                {email}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" fontWeight="600">
                {t("PHONE")}
              </Typography>
              <Typography variant="body2" mb={2}>
                {phone_code} {phone}
              </Typography>
            </Grid>
            <Button
              className="underline-button"
              onClick={() => navigate(`/users/info/${buyer_id}`)}
            >
              {t("SEE_BUYER_INFO")}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Divider variant="middle" flexItem />
          </Grid>

          {/* Bought */}
          <Grid item xs={12} container gap={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("BOUGHT")}
              </Typography>
            </Grid>

            <Grid item paddingLeft={2}>
              <Typography variant="body2" fontWeight="600" mb={2}>
                {t("TICKETS")}
              </Typography>
              {tickets.map((ticket, index) => (
                <Typography key={index} variant="body2">
                  {ticket.ticket_name}
                </Typography>
              ))}
            </Grid>

            <Grid item>
              <Typography variant="body2" fontWeight="600" sx={{ textTransform: "capitalize" }}>
                {t("INSURANCE")}
              </Typography>
              <Typography variant="body2" fontWeight="600" mb={2}></Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" fontWeight="600">
                {t("HOSTING")}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider variant="middle" flexItem />
          </Grid>

          {/* Order data */}
          <Grid item xs={12} container gap={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("ORDER_DATA")}
              </Typography>
            </Grid>
            <Grid item paddingLeft={2}>
              <Typography variant="body2" fontWeight="600">
                {t("DATE_AND_TIME")}
              </Typography>
              <Typography variant="body2" mb={2}>
                {formatDate(getOrderFirstPaymentDate(order))}{" "}
                {formatHours(getOrderFirstPaymentDate(order))}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" fontWeight="600">
                {t("ORDER_NUMBER")}
              </Typography>
              <Typography variant="body2" mb={2}>
                {order_id}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider variant="middle" flexItem />
          </Grid>

          {/* Payments */}
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                {t("PAYMENTS")}
              </Typography>
            </Grid>
            {isMultiplePayments(order) ? (
              order.payments
                .sort((a, b) => Number(a.position) - Number(b.position))
                .map((payment, index) => (
                  <Grid
                    key={index}
                    sx={{ marginBottom: 2, paddingLeft: 2 }}
                    item
                    xs={12}
                    container
                    gap={2}
                  >
                    <Grid item xs={12}>
                      <Typography variant="body2" fontWeight="800">
                        {index + 1}. {t("PAYMENT")}
                      </Typography>
                    </Grid>
                    {payment.payed_at ? (
                      <>
                        <Grid item paddingLeft={2}>
                          <Typography variant="body2" fontWeight="600">
                            {t("DATE_AND_TIME")}
                          </Typography>
                          <Typography variant="body2" mb={2}>
                            {formatDate(payment.payed_at)} {formatHours(payment.payed_at)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" fontWeight="600">
                            {t("PAYMENT_METHOD")}
                          </Typography>
                          <Typography variant="body2" mb={2}>
                            {t(payment.type?.toUpperCase())}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" fontWeight="600">
                            {t("TRANSACTION_ID")}
                          </Typography>
                          <Typography variant="body2">{payment.transaction_id}</Typography>
                        </Grid>
                      </>
                    ) : (
                      <Typography
                        variant="body2"
                        paddingLeft={2}
                        sx={{ color: "var(--red-error)" }}
                      >
                        {t("PENDING_PAYMENT")}
                      </Typography>
                    )}
                  </Grid>
                ))
            ) : (
              <Grid item sx={{ marginBottom: 1, paddingLeft: 2 }} container gap={2}>
                {first_payment_payed_at && (
                  <Grid item xs={12} container gap={2}>
                    <Grid item xs={12}>
                      <Typography variant="body2" fontWeight="800" mb={2}>
                        1. {t("PAYMENT")}
                      </Typography>
                    </Grid>
                    <Grid item paddingLeft={2}>
                      <Typography variant="body2" fontWeight="600">
                        {t("DATE_AND_TIME")}
                      </Typography>
                      <Typography variant="body2" mb={2}>
                        {formatDate(first_payment_payed_at)} {formatHours(first_payment_payed_at)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" fontWeight="600">
                        {t("PAYMENT_METHOD")}
                      </Typography>
                      <Typography variant="body2" mb={2}>
                        {t(first_payment_type?.toUpperCase())}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" fontWeight="600">
                        {t("TRANSACTION_ID")}
                      </Typography>
                      <Typography variant="body2">{first_payment_transaction_id}</Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} container gap={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2" fontWeight="800" mb={2}>
                      {first_payment_payed_at ? 2 : 1}. {t("SECOND_PAYMENT")}
                    </Typography>
                  </Grid>
                  <Grid item paddingLeft={2}>
                    <Typography variant="body2" fontWeight="600">
                      {t("DATE_AND_TIME")}
                    </Typography>
                    <Typography variant="body2" mb={2}>
                      {formatDate(payed_at)} {formatHours(payed_at)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" fontWeight="600">
                      {t("PAYMENT_METHOD")}
                    </Typography>
                    <Typography variant="body2" mb={2}>
                      {t(payment_type?.toUpperCase())}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" fontWeight="600">
                      {t("TRANSACTION_ID")}
                    </Typography>
                    <Typography variant="body2" mb={2}>
                      {transaction_id}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
