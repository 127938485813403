import { Check, Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PAGINATION_STYLES } from "../../../constants/styles";
import { B2B_AGREEMENT_OPTIONS, B2B_AGREEMENT_STATUS } from "../../../constants/variables";
import { useQueryB2bAgreements } from "../../../hooks/queries/b2b/useQueryB2bAgreements";
import usePagination from "../../../hooks/usePagination";
import SelectFilter from "../../events/filters/filter/SelectFilter";
import InfoToolTip from "../../shared/InfoToolTip";
import { Loading } from "../../shared/Loading";
import { AcceptAgreementDialog } from "../dialogs/AceptAgreementDialog";
import { CancelAgreementDialog } from "../dialogs/CancelAgreementDialog";

export const PendingAgreements = ({ setDoRefetch }) => {
  const { t } = useTranslation();
  const [openCancelled, setOpenCancelled] = useState({
    isOpen: false,
    agreement: null,
  });
  const [openCommission, setOpenCommission] = useState({
    isOpen: false,
    agreement: null,
  });
  const [filter, setFilter] = useState({
    status: B2B_AGREEMENT_STATUS.PENDING,
  });

  const initialPerPage = 5;
  const { page, perPage, changePage } = usePagination(initialPerPage);
  const { data, isLoading, isRefetching, refetch } = useQueryB2bAgreements(page, perPage, filter);
  const { amount: total = 0, data: trips = [] } = data || {};

  const numberPages = Math.ceil(parseFloat(total) / parseFloat(perPage));

  const statusOptions = B2B_AGREEMENT_OPTIONS.map((option) => ({
    ...option,
    name: t(option.name),
  }));

  return (
    <Box mb={3}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
        <Typography variant="body2" fontWeight="bold" mt={2}>
          {filter.status === B2B_AGREEMENT_STATUS.PENDING
            ? t("PENDING_AGREEMENTS")
            : t("CANCELLED_AGREEMENTS")}
        </Typography>
        <SelectFilter
          value={filter.status}
          onChange={(value) => setFilter({ ...filter, status: value })}
          options={statusOptions}
        />
      </Box>
      {isLoading || isRefetching ? (
        <Loading />
      ) : trips && trips.length > 0 ? (
        <>
          {numberPages > 1 ? (
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
              <Pagination
                count={numberPages}
                page={page}
                onChange={changePage}
                sx={PAGINATION_STYLES}
                size="small"
              />
            </Box>
          ) : null}
          <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
            <Table sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                  <TableCell align="center">{t("AGENT")}</TableCell>
                  <TableCell align="center">{t("TRIP_NAME")}</TableCell>
                  <TableCell align="center">{t("CONTACT")}</TableCell>
                  <TableCell align="center">{t("ACTION")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trips?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">
                      {row.premise_agent_name || row.premise_operator_name}
                    </TableCell>
                    <TableCell align="center">{row.event_name}</TableCell>
                    <TableCell align="center">{row.agent_email}</TableCell>

                    {filter.status === B2B_AGREEMENT_STATUS.PENDING ? (
                      <TableCell align="center">
                        <InfoToolTip text={t("ACCEPT")}>
                          <IconButton
                            onClick={() => setOpenCommission({ isOpen: true, agreement: row })}
                          >
                            <Check sx={{ color: "var(--green-success)" }} />
                          </IconButton>
                        </InfoToolTip>
                        <InfoToolTip text={t("DECLINE")}>
                          <IconButton
                            onClick={() => setOpenCancelled({ isOpen: true, agreement: row })}
                          >
                            <Close sx={{ color: "var(--oniria-red)" }} />
                          </IconButton>
                        </InfoToolTip>
                      </TableCell>
                    ) : (
                      <TableCell align="center">{row.cancellation_reason}</TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography variant="body2">{t("NO_DATA_AVAILABLE")}</Typography>
      )}

      <AcceptAgreementDialog
        isOpen={openCommission.isOpen}
        onClose={() => {
          setOpenCommission({ isOpen: false, agreement: null });
          refetch();
          setDoRefetch(true);
        }}
        agreement={openCommission.agreement}
      />
      <CancelAgreementDialog
        isOpen={openCancelled.isOpen}
        onClose={() => {
          setOpenCancelled({ isOpen: false, agreement: null });
          refetch();
          setDoRefetch(true);
        }}
        agreement={openCancelled.agreement}
      />
    </Box>
  );
};
