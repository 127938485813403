import { Grid, Pagination, Typography, useMediaQuery, useTheme } from "@mui/material";
import "jspdf-autotable";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { PAGINATION_STYLES } from "../../../constants/styles";
import { useDomain } from "../../../context/DomainContext";
import { useQueryListAssistants } from "../../../hooks/queries/events/assistants/useQueryListAssistants";
import useDebounce from "../../../hooks/useDebouncing";
import usePagination from "../../../hooks/usePagination";
import { validateExtra, validateTicket } from "../../../services/eventsServices";
import { EventNotificationDialog } from "../../mailing/notificationDialog/NotificationDialog";
import { Loading } from "../../shared/Loading";
import SelectPerPage from "../../shared/SelectPerPage";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";
import AssistantDetail from "../AssistantDetail";
import TicketsStock from "../ticketComponents/TicketsStock";
import { AssistantsFilters } from "./AssistantsFilters";
import AssistantsTable from "./AssistantsTable";
import MobileAssistants from "./MobileAssistants";
import RefundsAndPayments from "./RefundsAndPayments";
import RegisterAssistantDialog from "./RegisterDialog";
import TicketsSold from "./TicketsSold";

const ListAssistants = ({ eventId, qr }) => {
  const { t } = useTranslation();
  const [searchParam] = useSearchParams();
  const { selectedDomain } = useDomain();

  const [showDetailAssistant, setShowDetailAssistant] = useState({
    assistant: null,
    open: false,
  });
  const [selectedOption, setSelectedOption] = useState("");
  const [validateAssistant, setValidateAssistant] = useState("");
  const [filters, setFilters] = useState({
    search: "",
    ticket_id: "",
    extra_id: "",
    status: "",
    asc: [],
    desc: ["created_at"],
  });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectingAll, setSelectingAll] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);
  const [openMailDialog, setOpenMailDialog] = useState({
    open: false,
    type: "", // direct, programmed
  });
  const { page, perPage, changePage, changePerPage } = usePagination();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data = {},
    isLoading,
    refetch: refetchAssistants,
  } = useQueryListAssistants(eventId, selectedDomain, filters, page, perPage);

  const {
    data: { data: assistants = [] } = {},
    tickets: ticketsTypes = [],
    sold_tickets: totalSales = 0,
    extras: extrasTypes = [],
  } = data;

  const totalPages = Math.ceil((data?.data?.total || 0) / perPage) || 1;

  const handleSearchChange = (e) => {
    changePage(null, 1);
    setFilters((prev) => ({ ...prev, search: e.target.value }));
  };

  const debounceSearch = useDebounce(handleSearchChange, 500);

  const handleSortAssistants = (id, type) => {
    const oppositeType = type === "asc" ? "desc" : "asc";
    if (filters[type]?.includes(id)) {
      setFilters((prev) => ({ ...prev, [type]: prev[type]?.filter((item) => item !== id) }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [type]: [...(prev[type] || []), id],
        [oppositeType]: prev[oppositeType]?.filter((item) => item !== id),
      }));
    }
  };

  const handleRowClick = (assistant) => {
    setShowDetailAssistant({ assistant, open: true });
  };

  const handleClickOpenCheck = (assistant) => {
    setValidateAssistant(assistant);
    setOpenCheck(true);
  };

  const handleCloseCheck = () => {
    setSelectedOption("");
    setOpenCheck(false);
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    const optionValue = e.target.value;
    if (optionValue === "all") {
      if (selectedOption.includes("all")) {
        setSelectedOption([]);
      } else {
        const allOptions = [
          "all",
          "ticket",
          ...validateAssistant.extras.map((_, index) => `extra${index}`),
          ...validateAssistant.extras.flatMap((extra, index2) => {
            if (extra.extra_consumitions != null && extra.times_can_be_consumed > 1) {
              return [...Array(extra.times_can_be_consumed)]?.map(
                (_, i) => `extra${index2}-item${i}`
              );
            } else {
              return [];
            }
          }),
        ];
        setSelectedOption(allOptions);
      }
    } else {
      setSelectedOption((prevSelectedOption) => {
        //Para bonos copas
        let relatedItems = [];
        if (prevSelectedOption.includes(optionValue)) {
          return prevSelectedOption.filter(
            (option) => option !== optionValue && !option?.includes(optionValue)
          );
        } else {
          if (optionValue.includes("extra") && !optionValue.includes("-item")) {
            const indexExtra = parseInt(optionValue.replace("extra", ""), 10);
            relatedItems = validateAssistant.extras.flatMap((extra, index) => {
              if (
                extra.extra_consumitions != null &&
                index === indexExtra &&
                extra.times_can_be_consumed > 1
              ) {
                return [...Array(extra.times_can_be_consumed)]?.map(
                  (_, i) => `extra${index}-item${i}`
                );
              } else {
                return [];
              }
            });
          }
          return [...prevSelectedOption, optionValue, ...relatedItems];
        }
      });
    }
  };
  const handleRegisterButtonClick = async (e, selectedOptions) => {
    setOpenCheck(false);
    e.stopPropagation();
    try {
      let response = null;
      if (selectedOptions.includes("ticket") && validateAssistant?.consumed_at === null) {
        response = await validateTicket(validateAssistant?.order_ticket_id);
      }
      //limpiamos los bonos para que no haya peticiones de mas
      const prefixes = new Set(
        selectedOptions
          .filter((option) => option.includes("-item"))
          .map((option) => option.split("-")[0])
      );
      const filteredOptions = selectedOptions.filter((option) => !prefixes.has(option));

      for (let i = 0; i < filteredOptions.length; i++) {
        if (filteredOptions[i].startsWith("extra")) {
          const index = parseInt(filteredOptions[i].replace("extra", ""), 10);
          const { extra_consumed, extra_id } = validateAssistant.extras[index];
          if (extra_consumed === null) {
            const extraResponse = await validateExtra(extra_id);
            if (response === null) {
              response = extraResponse;
            }
          }
        }
      }
      toastMessageSuccess(t("EDIT_SUCCESS"));
      setTimeout(() => {
        refetchAssistants();
      }, 1500);
    } catch (error) {
      toastMessageError(error.response?.data?.error ?? t("EDIT_ERROR"));
    } finally {
      setSelectedOption("");
      setOpenCheck(false);
    }
  };

  const soldTicketsPercent = (soldTickets, totalSales) => {
    if (totalSales === 0) return 0;
    let percentage = (soldTickets * 100) / totalSales;
    return parseFloat(percentage.toFixed(2));
  };

  useEffect(() => {
    refetchAssistants();
  }, [qr]);

  //Check if the payment is complete
  const isCompletePayment = (assistant) => {
    if (assistant.payments && assistant.payments.length > 0) {
      return assistant.payments.every((payment) => !!payment.payed_at);
    }
    return !!assistant.payed_at;
  };

  const assistantArrived = (assistant) => {
    const complete = isCompletePayment(assistant);
    if (!complete || assistant.refunded_at) return false;
    return (
      assistant?.consumed_at !== null &&
      assistant?.extras?.every((extra) => extra?.extra_consumed_at !== null)
    );
  };

  // Check users for notifications
  const handleCheckboxUser = (userId, checked) => {
    if (userId === "all") {
      if (checked) {
        changePage(null, 1);
        changePerPage({ target: { value: data?.data?.total } });
        setSelectingAll(true);
      } else {
        setSelectedUsers([]);
        setSelectingAll(false);
      }
      return;
    }

    // Individual select
    setSelectedUsers((prevSelected) => {
      const newSelectedUsers = checked
        ? [...prevSelected, userId]
        : prevSelected.filter((id) => id !== userId);

      // Delete duplicates
      return Array.from(new Set(newSelectedUsers));
    });
  };

  useEffect(() => {
    if (selectingAll && assistants?.length) {
      setSelectedUsers((prevSelected) => {
        const allUsers = assistants.map((assistant) => assistant.buyer_user_id);
        // Delete duplicates
        return Array.from(new Set([...prevSelected, ...allUsers]));
      });
      setSelectingAll(false);
    }
  }, [assistants, selectingAll]);

  // Search the ticketId in the URL and show the assistant detail
  useEffect(() => {
    const ticketParamId = searchParam.get("ticket");
    if (ticketParamId) {
      setShowDetailAssistant({
        assistant: assistants.find((assistant) => assistant.order_ticket_id === ticketParamId),
        open: true,
      });
    }
  }, [searchParam, assistants]);

  return (
    <>
      <Grid
        container
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 3 }}
      >
        <Grid item xs={11} sx={{ display: "flex", flexDirection: "column" }}>
          {/* TICKETS SOLD AND TOTAL */}
          <TicketsSold
            tickets={ticketsTypes}
            totalSales={totalSales}
            soldTicketsPercent={soldTicketsPercent}
            isMobile={isMobile}
          />
          {/* TICKETS STOCK BY DOMAIN */}
          <TicketsStock tickets={ticketsTypes} />
          {/* REFUNDS AND DOBLE PAYMENTS */}
          <RefundsAndPayments
            numberRefunds={data?.refunded_tickets || 0}
            partiallyPayed={data?.partially_payed || 0}
          />
          {showDetailAssistant.open ? (
            <AssistantDetail
              ticketId={showDetailAssistant.assistant?.order_ticket_id}
              onBack={() => setShowDetailAssistant({ open: false })}
            />
          ) : (
            <>
              <AssistantsFilters
                filters={filters}
                ticketsTypes={ticketsTypes}
                setOpenMailDialog={setOpenMailDialog}
                debounceSearch={debounceSearch}
                eventId={eventId}
                selectedDomain={selectedDomain}
                selectedUsers={selectedUsers}
                setFilters={setFilters}
                extrasTypes={extrasTypes}
                changePage={changePage}
              />
              <Grid item mb={25}>
                {isLoading ? (
                  <Loading />
                ) : assistants.length === 0 ? (
                  <Grid item>
                    <Typography variant="h6" sx={{ textAlign: "center", mt: 20 }}>
                      {t("NO_ASSISTANTS_FOUND")}
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid container justifyContent="space-between" alignItems="center" mb={2}>
                      <Grid item xs={4}>
                        <Typography>
                          {t("TOTAL_ASSISTANTS")}: {data?.data?.total || 0}
                        </Typography>
                      </Grid>
                      <Grid item container xs={8} justifyContent="flex-end">
                        <Grid item>
                          <SelectPerPage
                            text={t("USERS")}
                            change={changePerPage}
                            value={perPage}
                            total={data?.data?.total || 0}
                          />
                        </Grid>
                        <Pagination
                          count={totalPages}
                          page={page}
                          onChange={changePage}
                          sx={PAGINATION_STYLES}
                        />
                      </Grid>
                    </Grid>
                    {isMobile ? (
                      <MobileAssistants
                        currentAssistants={assistants}
                        isCompletePayment={isCompletePayment}
                        assistantArrived={assistantArrived}
                        handleClickOpenCheck={handleClickOpenCheck}
                        handleRowClick={handleRowClick}
                      />
                    ) : (
                      <AssistantsTable
                        currentAssistants={assistants}
                        handleSortAssistants={handleSortAssistants}
                        filters={filters}
                        assistantArrived={assistantArrived}
                        handleRowClick={handleRowClick}
                        isCompletePayment={isCompletePayment}
                        handleClickOpenCheck={handleClickOpenCheck}
                        selectedUsers={selectedUsers}
                        handleCheckboxUser={handleCheckboxUser}
                      />
                    )}
                    <Grid container justifyContent="center" mt={5}>
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={changePage}
                        sx={PAGINATION_STYLES}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <RegisterAssistantDialog
        openCheck={openCheck}
        handleCloseCheck={handleCloseCheck}
        validateAssistant={validateAssistant}
        selectedOption={selectedOption}
        handleCheckboxChange={handleCheckboxChange}
        handleRegisterButtonClick={handleRegisterButtonClick}
      />
      <EventNotificationDialog
        open={openMailDialog.open}
        onClose={() => setOpenMailDialog({ open: false })}
        mailType={openMailDialog.type}
        eventId={eventId}
        filters={filters}
        selectedUsers={selectedUsers}
      />
    </>
  );
};

export default ListAssistants;
