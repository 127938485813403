import { SERVICE } from "../constants/api";
import { http } from "../constants/axios";
//HOTELS TEMPLATES
export const getHotelsTemplates = async (page, perPage, filters) => {
  try {
    const { asc, desc, search, isActive, tourPointId } = filters;
    const params = new URLSearchParams();
    if (page) params.append("page", page);
    if (perPage) params.append("perpage", perPage);
    if (search) params.append("search", search);
    if (isActive) params.append("is_active", isActive);

    if (asc && asc?.length > 0) {
      params.append("asc", asc.join(","));
    }

    if (desc && desc?.length > 0) {
      params.append("desc", desc.join(","));
    }
    if (tourPointId && tourPointId !== "all") params.append("tour_point_id", tourPointId);

    const response = await http.get(`${SERVICE.HOTELS_TEMPLATES}?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching hotels", error);
    throw error;
  }
};

export const getHotelDetails = async (id, isForTrip) => {
  try {
    const url = isForTrip ? SERVICE.HOTELS : SERVICE.HOTELS_TEMPLATES;
    const response = await http.get(`${url}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching hotel details:", error);
    throw error;
  }
};

export const createHotel = async (body, isForTrip) => {
  try {
    const url = isForTrip ? SERVICE.HOTELS : SERVICE.HOTELS_TEMPLATES;
    const response = await http.post(`${url}`, body);
    return response.data;
  } catch (error) {
    console.error("Error creating hotel:", error);
    throw error;
  }
};

export const editHotel = async (id, body, isForTrip) => {
  try {
    const url = isForTrip ? SERVICE.HOTELS : SERVICE.HOTELS_TEMPLATES;
    const response = await http.put(`${url}/${id}`, body);
    return response.data;
  } catch (error) {
    console.error("Error editing hotel:", error);
    throw error;
  }
};

export const deleteHotelTemplate = async (id) => {
  try {
    const response = await http.delete(`${SERVICE.HOTELS_TEMPLATES}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting hotel:", error);
    throw error;
  }
};

export const assignHotelToDomains = async (id, domainsIds, isForTrip) => {
  try {
    const url = isForTrip ? SERVICE.HOTELS : SERVICE.HOTELS_TEMPLATES;
    const response = await http.put(`${url}/domains/${id}`, {
      domains_ids: domainsIds,
    });
    return response.data;
  } catch (error) {
    console.error("Error assigning hotel to domains:", error);
    throw error;
  }
};

//HOTELS ASSIGNED
export const getHotelsAssigned = async (page, perPage, filters) => {
  try {
    const { asc, desc, search } = filters;
    const params = new URLSearchParams();
    if (page) params.append("page", page);
    if (perPage) params.append("perpage", perPage);
    if (search) params.append("search", search);

    if (asc && asc?.length > 0) {
      params.append("asc", asc.join(","));
    }

    if (desc && desc?.length > 0) {
      params.append("desc", desc.join(","));
    }

    const response = await http.get(`${SERVICE.HOTELS}?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching hotels", error);
    throw error;
  }
};

export const getHotelsList = async () => {
  try {
    const response = await http.get(`${SERVICE.HOTELS}/list`);
    return response.data;
  } catch (error) {
    console.error("Error fetching hotels list", error);
    throw error;
  }
};
