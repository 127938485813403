import { ArrowBack } from "@mui/icons-material";
import { Grid, IconButton, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { PAGINATION_STYLES } from "../../constants/styles";
import { useQueryB2bSales } from "../../hooks/queries/b2b/useQueryB2bSales";
import usePagination from "../../hooks/usePagination";
import OrderDetail from "../events/orderDetails/OrderDetail";
import InfoToolTip from "../shared/InfoToolTip";
import { Loading } from "../shared/Loading";
import SelectPerPage from "../shared/SelectPerPage";
import { B2BSalesTable } from "./sales/B2BSalesTable";

export const B2bBAgentSales = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    search: "",
    premiseId: id || "all",
    product: "all",
    status: "all",
    month: "all",
  });
  const [showDetailOrder, setShowDetailOrder] = useState({
    order_id: null,
    open: false,
  });

  const { page, perPage, changePage, changePerPage } = usePagination();

  // Sales Info
  const { data = {}, isLoading } = useQueryB2bSales(page, perPage, filters, true);
  const { data: sales = [], total = 0 } = data;

  const totalPages = Math.ceil(parseFloat(total) / parseFloat(perPage));

  return showDetailOrder.open ? (
    <OrderDetail
      order={showDetailOrder.order_id}
      transactionId={""}
      firstPaymentTransactionId={""}
      onBack={() => setShowDetailOrder({ open: false, order_id: null })}
    />
  ) : (
    <>
      <InfoToolTip text={t("GO_BACK")}>
        <IconButton sx={{ mt: 2 }} onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
      </InfoToolTip>

      <Grid container justifyContent="flex-end" alignItems="center" mb={2}>
        <Grid item>
          <SelectPerPage text={t("PRODUCTS")} change={changePerPage} value={perPage} />
        </Grid>
        <Pagination count={totalPages} page={page} onChange={changePage} sx={PAGINATION_STYLES} />
      </Grid>

      {isLoading ? (
        <Loading />
      ) : total === 0 ? (
        <Typography variant="h6" align="center" sx={{ mt: 2, mb: 2 }}>
          {t("NO_DATA_AVAILABLE")}
        </Typography>
      ) : (
        <B2BSalesTable sales={sales} setShowDetailOrder={setShowDetailOrder} />
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Pagination count={totalPages} page={page} onChange={changePage} sx={PAGINATION_STYLES} />
      </div>
    </>
  );
};
