import { Grid, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../../shared/textFieldStyle";

export const AgentAdminFields = () => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid item xs={12} container spacing={2} mt={2}>
      <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%", ml: 2 }}>
        {t("ADMIN")}
      </Typography>
      <Grid item xs={12}>
        <Controller
          name="admin.name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("NAME")}
              variant="outlined"
              fullWidth
              sx={focusColor}
              error={!!errors.admin?.name}
              helperText={errors.admin?.name?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="admin.surname"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("SURNAME")}
              variant="outlined"
              fullWidth
              sx={focusColor}
              error={!!errors.admin?.surname}
              helperText={errors.admin?.surname?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="admin.email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("EMAIL")}
              variant="outlined"
              fullWidth
              sx={focusColor}
              error={!!errors.admin?.email}
              helperText={errors.admin?.email?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
