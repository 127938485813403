import { Divider, Grid, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { endsWithNumber, generateNextUrl, transformNameToUrl } from "../../../constants/utils";
import { useTripLanguage } from "../../../context/TripLanguageContext";
import { useQueryPremisesList } from "../../../hooks/queries/useQueryPremisesList";
import { checkAvailableUrl } from "../../../services/eventsServices";
import SelectHookForm from "../../shared/SelectHookForm";
import { StyledAccordion } from "../../shared/StyledAccordion";
import { focusColor } from "../../shared/textFieldStyle";
import TextEditor from "../../textEditor/TextEditor";
import TripCategoriesDomain from "../configuration/TripCategoriesDomain";
import TripConfiguration from "../configuration/TripConfiguration";
import { FormFieldHeader } from "../FormFieldHeader";
import TripDates from "./TripDates";
import TripImages from "./TripImages";

const PrincipalInformationPlus = () => {
  const { t } = useTranslation();
  const { getFieldName } = useTripLanguage();

  const {
    register,
    formState: { errors, disabled },
    watch,
    setValue,
    control,
    getValues,
  } = useFormContext();

  // Default premise
  const handleSetPremise = (data) => {
    if (data.length === 1) {
      setValue("premise_id", data[0].id);
    }
  };

  const { data: premises = [] } = useQueryPremisesList("", "", handleSetPremise);

  const nameField = getFieldName("name");
  const descriptionField = getFieldName("description");

  // Set the url
  const tryUrlWithNumber = (url) => {
    const checkUrl = (currentUrl) => {
      checkAvailableUrl(currentUrl).then((isUrlAvailable) => {
        if (isUrlAvailable) {
          setValue("seo_name", currentUrl);
        } else {
          const nextUrl = generateNextUrl(currentUrl);
          checkUrl(nextUrl);
        }
      });
    };
    const initialUrl = endsWithNumber(url) ? url : `${url}-01`;
    checkUrl(initialUrl);
  };

  const handleSetUrl = async () => {
    const eventSeoName = getValues("seo_name");
    const eventName = getValues("name");
    if (!eventSeoName && !!eventName) {
      const newUrl = transformNameToUrl(eventName);
      if (newUrl) {
        const isUrlAvailable = await checkAvailableUrl(newUrl || "");
        if (isUrlAvailable) {
          setValue("seo_name", newUrl);
        } else {
          tryUrlWithNumber(newUrl);
        }
      }
    }
  };

  useEffect(() => {
    handleSetUrl();

    return () => {
      handleSetUrl();
    };
  }, []);

  return (
    <Grid component={Paper} container spacing={2} borderRadius={3} pb={2}>
      <Grid
        sx={{
          mb: 3,
          backgroundColor: "var(--grey-background)",
          width: "100%",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          padding: 3,
        }}
      >
        <Typography variant="h5" fontWeight="bolder" mb={1}>
          {t("PRINCIPAL_INFORMATION")}
        </Typography>
        <Typography variant="body1">{t("PRINCIPAL_INFORMATION_DESCRIPTION")}</Typography>
      </Grid>

      <Grid container spacing={2} paddingInline={3} pb={2}>
        {/* NAME TRIP */}
        <Grid item xs={12}>
          <FormFieldHeader title={t("TRIP_TITLE")} />
          <TextField
            key={nameField}
            {...register(nameField)}
            error={!!errors?.[nameField]}
            helperText={t(errors?.[nameField]?.message)}
            fullWidth
            sx={focusColor}
            placeholder={`${t("TITLE")}...`}
            required
            disabled={disabled}
          />
        </Grid>
        {/* PROPIETARIO */}
        {premises?.length > 1 ? (
          <Grid item xs={12}>
            <FormFieldHeader title={t("ORGANIZER")} description={t("ORGANIZER_DESCRIPTION")} />
            <SelectHookForm
              name={"premise_id"}
              defaultValue=""
              control={control}
              placeholder={t("ORGANIZER")}
              required
              error={errors?.premise_id}
              options={premises?.map((premise) => (
                <MenuItem key={premise.id} value={premise.id}>
                  {premise.name}
                </MenuItem>
              ))}
            />
          </Grid>
        ) : null}
        {/* CATEGORIES & DOMAINS */}
        <TripCategoriesDomain />
        {/* TRIP IMAGES */}
        <TripImages />
        <Grid item xs={12}>
          <Divider sx={{ width: "100%" }} />
        </Grid>
        {/* DESCRIPTION */}
        <Grid item xs={12}>
          <FormFieldHeader
            title={t("PRODUCT_DESCRIPTION")}
            description={t("PRODUCT_DESCRIPTION_DESCRIPTION")}
          />
          <TextEditor
            key={descriptionField}
            data={watch(descriptionField)}
            id={`trip_plus_description_${descriptionField}`}
            setData={(data) => setValue(descriptionField, data)}
          />
        </Grid>
        {/* ADVANCED OPTIONS */}
        <Grid item xs={12}>
          <StyledAccordion title={t("ADVANCED_OPTIONS")}>
            <TripConfiguration />
          </StyledAccordion>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ width: "100%" }} />
        </Grid>
        {/* DATES */}
        <TripDates />
      </Grid>
    </Grid>
  );
};

export default PrincipalInformationPlus;
