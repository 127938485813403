import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ImageUpload from "../../../shared/ImageUpload";
import { focusColor } from "../../../shared/textFieldStyle";
import Preview from "../preview/Preview";

const AdditionalInfo = () => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const searchBar = watch("style.search_bar");
  const imageUrl = watch("style.image_url");

  const sweeps = watch("sweeps") || [];

  const handleAddSweep = () => {
    setValue("sweeps", [
      ...sweeps,
      {
        title: "",
        subtitle: "",
        link: "",
        image_url: "",
        position: sweeps.length + 1,
      },
    ]);
  };

  const handleSelectImage = (url, type) => {
    if (Array.isArray(url)) {
      const imageArray = [];
      for (let i = 0; i < url.length; i++) {
        const urlParams = url[i].split(";");
        const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
        imageArray.push(imageObject);
      }
      if (imageArray.length === 0) {
        setValue(type, null);
      } else {
        setValue(type, imageArray);
      }
    } else {
      const urlParams = url.split(";");
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
      setValue(type, imageObject);
    }
  };

  const handleDeleteSweep = (index) => {
    const newSweeps = sweeps.filter((_, i) => i !== index);
    setValue("sweeps", newSweeps);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Preview />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">{t("ADDITIONAL_ELEMENTS")}</Typography>
        <Divider />
        <FormGroup>
          <FormControlLabel
            control={<Checkbox className="checkbox-oniria" />}
            label={t("SEARCH_BAR")}
            checked={searchBar === "true" || searchBar === 1}
            onChange={(e) => {
              e.target.checked ? setValue("style.search_bar", 1) : setValue("style.search_bar", 0);
            }}
          />
        </FormGroup>
        <Typography>{t("IMAGE")}</Typography>
        <Grid>
          <ImageUpload
            url={imageUrl ?? ""}
            selectedImagesInForm={imageUrl}
            multi={false}
            onImageSelect={(e) => handleSelectImage(e, "style.image_url")}
          />
        </Grid>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Typography>{t("VIDEO")}</Typography>
          <TextField
            variant="outlined"
            placeholder={t("VIDEO_URL")}
            sx={focusColor}
            {...register("style.video_url")}
          />
        </FormControl>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t("REEL")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {sweeps.map((sweep, index) => (
              <Grid
                sx={{ border: "1px solid lightgray", borderRadius: 2, padding: 2 }}
                key={`sweep-${index}`}
              >
                <Grid container justifyContent="flex-end">
                  <IconButton onClick={() => handleDeleteSweep(index)}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <Typography>{t("TITLE")}</Typography>
                  <TextField
                    variant="outlined"
                    placeholder={t("TITLE")}
                    sx={focusColor}
                    {...register(`sweeps.${index}.title`)}
                    error={!!errors?.sweeps?.[index]?.title}
                    helperText={
                      errors?.sweeps?.[index]?.title ? errors.sweeps[index].title.message : ""
                    }
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <Typography>{t("SHORT_DESCRIPTION")}</Typography>
                  <TextField
                    variant="outlined"
                    placeholder={t("DESCRIPTION")}
                    sx={focusColor}
                    {...register(`sweeps.${index}.subtitle`)}
                    error={!!errors?.sweeps?.[index]?.subtitle}
                    helperText={
                      errors?.sweeps?.[index]?.subtitle ? errors.sweeps[index].subtitle.message : ""
                    }
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <Typography>{t("LINK")}</Typography>
                  <TextField
                    variant="outlined"
                    placeholder={t("LINK")}
                    sx={focusColor}
                    {...register(`sweeps.${index}.link`)}
                    error={!!errors?.sweeps?.[index]?.link}
                    helperText={
                      errors?.sweeps?.[index]?.link ? errors.sweeps[index].link.message : ""
                    }
                  />
                </FormControl>
                <ImageUpload
                  url={sweep.image_url ?? ""}
                  multi={false}
                  onImageSelect={(e) => handleSelectImage(e, `sweeps.${index}.image_url`)}
                />
              </Grid>
            ))}
            <Grid container justifyContent="center" mt={2}>
              <Button
                onClick={handleAddSweep}
                className="oniria-border-btn"
                sx={{ borderRadius: 2 }}
              >
                {t("ADD_SWEEP")}
              </Button>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default AdditionalInfo;
