import { zodResolver } from "@hookform/resolvers/zod";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { parseFloatOrNull } from "../../../constants/utils";
import { agreementsSchema } from "../../../schemas/agreements";
import { createB2bAgreements, editB2bAgreements } from "../../../services/b2bServices";
import { sendPdfInBase64 } from "../../shared/FormsValidator";
import PdfUpload from "../../shared/PdfUpload";
import { focusColor } from "../../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";

export const AgreementFormDialog = ({ isOpen, onClose, agent = {}, refetch, canEdit = true }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm({
    resolver: zodResolver(agreementsSchema),
    defaultValues: {
      commission_type: agent?.commission_fixed ? "fixed" : "percentage",
      commission_fixed: agent?.commission_fixed ?? 0,
      commission_percentage: agent?.commission_percentage ?? 0,
      pdf: agent?.pdf ?? null,
    },
  });

  const commissionType = watch("commission_type");

  const onSubmit = async (data) => {
    try {
      const pdfBase64 = await sendPdfInBase64(data.pdf);
      const body = {
        commission_fixed: parseFloatOrNull(data.commission_fixed),
        commission_percentage: parseFloatOrNull(data.commission_percentage),
        pdf: pdfBase64,
        premise_operator_id: agent.premise_operator_id || null,
        premise_agent_id: agent.premise_id,
      };

      if (agent.agreement_id) {
        await editB2bAgreements(agent.agreement_id, body);
        toastMessageSuccess(t("CONTRACT_EDITED_SUCCESS"));
      } else {
        await createB2bAgreements(body);
        toastMessageSuccess(t("AGREEMENT_CREATED_SUCCESS"));
      }
      setTimeout(() => {
        onClose();
        refetch();
      }, 1500);
    } catch (error) {
      const errorMessage = error?.response?.data?.message || t("ERROR");
      toastMessageError(errorMessage);
    }
  };

  useEffect(() => {
    if (agent) {
      setValue("commission_fixed", agent.commission_fixed);
      setValue("commission_percentage", agent.commission_percentage);
      setValue("commission_type", agent.commission_fixed ? "fixed" : "percentage");
      setValue("pdf", agent.pdf ?? null);
    }
  }, [agent]);

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="alert-dialog-title" maxWidth="lg">
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", textAlign: "center" }}>
        {canEdit ? t("EDIT_AGREEMENT") : t("SEE_AGREEMENT")}
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth sx={[focusColor, { mt: 2 }]} error={!!errors.commission_type}>
            <InputLabel>{t("CHOOSE_COMMISSION_TYPE")}</InputLabel>
            <Controller
              name="commission_type"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t("CHOOSE_COMMISSION_TYPE")}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("commission_fixed", 0);
                    setValue("commission_percentage", 0);
                  }}
                  disabled={!canEdit}
                >
                  <MenuItem value="fixed">{t("FIXED")}</MenuItem>
                  <MenuItem value="percentage">{t("PERCENTAGE")}</MenuItem>
                </Select>
              )}
            />
          </FormControl>

          {commissionType === "fixed" && (
            <Controller
              name="commission_fixed"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  sx={[focusColor, { mt: 2 }]}
                  label={t("FIXED_COMMISSION")}
                  type="number"
                  error={!!errors.commission_fixed}
                  helperText={
                    errors.commission_fixed?.message && t(errors.commission_fixed.message)
                  }
                  inputProps={{ min: 0 }}
                  onWheel={(e) => e.target.blur()}
                  disabled={!canEdit}
                />
              )}
            />
          )}

          {commissionType === "percentage" && (
            <Controller
              name="commission_percentage"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  sx={[focusColor, { mt: 2 }]}
                  label={t("PERCENTAGE_COMMISSION")}
                  type="number"
                  inputProps={{ min: 0, max: 100 }}
                  error={!!errors.commission_percentage}
                  helperText={
                    errors.commission_percentage?.message && t(errors.commission_percentage.message)
                  }
                  onWheel={(e) => e.target.blur()}
                  disabled={!canEdit}
                />
              )}
            />
          )}

          <Box sx={{ mt: 2 }}>
            <PdfUpload value={watch("pdf")} onChange={(value) => setValue("pdf", value)} />
          </Box>

          {canEdit && (
            <DialogActions sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
              <Button
                className="oniria-btn-cancel"
                variant="contained"
                size="medium"
                sx={{ fontSize: "12px", borderRadius: 3 }}
                onClick={onClose}
                disabled={isSubmitting}
              >
                {t("CANCEL")}
              </Button>
              <Button
                className="oniria-btn"
                variant="contained"
                size="medium"
                sx={{ fontSize: "12px", borderRadius: 3 }}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? t("LOADING") : t("ACCEPT")}
              </Button>
            </DialogActions>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
};
