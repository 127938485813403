const { useQuery } = require("react-query");
const { RQ_KEY } = require("../../../constants/query");
const { getEventById } = require("../../../services/eventsServices");

const useQueryEventDetails = (eventId) => {
  return useQuery([RQ_KEY.EVENT_DETAILS, eventId], () => getEventById(eventId), {
    refetchOnWindowFocus: false,
    enabled: !!eventId,
  });
};

export default useQueryEventDetails;
