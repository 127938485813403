import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import { getOrderTotalAmount } from "../constants/orders";
import { formatCurrency, formatCurrencyForCSV, formatDate } from "../constants/utils";
import { ORDER_STATUS_OPTIONS } from "../constants/variables";
import useQueryOrdersEvent from "./queries/events/useQueryOrdersEvent";

const useDownloadOrders = (
  eventId,
  selectedDomain,
  search,
  order,
  selectedTicket,
  selectedStatus,
  enabledDownload
) => {
  const { t } = useTranslation();

  const { data: orders = [], isSuccess } = useQueryOrdersEvent(
    eventId,
    selectedDomain,
    "",
    "",
    search,
    order,
    selectedTicket,
    selectedStatus,
    enabledDownload
  );

  //CSV download
  const tableHeadersCsv = [
    t("NAME"),
    t("SURNAME"),
    t("DOMAIN"),
    t("TICKETS"),
    t("TRANSACTION_ID"),
    t("PAYMENT_DATE"),
    t("PAYMENT_TYPE"),
    t("REFUND_STATUS"),
    t("REFUND_AMOUNT"),
    t("AMOUNT"),
    t("COMMISSION"),
    t("PREMISE_COMMISSION"),
    t("TOTAL"),
  ];

  const uniqOrders = orders?.reduce(
    (acc, order) => {
      if (!acc.seen[order.order_id]) {
        acc.seen[order.order_id] = true;
        acc.result.push(order);
      }
      return acc;
    },
    { seen: {}, result: [] }
  ).result;

  const getTransactionId = (item) => {
    if (!item) return "";
    return [
      item.first_payment_transaction_id,
      item.transaction_id,
      ...(item.payments || []).map((payment) => payment.transaction_id).filter(Boolean),
    ]
      .filter(Boolean)
      .join("\n");
  };

  const getPaymentDates = (item) => {
    if (!item) return "";

    const payments =
      item.payments?.map((payment) =>
        payment.payed_at ? formatDate(payment.payed_at) : t("PENDING_PAYMENT")
      ) || [];

    const firstPayment = item.first_payment_payed_at
      ? formatDate(item.first_payment_payed_at)
      : null;

    const mainPayment =
      item.payments && item.payments.length > 0
        ? ""
        : item.payed_at
        ? formatDate(item.payed_at)
        : t("PENDING_PAYMENT");

    return [...payments, firstPayment, mainPayment].filter(Boolean).join("\n");
  };

  const getItemPaymentTypes = (item) => {
    if (!item) return "";

    const payments = item.payments?.map((payment) => t(payment.type?.toUpperCase())) || [];
    const firstPayment = item.first_payment_type ? t(item.first_payment_type?.toUpperCase()) : "";
    const mainPayment = item.payment_type ? t(item.payment_type?.toUpperCase()) : "";
    return [...payments, firstPayment, mainPayment].filter(Boolean).join("\n");
  };

  const getItemValue = (item, key) => {
    if (!item) return "";

    const payments = item.payments?.map((payment) => formatCurrencyForCSV(payment[key])) || [];
    const firstPayment = item[`first_payment_${key}`]
      ? formatCurrencyForCSV(item[`first_payment_${key}`])
      : "";
    const mainPayment = item[key] ? formatCurrencyForCSV(item[key]) : "";

    return [...payments, firstPayment, mainPayment].filter(Boolean).join("\n");
  };

  const getItemAmount = (item) => getItemValue(item, "amount");
  const getItemCommission = (item) => getItemValue(item, "commission");
  const getItemPremiseCommission = (item) => getItemValue(item, "premise_commission");

  const tableDataCsv = [];

  uniqOrders.forEach((item) => {
    const transactionId = getTransactionId(item).split("\n");
    const paymentDates = getPaymentDates(item).split("\n");
    const paymentTypes = getItemPaymentTypes(item).split("\n");
    const amounts = getItemAmount(item).split("\n");
    const commissions = getItemCommission(item).split("\n");
    const premiseCommissions = getItemPremiseCommission(item).split("\n");

    const maxRows = Math.max(
      transactionId.length,
      paymentDates.length,
      paymentTypes.length,
      amounts.length,
      commissions.length,
      premiseCommissions.length
    );

    for (let i = 0; i < maxRows; i++) {
      tableDataCsv.push([
        i === 0 ? item.buyer_first_name ?? item.tickets[0]?.client_name ?? "" : "",
        i === 0 ? item.buyer_last_name ?? item.tickets[0]?.client_surname ?? "" : "",
        i === 0 ? item.bought_in_domain ?? "" : "",
        i === 0 ? item.tickets?.map((ticket) => ticket.ticket_name)?.join(", ") ?? "" : "",
        transactionId[i] || "",
        paymentDates[i] || "",
        paymentTypes[i] || "",
        i === 0
          ? item.refund_status
            ? item.refund_amount == "0.00"
              ? t("CANCELED")
              : t("REFUNDED")
            : ""
          : "",
        i === 0 ? formatCurrencyForCSV(parseFloat(item.refund_amount || "0")) : "",
        amounts[i] || "",
        commissions[i] || "",
        premiseCommissions[i] || "",
        i === 0 ? formatCurrencyForCSV(getOrderTotalAmount(item)) : "",
      ]);
    }
  });

  //PDF download
  const headersPdf = [
    t("NAME"),
    t("SURNAME"),
    t("TRANSACTION_ID"),
    t("DOMAIN"),
    t("TICKETS"),
    t("PAYMENT_DATE"),
    t("PAYMENT_TYPE"),
    t("TOTAL"),
  ];

  const tableDataPdf = orders
    ?.filter((item) => item?.type !== ORDER_STATUS_OPTIONS.REFUND)
    ?.map((item) => [
      item.client_name ?? item.buyer_first_name ?? "",
      item.client_surname ?? item.buyer_last_name ?? "",
      item.transaction_id,
      item.bought_in_domain ?? "",
      item.tickets
        ?.map((ticket) => {
          return ticket.ticket_name + "\n";
        })
        ?.join("") ?? "",
      item.payed_at ? formatDate(item.payed_at) : t("PENDING_PAYMENT"),
      item.payment_type ? t(item?.payment_type?.toUpperCase()) : "",
      formatCurrency(getOrderTotalAmount(item) || 0),
    ]);

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [headersPdf],
      body: tableDataPdf,
      styles: { fontSize: 10 },
      theme: "grid",
      margin: { top: 30 },
    });
    doc.save("orders.pdf");
  };

  return {
    generatePDF,
    tableHeadersCsv,
    tableDataCsv,
    isSuccess,
  };
};

export default useDownloadOrders;
