import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getB2bEventSales, getB2bEventAgentSales } from "../../../services/b2bServices";

export function useQueryEventB2bSales(eventId, userDomain, isB2bSaleAgent) {
  return useQuery(
    [RQ_KEY.B2B_SALES, eventId, userDomain],
    () => (isB2bSaleAgent ? getB2bEventAgentSales(eventId, userDomain) : getB2bEventSales(eventId)),
    {
      refetchOnWindowFocus: false,
      enabled: !!eventId,
    }
  );
}
