import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import { CloseDialogButton } from "../../shared/CloseDialogButton";
import TripTicketDiscounts from "./TripTicketDiscounts";

export const TicketDiscountDialog = ({ isOpen, onClose, index }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogContent sx={{ padding: 0 }}>
        <CloseDialogButton onClose={onClose} />
        <Grid
          sx={{
            mb: 3,
            backgroundColor: "var(--grey-background)",
            width: "100%",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            padding: 3,
          }}
        >
          <Typography variant="h5" fontWeight="bolder" mb={1}>
            {t("DISCOUNTS")}
          </Typography>
        </Grid>
        <Grid container padding={2}>
          <TripTicketDiscounts index={index} />
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
          <Button
            type="submit"
            className="oniria-btn"
            variant="contained"
            size="medium"
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={onClose}
          >
            {t("SAVE")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
