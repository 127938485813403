import { DialogTitle, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { CloseDialogButton } from "../../shared/CloseDialogButton";
import TransportForm from "../TransportForm";

function CreateTransportDialog({ isOpen, onClose }) {
  const { t } = useTranslation();

  const handleGoBack = () => {
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <CloseDialogButton onClose={onClose} />
      <DialogTitle sx={{ fontWeight: "bold", backgroundColor: "var(--grey-background)" }}>
        {t("CREATE_TRANSPORT")}
      </DialogTitle>
      <Grid container sx={{ padding: 2, paddingTop: 0 }}>
        <TransportForm goBack={handleGoBack} isFromDialog />
      </Grid>
    </Dialog>
  );
}

export default CreateTransportDialog;
