import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { sanitizeBoolean } from "../../../constants/utils";
import { DEFAULT_TICKET } from "../../../schemas/trips";
import { FormHeader } from "../../shared/FormHeader";
import InfoToolTip from "../../shared/InfoToolTip";
import { CustomSwitch } from "../../shared/switch/CustomSwitch";
import { focusColor } from "../../shared/textFieldStyle";
import { FormFieldHeader } from "../FormFieldHeader";
import { TripMultiplePayments } from "../multiplePayments/TripMultiplePayments";

export const TripGlobalData = () => {
  const { t } = useTranslation();

  const {
    watch,
    formState: { errors },
    register,
    control,
    setValue,
    getValues,
  } = useFormContext();

  const isDoublePayment = sanitizeBoolean(watch("has_double_payment"));
  const pvp = watch("pvp", 0);
  const hasContactForm = sanitizeBoolean(watch("app_contact_form"));

  const handleChangeContactForm = (e) => {
    const isChecked = e.target.checked;
    setValue("app_contact_form", isChecked);
    setValue("limit_tickets_order", 1);

    if (isChecked) {
      const newTickets = [
        {
          ...DEFAULT_TICKET,
          name: "contact",
          price: getValues("pvp") || 0,
          initial_stock: 1,
        },
      ];
      setValue("tickets", newTickets);
    } else {
      const newTickets = getValues("tickets") || [];
      newTickets.splice(
        newTickets.findIndex((ticket) => ticket.name === "contact"),
        1
      );
      setValue("tickets", newTickets);
    }
  };

  useEffect(() => {
    const tickets = getValues("tickets") || [];
    const eventId = getValues("id");
    if (tickets && tickets.length > 0 && !eventId) {
      const newTickets = tickets.map((ticket) => ({
        ...ticket,
        price: pvp,
      }));
      setValue("tickets", newTickets);
    }
  }, [pvp]);

  return (
    <FormHeader title={t("GLOBAL_DATA")} description={t("GLOBAL_DATA_DESCRIPTION")}>
      <Grid item xs={12} container justifyContent="space-between">
        <Typography variant="subtitle1" fontWeight="800" mr={2}>
          {t("ONLY_FORM_CONTACT")}
        </Typography>
        <CustomSwitch checked={hasContactForm} handleChange={handleChangeContactForm} />
      </Grid>
      {hasContactForm && (
        <Grid item xs={12}>
          <Typography variant="body1">{t("ONLY_FORM_CONTACT_DESCRIPTION")}</Typography>
        </Grid>
      )}

      {/* PVP  */}
      <Grid item xs={12}>
        <FormFieldHeader title={t("PUBLIC_PVP")} />
        <TextField
          placeholder={"P.ej.- 120,50€"}
          {...register("pvp")}
          error={!!errors?.pvp}
          helperText={t(errors?.pvp?.message)}
          fullWidth
          sx={focusColor}
          type="number"
          inputProps={{ min: 0 }}
          required
          onWheel={(e) => e.target.blur()}
        />
      </Grid>
      {!hasContactForm && (
        <>
          {/* Orders limit */}
          <Grid item xs={12}>
            <FormFieldHeader title={t("TICKET_ORDER_LIMIT")} />
            <TextField
              placeholder={"10"}
              {...register("limit_tickets_order")}
              error={!!errors?.limit_tickets_order}
              helperText={t(errors?.limit_tickets_order?.message)}
              fullWidth
              sx={focusColor}
              type="number"
              inputProps={{ min: 0 }}
              required
              onWheel={(e) => e.target.blur()}
            />
          </Grid>
          <TripMultiplePayments />
          {/* OLD METHOD - Only for old events*/}
          {isDoublePayment && (
            <FormControlLabel
              control={
                <Checkbox
                  {...register("has_double_payment")}
                  className="checkbox-oniria"
                  checked={isDoublePayment}
                  sx={{ paddingLeft: 3 }}
                />
              }
              label={
                <div>
                  {t("DOUBLE_PAYMENT")}
                  <InfoToolTip text={t("DOUBLE_PAYMENT_INFO")} />
                </div>
              }
            />
          )}
        </>
      )}

      {/* Old method */}
      {isDoublePayment && (
        <>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography>{t("FIRST_PAYMENT_PERCENT")}</Typography>
              <InfoToolTip text={t("FIRST_PAYMENT_PERCENT_INFO")} />
            </Box>
            <TextField
              placeholder={"Ej.- 20%"}
              {...register("first_payment_percentage")}
              error={!!errors?.first_payment_percentage}
              helperText={t(errors?.first_payment_percentage?.message)}
              fullWidth
              sx={focusColor}
              type="number"
              inputProps={{ min: 0, max: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("SECOND_PAYMENT_END_DATE")}</Typography>
            <Controller
              name="second_payment_end_date"
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  {...field}
                  error={!!errors?.second_payment_end_date}
                  helperText={t(errors?.second_payment_end_date?.message)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={focusColor}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("SECOND_PAYMENT_END_TIME")}</Typography>
            <Controller
              name="second_payment_end_date_time"
              control={control}
              render={({ field }) => (
                <TextField
                  type="time"
                  {...field}
                  error={!!errors?.end_date}
                  helperText={t(errors?.end_date?.message)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={focusColor}
                />
              )}
            />
          </Grid>
        </>
      )}
    </FormHeader>
  );
};
