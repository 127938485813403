import { CalendarMonthOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Popover, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { focusColor } from "./textFieldStyle";

export const DateRangePicker = ({
  text,
  startDate = "",
  endDate = "",
  handleChangeStartDate,
  handleChangeEndDate,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStartDateChange = (event) => {
    const value = event.target.value;
    handleChangeStartDate(value);

    if (endDate && value > endDate) {
      handleChangeEndDate(value);
    }
  };

  const handleEndDateChange = (event) => {
    handleChangeEndDate(event.target.value);
  };

  const handleReset = () => {
    handleChangeStartDate("");
    handleChangeEndDate("");
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <Box sx={{ width: "100%", mb: 2, mt: 2 }}>
      <Button
        variant="outlined"
        onClick={handleOpen}
        fullWidth
        sx={{
          color: "var(--secondary-color)",
          borderColor: "var(--secondary-color)",
          "&:hover": { color: "var(--primary-color)", borderColor: "var(--primary-color)" },
        }}
        startIcon={<CalendarMonthOutlined sx={{ color: "var(--secondary-color)" }} />}
      >
        {startDate && endDate
          ? `${new Date(startDate).toLocaleDateString()} - ${new Date(
              endDate
            ).toLocaleDateString()}`
          : text}
      </Button>

      {/* Clean dates button*/}
      {startDate && endDate && (
        <IconButton onClick={handleReset} size="small" sx={{ marginLeft: 1 }}>
          <CloseIcon />
        </IconButton>
      )}

      {/* Dates pickers*/}
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={2} display="flex" flexDirection="column" gap={2} width={300}>
          <TextField
            label={t("START_DATE")}
            type="date"
            value={startDate || ""}
            onChange={handleStartDateChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={focusColor}
          />
          <TextField
            label={t("END_DATE")}
            type="date"
            value={endDate || ""}
            onChange={handleEndDateChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={focusColor}
          />
        </Box>
      </Popover>
    </Box>
  );
};
