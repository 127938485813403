const { z } = require("zod");

export const agreementsSchema = z.object({
  commission_type: z.enum(["fixed", "percentage"]),
  commission_fixed: z.union([z.string(), z.number()]).optional().nullable(),
  commission_percentage: z.union([z.string(), z.number()]).optional().nullable(),
  pdf: z.string().nullable().optional(),
  premise_operator_id: z.string().nullable().optional(),
  premise_agent_id: z.string().nullable().optional(),
});
