import { Grid, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../../shared/textFieldStyle";

export const AgentBillingFields = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid item xs={12} container spacing={2} mt={2}>
      <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%", ml: 2 }}>
        {t("BILLING_DATA")}
      </Typography>
      <Grid item xs={12} sm={9}>
        <Controller
          name="fiscal_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("FISCAL_NAME")}
              fullWidth
              error={!!errors.fiscal_name}
              helperText={errors.fiscal_name?.message}
              required
              sx={focusColor}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="cif"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("CIF")}
              fullWidth
              error={!!errors.cif}
              helperText={errors.cif?.message}
              required
              sx={focusColor}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="iban"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("IBAN")}
              fullWidth
              error={!!errors.iban}
              helperText={errors.iban?.message}
              required
              sx={focusColor}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
