import { useEffect, useState } from "react";
import { DEFAULT_EVENT_LANGUAGE } from "../constants/variables";

const useEventLanguage = (
  event_language_infos,
  setFormData,
  setErrors,
  defaultLanguage = DEFAULT_EVENT_LANGUAGE
) => {
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const selectedLanguageIndex = event_language_infos?.findIndex(
    (lang) => lang.language === selectedLanguage
  );
  const selectedLanguageInfo = event_language_infos?.find(
    (lang) => lang.language === selectedLanguage
  );

  const handleChangeAttribute = (attribute, value, regex, errorMessage) => {
    const updatedLanguageInfos = [...(event_language_infos ?? [])];
    updatedLanguageInfos[selectedLanguageIndex] = {
      ...selectedLanguageInfo,
      [attribute]: value,
    };

    setFormData((prevTrip) => ({
      ...prevTrip,
      details: {
        ...prevTrip.details,
        event_language_infos: updatedLanguageInfos,
      },
    }));

    if (regex.test(value)) {
      setErrors(selectedLanguageIndex, attribute, "");
    } else {
      setErrors(selectedLanguageIndex, attribute, errorMessage);
    }
  };

  useEffect(() => {
    if (defaultLanguage) {
      setSelectedLanguage(defaultLanguage);
    }
  }, [defaultLanguage]);

  return {
    selectedLanguage,
    setSelectedLanguage,
    selectedLanguageIndex,
    selectedLanguageInfo,
    handleChangeAttribute,
  };
};

export default useEventLanguage;
