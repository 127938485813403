import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency, generateRandomColor } from "../../constants/utils";
import { useQueryEventB2bSales } from "../../hooks/queries/b2b/useQueryB2bEventSales";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import useUserRoles from "../../hooks/useUserRoles";
import { settleB2bPayment } from "../../services/b2bServices";
import CustomDialog from "../shared/CustomDialog";
import InfoToolTip from "../shared/InfoToolTip";
import { Loading } from "../shared/Loading";
import { toastMessageSuccess } from "../shared/toastMessage";
import { AgentSales } from "./b2b/AgentSales";
import OrderDetail from "./orderDetails/OrderDetail";

export const EventB2bDetails = ({ eventId }) => {
  const { t } = useTranslation();
  const { isB2bSaleAgent } = useUserRoles();
  const { data: userDomains = [] } = useQueryDomains();
  const userDomain = userDomains[0]?.domain_name || "";

  const [openPayCommissionDialog, setOpenPayCommissionDialog] = useState({
    open: false,
    event_id: null,
    ticket_id: null,
    premise_agent_id: null,
    payment_type: null,
    net_product: null,
    margin: null,
  });
  const [openSettleAllDialog, setOpenSettleAllDialog] = useState({
    open: false,
    agent: null,
  });
  const [showDetailOrder, setShowDetailOrder] = useState({
    order_id: null,
    open: false,
  });

  const {
    data: eventB2bSales,
    refetch: refetchSales,
    isLoading,
  } = useQueryEventB2bSales(eventId, userDomain, isB2bSaleAgent);

  const { agents = [], own_sales = [] } = eventB2bSales || {};

  const handleClosePayCommissionDialog = () => {
    setOpenPayCommissionDialog({
      open: false,
      event_id: null,
      ticket_id: null,
      premise_agent_id: null,
      payment_type: null,
      net_product: null,
      margin: null,
    });
  };

  const handlePayCommission = async () => {
    try {
      const { event_id, premise_agent_id, payment_type, net_product, margin, ticket_id } =
        openPayCommissionDialog;
      const body = {
        event_id,
        ticket_id,
        premise_agent_id,
        payment_type,
        net_product,
        margin,
      };
      await settleB2bPayment(body);
      toastMessageSuccess(t("SETTLE_PAYMENT_SUCCESS"));
      handleClosePayCommissionDialog();
      refetchSales();
    } catch (error) {
      const message = error.response?.data?.error || t("SETTLE_PAYMENT_ERROR");
      toastMessageSuccess(message);
    }
  };

  const handleCloseSettleAllDialog = () => {
    setOpenSettleAllDialog({
      open: false,
      agent: null,
    });
  };

  const handleSettledAll = async () => {
    try {
      const agent = openSettleAllDialog.agent;
      const agentSales = agent?.sales.filter((sale) =>
        agent.settled_payments?.every((payment) => payment.ticket_id !== sale.ticket_id)
      );

      for (let i = 0; i < agentSales.length; i++) {
        const body = {
          event_id: eventId,
          ticket_id: agentSales[i].id,
          premise_agent_id: agent.id,
          payment_type: agentSales[i].payment_type,
          net_product: agentSales[i].amount,
          margin: agentSales[i].commission,
        };
        await settleB2bPayment(body);
      }

      toastMessageSuccess(t("SETTLE_PAYMENT_SUCCESS"));
      handleCloseSettleAllDialog();
      refetchSales();
    } catch (error) {
      const message = error.response?.data?.error || t("SETTLE_PAYMENT_ERROR");
      toastMessageSuccess(message);
    }
  };

  const salesByAgent = [
    ...agents?.map((agent) => {
      return {
        name: agent.name,
        amount: agent.orders || 0,
      };
    }),
    { name: t("OWN_SALES"), amount: own_sales?.sold_tickets || 0 },
  ];

  const totalSales = salesByAgent.reduce((sum, sale) => sum + sale.amount, 0);

  return isLoading ? (
    <Loading />
  ) : showDetailOrder.open ? (
    <OrderDetail
      order={showDetailOrder.order_id}
      transactionId={""}
      firstPaymentTransactionId={""}
      onBack={() => setShowDetailOrder({ open: false, order_id: null })}
    />
  ) : (
    <Grid container mt={2} spacing={2} alignItems="center">
      {/* TOTAL SALES */}
      <Grid item xs={12}>
        <Typography variant="h6">{t("Total plazas vendidas")}</Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Grid
          item
          container
          flexDirection="column"
          alignItems="flex-end"
          sx={{ width: "fit-content" }}
        >
          <Typography variant="h6" sx={{ margin: "0 auto" }}>
            {totalSales}
          </Typography>
          <Typography variant="caption">{t("Total plazas vendidas")}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container alignItems="center">
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: 20,
            borderRadius: 10,
            overflow: "hidden",
          }}
        >
          {salesByAgent?.map((sale) => {
            const widthPercentage = (sale.amount / totalSales) * 100;

            return (
              <InfoToolTip key={sale.name} text={sale.name} arrow placement="top">
                <Box
                  sx={{
                    width: `${widthPercentage}%`,
                    backgroundColor: `${generateRandomColor()}`,
                    height: "100%",
                  }}
                />
              </InfoToolTip>
            );
          })}
        </Box>
      </Grid>
      {/* OWN SALES */}
      <Grid item xs={12}>
        <Typography variant="h6">{t("SELF_SALES")}</Typography>
        <TableContainer
          component={Paper}
          elevation={3}
          sx={{ marginTop: "20px", borderRadius: "10px" }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("PLACES_SOLD")}</TableCell>
                <TableCell align="center">{t("PVP")}</TableCell>
                <TableCell align="center">{t("NET_INCOME")}</TableCell>
                <TableCell align="center">{t("TOTAL_SALES")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{own_sales?.sold_tickets || 0}</TableCell>
                <TableCell align="center">{formatCurrency(own_sales?.pvp || 0)}</TableCell>
                <TableCell align="center">{formatCurrency(own_sales?.pn || 0)}</TableCell>
                <TableCell align="center">{formatCurrency(own_sales?.sales || 0)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* B2B SALES */}
      <Grid item xs={12}>
        <Typography variant="h6">{t("B2B_AGENTS_TRIPS_SALES")}</Typography>
        {agents.map((agent, agentIndex) => (
          <AgentSales
            agent={agent}
            setOpenSettleAllDialog={setOpenSettleAllDialog}
            setShowDetailOrder={setShowDetailOrder}
            setOpenPayCommissionDialog={setOpenPayCommissionDialog}
            eventId={eventId}
            key={`agent-${agentIndex}`}
          />
        ))}
      </Grid>
      <CustomDialog
        title={t("PAY_COMMISSION")}
        content={t("PAY_COMMISSION_DESCRIPTION")}
        onClose={handleClosePayCommissionDialog}
        onAccept={handlePayCommission}
        isOpen={openPayCommissionDialog.open}
      />
      <CustomDialog
        title={t("SETTLE_ALL")}
        content={t("SETTLE_ALL_DESCRIPTION")}
        onClose={handleCloseSettleAllDialog}
        onAccept={handleSettledAll}
        isOpen={openSettleAllDialog.open}
      />
    </Grid>
  );
};
