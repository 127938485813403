import { SERVICE } from "../constants/api";
import { http } from "../constants/axios";

export async function getUsersAdmins(page = 1, perPage = 10, search, selectedPremise, is_active) {
  const params = new URLSearchParams();
  params.append("page", page);
  params.append("perPage", perPage);
  if (search && search !== "") params.append("search", search);
  if (selectedPremise && selectedPremise !== "all") params.append("premiseId", selectedPremise);
  if (is_active !== undefined) params.append("is_active", is_active);

  const { data } = await http.get(`${SERVICE.ADMINS}?${params}`);
  return data;
}
export async function createAdmin(body) {
  const { data } = await http.post(`${SERVICE.CREATE_ADMIN}`, body);
  return data;
}
