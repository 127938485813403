import { Add, Close } from "@mui/icons-material";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { compareDatesIgnoringTime } from "../../../constants/utils";
import { deleteTicket } from "../../../services/eventsServices";
import { focusColor } from "../../shared/textFieldStyle";
import { toastMessageError } from "../../shared/toastMessage";
import { FormFieldHeader } from "../FormFieldHeader";

const TripDates = () => {
  const { t } = useTranslation();
  const {
    watch,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useFormContext();

  const handleAddDate = () => {
    const dates = [...getValues("dates")];
    dates.push({
      start_date: "",
      start_date_time: "",
      end_date: "",
      end_date_time: "",
    });
    setValue("dates", dates);

    setValue("multi_date", dates?.length > 1);
  };

  const handleRemoveDate = async (index) => {
    const dates = [...getValues("dates")];
    const tickets = getValues("tickets") || [];

    const dateToRemove = dates[index];
    const ticketsToKeep = [];
    const deletePromises = [];

    tickets
      .sort((a, b) => Number(a.position) - Number(b.position))
      .forEach((ticket) => {
        if (
          compareDatesIgnoringTime(ticket.start_date, dateToRemove.start_date) &&
          compareDatesIgnoringTime(ticket.end_date, dateToRemove.end_date)
        ) {
          if (ticket.id) {
            deletePromises.push(
              deleteTicket(ticket.id).catch((error) => {
                const errorMessage = error?.response?.data?.error || t("ERROR");
                toastMessageError(errorMessage);
              })
            );
          }
        } else {
          ticketsToKeep.push({
            ...ticket,
            position: ticketsToKeep.length,
          });
        }
      });

    await Promise.all(deletePromises);

    dates.splice(index, 1);

    setValue("dates", dates);
    setValue("tickets", ticketsToKeep);

    setValue("multi_date", dates?.length > 1);
  };

  const handleChangeDate = (e, index, key) => {
    const dates = [...getValues("dates")];
    const tickets = [...(getValues("tickets") || [])];
    const prevValue = dates[index][key];

    //Change dates in tickets with same date
    tickets.forEach((ticket) => {
      if (ticket[key] === prevValue) {
        ticket[key] = e.target.value;
      }
    });
    setValue("tickets", tickets);

    dates[index][key] = e.target.value;
    setValue("dates", dates);

    if (index === 0) {
      setValue(key, dates[0][key]);
    }
  };

  const dates = watch("dates", []);

  // On dismount set the first event date
  useEffect(() => {
    if (dates && dates?.length === 1) {
      setValue("start_date", dates[0].start_date);
      setValue("end_date", dates[0].end_date);
      setValue("start_date_time", dates[0].start_date_time);
      setValue("end_date_time", dates[0].end_date_time);
    }
  }, [dates]);

  return (
    <Grid item xs={12}>
      <FormFieldHeader title={t("DATES")} description={t("DATES_DESCRIPTION")} />
      <Grid container mt={4} padding={2} gap={4}>
        {dates?.map((_, index) => (
          <Grid
            item
            xs={12}
            container
            spacing={2}
            sx={{
              border: "1px solid var(--field-grey)",
              borderRadius: 2,
              padding: 2,
              position: "relative",
            }}
            key={index}
          >
            {index !== 0 && (
              <IconButton
                aria-label="close"
                onClick={() => handleRemoveDate(index)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <Close />
              </IconButton>
            )}
            <Grid item xs={12} sm={6}>
              <Typography>{t("START_DATE")}</Typography>
              <Controller
                name={`dates.${index}.start_date`}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    type="date"
                    {...field}
                    value={field.value}
                    onChange={(e) => handleChangeDate(e, index, "start_date")}
                    fullWidth
                    error={!!errors?.dates?.[index]?.start_date}
                    helperText={t(errors?.dates?.[index]?.start_date?.message)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={focusColor}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>{t("END_DATE")}</Typography>
              <Controller
                name={`dates.${index}.end_date`}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    type="date"
                    {...field}
                    value={field.value}
                    onChange={(e) => handleChangeDate(e, index, "end_date")}
                    fullWidth
                    error={!!errors?.dates?.[index]?.end_date}
                    helperText={t(errors?.dates?.[index]?.end_date?.message)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={focusColor}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>{t("START_TIME")}</Typography>
              <Controller
                name={`dates.${index}.start_date_time`}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    type="time"
                    {...field}
                    value={field.value}
                    onChange={(e) => handleChangeDate(e, index, "start_date_time")}
                    fullWidth
                    error={!!errors?.dates?.[index]?.start_date_time}
                    helperText={t(errors?.dates?.[index]?.start_date_time?.message)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={focusColor}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>{t("END_TIME")}</Typography>
              <Controller
                name={`dates.${index}.end_date_time`}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    type="time"
                    {...field}
                    value={field.value}
                    onChange={(e) => handleChangeDate(e, index, "end_date_time")}
                    fullWidth
                    error={!!errors?.dates?.[index]?.end_date_time}
                    helperText={t(errors?.dates?.[index]?.end_date_time?.message)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={focusColor}
                  />
                )}
              />
            </Grid>
          </Grid>
        ))}
        {errors?.dates && errors?.dates?.message ? (
          <Typography className="form-input-error">{t(errors?.dates?.message)}</Typography>
        ) : null}
      </Grid>
      <Button
        className="oniria-border-btn"
        onClick={handleAddDate}
        startIcon={<Add />}
        sx={{ borderRadius: 3, textTransform: "capitalize" }}
      >
        {t("ADD_DATES")}
      </Button>
    </Grid>
  );
};

export default TripDates;
