import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid, LinearProgress, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PROGRESS_BAR_STYLES, SMALL_TAB } from "../../../constants/styles";
import { getYesterday } from "../../../constants/utils";

const TicketsSold = ({ tickets = [], totalSales, soldTicketsPercent, isMobile }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const yesterday = getYesterday();
  const isMultiDate = tickets.some((ticket) => !!ticket.end_date);

  const handleTabChange = (_, newValue) => setTab(newValue);

  const sortedTickets = tickets.toSorted((a, b) => new Date(a.end_date) - new Date(b.end_date));

  const upcomingTickets = sortedTickets.filter((ticket) => new Date(ticket.end_date) >= yesterday);
  const pastTickets = sortedTickets.filter((ticket) => new Date(ticket.end_date) < yesterday);

  const filteredTickets = !isMultiDate ? sortedTickets : tab === 0 ? upcomingTickets : pastTickets;

  const numberValidated = tickets?.reduce((total, ticket) => {
    return total + (ticket.consumed_tickets || 0);
  }, 0);

  return (
    <Box
      component={Paper}
      elevation={3}
      sx={{
        py: 2,
        px: 1,
        mb: 2,
        border: "1px solid #E4E4E4",
        borderRadius: "15px",
        maxWidth: "100%",
      }}
    >
      {isMultiDate && (
        <Tabs
          value={tab}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{
            style: { backgroundColor: "var(--secondary-color)" },
          }}
          sx={{
            mb: 1,
            minHeight: "32px",
          }}
        >
          <Tab label={t("UPCOMING")} value={0} sx={SMALL_TAB} />
          <Tab label={t("PAST")} value={1} sx={SMALL_TAB} />
        </Tabs>
      )}

      <Grid item container flexDirection="row" justifyContent="space-between" mb={1}>
        <Grid item xs={6} container alignItems="center" gap={1}>
          {isMobile && <CheckCircleIcon sx={{ color: "var(--green-success)" }} />}
          <Typography variant="subtitle2">
            {numberValidated}/{totalSales} {!isMobile && t("REGISTER_ASSISTANTS_STOCK")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            {soldTicketsPercent(numberValidated, totalSales)}% {t("PERCENTAGE_ARRIVED")}
          </Typography>
        </Grid>
      </Grid>
      <LinearProgress
        variant="determinate"
        value={soldTicketsPercent(numberValidated, totalSales)}
        sx={PROGRESS_BAR_STYLES}
      />
      {filteredTickets && filteredTickets.length > 0
        ? filteredTickets.map((ticket, index) => (
            <Grid
              item
              container
              flexDirection="row"
              justifyContent="space-between"
              mt={2}
              xs={12}
              pl={2}
              key={`ticket-${index}`}
            >
              <Grid item xs={6} container alignItems="center" gap={1}>
                <Typography variant="subtitle2">
                  {ticket.consumed_tickets || 0}/{ticket.sold_tickets || 0} {ticket.ticket_name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {soldTicketsPercent(ticket.consumed_tickets || 0, ticket.sold_tickets || 0)}%{" "}
                  {t("PERCENTAGE_ARRIVED")}
                </Typography>
              </Grid>
              <LinearProgress
                variant="determinate"
                value={soldTicketsPercent(ticket.consumed_tickets || 0, ticket.sold_tickets || 0)}
                sx={PROGRESS_BAR_STYLES}
              />
            </Grid>
          ))
        : null}
    </Box>
  );
};

export default TicketsSold;
