import { Close } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyledAccordion } from "../../shared/StyledAccordion";
import { focusColor } from "../../shared/textFieldStyle";
import TripExtraWhereToBuy from "./TripExtraWhereToBuy";

export const TripExtraDialog = ({ isOpen, onClose, extraIndex }) => {
  const { t } = useTranslation();

  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  if (extraIndex === null || extraIndex === "undefined") return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <Grid
        sx={{
          backgroundColor: "var(--grey-background)",
          width: "100%",
          padding: 3,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" fontWeight="bolder" mb={1}>
          {t("ASSIGN")}
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Grid>
      <Grid container padding={2} spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                {...register(`extras[${extraIndex}].no_after_sales`)}
                checked={watch(`extras.${extraIndex}.no_after_sales`, false)}
                className="checkbox-oniria"
              />
            }
            label={t("NO_AFTER_SALES")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography fontWeight="600">{t("EXTRA_PRICE")}</Typography>
          <TextField
            placeholder={"00 €"}
            {...register(`extras[${extraIndex}].price`)}
            error={!!errors?.extras?.[extraIndex]?.price}
            helperText={t(errors?.extras?.[extraIndex]?.price?.message)}
            fullWidth
            sx={focusColor}
            type="number"
            inputProps={{ min: 0 }}
            required
            onWheel={(e) => e.target.blur()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography fontWeight={"600"}>{t("EXTRA_STOCK ")}</Typography>
          <TextField
            placeholder={"00"}
            {...register(`extras[${extraIndex}].initial_stock`)}
            error={!!errors?.extras?.[extraIndex]?.initial_stock}
            helperText={t(errors?.extras?.[extraIndex]?.initial_stock?.message)}
            fullWidth
            sx={focusColor}
            type="number"
            inputProps={{ min: 0 }}
            required
            onWheel={(e) => e.target.blur()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography fontWeight={"600"}>{t("LIMIT_EXTRA")}</Typography>
          <TextField
            placeholder={"00"}
            {...register(`extras[${extraIndex}].stock_by_ticket_max`)}
            error={!!errors?.extras?.[extraIndex]?.stock_by_ticket_max}
            helperText={t(errors?.extras?.[extraIndex]?.stock_by_ticket_max?.message)}
            fullWidth
            sx={focusColor}
            type="number"
            inputProps={{ min: 0 }}
            required
            onWheel={(e) => e.target.blur()}
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <StyledAccordion title={t("ADVANCED_OPTIONS")}>
          <TripExtraWhereToBuy index={extraIndex} />
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  {...register(`extras[${extraIndex}].only_international`)}
                  checked={watch(`extras.${extraIndex}.only_international`, false)}
                  className="checkbox-oniria"
                />
              }
              label={t("ONLY_MEMBER_CARD")}
            />
          </Grid>
        </StyledAccordion>
      </Grid>
      <Grid container justifyContent="center">
        <Button variant="contained" className="oniria-btn" sx={{ mt: 2, mb: 2 }} onClick={onClose}>
          {t("SAVE")}
        </Button>
      </Grid>
    </Dialog>
  );
};
