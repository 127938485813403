import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseDialogButton } from "../../shared/CloseDialogButton";
import { focusColor } from "../../shared/textFieldStyle";

export const AddPaymentsDialog = ({ isOpen, onClose, onAccept }) => {
  const { t } = useTranslation();
  const [nPayments, setNPayments] = useState(0);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <CloseDialogButton onClose={onClose} />
      <DialogTitle sx={{ fontWeight: "bolder" }}>{t("ADD_GROUP_PAYMENTS")}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "black", textAlign: "center" }}>
          {t("SELECT_THE_NUMBER_OF_PAYMENTS")}
        </DialogContentText>
        <TextField
          value={nPayments}
          onChange={(e) => {
            const value = Math.max(2, Number(e.target.value) || 2);
            setNPayments(value);
          }}
          type="number"
          inputProps={{ min: 2 }}
          sx={[focusColor, { width: "100%" }]}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={() => onAccept(nPayments)}
        >
          {t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
