import { ArrowBack } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { defaultBlog } from "../../classes/blogClass";
import EmailsEditor from "../../components/emailEditor/EmailEditor";
import LanguageTabs from "../../components/events/languages/LanguageTabs";
import { FormField } from "../../components/shared/FormField";
import { FormSelect } from "../../components/shared/FormSelect";
import { handleSelectImage } from "../../components/shared/FormsValidator";
import ImageUpload from "../../components/shared/ImageUpload";
import { Loading } from "../../components/shared/Loading";
import { focusColor } from "../../components/shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { TranslationFields } from "../../components/shared/TranslationFields";
import { prepareBlogToReceive, prepareBlogToSend } from "../../constants/blogs";
import { ROUTES } from "../../constants/routes";
import { AVAILABLE_LANGUAGES, DEFAULT_EVENT_LANGUAGE } from "../../constants/variables";
import { useQueryBlogDetail } from "../../hooks/queries/blogs/useQueryBlogDetail";
import { useQueryTagsList } from "../../hooks/queries/tags/useQueryTagsList";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import { checkBlogSlug, createBlog, updateBlog } from "../../services/blogServices";

const BlogForm = ({ isEditMode, isDuplicateMode }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const emailEditorRefs = useRef([]);

  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(defaultBlog)));
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(DEFAULT_EVENT_LANGUAGE);

  // get blog post details
  const handleSetFormData = async (data) => {
    const newData = await prepareBlogToReceive(data);
    if (isDuplicateMode) {
      newData.id = null;
      newData.slug = null;
    }
    setFormData(newData);

    // SET TAB WITH DEFAULT LANGUAGE
    if (newData.default_language) {
      setActiveTab(newData.default_language);
    }
  };

  const { isLoading } = useQueryBlogDetail(id, handleSetFormData);

  // select options
  const { data: premises } = useQueryPremisesList();
  const { data: domains } = useQueryDomains();
  const { data: tags } = useQueryTagsList();

  const handleChangeTags = (e) => {
    setFormParams("tag_ids", e.target.value);
  };

  const domainsOptions = domains?.map((domain) => ({
    id: domain.domain_id,
    name: domain.domain_name,
  }));

  const handleGoBack = () => {
    navigate(ROUTES.BLOG);
  };

  const setFormParams = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const setTranslationParams = (index, field, value) => {
    const newTranslations = [...formData.translations];
    newTranslations[index][field] = value;
    setFormData((prev) => ({
      ...prev,
      translations: newTranslations,
    }));
  };

  const validateForm = () => {
    const errors = {};
    const requiredFields = ["slug", "premise_id"];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = t("REQUIRED_FIELD");
      }
    });

    if (formData.translations && formData.translations.length > 0) {
      formData.translations.forEach((translation, index) => {
        ["language", "title"].forEach((field) => {
          if (!translation[field]) {
            errors[`translations.${index}.${field}`] = t("REQUIRED_FIELD");
          }
        });
      });
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    setLoading(true);
    // check fields
    if (!validateForm()) {
      toastMessageError(t("PLEASE_FIX_ERRORS"));
      setLoading(false);
      return;
    }

    const formDataToSend = await prepareBlogToSend(formData, tags, isEditMode);

    try {
      if (isEditMode) {
        await updateBlog(id, formDataToSend);
        toastMessageSuccess(t("BLOG_UPDATED_SUCCESS"));
        setTimeout(() => {
          navigate(ROUTES.BLOG);
        }, 1500);
      } else {
        // CREATE BLOG
        const slugAvailable = await checkBlogSlug(formDataToSend.slug);
        if (slugAvailable) {
          await createBlog(formDataToSend);
          toastMessageSuccess(t("BLOG_CREATED_SUCCESS"));
          setTimeout(() => {
            navigate(ROUTES.BLOG);
          }, 1500);
        } else {
          setFormErrors((prev) => ({ ...prev, slug: t("BLOG_SLUG_ERROR") }));
          toastMessageError(t("BLOG_SLUG_ERROR"));
        }
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error
        ? error.response.data.error
        : isEditMode
        ? t("ERROR_UPDATING_BLOG")
        : t("ERROR_CREATING_BLOG");
      toastMessageError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const getTabIdiomPosition = (language) => {
    return (
      formData?.translations?.findIndex((translation) => translation.language === language) + 1
    );
  };

  const languagesOptions = AVAILABLE_LANGUAGES.map((lang) => ({
    id: lang.id,
    name: t(lang.name),
  }));

  const handleChangeDefaultLanguage = (e) => {
    const value = e.target.value;
    setFormParams("default_language", value);
    setActiveTab(value);

    // IF DEFAULT_LANGUAGE TRANSLATIONS , DELETE THEM
    if (formData.translations && formData.translations.length > 0) {
      setFormData((prev) => ({
        ...prev,
        translations: prev.translations.filter((translation) => translation.language !== value),
      }));
    }
  };

  return (
    <Grid mt={7}>
      <IconButton onClick={handleGoBack} mt={10}>
        <ArrowBack />
      </IconButton>
      <Grid container justifyContent="center" mb={5}>
        <Typography variant="h5">{isEditMode ? t("EDIT_BLOG") : t("CREATE_BLOG")}</Typography>
      </Grid>
      {isLoading ? (
        <Loading />
      ) : (
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <ImageUpload
                url={formData.image_url}
                onImageSelect={(url) => handleSelectImage(url, setFormData, "image_url")}
                multi={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} container>
              <FormSelect
                label={t("LANGUAGE")}
                value={formData?.default_language}
                onChange={handleChangeDefaultLanguage}
                error={formErrors?.default_language}
                helperText={formErrors?.default_language}
                options={languagesOptions}
              />
              <FormField
                label={t("TITLE")}
                value={formData?.title}
                onChange={(e) => setFormParams("title", e.target.value)}
                error={formErrors.title}
                helperText={formErrors.title}
                sx={focusColor}
                required
              />
              <FormField
                label={t("SLUG")}
                value={formData?.slug}
                onChange={(e) => setFormParams("slug", e.target.value)}
                error={formErrors.slug}
                helperText={formErrors.slug}
                sx={focusColor}
              />
              <FormField
                label={t("DESCRIPTION")}
                value={formData?.description}
                onChange={(e) => setFormParams("description", e.target.value)}
                error={formErrors.description}
                helperText={formErrors.description}
                sx={focusColor}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormSelect
                label={t("PREMISE")}
                value={formData?.premise_id}
                onChange={(e) => setFormParams("premise_id", e.target.value)}
                error={formErrors.premise_id}
                helperText={formErrors.premise_id}
                options={premises}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormSelect
                label={t("TAGS")}
                value={formData?.tag_ids || []}
                onChange={handleChangeTags}
                options={tags}
                multiple
                error={formErrors.tag_ids}
                helperText={formErrors.tag_ids}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormSelect
                label={t("DOMAINS")}
                value={formData?.domain_ids || []}
                onChange={(e) => setFormParams("domain_ids", e.target.value)}
                options={domainsOptions}
                multiple
                error={formErrors.domain_ids}
                helperText={formErrors.domain_ids}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormSelect
                label={t("STATUS")}
                value={formData?.status}
                onChange={(e) => setFormParams("status", e.target.value)}
                options={[
                  { id: "draft", name: t("DRAFT") },
                  { id: "published", name: t("PUBLISHED") },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} container height="fit-content">
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData?.featured === true || formData?.featured === 1}
                      onChange={(e) => setFormParams("featured", e.target.checked)}
                      className="checkbox-oniria"
                    />
                  }
                  label={t("FEATURED")}
                />
              </Grid> */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData?.visible === true || formData?.visible === 1}
                      onChange={(e) => setFormParams("visible", e.target.checked)}
                      className="checkbox-oniria"
                    />
                  }
                  label={t("VISIBLE")}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData?.is_sponsored === true || formData?.is_sponsored === 1}
                      onChange={(e) => setFormParams("is_sponsored", e.target.checked)}
                      className="checkbox-oniria"
                    />
                  }
                  label={t("SPONSORED")}
                />
              </Grid> */}
            </Grid>
            <Grid item xs={12}>
              <TranslationFields
                setFormData={setFormData}
                translations={formData?.translations}
                onChange={setTranslationParams}
                errors={formErrors}
                defaultLanguage={formData?.default_language}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: "0 auto" }}>
              <Typography variant="h7">{t("BLOG_DESIGN")}:</Typography>
            </Grid>
            {formData?.translations && formData.translations.length > 0 && (
              <LanguageTabs
                event_languages_info={formData.translations}
                selectedLanguage={activeTab}
                setSelectedLanguage={setActiveTab}
                defaultLanguage={formData?.default_language}
              />
            )}
            {activeTab === formData?.default_language ? (
              <EmailsEditor
                ref={(el) => el && (emailEditorRefs.current[0] = el)}
                initialDesign={formData?.design}
                onExportHtml={(html) => setFormParams("html", html)}
                onDesignChange={(design) => {
                  setFormParams("design", design);
                }}
                key={`editor-blog-${formData?.default_language}`}
              />
            ) : (
              <EmailsEditor
                ref={(el) => {
                  const tabIndex = getTabIdiomPosition(activeTab);
                  if (el) emailEditorRefs.current[tabIndex] = el;
                }}
                initialDesign={formData?.translations[getTabIdiomPosition(activeTab) - 1]?.design}
                onExportHtml={(html) =>
                  setTranslationParams(getTabIdiomPosition(activeTab) - 1, "html", html)
                }
                onDesignChange={(design) =>
                  setTranslationParams(getTabIdiomPosition(activeTab) - 1, "design", design)
                }
                key={`editor-blog-language-${activeTab}`}
              />
            )}
            <Grid item xs={12} mb={4} container justifyContent="center">
              <Button
                variant="contained"
                onClick={handleSubmit}
                className="oniria-btn"
                disabled={loading}
              >
                {loading ? t("SAVING") : isEditMode ? t("UPDATE_BLOG") : t("CREATE_BLOG")}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Grid>
  );
};

export default BlogForm;
