import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { normalizeVisibility } from "../../../constants/utils";
import { ASSIGN_TRIP_TYPES, EXTRAS_TYPES } from "../../../constants/variables";
import { HotelsAssignZone } from "./HotelsAssignZone";
import { TransportsAssignZone } from "./TransportsAssignZone";
import { ExtrasAssignZone } from "./ExtrasAssignZone";
import { ActivitiesAssignZone } from "./ActivitiesAssignZone";
import { TourLeadersAssignZone } from "./TourLeadersAssignZone";

const RightDragArea = ({ tripInfo, handleRemove, type }) => {
  const { t } = useTranslation();

  return (
    <Box width="45%" sx={{ border: "1px solid #ccc", borderRadius: 3, padding: 2 }}>
      <Box>
        <Typography variant="body1" sx={{ fontWeight: 900 }}>
          {tripInfo.name}
        </Typography>
        <Typography variant="caption">
          {normalizeVisibility(tripInfo?.multi_date)
            ? t("MULTI_DATE")
            : `${tripInfo?.start_date || ""} - ${tripInfo?.end_date || ""}`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
        {type === ASSIGN_TRIP_TYPES.HOSTING && (
          <HotelsAssignZone itineraries={tripInfo?.itineraries} handleRemove={handleRemove} />
        )}
        {type === ASSIGN_TRIP_TYPES.TRANSPORTS && (
          <TransportsAssignZone itineraries={tripInfo?.itineraries} handleRemove={handleRemove} />
        )}
        {type === ASSIGN_TRIP_TYPES.EXTRAS_INSURANCES && (
          <ExtrasAssignZone
            tripInfo={tripInfo}
            handleRemove={handleRemove}
            type={EXTRAS_TYPES.INSURANCE}
          />
        )}
        {type === ASSIGN_TRIP_TYPES.ACTIVITY && (
          <ActivitiesAssignZone tripInfo={tripInfo} handleRemove={handleRemove} />
        )}
        {type === ASSIGN_TRIP_TYPES.EXTRAS && (
          <ExtrasAssignZone
            tripInfo={tripInfo}
            handleRemove={handleRemove}
            type={EXTRAS_TYPES.GENERAL}
          />
        )}
        {type === ASSIGN_TRIP_TYPES.TOUR_LEADERS && (
          <TourLeadersAssignZone tripInfo={tripInfo} handleRemove={handleRemove} />
        )}
      </Box>
    </Box>
  );
};

export default RightDragArea;
