import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack } from "@mui/icons-material";
import { Button, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import useQueryTourPointDetails from "../../hooks/queries/tourPoints/useQueryTourPointDetails";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import { defaultTourPointLanguage, tourPointSchema } from "../../schemas/tourPoints";
import {
  assignDomainsToTourPoint,
  createTourPoint,
  editTourPoint,
} from "../../services/tourPoints";
import { prepareTourPointToReceive, prepareTourPointToSend } from "../../utils/tourPoints";
import LanguageSelect from "../shared/languages/LanguageSelect";
import { Loading } from "../shared/Loading";
import MapContent from "../shared/Map/LeafletMap";
import PdfUpload from "../shared/PdfUpload";
import SelectHookForm from "../shared/SelectHookForm";
import { focusColor } from "../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import TextEditor from "../textEditor/TextEditor";
import TourPointAddress from "./TourPointAddress";
import TourPointImages from "./TourPointImages";
import LanguageTabs from "../shared/languages/LanguageTabs";
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from "../../constants/variables";
import InfoToolTip from "../../components/shared/InfoToolTip";

const TourPointForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [languageTab, setLanguageTab] = useState(DEFAULT_LANGUAGE);

  const { data: premises = [] } = useQueryPremisesList();
  const { data: domains = [] } = useQueryDomains();

  const formMethods = useForm({
    resolver: zodResolver(tourPointSchema),
    mode: "onBlur",
    defaultValues: {
      default_language: DEFAULT_LANGUAGE,
    },
  });

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = formMethods;

  const handleSetFormData = (data) => {
    const newData = prepareTourPointToReceive(data);
    reset(newData);
  };

  const { isLoading, isRefetching } = useQueryTourPointDetails(id, handleSetFormData);

  const onSubmit = async (data) => {
    setLoadingSubmit(true);
    try {
      const bodyToSend = await prepareTourPointToSend(data);
      if (id) {
        // EDIT TOUR POINT
        await editTourPoint(id, bodyToSend);
        if (bodyToSend.domains_ids && bodyToSend.domains_ids.length > 0) {
          await assignDomainsToTourPoint(id, bodyToSend.domains_ids);
        }
      } else {
        // NEW TOUR POINT
        //TODO: mirar todos los required
        if (!bodyToSend.name || !bodyToSend.default_language || !bodyToSend.owner_premise_id) {
          return toastMessageSuccess(t("FALTAN CAMPOS"));
        }
        const response = await createTourPoint(bodyToSend);
        if (response.id && bodyToSend.domains_ids && bodyToSend.domains_ids.length > 0) {
          await assignDomainsToTourPoint(response.id, bodyToSend.domains_ids);
        }
      }
      setTimeout(() => {
        handleGoBack();
      }, 1500);
      toastMessageSuccess(t("CREATED_SUCCESSFULLY"));
    } catch (e) {
      toastMessageError(e?.response?.data?.error || t("ERROR"));
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onError = (errors) => {
    console.log("Errores formulario tour point", errors);
  };

  const handleGoBack = () => {
    navigate(ROUTES.TOUR_POINTS);
  };

  const handleChangeLatLong = (lat, lng) => {
    setValue("latitude", lat);
    setValue("longitude", lng);
  };

  const handleChangePdf = (value) => {
    setValue("pdf_info", value);
  };

  const handleChangeDescription = (data) => {
    setValue("long_description", data);
  };

  const handleChangeCheckbox = (isChecked, value) => {
    const newLanguage = JSON.parse(JSON.stringify(defaultTourPointLanguage));
    const languages = getValues("tour_point_language_infos") || [];
    if (isChecked) {
      newLanguage.language = value;
      setValue("tour_point_language_infos", [...languages, newLanguage]);
    } else {
      setValue(
        "tour_point_language_infos",
        languages.filter((language) => language?.language !== value)
      );
    }
  };

  const defaultLanguage = watch("default_language");
  const longDescription = watch("long_description");
  const tourPointTranslations = watch("tour_point_language_infos") || [];
  const translationIndex = tourPointTranslations.findIndex((tour) => tour.language === languageTab);

  const handleChangeLanguageDescription = (data) => {
    setValue(`tour_point_language_infos[${translationIndex}].long_description`, data);
  };

  const handleChangeLanguagePdf = (value) => {
    setValue(`tour_point_language_infos[${translationIndex}].pdf_info`, value);
  };

  useEffect(() => {
    if (defaultLanguage) {
      setLanguageTab(defaultLanguage);
    }
  }, [defaultLanguage]);

  return (
    <Grid mt={7} mb={5}>
      <IconButton onClick={handleGoBack}>
        <ArrowBack />
      </IconButton>
      <Grid container justifyContent="center" mb={5}>
        <Typography variant="h5">
          {!!id ? t("EDIT") : t("CREATE")} {t("TOUR_POINT")}
        </Typography>
      </Grid>
      {isLoading || isRefetching ? (
        <Loading />
      ) : (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <h3>{t("TOURPOINT_MULTILANG")}</h3>
            <Grid container spacing={2}>
              {/* SELECCIÓN DE DE IDIOMA */}
              <Grid item xs={12}>
                <Grid mb={2} mt={1}>
                  <SelectHookForm
                    name="default_language"
                    control={control}
                    label={t("DEFAULT_LANGUAGE")}
                    required
                    error={errors?.default_language}
                    defaultValue={""}
                    options={AVAILABLE_LANGUAGES.map((lang) => (
                      <MenuItem key={lang.id} value={lang.id}>
                        {t(lang.name)}
                      </MenuItem>
                    ))}
                  />
                </Grid>
                <LanguageSelect
                  translatedFields={watch("tour_point_language_infos")}
                  handleChangeCheckbox={handleChangeCheckbox}
                  defaultLanguage={defaultLanguage}
                />
                {tourPointTranslations.length > 0 ? (
                  <LanguageTabs
                    languagesInfo={tourPointTranslations}
                    selectedLanguage={languageTab}
                    setSelectedLanguage={setLanguageTab}
                    defaultLanguage={defaultLanguage}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <h3>{t("TOURPOINT_FILL_PRINCIPAL_INFO")}</h3>
              </Grid>

              {/* NAME */}
              <Grid item xs={12} sm={6}>
                <Typography>{t("NAME")}*</Typography>
                {languageTab === defaultLanguage ? (
                  <TextField
                    {...register("name")}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    fullWidth
                    required
                    sx={focusColor}
                    placeholder={t("NAME")}
                  />
                ) : (
                  <>
                    <TextField
                      {...register(`tour_point_language_infos[${translationIndex}].name`)}
                      error={!!errors.tour_point_language_infos?.[translationIndex]?.name}
                      helperText={
                        errors.tour_point_language_infos?.[translationIndex]?.name?.message
                      }
                      fullWidth
                      required
                      sx={focusColor}
                      placeholder={t("NAME")}
                    />
                  </>
                )}
              </Grid>
              {/* SKU */}
              <Grid item xs={12} sm={6}>
                <Typography>
                  {t("SKU")}
                  <InfoToolTip text={t("SKU_INFO")} />
                </Typography>
                <TextField
                  placeholder={t("SKU")}
                  {...register("sku")}
                  error={!!errors.sku}
                  helperText={errors.sku?.message}
                  fullWidth
                  sx={focusColor}
                />
              </Grid>
              {/*  */}
              <TourPointAddress />
              <Grid item xs={12}>
                <Typography>{t("SHORT_DESCRIPTION_TEXT")}</Typography>
                {languageTab === defaultLanguage ? (
                  <TextField
                    placeholder={t("SHORT_DESCRIPTION_TEXT")}
                    {...register("short_description")}
                    error={!!errors.short_description}
                    helperText={errors.short_description?.message}
                    fullWidth
                    sx={focusColor}
                  />
                ) : (
                  <>
                    <TextField
                      {...register(
                        `tour_point_language_infos[${translationIndex}].short_description`
                      )}
                      error={
                        !!errors.tour_point_language_infos?.[translationIndex]?.short_description
                      }
                      helperText={
                        errors.tour_point_language_infos?.[translationIndex]?.short_description
                          ?.message
                      }
                      fullWidth
                      sx={focusColor}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("DESCRIPTION")}</Typography>
                {languageTab === defaultLanguage ? (
                  <TextEditor
                    data={longDescription}
                    id={`description_tour_point_${id}`}
                    setData={handleChangeDescription}
                  />
                ) : (
                  <>
                    <TextEditor
                      data={tourPointTranslations[translationIndex]?.long_description}
                      id={`description_tour_point_${id}_${translationIndex}`}
                      setData={handleChangeLanguageDescription}
                    />
                  </>
                )}
              </Grid>
              <TourPointImages />
              <Grid item xs={12}>
                <h3>{t("MAP")}</h3>
                <Typography>{t("MAP_DESCRIPTION")}</Typography>
                <Typography sx={{ fontWeight: "bold" }}>{t("MAP_DESCRIPTION_2")}</Typography>
              </Grid>
              <Grid item xs={12} lg={8} sx={{ height: "250px", width: "100%" }}>
                <MapContent
                  marker
                  handleChangeLatLong={handleChangeLatLong}
                  isDisabled={false}
                  lat={watch("latitude")}
                  lng={watch("longitude")}
                />
              </Grid>

              {/* ADDITIONAL INFO */}
              <Grid item xs={12}>
                <h3>{t("TOURPOINT_FILL_ADDITIONAL_INFO")}</h3>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {t("VIDEO_URL")}
                  <InfoToolTip text={t("TOURPOINT_FILL_VIDEO")} />
                </Typography>
                <TextField
                  placeholder={t("VIDEO_URL")}
                  {...register("video_url")}
                  error={!!errors.video_url}
                  helperText={errors.video_url?.message}
                  fullWidth
                  sx={focusColor}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>
                  {t("PDF_INFORMATIVE")}
                  <InfoToolTip text={t("TOURPOINT_PDF_INFO")} />
                </Typography>
                {languageTab === defaultLanguage ? (
                  <PdfUpload value={watch("pdf_info")} onChange={handleChangePdf} />
                ) : (
                  <>
                    <PdfUpload
                      value={tourPointTranslations[translationIndex]?.pdf_info}
                      onChange={handleChangeLanguagePdf}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              {/* CONFIGURACION */}
              <Grid item xs={12}>
                <h3>{t("TOURPOINT_CONFIG")}</h3>
              </Grid>
              {domains && domains.length > 0 && (
                <Grid item xs={12} sm={6}>
                  <SelectHookForm
                    name={"domains_ids"}
                    control={control}
                    label={t("DOMAINS")}
                    error={errors.domains_ids}
                    required={true}
                    multiple
                    defaultValue={[]}
                    options={[{ domain_id: "", name: t("NONE") }, ...domains].map((domain) => (
                      <MenuItem key={domain.domain_id} value={domain.domain_id}>
                        {domain.domain_name}
                      </MenuItem>
                    ))}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <SelectHookForm
                  name={"owner_premise_id"}
                  control={control}
                  label={t("PREMISE")}
                  required={true}
                  error={errors.owner_premise_id}
                  options={premises.map((premise) => (
                    <MenuItem key={premise.id} value={premise.id}>
                      {premise.name}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center" gap={2}>
                <Button
                  onClick={handleGoBack}
                  className="oniria-btn-cancel"
                  variant="contained"
                  size="medium"
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  {t("CANCEL")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="oniria-btn"
                  disabled={loadingSubmit}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  {loadingSubmit ? t("SAVING") : t("SAVE")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
    </Grid>
  );
};

export default TourPointForm;
