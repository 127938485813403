import { DialogTitle, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import ExtrasForm from "./ExtrasForm";
import { CloseDialogButton } from "../shared/CloseDialogButton";
import { useTranslation } from "react-i18next";

export const ExtraFormDialog = ({ isOpen, onClose, extraType = "" }) => {
  const { t } = useTranslation();

  const handleGoBack = () => {
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <CloseDialogButton onClose={onClose} />
      <DialogTitle sx={{ fontWeight: "bold", backgroundColor: "var(--grey-background)" }}>
        {t("CREATE")} {t(extraType.toUpperCase())}
      </DialogTitle>
      <Grid container sx={{ padding: 2, paddingTop: 0 }}>
        <ExtrasForm goBack={handleGoBack} extraType={extraType} />
      </Grid>
    </Dialog>
  );
};
