import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Grid, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { RQ_KEY } from "../../constants/query";
import { formatDate, formatHours } from "../../constants/utils";
import { getBlogDetail } from "../../services/blogServices";
import { Loading } from "./../../components/shared/Loading";

const BlogDetail = (props) => {
  const { t } = useTranslation();
  const { postId } = useParams();

  const [blogDetail, setBlogDetail] = useState({});

  const handleQuerySuccess = (data) => {
    setBlogDetail(data[0]);
  };

  const { isLoading, error } = useQuery([RQ_KEY.BLOG_ID + postId], () => getBlogDetail(postId), {
    onSuccess: handleQuerySuccess,
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <Loading />;
  } else if (error) {
    return <Typography>{t("ERROR_LOADING_DATA")}</Typography>;
  }

  return (
    <>
      {blogDetail && (
        <Grid container sx={{ display: "flex", flexDirection: "row", mb: 3, mt: 2 }}>
          <Link
            className="oniria-colorText"
            underline="none"
            onClick={props.onBack}
            style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
          >
            <ChevronLeftIcon style={{ marginRight: "4px" }} />
            <span style={{ verticalAlign: "middle" }}>{t("LIST_BLOGS")}</span>
          </Link>
        </Grid>
      )}
      <Grid container rowGap={2}>
        {/* BLOG DATA */}
        <Grid
          item
          xs={12}
          mt={1}
          padding={{ xs: 1, sm: 2 }}
          component={Paper}
          sx={{ borderRadius: "20px" }}
        >
          <Typography
            variant="h6"
            sx={{
              borderBottom: "2px solid var(--secondary-color)",
              width: "fit-content",
              marginBottom: "10px",
              textTransform: "capitalize",
            }}
          >
            {t("BLOG_DETAILS")}:
          </Typography>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4} lg={4}>
                <ListItem>
                  <ListItemText primary={t("TITLE")} secondary={blogDetail?.translated_title} />
                </ListItem>
              </Grid>
              <Grid item xs={6} md={4} lg={4}>
                <ListItem>
                  <ListItemText primary={t("AUTHOR")} secondary={blogDetail?.author_id} />
                </ListItem>
              </Grid>
              <Grid item xs={6} md={4} lg={4}>
                <ListItem>
                  <ListItemText
                    primary={t("CREATED_AT")}
                    secondary={formatDate(blogDetail?.created_at)}
                  />
                </ListItem>
              </Grid>
              <Grid item xs={6} md={4} lg={4}>
                <ListItem>
                  <ListItemText
                    primary={t("STATUS")}
                    secondary={t(blogDetail?.status?.toUpperCase())}
                  />
                </ListItem>
              </Grid>
              {blogDetail?.view_count && (
                <Grid item xs={6} md={4} lg={4}>
                  <ListItem>
                    <ListItemText
                      primary={t("VIEWS")}
                      secondary={blogDetail?.view_count ? blogDetail.view_count : ""}
                    />
                  </ListItem>
                </Grid>
              )}
              {blogDetail?.modified_at && (
                <Grid item xs={6} md={4} lg={4}>
                  <ListItem>
                    <ListItemText
                      primary={t("MODIFIED_AT")}
                      secondary={`${formatDate(blogDetail?.modified_at)} ${formatHours(
                        blogDetail.modified_at
                      )}`}
                    />
                  </ListItem>
                </Grid>
              )}
              {blogDetail?.slug && (
                <Grid item xs={6} md={4} lg={4}>
                  <ListItem>
                    <ListItemText primary={t("SLUG")} secondary={blogDetail?.slug} />
                  </ListItem>
                </Grid>
              )}
              {blogDetail?.language && (
                <Grid item xs={6} md={4} lg={4}>
                  <ListItem>
                    <ListItemText primary={t("LANGUAGE")} secondary={blogDetail?.language} />
                  </ListItem>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* BLOG CONTENT */}
        <Grid
          item
          xs={12}
          mt={2}
          mb={1}
          padding={{ xs: 1, sm: 2 }}
          component={Paper}
          sx={{ borderRadius: "20px" }}
        >
          <Typography
            variant="h6"
            sx={{
              borderBottom: "2px solid var(--secondary-color)",
              width: "fit-content",
              marginBottom: "10px",
            }}
          >
            {t("CONTENT")}:
          </Typography>
          <Grid item xs={12}>
            <Typography variant="body1">{blogDetail?.translated_description}</Typography>
          </Grid>
        </Grid>

        {/* BLOG METADATA */}
        <Grid
          item
          xs={12}
          mb={25}
          padding={{ xs: 1, sm: 2 }}
          component={Paper}
          sx={{ borderRadius: "20px" }}
        >
          <Typography
            variant="h6"
            sx={{
              borderBottom: "2px solid var(--secondary-color)",
              width: "fit-content",
              marginBottom: "10px",
            }}
          >
            {t("METADATA")}:
          </Typography>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {blogDetail?.meta_description && (
                <Grid item xs={12}>
                  <ListItem>
                    <ListItemText
                      primary={t("META_DESCRIPTION")}
                      secondary={blogDetail?.meta_description}
                    />
                  </ListItem>
                </Grid>
              )}
              {blogDetail?.keywords && (
                <Grid item xs={12}>
                  <ListItem>
                    <ListItemText primary={t("KEYWORDS")} secondary={blogDetail?.keywords} />
                  </ListItem>
                </Grid>
              )}
              {blogDetail?.is_sponsored && (
                <Grid item xs={12}>
                  <ListItem>
                    <ListItemText
                      primary={t("SPONSORED")}
                      secondary={blogDetail?.is_sponsored ? t("YES") : t("NO")}
                    />
                  </ListItem>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BlogDetail;
