import { WarningAmberOutlined } from "@mui/icons-material";
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../constants/utils";

export const OrderUserDetail = ({ order }) => {
  const { t } = useTranslation();

  const { tickets = [] } = order || {};

  return (
    <Card sx={{ borderRadius: 3, width: "100%" }}>
      <CardHeader
        title={t("TRAVELER_DATA")}
        sx={{
          backgroundColor: "var(--grey-background)",
        }}
      />
      <CardContent>
        <Grid container spacing={2}>
          {/* Traveler Info */}
          {tickets.map((ticket, index) => {
            if (!ticket.client_name && !ticket.client_email) {
              return (
                <>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight="bold" mb={2}>
                      {t("TRAVELER")} {index + 1}
                    </Typography>
                  </Grid>
                  <Grid item container>
                    <WarningAmberOutlined color="error" />
                    <Typography color="error">{t("MISSING_DATA")}</Typography>
                  </Grid>
                </>
              );
            }
            return (
              <Grid container item xs={12} key={index}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontWeight="bold" mb={2}>
                    {t("TRAVELER")} {index + 1}
                  </Typography>
                </Grid>
                {/* Column 1 */}
                <Grid item>
                  <Typography variant="body2" fontWeight="600">
                    {t("NAME_SURNAME")}
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    {ticket.client_name}, {ticket.client_surname}
                  </Typography>
                  <Typography variant="body2" fontWeight="600">
                    {t("EMAIL")}
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    {ticket.client_email}
                  </Typography>
                  <Typography variant="body2" fontWeight="600">
                    {t("PHONE")}
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    {ticket.client_phone_code} {ticket.client_phone}
                  </Typography>
                  <Typography variant="body2" fontWeight="600">
                    {t("NATIONALITY")}
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    {ticket.client_nationality}
                  </Typography>
                  <Typography variant="body2" fontWeight="600">
                    {t("ADDRESS")}
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    {ticket.client_address}
                  </Typography>
                </Grid>
                {/* Colum 2 */}
                <Grid item>
                  <Typography variant="body2" fontWeight="600">
                    {t("BORN_AT")}
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    {formatDate(ticket.client_birth_date)}
                  </Typography>
                  <Typography variant="body2" fontWeight="600">
                    {t("DOCUMENT")}
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    {ticket.client_document_type}
                  </Typography>
                  <Typography variant="body2" fontWeight="600">
                    {t("DOCUMENT_NUMBER")}
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    {ticket.client_document_number}
                  </Typography>
                  <Typography variant="body2" fontWeight="600">
                    {t("EXPIRATION_DATE")}
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    {formatDate(ticket.client_expiration_date)}
                  </Typography>
                  <Typography variant="body2" fontWeight="600">
                    {t("EXPEDITION_DATE")}
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    {formatDate(ticket.expedition_date)}
                  </Typography>
                </Grid>
                {index !== tickets.length - 1 && (
                  <Grid item>
                    <Divider orientation="vertical" variant="middle" flexItem />
                  </Grid>
                )}
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};
