import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

function CustomDialog({ isOpen, onClose, title, content, onAccept }) {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", textAlign: "center" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "black", textAlign: "center" }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onAccept}
        >
          {t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;
