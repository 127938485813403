import { Add } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import useDebounce from "../../../hooks/useDebouncing";
import SearchFilter from "../../events/filters/filter/SearchFilter";

export const B2bSalesFilters = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCreateTripAgent = () => {
    navigate(ROUTES.CREATE_TRIP_AGENT);
  };

  const handleSearchChange = (e) => {
    setFilters({
      ...filters,
      search: e.target.value,
    });
  };
  const debounceSearch = useDebounce(handleSearchChange, 300);

  return (
    <>
      <Grid sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Grid item xs={12} md={6}>
          <SearchFilter onSearchChange={debounceSearch} />
        </Grid>
        <Button
          className="oniria-border-btn"
          size="small"
          sx={{ textTransform: "capitalize" }}
          startIcon={<Add />}
          onClick={handleCreateTripAgent}
        >
          {t("CREATE_TRIPS_AGENTS")}
        </Button>
      </Grid>
    </>
  );
};
