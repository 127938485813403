export const RQ_KEY = {
  ACTIVE_CARDS: "activeCards",
  ALL_CARDS: "allCards",
  ALL_EVENTS_FILTERED: "allEventsFiltered",
  ALL_EVENTS: "allEvents",
  ALL_TRIPS_FILTERED: "allTripsFiltered",
  ALL_GROUPING: "allGrouping",
  ALL_ORGANIZATIONS: "allOrganizations",
  ALL_CITIES: "allCities",
  ASSISTANTS_EVENT_ID: "assistantsEventId",
  ALL_RRPPS: "allRrpps",
  CARDS_DOMAIN: "cardsDomain",
  CARDS_STATISTICS: "cardsStatistics",
  CATEGORIES_DOMAIN: "categoriesDomain",
  CATEGORIES_DOMAINS_BY_PREMISE: "categoriesDomainsByPremise",
  DOMAINS: "domains",
  EVENTS_DOMAIN: "eventsDomain",
  ORDERS_EVENT_ID: "ordersEventId",
  ORDERS_LIST: "ordersList",
  RRPP_BY_ID: "rrppById",
  RRPP_BY_EVENT_ID: "rrppByEventId",
  SPONSORS_DOMAIN: "sponsorsDomain",
  USER_CARDS: "userCards",
  USER_INFO: "userInfo",
  USERS_FOR_EVENT: "usersForEvent",
  USERS_LIST: "usersList",
  HOME: "home",
  EVENT_STATISTICS: "eventStatistics",
  ASSISTANT_DETAIL: "assistantDetail",
  PREMISE_ID: "premiseId",
  CARDS_PREMISE: "cardsPremise",
  MONTH_STATISTICS_GENERAL: "monthStatisticsGeneral",
  MONTH_STATISTICS_SALES: "monthStatisticsSales",
  MONTH_STATISTICS_USERS: "monthStatisticsUsers",
  HOME_NEXT_EVENTS: "homeNextEvents",
  HOME_STATISTICS: "homeStatistics",
  ALL_BLOGS: "allBlogs",
  BLOG_ID: "blogId",
  BLOGS_CATEGORIES: "blogsCategories",
  BLOG_DETAIL: "blogDetail",
  EVENT_SALES: "eventSales",
  EXTRAS_TEMPLATES: "extrasTemplates",
  EXTRA_DETAIL: "extraDetail",
  BLOGS_TAGS: "blogsTags",
  TAG_DETAIL: "tagDetail",
  TAGS_LIST: "tagsList",
  TPV_LIST: "tpvList",
  MONTH_EVENT_STATISTICS: "monthEventStatistics",
  TOUR_POINTS: "tourPoints",
  COUNTRIES: "countries",
  REGIONS: "regions",
  CITIES: "cities",
  TOUR_POINTS_DETAILS: "tourPointsDetails",
  TRANSPORTS: "transports",
  TRANSPORTS_DETAILS: "transportsDetails",
  HOTELS: "hotels",
  HOTELS_DETAILS: "hotelsDetails",
  HOTELS_TEMPLATES: "hotelsTemplates",
  HOTELS_TEMPLATES_DETAILS: "hotelsTemplatesDetails",
  TOUR_POINTS_LIST: "tourPointsList",
  HOTELS_ASSIGNED: "hotelsAssigned",
  TOUR_LEADERS: "tourLeaders",
  TOUR_LEADERS_DETAILS: "tourLeadersDetails",
  EVENT_DETAILS: "eventDetails",
  EVENT_EXTRAS: "eventExtras",
  EVENT_TICKETS: "eventTickets",
  CHILD_EVENTS: "childEvents",
  TRANSPORT_ON_TRIPS: "transportOnTrips",
  B2B_SALES: "b2bSales",
  TRIPS_B2B: "tripsB2b",
  MAILINGS: "mailings",
  MAILING_PLAN_DETAILS: "mailingPlanDetails",
  PREMISE_LIST: "premiseList",
  B2B_SALES_AGENT: "b2bSalesAgent",
  EVENT_LIST: "eventList",
  EVENT_DETAILS_NO_TOKEN: "eventDetailsNoToken",
  DOMAIN_ID: "domainId",
  TICKETS_EVENT_ID: "ticketsEventId",
  EXTRAS_EVENT_ID: "extrasEventId",
  EVENTS_REQUESTS: "eventsRequests",
  TICKET_AND_EXTRAS: "ticketAndExtras",
};
