import HomeIcon from "../components/icons/HomeIcon";
import MapIcon from "../components/icons/MapIcon";
import FavoriteIcon from "../components/icons/FavoriteIcon";
import AffiliatedCarIcon from "../components/icons/AffiliatedCarIcon";
import TicketsIcon from "../components/icons/TicketsIcon";
import ProfileIcon from "../components/icons/ProfileIcon";

export const DEFAULT_STYLES = {
  header_type: "basic",
  logo_position: "center",
  header_color: "#07B1BC",
  header_icons: "profile",
  header_icons_color: "#ffffff",
  principal_color: "#07B1BC",
  text_color: "#000000",
  border_header: "",
  search_bar: 0,
  footer_type: "basic",
  footer_color: "#07B1BC",
  footer_icons: "tickets,profile",
  footer_icons_color: "#ffffff",
  footer_text_color: "#ffffff",
  footer_web_color: "#000000",
  sponsors_background_color: "#ffffff",
  font_text: "Poppins",
  lopd: "oniria",
  web_name: "web",
  app_name: "app",
  default_language: "ES",
  logo_url: "",
  dark_logo_url: "",
  icon_url: "",
  footer_logo: "",
  card_premise_id: "",
  category_styles: [],
  social_instagram_url: "",
  social_facebook_url: "",
  social_whatsapp_url: "",
  social_twitter_url: "",
  social_youtube_url: "",
  social_tiktok_url: "",
  social_spotify_url: "",
  social_itune_url: "",
  social_soundcloud_url: "",
  sweeps: [],
};

export const HEADER_FORMATS = {
  BASIC: "basic",
  MIDDLE_CIRCLE: "middleCircle",
  ROUNDED: "rounded",
};

export const HEADER_LINKS = {
  PROFILE: "profile",
  TICKETS: "tickets",
  MAPS: "maps",
  FAVORITES: "favorites",
  AFFILIATED_CARD: "affiliateCard",
};

export const HEADER_OPTIONS = [
  "profile",
  "tickets",
  "maps",
  "favorites",
  "affiliateCard",
  "profileWeb",
];

export const FOOTER_OPTIONS = ["home", "profile", "tickets", "maps", "favorites", "affiliateCard"];

export const EVENT_CARD_FORMATS = {
  SQUARE: "square",
  FULL_WIDTH: "fullWidth",
  FORMAT_59: "format59",
  PORTRAIT: "portrait",
};

export const LOGO_POSITIONS = {
  LEFT: "left",
  CENTER: "center",
};

export const FOOTER_FORMATS = {
  BASIC: "basic",
  MIDDLE_CIRCLE: "middleCircle",
  ROUNDED: "rounded",
};

export const LOPD_TYPES = {
  ONIRIA: "oniria",
  CUSTOM: "custom",
};

export const CATEGORIES_STYLES = {
  SQUARE: "square",
  FULL_WIDTH: "fullWidth",
  FORMAT_59: "format59",
  PORTRAIT: "portrait",
  SQUARED_BORDER: "squaredBorder",
  HORIZONTAL_INFO: "horizontalCard",
};

export const EVENT_CARD_CONTENT = [
  "title",
  "date",
  "price",
  "duration",
  "short_description",
  "rating",
  "days_time",
  "cities",
  "countries",
  "button",
];

export const ICONS_MAP = {
  home: HomeIcon,
  maps: MapIcon,
  favorites: FavoriteIcon,
  affiliateCard: AffiliatedCarIcon,
  tickets: TicketsIcon,
  profile: ProfileIcon,
  profileWeb: ProfileIcon,
};

export const FONT_TEXT_OPTIONS = [
  "Poppins",
  "Nunito",
  "Titillium",
  "Playfair",
  "Montserrat",
  "Oswald",
  "Lato",
  "Merriweather",
];

export const PAGINATION_STYLES = {
  display: "flex",
  justifyContent: "center",
  fontWeight: "bolder",
  "& .MuiPaginationItem-root": {
    "&.Mui-selected": {
      background: "#3D3B38",
      color: "#fff",
      fontWeight: "bolder",
    },
    fontWeight: "600",
  },
};

export const MUI_SELECT_PRIM_COLOR = {
  "&.Mui-selected": {
    color: "var(--secondary-color)",
  },
};

export const PROGRESS_BAR_STYLES = {
  width: "100%",
  height: 6,
  backgroundColor: "var(--oniria-sky)",
  borderRadius: 2,
  overflow: "hidden",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "var(--secondary-color)",
    borderRadius: 2,
    transition: "width 0.5s ease-in-out",
  },
};

export const DIVIDER_STYLE = {
  width: "100%",
  borderColor: "var(--secondary-color)",
  mt: 2,
  mb: 2,
};

export const GREY_BUTTON_STYLE = {
  color: "var(--secondary-color)",
  borderColor: "var(--secondary-color)",
  "&:hover": {
    color: "var(--secondary-color)",
    borderColor: "var(--secondary-color)",
    backgroundColor: "white",
  },
};

export const STEEP_STYLE = {
  "& .MuiStepLabel-root .Mui-active": {
    color: "var(--secondary-color)",
  },
  "& .MuiStepLabel-root .Mui-disabled": { display: "none" },
  "& .MuiStepLabel-root .Mui-completed": { display: "none" },
};

export const VERTICAL_STEEP_STYLE = {
  "& .MuiStepLabel-root .Mui-active": {
    color: "var(--secondary-color)",
  },
  "& .MuiStepLabel-root .Mui-completed": {
    color: "var(--secondary-color)",
  },
};

export const MUI_CUSTOM_SLIDER = {
  mb: 2,
  color: "var(--oniria-gold)",
  "& .MuiSlider-markLabel": {
    top: -20,
  },
  "& .MuiSlider-thumb:hover": {
    boxShadow: "0px 0px 0px 8px rgba(255, 215, 0, 0.16)",
  },
};

export const SMALL_TAB = {
  ...MUI_SELECT_PRIM_COLOR,
  fontSize: "0.75rem",
  minHeight: "32px",
  padding: "2px 4px",
};
