import { ArrowBack } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RQ_KEY } from "../../../constants/query";
import { ROUTES } from "../../../constants/routes";
import { formatCurrency, getHotelName, sanitizeBoolean } from "../../../constants/utils";
import { B2B_APP_URL, LOGOS } from "../../../constants/variables";
import useQueryEventDetails from "../../../hooks/queries/events/useQueryEventDetails";
import useQueryHotelsAssigned from "../../../hooks/queries/hotels/useQueryHotelsAssigned";
import { useQueryAssignDomains } from "../../../hooks/queries/useQueryAssignDomains";
import { contactForTrip } from "../../../services/b2bServices";
import { findDomainById } from "../../../services/domainsServices";
import { getExtrasByEventId } from "../../../services/eventsServices";
import { ASSIGNED_TAB } from "../../../utils/hotels";
import InfoToolTip from "../../shared/InfoToolTip";
import { Loading } from "../../shared/Loading";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";
import TextEditor from "../../textEditor/TextEditor";
import { B2BAddToMyCatalog } from "./B2BAddToMyCatalog";
import { set } from "react-hook-form";

export const B2BCatalogTrip = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { id: eventId } = useParams();
  const agreements = searchParams.get("agreements") || 0;

  const { data: domains = [] } = useQueryAssignDomains();
  const agentDomainId = domains[0]?.id || null;

  const [openAddToCatalog, setOpenAddToCatalog] = useState(false);
  const [hasContacted, setHasContacted] = useState(false);
  const [loadingContact, setLoadingContact] = useState(false);

  const { data: eventDetails, isLoading } = useQueryEventDetails(eventId);
  const { data: extras = [] } = useQuery(["extras", eventId], () => getExtrasByEventId(eventId), {
    refetchOnWindowFocus: false,
    enabled: !!eventId,
  });
  const { data: hotels = [] } = useQueryHotelsAssigned("", "", {}, ASSIGNED_TAB, () => {});

  const { name, pvp, days = 0, itineraries = [], principal_url } = eventDetails || {};

  const tripHasHotels = itineraries.some((itinerary) => itinerary.hotel_ids.length > 0);

  const handleShowClient = () => {
    window.open(`${B2B_APP_URL}events/${eventId}`, "_blank", "noopener,noreferrer");
  };

  const handleContact = async () => {
    try {
      setLoadingContact(true);
      await contactForTrip(eventDetails?.premise_id || "", eventId);
      toastMessageSuccess(t("CONTACT_SENT_SUCCESS"));
      setHasContacted(true);
    } catch (error) {
      const errorMessage = error?.response?.data?.error || t("ERROR");
      toastMessageError(errorMessage);
    } finally {
      setLoadingContact(false);
    }
  };

  const { data: domainInfo = {} } = useQuery(
    [RQ_KEY.DOMAIN_ID, agentDomainId],
    () => findDomainById(agentDomainId),
    {
      refetchOnWindowFocus: false,
      enabled: !!agentDomainId,
    }
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--secondary-color)",
          height: "60px",
          display: "flex",
          alignItems: "center",
          paddingLeft: 2,
        }}
      >
        <InfoToolTip text={t("GO_HOME")}>
          <Box
            sx={{
              color: "white",
              maxHeight: "40px",
              cursor: "pointer",
            }}
            component="img"
            alt="Logo"
            src={domainInfo?.style?.icon_url || LOGOS.PRINCIPAL_WHITE}
            onClick={() => navigate(ROUTES.HOME)}
          />
        </InfoToolTip>
      </Box>
      {isLoading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            backgroundColor: "white",
            minHeight: "100dvh",
            color: "var(--secondary-color)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              backgroundImage: `url(${principal_url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100%",
              width: "100%",
              color: "var(--white)",
              padding: 6,
            }}
          >
            <Typography variant="h2" sx={{ fontWeight: "500" }}>
              {name}
            </Typography>
            <Typography variant="h6">Pvp {formatCurrency(pvp)} </Typography>
          </Box>
          <Box padding={2}>
            <InfoToolTip text={t("GO_BACK")}>
              <IconButton sx={{ mt: 2 }} onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
            </InfoToolTip>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                height: "fit-content",
                mt: 3,
              }}
            >
              <Button className="oniria-border-btn" size="small" onClick={handleShowClient}>
                {t("SHOW_TRIP_TO_CLIENT")}
              </Button>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mt: 4 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {t("TRIP_RESUME")}
              </Typography>
              <TextEditor
                data={eventDetails?.description}
                id={`trip_resume_description`}
                setData={() => {}}
                disabled
              />

              {/* Duration */}
              {days > 0 && (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "text.primary", flexBasis: "30%" }}
                  >
                    {t("DAYS_QUANTITY")}:
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary", flexGrow: 1 }}>
                    {days} {t("DAYS")}
                  </Typography>
                </Box>
              )}

              {/* Tour points*/}
              {itineraries.length > 0 && (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "text.primary", flexBasis: "30%" }}
                  >
                    {t("TOUR_POINTS")}:
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary", flexGrow: 1 }}>
                    {itineraries.map((itinerary) => itinerary.title).join(", ")}
                  </Typography>
                </Box>
              )}

              {/* Hotels */}
              {tripHasHotels && (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "text.primary", flexBasis: "30%" }}
                  >
                    {t("HOTELS")}:
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary", flexGrow: 1 }}>
                    {itineraries
                      ?.map((itinerary) => itinerary.hotel_ids)
                      .flat()
                      .map((hotelId) => getHotelName(hotelId, hotels))
                      .join(", ")}
                  </Typography>
                </Box>
              )}

              {/* Extras */}
              {extras.length > 0 ? (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "text.primary", flexBasis: "30%" }}
                  >
                    {t("EXTRAS")}:
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary", flexGrow: 1 }}>
                    {extras
                      .filter((extra) => !extra.tags)
                      .map((extra) => extra.name)
                      .join(", ")}
                  </Typography>
                </Box>
              ) : null}
            </Box>

            {sanitizeBoolean(agreements) ? null : !hasContacted ? (
              <Button
                className="oniria-btn"
                sx={{ color: "var(--white)", mt: 3 }}
                onClick={handleContact}
                disabled={loadingContact}
              >
                {loadingContact ? t("SAVING") : t("CONTACT_VERB")}
              </Button>
            ) : null}

            <B2BAddToMyCatalog
              isOpen={openAddToCatalog}
              onClose={() => setOpenAddToCatalog(false)}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
