import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AgentsAgreements } from "./agreements/AgentsAgreements";
import { EspecialCommission } from "./agreements/EspecialCommission";
import { PendingAgreements } from "./agreements/PendingAgreements";
import { ROLES } from "../../constants/variables";
import { useState } from "react";

export const B2BConfiguration = ({ userType }) => {
  const { t } = useTranslation();
  const [doRefetch, setDoRefetch] = useState(false);

  return (
    <>
      <Box>
        {userType === ROLES.B2B_OPERATOR && <PendingAgreements setDoRefetch={setDoRefetch} />}
        <Typography variant="h6" mb={3}>
          {t("AGREEMENTS_ESTABLISHED")}
        </Typography>
        <AgentsAgreements userType={userType} />
        <EspecialCommission userType={userType} doRefetch={doRefetch} setDoRefetch={setDoRefetch} />
      </Box>
    </>
  );
};
