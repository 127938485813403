import { SERVICE } from "../constants/api";
import { http } from "../constants/axios";

export async function getExtras(page, perPage, filters) {
  const params = new URLSearchParams();
  if (page) params.append("page", page);
  if (perPage) params.append("perpage", perPage);
  if (filters.search) params.append("search", filters.search);
  if (filters.premiseId) params.append("premise_id", filters.premiseId);
  if (filters.asc && filters.asc.length > 0) {
    params.append("asc", filters.asc.join(","));
  }
  if (filters.desc && filters.desc.length > 0) {
    params.append("desc", filters.desc.join(","));
  }
  if (filters.domains_ids && filters.domains_ids.length > 0) {
    params.append("domain_ids", filters.domains_ids.join(";"));
  }
  if (filters.tags && filters.tags !== "all") {
    params.append("tag", filters.tags);
  }

  const { data } = await http.get(`${SERVICE.EXTRAS_TEMPLATES}?${params.toString()}`);
  return data;
}
export async function getExtraById(id) {
  const { data } = await http.get(`${SERVICE.EXTRAS_TEMPLATES}/${id}`);
  return data;
}
export async function createExtra(body) {
  const { data } = await http.post(`${SERVICE.EXTRAS_TEMPLATES}`, body);
  return data;
}
export async function updateExtra(id, body) {
  const { data } = await http.put(`${SERVICE.EXTRAS_TEMPLATES}/${id}`, body);
  return data;
}
export async function deleteExtraTemplate(id) {
  const { data } = await http.delete(`${SERVICE.EXTRAS_TEMPLATES}/${id}`);
  return data;
}

export async function getExtraStatistics(id, page, perPage, filters) {
  const params = new URLSearchParams();

  if (page) params.append("page", page);
  if (perPage) params.append("perpage", perPage);
  if (filters.date) params.append("date", filters.date);
  if (filters.domainId) params.append("domain_id", filters.domainId);
  if (filters.search) params.append("search", filters.search);
  if (filters.asc && filters.asc.length > 0) {
    params.append("asc", filters.asc.join(","));
  }
  if (filters.desc && filters.desc.length > 0) {
    params.append("desc", filters.desc.join(","));
  }

  const { data } = await http.get(`${SERVICE.EXTRAS_TEMPLATES}/statistics/${id}?${params}`);
  return data;
}

export async function assignDomainsToExtra(id, domains) {
  const body = { domains_ids: domains };
  const { data } = await http.put(`${SERVICE.EXTRAS_TEMPLATES}/domains/${id}`, body);
  return data;
}
