import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { handleSelectImageReactHook } from "../../shared/FormsValidator";
import ImageUpload from "../../shared/ImageUpload";
import { StyledAccordion } from "../../shared/StyledAccordion";
import { FormFieldHeader } from "../FormFieldHeader";

const formatImage = (imageData) => {
  if (!imageData) return "/media/landscape.svg";
  if (typeof imageData === "string") {
    if (imageData.startsWith("data:image/")) return imageData;
    return imageData;
  }

  if (typeof imageData === "object" && imageData.image && imageData.mime) {
    let base64Data = imageData.image.replace(/^base64,/, "");
    return `data:${imageData.mime};base64,${base64Data}`;
  }

  return "/media/landscape.svg";
};

const TripImages = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const title = watch("title", t("TITLE"));
  const shortDescription = watch("short_description", t("SHORT_DESCRIPTION_TEXT"));
  const imageBase64 = watch("principal_url");
  const image = formatImage(imageBase64);

  const images = watch("slider_urls");
  const imgToShow = images?.length > 0 ? formatImage(images[0]) : formatImage(images);

  return (
    <Grid item xs={12} container>
      <Grid item xs={10}>
        <FormFieldHeader title={t("TRIP_IMAGES")} description={t("TRIP_IMAGES_DESCRIPTION")} />
      </Grid>
      <Grid item xs={12}>
        {/* PRINCIPAL IMAGE */}
        <Grid item xs={12} container>
          <Grid item xs={12} sm={4}>
            <ImageUpload
              onImageSelect={(e) => handleSelectImageReactHook(e, "principal_url", setValue)}
              url={watch("principal_url")}
            />
          </Grid>
          <Grid item xs={12} sm={8} container spacing={2} sx={{ padding: 2 }}>
            <Grid item xs={6} sm={3}>
              <Card sx={{ borderRadius: 3, height: "100%", maxHeight: "400px" }}>
                <CardMedia component="img" image={image} alt="Image" sx={{ aspectRatio: "1/1" }} />
                <CardContent>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {shortDescription}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Card sx={{ borderRadius: 3, height: "100%", maxHeight: "400px" }}>
                <CardMedia
                  component="img"
                  image={image}
                  alt="Image"
                  sx={{ height: "100%", aspectRatio: "16/9" }}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card sx={{ borderRadius: 3, height: "100%", maxHeight: "400px" }}>
                <CardMedia
                  component="img"
                  image={image}
                  alt="Image"
                  sx={{
                    objectFit: !imageBase64 ? "cover" : "fill",
                    height: "80%",
                    aspectRatio: "16/9",
                  }}
                />
                <CardContent>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {shortDescription}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/* SLIDER IMAGES */}
        <StyledAccordion title={t("ADVANCED_IMAGES_OPTIONS")}>
          <FormFieldHeader
            title={t("TRIP_IMAGES_HEADER")}
            titleStyle={{ fontWeight: "500" }}
            description={t("TRIP_IMAGES_INFO")}
          />
          <Grid container>
            <Grid item xs={12} sm={6}>
              <ImageUpload
                onImageSelect={(e) => handleSelectImageReactHook(e, "slider_urls", setValue)}
                multi
                selectedImagesInForm={watch("slider_urls")}
                size="21:9"
              />
            </Grid>
            <Grid item xs={12} sm={6} padding={2}>
              <Card sx={{ borderRadius: 3, maxHeight: "400px", height: "100%" }}>
                <CardMedia
                  component="img"
                  image={imgToShow}
                  alt="Image"
                  sx={{ height: "80%", objectFit: !images ? "cover" : "fill" }}
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {shortDescription}
                  </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {t("DESCRIPTION")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </StyledAccordion>
      </Grid>
    </Grid>
  );
};

export default TripImages;
