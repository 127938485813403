import { RemoveRedEyeOutlined } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatCurrency, formatDate } from "../../../constants/utils";
import { B2B_STATUSES } from "../../../constants/variables";
import CustomDialog from "../../shared/CustomDialog";
import { useState } from "react";
import { toastMessageSuccess } from "../../shared/toastMessage";
import { settleB2bPayment } from "../../../services/b2bServices";

export const B2BSalesTable = ({ sales = [], setShowDetailOrder, refetchSales }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openPayCommissionDialog, setOpenPayCommissionDialog] = useState({
    open: false,
    event_id: null,
    ticket_id: null,
    premise_agent_id: null,
    payment_type: null,
    net_product: null,
    margin: null,
  });

  const handleSee = (e, id) => {
    e?.stopPropagation();
    navigate(`/event/${id}?tab=5`);
  };

  const handleClosePayCommissionDialog = () => {
    setOpenPayCommissionDialog({
      open: false,
      event_id: null,
      ticket_id: null,
      premise_agent_id: null,
      payment_type: null,
      net_product: null,
      margin: null,
    });
  };

  const handlePayCommission = async () => {
    try {
      const { event_id, premise_agent_id, payment_type, net_product, margin, ticket_id } =
        openPayCommissionDialog;
      const body = {
        event_id,
        ticket_id,
        premise_agent_id,
        payment_type,
        net_product,
        margin,
      };
      await settleB2bPayment(body);
      toastMessageSuccess(t("SETTLE_PAYMENT_SUCCESS"));
      handleClosePayCommissionDialog();
      refetchSales();
    } catch (error) {
      const message = error.response?.data?.error || t("SETTLE_PAYMENT_ERROR");
      toastMessageSuccess(message);
    }
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
        <Table sx={{ whiteSpace: "nowrap" }}>
          <TableHead>
            <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
              <TableCell align="center">{t("SALE_AGENT")}</TableCell>
              <TableCell align="center">{t("PRODUCT")}</TableCell>
              <TableCell align="center">{t("RESERVE_DATE")}</TableCell>
              <TableCell align="center">{t("NET_PRICE")}</TableCell>
              <TableCell align="center">{t("COMMISSION_WORD")}</TableCell>
              <TableCell align="center">{t("PVP")}</TableCell>
              <TableCell align="center">{t("MARGIN")}</TableCell>
              <TableCell align="center">{t("STATUS")}</TableCell>
              <TableCell align="center">{t("SEE_DETAILS")}</TableCell>
              <TableCell align="center">{t("SETTLE")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sales?.map((row, index) => (
              <TableRow key={`${row.order_id}-${index}`}>
                <TableCell align="center">{row.premise_name}</TableCell>
                <TableCell align="center">
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: "bolder",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleSee(e, row.id)}
                  >
                    {row.event_name}
                  </Typography>
                </TableCell>
                <TableCell align="center">{formatDate(row.created_at)}</TableCell>
                <TableCell align="center">{formatCurrency(row.amount)}</TableCell>
                <TableCell align="center">{formatCurrency(row.commission)}</TableCell>
                <TableCell align="center">{formatCurrency(row.net_price)}</TableCell>
                <TableCell align="center">
                  {row.commission_percentage && row.commission_percentage !== "0"
                    ? `${row.commission_percentage}%`
                    : row.commission_fixed
                    ? formatCurrency(row.commission_fixed)
                    : null}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color:
                      row.status === B2B_STATUSES.SETTLED
                        ? "var(--green-success)"
                        : "var(--oniria-red)",
                    fontWeight: "bold",
                  }}
                >
                  {t(row.status?.toUpperCase())}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => setShowDetailOrder({ order_id: row.order_id, open: true })}
                  >
                    <RemoveRedEyeOutlined sx={{ color: "var(--oniria-gold)" }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  {row.status === B2B_STATUSES.PENDING ? (
                    <Button
                      className="underline-button"
                      onClick={() =>
                        setOpenPayCommissionDialog({
                          open: true,
                          event_id: row.event_id,
                          premise_agent_id: row.premise_id,
                          payment_type: row.payment_type,
                          net_product: row.amount,
                          margin: row.commission_fixed || row.commission_percentage || 0,
                          ticket_id: row.ticket_id,
                        })
                      }
                    >
                      {t("SETTLE")}
                    </Button>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomDialog
        title={t("PAY_COMMISSION")}
        content={t("PAY_COMMISSION_DESCRIPTION")}
        onClose={handleClosePayCommissionDialog}
        onAccept={handlePayCommission}
        isOpen={openPayCommissionDialog.open}
      />
    </>
  );
};
