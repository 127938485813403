import { createSlice } from "@reduxjs/toolkit";
import { setHttpBearerToken } from "../../constants/axios";

let tokenStoredValue = "";
let userInfoStoredValueJSON = "";
let userDataStoredValueJSON = "";

let userInfoStoredValue = null;
let userDataStoredValue = {};

if (localStorage.getItem("token")) {
  tokenStoredValue = localStorage.getItem("token");
  userInfoStoredValueJSON = localStorage.getItem("userInfo");
  userDataStoredValueJSON = localStorage.getItem("userData");

  userInfoStoredValue = JSON.parse(userInfoStoredValueJSON);
  userDataStoredValue = JSON.parse(userDataStoredValueJSON);
} else if (sessionStorage.getItem("token")) {
  tokenStoredValue = sessionStorage.getItem("token");
  userInfoStoredValueJSON = sessionStorage.getItem("userInfo");
  userDataStoredValueJSON = sessionStorage.getItem("userData");
  userInfoStoredValue = JSON.parse(userInfoStoredValueJSON);
  userDataStoredValue = JSON.parse(userDataStoredValueJSON);
} else {
  console.log("No storage");
}

setHttpBearerToken(tokenStoredValue);

const initialState = {
  token: tokenStoredValue,
  id: userInfoStoredValue ? userInfoStoredValue.id : "",
  organization_id: userInfoStoredValue ? userInfoStoredValue.organization_id : "",
  email: userInfoStoredValue ? userInfoStoredValue.email : "",
  roles: userInfoStoredValue ? userInfoStoredValue.roles : [],
  userData: userDataStoredValue,
  trips: userInfoStoredValue ? userInfoStoredValue.trips : "",
  grouping_id: userDataStoredValue ? userDataStoredValue.grouping_id : "",
  premises_id: userInfoStoredValue ? userInfoStoredValue.premises_id : "",
};

// const initialState = {
//     token: tokenStoredValue ? tokenStoredValue : "",
//     id: userInfoStoredValue ? userInfoStoredValue.id : "",
//     email: userInfoStoredValue ? userInfoStoredValue.email : "",
//     roles: userInfoStoredValue ? userInfoStoredValue.roles : [],
//     userData: userDataStoredValue ? userDataStoredValue : {}
// }

const voidState = {
  token: "",
  id: "",
  email: "",
  roles: [],
  userData: {},
  trips: "",
  premises_id: [],
};

export const userSlice = createSlice({
  name: "userInfo",
  initialState: initialState,
  reducers: {
    userToken: (state, { payload }) => {
      state.token = payload.token;
    },
    userInfo: (state, { payload }) => {
      state.id = payload.id;
      state.email = payload.email;
      state.roles = payload.roles;
      state.trips = payload.trips;
      state.organization_id = payload.organization_id;
      state.grouping_id = payload.grouping_id;
      state.premises_id = payload.premises_id;
    },
    userData: (state, { payload }) => {
      state.userData = payload.userData;
    },
    logout: () => voidState,
  },
});
export const { userToken, userInfo, userData, logout } = userSlice.actions;

export default userSlice.reducer;
