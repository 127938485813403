import { TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { focusColor } from "../shared/textFieldStyle";
import { putUser } from "../../services/usersServices";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import { dateWithoutTZ } from "../../constants/utils";

export const EditMailDialog = ({ isOpen, onClose, userData, refetchUserData }) => {
  const { t } = useTranslation();
  const [mail, setMail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmitMail = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const newUser = { ...userData };
      newUser.born_at = dateWithoutTZ(newUser?.born_at);
      newUser.doc_expedition_date = dateWithoutTZ(newUser?.doc_expedition_date);
      newUser.doc_expiration_date = dateWithoutTZ(newUser?.doc_expiration_date);
      newUser.welcome_pack_date = dateWithoutTZ(newUser?.welcome_pack_date);

      newUser.email = mail;

      delete newUser.created_at;
      delete newUser.last_modified_at;

      await putUser(userData.id, newUser);
      toastMessageSuccess(t("EDIT_SUCCESS"));
      refetchUserData();
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      console.log(error);
      toastMessageError(error?.response?.data?.error || t("ERROR"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.email) {
      setMail(userData.email);
    }
  }, [userData]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontWeight: "bolder" }}>{t("CHANGE_EMAIL")}</DialogTitle>
      <DialogContent>
        <Typography sx={{ color: "black", textAlign: "center" }}>
          {t("CHANGE_EMAIL_DESCRIPTION")}
        </Typography>
        <TextField
          aria-label="Email"
          margin="normal"
          required
          fullWidth
          id="email"
          name="email"
          label={t("EMAIL")}
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          sx={focusColor}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
          disabled={loading}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={handleSubmitMail}
          disabled={loading}
        >
          {loading ? t("SAVING") : t("SAVE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
