import { EditOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, Grid, IconButton, LinearProgress, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PROGRESS_BAR_STYLES } from "../../../constants/styles";
import useQueryTransportDetails from "../../../hooks/queries/transports/useQueryTransportDetails";
import { getTransportIconById } from "../../../constants/utils";

const AssignedTransportCard = ({ transportId, handleClose, isSimple = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: transport } = useQueryTransportDetails(transportId, () => {}, true);

  const handleEdit = (id) => {
    navigate(`/transports/edit_trip_transport/${id}`);
  };

  return isSimple ? (
    <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
      {getTransportIconById(transport?.type, 30)}
      <Typography variant="caption" color="text.secondary" sx={{ marginLeft: 1 }}>
        {t(transport?.type?.toUpperCase())}
      </Typography>
    </Box>
  ) : (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 1,
        paddingTop: 2.5,
        gap: 2,
        position: "relative",
      }}
    >
      <IconButton
        onClick={handleClose}
        size="small"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 1,
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>

      <Grid
        item
        sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginRight: 2 }}
      >
        {getTransportIconById(transport?.type, 30)}
        <Typography variant="caption" color="text.secondary" sx={{ marginLeft: 1 }}>
          {t(transport?.type?.toUpperCase())}
        </Typography>
      </Grid>

      <Grid item sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {transport?.name || ""}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={transport?.occupancy || 0}
          sx={PROGRESS_BAR_STYLES}
        />
      </Grid>

      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={t("EDIT_TRANSPORT")}>
          <IconButton onClick={() => handleEdit(transportId)}>
            <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Card>
  );
};

export default AssignedTransportCard;
