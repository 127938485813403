import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getB2bCatalogs } from "../../../services/b2bServices";

export function useQueryB2bCatalogs(filters, page, perPage) {
  return useQuery(
    [RQ_KEY.B2B_CATALOGS, filters, page, perPage],
    () => getB2bCatalogs(filters, page, perPage),
    {
      refetchOnWindowFocus: false,
    }
  );
}
