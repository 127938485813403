import { Box, Grid, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { RQ_KEY } from "../../../constants/query";
import { ROUTES } from "../../../constants/routes";
import { PAGINATION_STYLES } from "../../../constants/styles";
import { LOGOS } from "../../../constants/variables";
import { useQueryB2bCatalogs } from "../../../hooks/queries/b2b/useQueryB2bCatalogs";
import { useQueryAssignDomains } from "../../../hooks/queries/useQueryAssignDomains";
import usePagination from "../../../hooks/usePagination";
import { findDomainById } from "../../../services/domainsServices";
import InfoToolTip from "../../shared/InfoToolTip";
import { Loading } from "../../shared/Loading";
import SelectPerPage from "../../shared/SelectPerPage";
import { B2BCatalogCard } from "./B2BCatalogCard";
import { B2BCatalogHeadFilters } from "./B2BCatalogHeadFilters";
import { B2BCatalogsFilter } from "./B2BCatalogsFilter";
import useUserRoles from "../../../hooks/useUserRoles";

export const B2BCatalogs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isSuperAdmin } = useUserRoles();
  const { data: domains = [] } = useQueryAssignDomains();
  const agentDomainId = domains[0]?.id || null;

  const { changePage, changePerPage, page, perPage } = usePagination();

  const [filters, setFilters] = useState({
    search: "",
    catalogType: "all",
    fromDate: null,
    toDate: null,
    tourPointId: "all",
    premiseId: id || "all",
    price: [0, 15000],
    days: [0, 28],
    order: { asc: [], desc: ["created_at"] },
  });
  // Collapse filter
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isClientView, setIsClientView] = useState(false);

  const { data, isLoading, isRefetching } = useQueryB2bCatalogs(filters, page, perPage);
  const { data: trips = [], total: totalEvents = 0 } = data || {};

  const handleChangeFilter = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    changePage(null, 1);
  };

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const { data: domainInfo = {} } = useQuery(
    [RQ_KEY.DOMAIN_ID, agentDomainId],
    () => findDomainById(agentDomainId),
    {
      refetchOnWindowFocus: false,
      enabled: !!agentDomainId,
    }
  );

  const numberPages = Math.ceil(parseFloat(totalEvents) / parseFloat(perPage));

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--secondary-color)",
          height: "60px",
          display: "flex",
          alignItems: "center",
          paddingLeft: 2,
        }}
      >
        <InfoToolTip text={t("GO_HOME")}>
          <Box
            sx={{
              color: "white",
              maxHeight: "40px",
              cursor: "pointer",
            }}
            component="img"
            alt="Logo"
            src={
              isSuperAdmin
                ? LOGOS.PRINCIPAL_WHITE
                : domainInfo?.style?.icon_url || LOGOS.PRINCIPAL_WHITE
            }
            onClick={() => navigate(ROUTES.HOME)}
          />
        </InfoToolTip>
      </Box>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "white",
          width: "100%",
          minHeight: "100dvh",
          paddingTop: 2,
        }}
      >
        <Box
          sx={{
            width: {
              xs: !isCollapsed ? "40%" : "10%",
              sm: !isCollapsed ? "30%" : "8%",
              md: !isCollapsed ? "25%" : "6%",
              lg: !isCollapsed ? "20%" : "5%",
            },
            maxWidth: "250px",
          }}
        >
          <B2BCatalogsFilter
            filters={filters}
            handleChangeFilter={handleChangeFilter}
            isCollapsed={isCollapsed}
            toggleCollapse={toggleCollapse}
          />
        </Box>
        <Box
          sx={{
            width: {
              xs: !isCollapsed ? "90%" : "98%",
              sm: !isCollapsed ? "85%" : "95%",
              md: !isCollapsed ? "80%" : "93%",
              lg: !isCollapsed ? "75%" : "95%",
            },
          }}
        >
          <B2BCatalogHeadFilters
            isClientView={isClientView}
            setIsClientView={setIsClientView}
            handleChangeFilter={handleChangeFilter}
            filters={filters}
          />

          <Box sx={{ p: 2 }}>
            {isLoading || isRefetching ? (
              <Loading />
            ) : totalEvents > 0 ? (
              <>
                {numberPages > 1 && (
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <SelectPerPage text={t("PRODUCTS")} change={changePerPage} value={perPage} />
                    </Grid>
                    <Pagination
                      count={numberPages}
                      mr={3}
                      sx={PAGINATION_STYLES}
                      page={page}
                      onChange={changePage}
                    />
                  </Grid>
                )}
                <Grid container spacing={1}>
                  {trips.map((trip, index) => {
                    return (
                      <Grid item key={`b2b-catalog-card-${index}`} xs={12} sm={6} md={4} lg={3}>
                        <B2BCatalogCard trip={trip} isClientView={isClientView} />
                      </Grid>
                    );
                  })}
                </Grid>
                {numberPages > 1 && (
                  <Grid container justifyContent="center">
                    <Pagination
                      count={numberPages}
                      mr={3}
                      sx={PAGINATION_STYLES}
                      page={page}
                      onChange={changePage}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <Typography variant="caption" sx={{ color: "#000" }}>
                {t("NO_DATA_AVAILABLE")}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
