import { Add, Close, Delete } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { deleteGroupDiscount } from "../../../services/eventsServices";
import CustomDialog from "../../shared/CustomDialog";
import { focusColor } from "../../shared/textFieldStyle";

const TripTicketDiscounts = ({ index }) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const {
    watch,
    setValue,
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const ticket = watch(`tickets.${index}`, {});

  const handleOpenDialog = (index) => {
    setSelectedIndex(index);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenConfirm = (index) => {
    setSelectedIndex(index);
    setOpenConfirm(true);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleDesactiveCode = () => {
    setValue(`tickets.${index}.promotional_code_discounts.${selectedIndex}.is_active`, false);
    handleCloseDialog();
  };

  const handleActiveCode = () => {
    setValue(`tickets.${index}.promotional_code_discounts.${selectedIndex}.is_active`, true);
    handleCloseConfirm();
  };

  const handleAddPromoCode = () => {
    setValue(`tickets.${index}.promotional_code_discounts`, [
      ...ticket.promotional_code_discounts,
      { code: "", discount: 0, is_active: true },
    ]);
  };

  const removeGroupDiscount = async (discountIndex) => {
    if (ticket?.group_discounts?.[discountIndex]?.id) {
      await deleteGroupDiscount(ticket.group_discounts[discountIndex].id);
    }
    setValue(
      `tickets.${index}.group_discounts`,
      ticket.group_discounts.filter((_, i) => i !== discountIndex)
    );
  };

  const handleAddGroupDiscount = () => {
    setValue(`tickets.${index}.group_discounts`, [
      ...ticket.group_discounts,
      { min_size: 0, max_size: 0, discount: 0 },
    ]);
  };

  return (
    <Grid container sx={{ display: "flex", flexDirection: "row" }}>
      {/* Promotional code discount */}
      <Typography sx={{ fontWeight: "600", mb: 2 }}>{t("PROMOTIONAL_CODE")}</Typography>
      {ticket?.promotional_code_discounts?.map((promo, promoIndex) => {
        return (
          <Grid
            key={`promo-${promoIndex}`}
            xs={12}
            item
            container
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              border: "1px solid lightgrey",
              borderRadius: "4px",
              marginBottom: "1rem",
              padding: "1rem",
              ml: 1,
            }}
            columnSpacing={2}
          >
            <Grid item xs={4}>
              <Typography variant="body1">{t("PROMOTIONAL_CODE")}</Typography>
              <TextField
                {...register(`tickets.${index}.promotional_code_discounts.${promoIndex}.code`)}
                error={!!errors?.tickets?.[index]?.promotional_code_discounts?.[promoIndex]?.code}
                helperText={t(
                  errors?.tickets?.[index]?.promotional_code_discounts?.[promoIndex]?.code?.message
                )}
                fullWidth
                sx={focusColor}
                placeholder={t("PROMOTIONAL_CODE")}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">{t("DISCOUNT")}</Typography>
              <TextField
                {...register(`tickets.${index}.promotional_code_discounts.${promoIndex}.discount`)}
                error={
                  !!errors?.tickets?.[index]?.promotional_code_discounts?.[promoIndex]?.discount
                }
                helperText={t(
                  errors?.tickets?.[index]?.promotional_code_discounts?.[promoIndex]?.discount
                    ?.message
                )}
                fullWidth
                sx={focusColor}
                type="number"
                inputProps={{ min: 0 }}
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">{t("USE_LIMIT")}</Typography>
              <TextField
                {...register(`tickets.${index}.promotional_code_discounts.${promoIndex}.limit`)}
                error={!!errors?.tickets?.[index]?.promotional_code_discounts?.[promoIndex]?.limit}
                helperText={t(
                  errors?.tickets?.[index]?.promotional_code_discounts?.[promoIndex]?.limit?.message
                )}
                fullWidth
                sx={focusColor}
                type="number"
                inputProps={{ min: 0 }}
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
            <Grid item xs={2} container justifyContent="center">
              {promo.is_active ? (
                <IconButton onClick={() => handleOpenDialog(promoIndex)}>
                  <Delete sx={{ color: "var(--oniria-red)" }} />
                </IconButton>
              ) : (
                <Button
                  sx={{ color: "var(--oniria-red)", border: "1px solid var(--oniria-red)" }}
                  onClick={() => handleOpenConfirm(promoIndex)}
                >
                  {t("INACTIVE ")}
                </Button>
              )}
            </Grid>
          </Grid>
        );
      })}
      <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
        <Button
          variant="outlined"
          onClick={handleAddPromoCode}
          size="small"
          className="oniria-border-btn"
          sx={{ borderRadius: 3 }}
          startIcon={<Add />}
        >
          {t("ADD_PROMOTIONAL_CODE")}
        </Button>
      </Grid>
      <Typography sx={{ fontWeight: "600" }}>{t("DISCOUNT_PAYMENT")}</Typography>
      <Grid item xs={12}>
        <Typography>{t("DISCOUNT_PAYMENT")}</Typography>
        <TextField
          placeholder={t("IN_EU")}
          {...register(`tickets.${index}.early_payment_discount`)}
          error={!!errors?.tickets?.[index]?.early_payment_discount}
          helperText={t(errors?.tickets?.[index]?.early_payment_discount?.message)}
          fullWidth
          sx={focusColor}
          type="number"
          inputProps={{ min: 0 }}
        />
      </Grid>
      <Grid
        container
        columnSpacing={2}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <Grid item xs={12} mt={2}>
          <Typography>{t("VALID_UNTIL")}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={`tickets.${index}.early_payment_discount_date`}
            control={control}
            render={({ field }) => (
              <TextField
                type="date"
                {...field}
                error={!!errors?.tickets?.[index]?.early_payment_discount_date}
                helperText={t(errors?.tickets?.[index]?.early_payment_discount_date?.message)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={focusColor}
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                  max: "2100-12-31",
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={`tickets.${index}.early_payment_discount_date_time`}
            control={control}
            render={({ field }) => (
              <TextField
                type="time"
                {...field}
                error={!!errors?.tickets?.[index]?.early_payment_discount_date_time}
                helperText={t(errors?.tickets?.[index]?.early_payment_discount_date_time?.message)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={focusColor}
              />
            )}
          />
        </Grid>
      </Grid>
      {/* Group discount */}
      <Typography sx={{ fontWeight: "600", mt: 2 }}>{t("GROUP_DISCOUNT")}</Typography>
      <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          {ticket?.group_discounts?.map((_, discountIndex) => {
            return (
              <Box
                key={`discount-${discountIndex}`}
                component={Paper}
                elevation={0}
                sx={{
                  py: 2,
                  px: 1,
                  mb: 2,
                  border: "1px solid #E4E4E4",
                  borderRadius: "15px",
                }}
              >
                <Grid container spacing={1} sx={{ alignItems: "center" }}>
                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse" }}>
                    {discountIndex === ticket.group_discounts.length - 1 && (
                      <IconButton onClick={() => removeGroupDiscount(discountIndex)}>
                        <Close fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={2} sx={{ textAlign: "center" }}>
                    <Typography sx={{ mt: 1, textAlign: "center" }}>{t("FROM_MIN")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      {...register(`tickets.${index}.group_discounts.${discountIndex}.min_size`)}
                      error={!!errors?.tickets?.[index]?.group_discounts?.[discountIndex]?.min_size}
                      helperText={t(
                        errors?.tickets?.[index]?.group_discounts?.[discountIndex]?.min_size
                          ?.message
                      )}
                      fullWidth
                      sx={focusColor}
                      placeholder={t("FROM_MIN")}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1} sx={{ textAlign: "center" }}>
                    <Typography sx={{ mt: 1 }}>{t("TO")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      {...register(`tickets.${index}.group_discounts.${discountIndex}.max_size`)}
                      error={!!errors?.tickets?.[index]?.group_discounts?.[discountIndex]?.max_size}
                      helperText={t(
                        errors?.tickets?.[index]?.group_discounts?.[discountIndex]?.max_size
                          ?.message
                      )}
                      fullWidth
                      sx={focusColor}
                      placeholder={t("TO")}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} sx={{ textAlign: "center" }}>
                    <Typography sx={{ mt: 1 }}>{t("DISCOUNT_OF")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      {...register(`tickets.${index}.group_discounts.${discountIndex}.discount`)}
                      error={!!errors?.tickets?.[index]?.group_discounts?.[discountIndex]?.discount}
                      helperText={t(
                        errors?.tickets?.[index]?.group_discounts?.[discountIndex]?.discount
                          ?.message
                      )}
                      fullWidth
                      sx={focusColor}
                      placeholder={t("DISCOUNT_OF")}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1} sx={{ textAlign: "center" }}>
                    <Typography sx={{ mt: 1 }}>€</Typography>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
            <Button
              variant="outlined"
              size="small"
              className="oniria-border-btn"
              sx={{ borderRadius: 3 }}
              startIcon={<Add />}
              onClick={handleAddGroupDiscount}
            >
              {t("ADD_DISCOUNT_GROUP")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {/* Member card discount */}
      <Grid item xs={12}>
        <Typography>{t("DISCOUNT_MEMBER_CARD")}</Typography>
        <TextField
          placeholder={"€"}
          {...register(`tickets.${index}.international_discount`)}
          error={!!errors?.tickets?.[index]?.international_discount}
          helperText={t(errors?.tickets?.[index]?.international_discount?.message)}
          fullWidth
          sx={focusColor}
          type="number"
          inputProps={{ min: 0 }}
        />
      </Grid>
      <CustomDialog
        isOpen={openDialog}
        onClose={handleCloseDialog}
        title={t("UNCHECK_CODE")}
        content={t("UNCHECK_CODE_TEXT")}
        onAccept={(e) => handleDesactiveCode(e)}
      />
      <CustomDialog
        isOpen={openConfirm}
        onClose={handleCloseConfirm}
        title={t("CHECK_CODE")}
        content={t("CHECK_CODE_TEXT")}
        onAccept={(e) => handleActiveCode(e)}
      />
    </Grid>
  );
};

export default TripTicketDiscounts;
