import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";

export const StyledAccordion = ({ title, children }) => {
  return (
    <Accordion
      sx={{
        backgroundColor: "white",
        borderRadius: 2,
        "&:before": { display: "none" },
        boxShadow: "none",
      }}
    >
      <AccordionSummary
        sx={{
          fontWeight: "bold",
          "& .MuiAccordionSummary-content": {
            margin: "8px 0",
          },
          "&.Mui-expanded": {
            minHeight: "48px",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: 600,
            color: "var(--oniria-gold)",
            textDecoration: "underline",
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingLeft: 5,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
