import { Button, Grid, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EventFilters from "../components/eventStatistics/EventFilters";
import EventsTable from "../components/eventStatistics/EventsTable";
import Header from "../components/Header";
import { Loading } from "../components/shared/Loading";
import SelectPerPage from "../components/shared/SelectPerPage";
import { ROUTES } from "../constants/routes";
import { PAGINATION_STYLES } from "../constants/styles";
import { getCurrentMonth } from "../constants/utils";
import { useQueryEventStatistics } from "../hooks/queries/events/useQueryEventStatistics";
import useDebounce from "../hooks/useDebouncing";
import usePagination from "../hooks/usePagination";
import useUserRoles from "../hooks/useUserRoles";
import { generateStatistics } from "../services/utilsServices";
import { toastMessageError, toastMessageSuccess } from "../components/shared/toastMessage";

const EventStatistics = () => {
  const { t } = useTranslation();

  const breadcrumbs = [{ name: t("STATISTICS"), link: ROUTES.EVENT_STATISTICS }];
  const { isSuperAdmin } = useUserRoles();

  const { page, perPage, changePage, changePerPage } = usePagination();
  const [filters, setFilters] = useState({
    search: "",
    month: getCurrentMonth(),
    asc: [],
    desc: ["start_date"],
    premiseId: "all",
  });
  const [loadingGenerate, setLoadingGenerate] = useState(false);

  const { data = {}, isLoading, refetch } = useQueryEventStatistics(page, perPage, filters);
  const { data: statistics = [], total = 0 } = data;

  const numberPages = Math.ceil(parseFloat(total) / parseFloat(perPage));

  const updateFilter = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    changePage(null, 1);
  };

  const handleSearchChange = (event) => {
    updateFilter("search", event.target.value);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleGenerateStatistics = async () => {
    try {
      setLoadingGenerate(true);
      await generateStatistics(filters);
      toastMessageSuccess(t("GENERATED_SUCCESSFULLY"));
    } catch (error) {
      toastMessageError(error?.response?.data?.error || t("ERROR"));
    } finally {
      setLoadingGenerate(false);
      refetch();
    }
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("EVENT_STATISTICS_DESCRIPTION")} />
      {isSuperAdmin && (
        <Grid container justifyContent="flex-end" mb={2}>
          <Button
            className="oniria-btn"
            onClick={handleGenerateStatistics}
            sx={{ color: "#fff" }}
            disabled={loadingGenerate}
          >
            {loadingGenerate ? t("SAVING") : t("GENERATE_SALES")}
          </Button>
        </Grid>
      )}
      <Grid
        item
        xs={11}
        sm={12}
        container
        justifyContent={{ xs: "center", sm: "space-between" }}
        alignItems={"center"}
        pb={2}
        gap={{ xs: 2, sm: 0 }}
      >
        <Typography>
          {t("TOTAL_EVENTS")}: {total}
        </Typography>
        {numberPages >= 1 && (
          <Grid item xs={12} md={8} container justifyContent="flex-end">
            <Grid item>
              <SelectPerPage
                text={t("EVENTS")}
                change={changePerPage}
                value={perPage}
                total={total}
              />
            </Grid>
            <Pagination
              count={numberPages}
              mr={3}
              sx={PAGINATION_STYLES}
              page={page}
              onChange={changePage}
            />
          </Grid>
        )}
      </Grid>
      <EventFilters onSearchChange={debounceSearch} updateFilter={updateFilter} filters={filters} />
      {isLoading ? (
        <Loading />
      ) : data ? (
        <div style={{ marginBottom: "20px" }}>
          {total > 0 ? (
            <EventsTable data={statistics} />
          ) : (
            <Typography>{t("EVENTS_NO_FOUND")}</Typography>
          )}
          {numberPages >= 1 && (
            <Grid container justifyContent="center">
              <Pagination
                count={numberPages}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </div>
      ) : (
        <div>{t("NO_EVENTS_FOUND")}</div>
      )}
    </>
  );
};

export default EventStatistics;
