import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { cloneElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const WebBarItem = ({ item, isOpen }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ListItem key={item.title} disablePadding sx={{ display: "block", color: "white" }}>
      <ListItemButton
        onClick={() => navigate(item.route)}
        sx={{
          minHeight: 48,
          justifyContent: isOpen ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: isOpen ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {item.icon && typeof item.icon === "string" ? (
            <Box
              component="img"
              alt={t(item.title)}
              src={item.icon}
              sx={{ height: "25px", width: "25px", color: "#fff" }}
            />
          ) : item.icon ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {cloneElement(item.icon, { sx: { height: "25px", width: "25px", color: "#fff" } })}
            </Box>
          ) : null}
        </ListItemIcon>
        <ListItemText primary={t(item.title)} sx={{ opacity: isOpen ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
};

export default WebBarItem;
