import {
  Autocomplete,
  Avatar,
  Checkbox,
  Chip,
  Grid,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTripLanguage } from "../../../context/TripLanguageContext";
import { optionsValue } from "../../shared/OptionsMultiSelect";
import { focusColor } from "../../shared/textFieldStyle";
import TextEditor from "../../textEditor/TextEditor";

const TripMoreInfo = () => {
  const { t } = useTranslation();

  const { watch, setValue, control } = useFormContext();

  const { getFieldName } = useTripLanguage();

  const includedOptions = watch("included_options", []);
  const notIncludedOptions = getFieldName("not_included_options");

  return (
    <>
      <Grid item xs={12}>
        <Typography>{t("INCLUDING")}</Typography>
        <Controller
          name={"included_options"}
          control={control}
          render={({ field }) => (
            <Autocomplete
              multiple
              options={optionsValue}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              value={includedOptions}
              renderGroup={(option, { selected }) => (
                <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                  <Avatar alt={option.name} src={option.img} />
                  <ListItemText primary={option?.value} />
                  <Checkbox checked={selected} />
                </Grid>
              )}
              renderTags={(value, getTagProps) =>
                value?.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      key={key}
                      avatar={<Avatar alt={option.name} src={option.img} />}
                      label={option.name}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t("INCLUDING_OPTIONS")}
                  fullWidth
                  placeholder={t("WRITE_INCLUDING")}
                  sx={focusColor}
                />
              )}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography>{t("NOT_INCLUDED_OPTIONS")}</Typography>
        <TextEditor
          key={notIncludedOptions}
          data={watch(notIncludedOptions)}
          setData={(data) => setValue(notIncludedOptions, data)}
          id={`language-not-included-${notIncludedOptions}`}
        />
      </Grid>
    </>
  );
};

export default TripMoreInfo;
