import { useEffect } from "react";
import { useQuery } from "react-query";
import {
  createTripErrorsAllOk,
  details,
  eventLanguageErrorsAllOk,
  extra,
  moreInformation,
  ticket,
} from "../classes/tripClass";
import { objectWithTemplateKeys } from "../components/shared/FormsValidator";
import { getEventById, getExtrasByEventId, getTicketsByEventId } from "../services/eventsServices";
import { includedOptionsComplete } from "../constants/utils";

const useEventLogic = (eventId, formData, setFormData, setFormParamsErrors, setLoading) => {
  // Delete the id and event_id from the itineraries
  const modifyItineraries = (itineraries) => {
    return itineraries.map((itinerary) => {
      let newItinerary = { ...itinerary, event_id: null, id: null };
      for (let key in newItinerary) {
        if (newItinerary[key] === null || newItinerary[key] === "") {
          delete newItinerary[key];
        }
      }
      return newItinerary;
    });
  };

  //Delete the id and event_id from blocks
  const modifyBlocks = (blocks) => {
    return blocks.map((block) => {
      if (block.images_urls) {
        block.images_urls = block.images_urls.map((image) => {
          if (image === "") return null;
          return image;
        });
      }
      let newBlock = { ...block, event_id: null, id: null };

      for (let key in newBlock) {
        if (newBlock[key] === null || newBlock[key] === "") {
          delete newBlock[key];
        }
      }
      return newBlock;
    });
  };

  const handleEventQuerySuccess = (data) => {
    const moreInformationOrganized = objectWithTemplateKeys(data, moreInformation);
    moreInformationOrganized.included_options = includedOptionsComplete(
      moreInformationOrganized.included_options
    );
    moreInformationOrganized.blocks = modifyBlocks(moreInformationOrganized.blocks);
    moreInformationOrganized.itineraries = modifyItineraries(moreInformationOrganized.itineraries);

    data.is_visible = data.is_visible === 1;
    data.autovalidation = data.autovalidation === 1;
    data.is_international = data.is_international === 1;

    data.sku = "";
    data.event_categories =
      data?.event_categories?.map((category) => {
        let newCategory = { ...category, event_id: null, id: null };
        for (let key in newCategory) {
          if (newCategory[key] === null || newCategory[key] === "") {
            delete newCategory[key];
          }
        }

        return newCategory;
      }) || [];

    const eventToSee = {
      event_type: data.event_type,
      details: objectWithTemplateKeys(data, details),
      moreInformation: moreInformationOrganized,
    };
    setFormData({ ...formData, ...eventToSee });
    setLoading(false);
  };

  const handleTicketsQuerySuccess = (data) => {
    const processedTickets = [];
    for (let i = 0; i < data.length; i++) {
      const nextTicket = objectWithTemplateKeys(data[i], ticket, ["id", "event_id"]);
      nextTicket.is_visible = nextTicket.is_visible === 1;
      nextTicket.early_payment_discount_date_time = nextTicket.early_payment_discount_date
        ? nextTicket.early_payment_discount_date.split("T")[1].split(".")[0]
        : "";
      nextTicket.early_payment_discount_date = nextTicket.early_payment_discount_date
        ? nextTicket.early_payment_discount_date.split("T")[0]
        : "";
      nextTicket.id = "";
      nextTicket.event_id = "";
      processedTickets.push(nextTicket);
    }
    //
    const ticketsToSee = {
      tickets: processedTickets,
    };
    setFormData({ ...formData, ...ticketsToSee });
  };

  const handleExtrasQuerySuccess = (data) => {
    //
    const processedExtras = [];
    for (let i = 0; i < data.length; i++) {
      const nextExtra = objectWithTemplateKeys(data[i], extra, ["id", "event_id"]);
      nextExtra.id = "";
      nextExtra.event_id = "";
      processedExtras.push(nextExtra);
    }
    //
    const extrasToSee = {
      extras: processedExtras,
    };
    setFormData({ ...formData, ...extrasToSee });
  };

  const { refetch: refetchEvent } = useQuery("event" + eventId, () => getEventById(eventId), {
    onSuccess: handleEventQuerySuccess,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const { refetch: refetchTickets } = useQuery(
    "tickets" + eventId,
    () => getTicketsByEventId(eventId),
    {
      onSuccess: handleTicketsQuerySuccess,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { refetch: refetchExtras } = useQuery(
    "extras" + eventId,
    () => getExtrasByEventId(eventId),
    {
      onSuccess: handleExtrasQuerySuccess,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  useEffect(() => {
    if (eventId) {
      setLoading(true);
      refetchEvent();
      refetchTickets();
      refetchExtras();
      // Set the event language info errors
      if (
        formData?.event_language_infos &&
        formData.event_language_infos.length > 0 &&
        setFormParamsErrors
      ) {
        const eventLanguageInfoErrors = formData.event_language_infos.map(
          () => eventLanguageErrorsAllOk
        );
        setFormParamsErrors({
          ...createTripErrorsAllOk,
          details: { event_language_infos: eventLanguageInfoErrors },
        });
      } else {
        setFormParamsErrors(createTripErrorsAllOk);
      }
    }
  }, [eventId]);
};

export default useEventLogic;
