import { DeleteOutline, EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ASSIGNED_TAB } from "../../utils/hotels";
import { getTransportIconById } from "../../constants/utils";

const TransportsTable = ({ data, handleEdit, handleSee, handleOpenDelete, selectedTab }) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
      <Table sx={{ whiteSpace: "nowrap" }}>
        <TableHead>
          <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
            <TableCell>{t("TYPE")}</TableCell>
            <TableCell>{t("SKU")}</TableCell>
            <TableCell>{t("NAME")}</TableCell>
            <TableCell>{t("PLACES")}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                backgroundColor: row.is_active === 0 ? "var(--grey-cancelled)" : "",
                whiteSpace: "nowrap",
              }}
            >
              <TableCell>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  {getTransportIconById(row.type, 30)}
                  <Typography>{t(row.type?.toUpperCase())}</Typography>
                </Box>
              </TableCell>
              <TableCell>{row.sku}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.places}</TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  {selectedTab === ASSIGNED_TAB && (
                    <Tooltip title={t("SEE_EVENTS")}>
                      <IconButton onClick={() => handleSee(row.id)}>
                        <RemoveRedEyeOutlined sx={{ color: "var(--oniria-gold)" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title={t("EDIT_TRANSPORT")}>
                    <IconButton onClick={() => handleEdit(row.id)}>
                      <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("DELETE_TRANSPORT")}>
                    <IconButton onClick={() => handleOpenDelete(row.id)}>
                      <DeleteOutline sx={{ color: "var(--oniria-gold)" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransportsTable;
