import { Switch } from "@mui/material";
import { styled } from "@mui/system";

const MuiSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 25,
  padding: 0,
  "& .MuiSwitch-track": {
    borderRadius: 16,
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    opacity: 1,
    position: "relative",
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgba(0, 35, 11, 0.2)",
    width: 21,
    height: 21,
    borderRadius: "50%",
    transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "+ .MuiSwitch-track": {
        backgroundColor: "var(--oniria-gold)",
        opacity: 1,
      },
    },
  },
}));

export function CustomSwitch({ checked, handleChange, disabled = false }) {
  return <MuiSwitch checked={checked} onChange={handleChange} disabled={disabled} />;
}
