import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getCountries } from "../../../services/utilsServices";

export function useQueryCountries(enabled = true) {
  return useQuery([RQ_KEY.COUNTRIES, enabled], () => getCountries(), {
    refetchOnWindowFocus: false,
    enabled,
  });
}
