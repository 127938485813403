import { Add, Delete, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DEFAULT_N_PAYMENT } from "../../../constants/variables";
import { CustomSwitch } from "../../shared/switch/CustomSwitch";
import { AddPaymentsDialog } from "./AddPaymentsDialog";
import { TripPaymentItem } from "./TripPaymentItem";

export const TripMultiplePayments = () => {
  const { t } = useTranslation();

  const {
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  const [openDialog, setOpenDialog] = useState(false);
  const nPayments = watch("event_payments", []);
  const groupedPayments = Object.groupBy(nPayments, (payment) => payment.number_of_payments);

  const handleChangeMultiplePayments = (e) => {
    setValue("event_payments", e.target.checked ? setOpenDialog(true) : []);
  };

  const removeGroupPayment = (e, key) => {
    e.preventDefault();
    e.stopPropagation();

    const updatedPayments = getValues("event_payments")
      .filter((payment) => Number(payment.number_of_payments) !== Number(key))
      .map((payment, index) => ({ ...payment, position: index }));

    setValue("event_payments", updatedPayments);
  };

  const handleAddPayment = (nPayments) => {
    const prev = getValues("event_payments") || [];
    const lastIndex = prev.length;

    const totalPayments = parseInt(nPayments, 10);
    let basePercentage = Math.floor((100 / totalPayments) * 10) / 10;
    let remainingPercentage = Number((100 - basePercentage * (totalPayments - 1)).toFixed(1));

    const newPayments = Array.from({ length: totalPayments }, (_, index) => ({
      ...DEFAULT_N_PAYMENT,
      position: lastIndex + index,
      number_of_payments: totalPayments,
      percentage: index === totalPayments - 1 ? remainingPercentage : basePercentage,
    }));

    setValue("event_payments", [...prev, ...newPayments]);
    setOpenDialog(false);
  };

  return (
    <Grid item xs={12}>
      <Grid item xs={12} container justifyContent="space-between">
        <Box>
          <Typography variant="subtitle1" fontWeight="800" mr={2}>
            {t("MULTIPLE_PAYMENTS")}
          </Typography>
          <Typography variant="body1">{t("MULTIPLE_PAYMENTS_DESCRIPTION")}</Typography>
        </Box>
        <CustomSwitch
          checked={Boolean((nPayments?.length || 0) > 0)}
          handleChange={handleChangeMultiplePayments}
        />
      </Grid>
      {nPayments?.length > 0 ? (
        <Box
          paddingLeft={2}
          paddingTop={2}
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        >
          {Object.keys(groupedPayments).map((key) => {
            const value = groupedPayments[key];

            return (
              <Accordion
                key={"accordion-" + key}
                sx={{
                  boxShadow: "none",
                  border: "1px solid var(--grey-cancelled)",
                  borderRadius: 2,
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Grid container alignItems="center">
                    <Typography>{t("NUMBER_PAYMENTS") + ": " + key}</Typography>
                  </Grid>
                  <IconButton onClick={(e) => removeGroupPayment(e, key)}>
                    <Delete />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12} container mt={2} gap={2}>
                    {value.map((p, i) => (
                      <TripPaymentItem
                        key={`${i}-${key}-value`}
                        index={i}
                        payment={p}
                        groupKey={key}
                      />
                    ))}
                    {errors?.event_payments?.[key] && (
                      <Typography color="error">
                        {t(errors.event_payments[key]?.message)}
                      </Typography>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}

          <Button
            className="oniria-border-btn"
            size="small"
            onClick={() => setOpenDialog(true)}
            startIcon={<Add />}
            sx={{ mt: 2, width: "fit-content" }}
          >
            {t("ADD_N_PAYMENTS")}
          </Button>
        </Box>
      ) : null}
      <AddPaymentsDialog
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
        onAccept={handleAddPayment}
      />
    </Grid>
  );
};
