import { Divider, Grid, Typography } from "@mui/material";
import { Loading } from "../../shared/Loading";
import { useTranslation } from "react-i18next";
import ChildEventCard from "./ChildEventCard";

const EventCardChilds = ({ childEvents, loadingChildEvents, refetch }) => {
  const { t } = useTranslation();
  if (!childEvents) return null;

  return (
    <Grid item xs={12}>
      {loadingChildEvents ? (
        <Loading />
      ) : childEvents && childEvents.length > 0 ? (
        <Grid container gap={2}>
          <Divider sx={{ width: "100%", borderColor: "var(--secondary-color)", mt: 2, mb: 2 }} />
          <Typography mb={1}>{t("CHILD_EVENTS")}</Typography>
          {childEvents.map((childEvent, index) => (
            <ChildEventCard key={index} event={childEvent} refetch={refetch} />
          ))}
        </Grid>
      ) : (
        <Typography>{t("NO_DATA")}</Typography>
      )}
    </Grid>
  );
};

export default EventCardChilds;
