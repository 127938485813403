import { Delete } from "@mui/icons-material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import {
  groupDiscount,
  groupDiscountErrors,
  groupDiscountErrorsAllOK,
  promotionalCodeErrorsAllOk,
  ticketErrorsAllOk,
} from "../../../../classes/tripClass";
import { sanitizeBoolean } from "../../../../constants/utils";
import { DEFAULT_EVENT_LANGUAGE, IVA_OPTIONS } from "../../../../constants/variables";
import { deleteGroupDiscount } from "../../../../services/eventsServices";
import InfoToolTip from "../../../shared/InfoToolTip";
import { focusColor } from "../../../shared/textFieldStyle";
import GroupDiscount from "../../GroupDiscount";
import LanguageTabs from "../../languages/LanguageTabs";
import PromotionalCode from "../../ticketComponents/PromotionalCode";
import SaveTicketButton from "./SaveTicketButton";
import TicketDates from "./TicketDates";
import TicketDescription from "./TicketDescription";
import TicketDomainSelect from "./TicketDomainSelect";
import TicketName from "./TicketName";
import TicketPolicy from "./TicketPolicy";

const Ticket = ({
  ticket,
  isDisabled,
  errors,
  setErrors,
  checkTicketField,
  index,
  setFormData,
  tickets,
  checkTicketDiscountField,
  removeTicket,
  catchButtonClicked,
  checkPromoCodeDiscount,
  event_language_infos,
  event_categories,
  multi_date,
  has_double_payment,
  defaultLanguage = DEFAULT_EVENT_LANGUAGE,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const url = window.location.href;
  const path = url.split("/").slice(3).join("/");

  if (!errors[index]) errors[index] = JSON.parse(JSON.stringify(ticketErrorsAllOk));

  for (let i = 0; i < ticket.group_discounts.length; i++) {
    if (!errors[index].group_discounts[i])
      errors[index].group_discounts[i] = groupDiscountErrorsAllOK;
  }
  for (let i = 0; i < ticket.promotional_code_discounts.length; i++) {
    if (!errors[index].promotional_code_discounts[i])
      errors[index].promotional_code_discounts[i] = promotionalCodeErrorsAllOk;
  }

  const handleAccordionToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const handleCheckboxVisible = (event) => {
    checkTicketField(
      "is_visible",
      event,
      /^(true|false)$/,
      t("CHOOSE_OPTION"),
      true,
      index,
      "checked"
    );
  };

  const handleOnlyAPP = (event) => {
    checkTicketField(
      "only_in_app",
      event,
      /^(true|false)$/,
      t("CHOOSE_OPTION"),
      true,
      index,
      "checked"
    );
  };

  const handleOnlyInternational = (event) => {
    checkTicketField(
      "only_international",
      event,
      /^(true|false)$/,
      t("CHOOSE_OPTION"),
      true,
      index,
      "checked"
    );
  };

  const addGroupDiscount = () => {
    //
    const newGroupDiscount = JSON.parse(JSON.stringify(groupDiscount));
    //
    const updatedTickets = JSON.parse(JSON.stringify(tickets));
    //
    const firstDiscount = updatedTickets[index].group_discounts.length === 0;
    if (!firstDiscount) {
      const gdLength = updatedTickets[index].group_discounts.length;
      const maxSize = parseInt(updatedTickets[index].group_discounts[gdLength - 1].max_size);
      if (Number.isFinite(maxSize)) {
        newGroupDiscount.min_size = maxSize + 1;
      }
    }
    //
    updatedTickets[index].group_discounts = [
      ...updatedTickets[index].group_discounts,
      newGroupDiscount,
    ];
    setFormData((prev) => ({
      ...prev,
      tickets: updatedTickets,
    }));
    //
    const ticketErrors = JSON.parse(JSON.stringify(errors));
    const newGroupDiscountErrors = JSON.parse(JSON.stringify(groupDiscountErrors));
    //
    if (!firstDiscount) {
      newGroupDiscountErrors.min_size = "";
    }
    //
    ticketErrors[index].group_discounts.push(newGroupDiscountErrors);
    setErrors(ticketErrors);
  };

  // TODO: Botón para hacer esta operación
  const removeGroupDiscount = async (ticketIndex, discountIndex) => {
    //
    if (tickets[ticketIndex].group_discounts[discountIndex].id) {
      await deleteGroupDiscount(tickets[ticketIndex].group_discounts[discountIndex].id);
    }
    //
    const updatedTickets = JSON.parse(JSON.stringify(tickets));
    updatedTickets[ticketIndex].group_discounts = updatedTickets[
      ticketIndex
    ].group_discounts.filter((_, index) => discountIndex !== index);
    setFormData((prev) => ({
      ...prev,
      tickets: updatedTickets,
    }));
    //
    const updatedTicketsErrors = JSON.parse(JSON.stringify(errors));
    updatedTicketsErrors[ticketIndex].group_discounts = updatedTicketsErrors[
      ticketIndex
    ].group_discounts.filter((_, index) => discountIndex !== index);
    setErrors(updatedTicketsErrors);
  };

  //PROMOTIONAL CODE
  const handleAddPromoCode = (i) => {
    const newPromoCode = { code: "", discount: 0, is_active: true };
    const newPromoCodeErrors = { code: "", discount: "", is_active: "" };
    const updatedTickets = [...tickets];
    const updatedTicketsErrors = [...errors];
    //Errores
    updatedTicketsErrors[i] = {
      ...updatedTicketsErrors[i],
      promotional_code_discounts: [
        ...updatedTicketsErrors[i]?.promotional_code_discounts,
        newPromoCodeErrors,
      ],
    };
    setErrors(updatedTicketsErrors);
    //Tickets
    updatedTickets[i] = {
      ...updatedTickets[i],
      promotional_code_discounts: [...updatedTickets[i]?.promotional_code_discounts, newPromoCode],
    };
    setFormData((prev) => ({
      ...prev,
      tickets: updatedTickets,
    }));
  };

  const handleChangeLanguageParam = (value, key, languageIndex) => {
    const updatedTickets = JSON.parse(JSON.stringify(tickets));
    updatedTickets[index].ticket_language_infos[languageIndex][key] = value;
    setFormData((prev) => ({
      ...prev,
      tickets: updatedTickets,
    }));
  };

  const handleDeleteTicket = (e, index) => {
    e.stopPropagation();
    removeTicket(index);
  };

  useEffect(() => {
    if (defaultLanguage) {
      setSelectedLanguage(defaultLanguage);
    }
  }, [defaultLanguage]);

  return (
    <Draggable draggableId={`draggable-${ticket?.id || index}`} index={parseInt(index, 10) || 0}>
      {(provided, snapshot) => (
        <Accordion
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          component={Paper}
          elevation={0}
          sx={{
            backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
            mt: 2,
            border: "1px solid #E4E4E4",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container justifyContent="space-between" alignItems="center" mr={4}>
              <Grid item container width="fit-content" alignItems="center" gap={2}>
                <DragIndicatorIcon />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {t("TICKET")} - {ticket.name ? ticket.name : index + 1}
                </Typography>
              </Grid>
              <IconButton onClick={(e) => handleDeleteTicket(e, index)}>
                <Delete fontSize="small" />
              </IconButton>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Box component="form" noValidate>
              <Grid
                container
                sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
              >
                {event_language_infos?.length > 0 ? (
                  <LanguageTabs
                    event_languages_info={event_language_infos}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    defaultLanguage={defaultLanguage}
                  />
                ) : null}
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                  <TicketName
                    ticket={ticket}
                    errors={errors}
                    index={index}
                    checkTicketField={checkTicketField}
                    isDisabled={isDisabled}
                    catchButtonClicked={catchButtonClicked}
                    handleChangeLanguageParam={handleChangeLanguageParam}
                    selectedLanguage={selectedLanguage}
                    defaultLanguage={defaultLanguage}
                  />
                  <TicketDescription
                    ticket={ticket}
                    errors={errors}
                    index={index}
                    checkTicketField={checkTicketField}
                    isDisabled={isDisabled}
                    catchButtonClicked={catchButtonClicked}
                    handleChangeLanguageParam={handleChangeLanguageParam}
                    selectedLanguage={selectedLanguage}
                    defaultLanguage={defaultLanguage}
                  />
                </Grid>
                <Grid container flexDirection="row" gap={1} justifyContent="center">
                  <Grid item xs={12} md={5.9} lg={3.9}>
                    <TextField
                      onChange={(e) =>
                        checkTicketField(
                          "initial_stock",
                          e,
                          /^[0-9]\d*$/,
                          t("INCORRECT"),
                          false,
                          index
                        )
                      }
                      value={ticket.initial_stock}
                      margin="normal"
                      required
                      type="number"
                      fullWidth
                      id="initial_stock"
                      name="initial_stock"
                      label={path === "create_trip" ? t("NUMBER_PLACES") : t("NUMBER_TICKETS")}
                      autoComplete="stock"
                      error={
                        (errors[index].initial_stock !== "" &&
                          errors[index].initial_stock !== "empty") ||
                        (errors[index].initial_stock === "empty" && catchButtonClicked)
                      }
                      helperText={
                        errors[index].initial_stock !== "empty" ? errors[index].initial_stock : ""
                      }
                      disabled={isDisabled}
                      sx={focusColor}
                      onWheel={(e) => e.target.blur()}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5.8}
                    lg={7.9}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={5.8} container alignItems="center">
                      <TextField
                        onChange={(e) =>
                          checkTicketField(
                            "price",
                            e,
                            /^\d+(?:\.\d+)?$/,
                            t("INCORRECT"),
                            false,
                            index
                          )
                        }
                        value={ticket.price}
                        fullWidth
                        margin="normal"
                        required
                        type="number"
                        id="price"
                        name="price"
                        label={t("PRICE")}
                        autoComplete="price"
                        error={
                          (errors[index].price !== "" && errors[index].price !== "empty") ||
                          (errors[index].price === "empty" && catchButtonClicked)
                        }
                        helperText={errors[index].price !== "empty" ? errors[index].price : ""}
                        disabled={isDisabled}
                        sx={focusColor}
                        onWheel={(e) => e.target.blur()}
                      />
                    </Grid>
                    <Grid item xs={5.8}>
                      <FormControl
                        sx={focusColor}
                        fullWidth
                        required
                        margin="normal"
                        error={
                          (errors[index].iva !== "" && errors[index].iva !== "empty") ||
                          (errors[index].iva === "empty" && catchButtonClicked)
                        }
                      >
                        <InputLabel>{t("IVA%")}</InputLabel>
                        <Select
                          value={ticket.iva}
                          label={t("IVA%")}
                          onChange={(e) =>
                            checkTicketField(
                              "iva",
                              e,
                              /^\d+(?:\.\d+)?$/,
                              t("INCORRECT"),
                              false,
                              index
                            )
                          }
                        >
                          {IVA_OPTIONS.map((iva) => (
                            <MenuItem key={iva} value={iva}>
                              {iva}%
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <TicketPolicy
                  ticket={ticket}
                  errors={errors}
                  index={index}
                  checkTicketField={checkTicketField}
                  isDisabled={isDisabled}
                  handleChangeLanguageParam={handleChangeLanguageParam}
                  selectedLanguage={selectedLanguage}
                  defaultLanguage={defaultLanguage}
                />
                {sanitizeBoolean(multi_date) && (
                  <TicketDates
                    checkTicketField={checkTicketField}
                    index={index}
                    ticket={ticket}
                    ticketErrors={errors[index]}
                    buttonClicked={catchButtonClicked}
                    isDisabled={isDisabled}
                    has_double_payment={has_double_payment}
                  />
                )}
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_visible"
                        checked={ticket.is_visible || ticket.is_visible === 1}
                        onChange={handleCheckboxVisible}
                        disabled={isDisabled}
                        value={ticket.is_visible}
                        className="checkbox-oniria"
                      />
                    }
                    label={t("VISIBLE_TICKET")}
                  />
                  <InfoToolTip text={t("VISIBLE_TICKET_INFO")} />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="only_in_app"
                        checked={ticket.only_in_app || ticket.only_in_app === 1}
                        onChange={handleOnlyAPP}
                        disabled={isDisabled}
                        value={ticket.only_in_app}
                        className="checkbox-oniria"
                      />
                    }
                    label={t("ONLY_APP")}
                  />
                  <InfoToolTip text={t("ONLY_APP_INFO")} />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="only_international"
                        checked={ticket.only_international || ticket.only_international === 1}
                        onChange={handleOnlyInternational}
                        disabled={isDisabled}
                        value={ticket.only_international}
                        className="checkbox-oniria"
                      />
                    }
                    label={t("ONLY_INTERNATIONAL")}
                  />
                </Grid>
                <TicketDomainSelect
                  event_categories={event_categories}
                  ticketDomains={ticket.domain_names}
                  setFormData={setFormData}
                  ticketIndex={index}
                />
                <Grid
                  item
                  xs={12}
                  columnGap={{ md: 4 }}
                  sx={{
                    mt: 2,
                    mb: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: { xs: "space-between", md: "start" },
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleAccordionToggle}
                >
                  <InputLabel
                    sx={{ fontSize: "18px", color: "black", fontWeight: "bold", cursor: "pointer" }}
                  >
                    {t("DISCOUNTS")}
                  </InputLabel>
                  <IconButton>{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                </Grid>
                <Collapse in={isExpanded} sx={{ border: "none", overflow: "hidden" }}>
                  <Box sx={{ maxWidth: "100%", margin: "0 auto" }}>
                    <Grid
                      container
                      sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                    >
                      <Grid item xs={12}>
                        <InputLabel
                          sx={{
                            fontSize: "18px",
                            color: "black",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {t("DISCOUNTS_MEMBERS")}
                          <InfoToolTip text={t("DISCOUNTS_MEMBERS_INFO")} />
                        </InputLabel>
                        <TextField
                          onChange={(e) =>
                            checkTicketField(
                              "international_discount",
                              e,
                              /^(?:\d+(?:\.\d+)?|)$/,
                              t("INCORRECT"),
                              true,
                              index
                            )
                          }
                          value={ticket.international_discount}
                          margin="normal"
                          type="number"
                          fullWidth
                          id="international_discount "
                          name="international_discount "
                          label={t("IN_EU")}
                          autoComplete="international_discount "
                          error={errors[index].international_discount !== ""}
                          helperText={errors[index].international_discount}
                          disabled={isDisabled}
                          sx={focusColor}
                          onWheel={(e) => e.target.blur()}
                        />
                      </Grid>
                      {/*  START PROMOTIONAL CODE */}
                      <Grid item xs={12}>
                        <InputLabel
                          sx={{
                            fontSize: "18px",
                            color: "black",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                            marginBottom: "1rem",
                          }}
                        >
                          {t("PROMOTIONAL_CODE")}
                          <InfoToolTip text={t("PROMOTIONAL_CODE_INFO")} />
                        </InputLabel>
                      </Grid>
                      {/* TODO: CÓDIGO PROMOCIONAL VIEJO HABRÁ QUE ELIMINARLO CUANDO YA NO SE USE*/}
                      {ticket.promotional_code && ticket.promotional_code_discount && (
                        <Grid container justifyContent="space-between">
                          <Grid item xs={5.5}>
                            <TextField
                              value={ticket.promotional_code}
                              margin="normal"
                              fullWidth
                              id="promotional_code"
                              name="promotional_code"
                              label={t("PROMOTIONAL_CODE")}
                              autoComplete="promotional_code"
                              disabled
                              sx={focusColor}
                            />
                          </Grid>
                          <Grid item xs={5.5}>
                            <TextField
                              value={ticket.promotional_code_discount}
                              margin="normal"
                              type="number"
                              fullWidth
                              id="promotional_code_discount"
                              name="promotional_code_discount"
                              label={t("IN_EU")}
                              autoComplete="promotional_code_discount"
                              disabled
                              sx={focusColor}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {/* FIN DE LA ELIMINACION */}
                      {ticket?.promotional_code_discounts?.map((code, i) => {
                        return (
                          <PromotionalCode
                            key={i}
                            checkPromoCodeDiscount={checkPromoCodeDiscount}
                            promo={code}
                            errors={errors}
                            isDisabled={isDisabled}
                            ticketIndex={index}
                            promoIndex={i}
                            maxPrice={ticket.price}
                            catchButtonClicked={catchButtonClicked}
                          />
                        );
                      })}
                      <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                        <Button
                          fullWidth
                          variant="outlined"
                          size="large"
                          sx={{
                            color: "var(--secondary-color)",
                            borderColor: "var(--secondary-color)",
                            "&:hover": {
                              color: "var(--secondary-color)",
                              borderColor: "var(--secondary-color)",
                              backgroundColor: "white",
                            },
                          }}
                          onClick={() => handleAddPromoCode(index)}
                        >
                          {t("ADD_PROMOTIONAL_CODE")}
                        </Button>
                      </Grid>
                      {/*  FIN CÓDIGO PROMOCIONAL */}
                      <Grid item xs={12}>
                        <InputLabel sx={{ fontSize: "18px", color: "black", textAlign: "left" }}>
                          {t("DISCOUNT_PAYMENT")}
                        </InputLabel>
                        <TextField
                          onChange={(e) =>
                            checkTicketField(
                              "early_payment_discount",
                              e,
                              /^(?:\d+(?:\.\d+)?|)$/,
                              t("INCORRECT"),
                              true,
                              index
                            )
                          }
                          value={ticket.early_payment_discount}
                          margin="normal"
                          type="number"
                          fullWidth
                          id="early_payment_discount"
                          name="early_payment_discount"
                          label={t("IN_EU")}
                          autoComplete="early_payment_discount"
                          error={
                            errors[index].early_payment_discount !== "" &&
                            errors[index].early_payment_discount !== "empty"
                          }
                          helperText={errors[index].early_payment_discount}
                          disabled={isDisabled}
                          sx={focusColor}
                          onWheel={(e) => e.target.blur()}
                        />
                      </Grid>
                      <Grid
                        container
                        columnSpacing={2}
                        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <Grid item xs={12}>
                          <InputLabel sx={{ fontSize: "18px", color: "black", textAlign: "left" }}>
                            {t("VALID_UNTIL")}
                          </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            onChange={(e) =>
                              checkTicketField(
                                "early_payment_discount_date",
                                e,
                                /^(\d{4})-(\d{2})-(\d{2})$|^$/,
                                t("CHOOSE_OPTION"),
                                true,
                                index
                              )
                            }
                            type="date"
                            margin="normal"
                            fullWidth
                            id="early_payment_discount_date"
                            name="early_payment_discount_date"
                            value={ticket.early_payment_discount_date}
                            autoComplete="early_payment_discount_date"
                            error={errors[index].early_payment_discount_date !== ""}
                            helperText={errors[index].early_payment_discount_date}
                            disabled={isDisabled}
                            sx={focusColor}
                            inputProps={{
                              min: new Date().toISOString().split("T")[0],
                              max: "2100-12-31",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            onChange={(e) =>
                              checkTicketField(
                                "early_payment_discount_date_time",
                                e,
                                /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$|^$/,
                                t("CHOOSE_OPTION"),
                                true,
                                index
                              )
                            }
                            margin="normal"
                            type="time"
                            fullWidth
                            id="early_payment_discount_date_time"
                            name="early_payment_discount_date_time"
                            value={ticket.early_payment_discount_date_time}
                            autoComplete="early_payment_discount_date_time"
                            error={errors[index].early_payment_discount_date_time !== ""}
                            helperText={errors[index].early_payment_discount_date_time}
                            disabled={isDisabled}
                            sx={focusColor}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                          {t("GROUP_DISCOUNT")}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                          {t("EXAMPLE_DISCOUNT")}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                          {ticket.group_discounts.map((discount, discountIndex) => {
                            return (
                              <GroupDiscount
                                key={discountIndex}
                                ticket={ticket}
                                checkTicketField={checkTicketField}
                                errors={errors[index].group_discounts[discountIndex]}
                                ticketIndex={index}
                                discountIndex={discountIndex}
                                isDisabled={isDisabled}
                                group_discounts={discount}
                                checkTicketDiscountField={checkTicketDiscountField}
                                closeDiscount={removeGroupDiscount}
                              />
                            );
                          })}
                          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                            <Button
                              fullWidth
                              variant="outlined"
                              size="large"
                              sx={{
                                color: "var(--secondary-color)",
                                borderColor: "var(--secondary-color)",
                                "&:hover": {
                                  color: "var(--secondary-color)",
                                  borderColor: "var(--secondary-color)",
                                  backgroundColor: "white",
                                },
                              }}
                              onClick={() => addGroupDiscount()}
                            >
                              {t("ADD_DISCOUNT_GROUP")}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </Grid>
              <SaveTicketButton
                tickets={tickets}
                ticket={ticket}
                setFormData={setFormData}
                ticketIndex={index}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
};

export default Ticket;
