import { Check, CheckCircleOutline } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { PAGINATION_STYLES } from "../../../constants/styles";
import SearchFilter from "../../events/filters/filter/SearchFilter";
import SelectPerPage from "../../shared/SelectPerPage";

export const TransportUsers = ({ passangersInfo, perPage, page, changePage, changePerPage }) => {
  const { t } = useTranslation();

  const numberPages = Math.ceil(parseFloat(passangersInfo?.length || "0") / parseFloat(perPage));
  const totalPassengers = passangersInfo?.length;

  return (
    <>
      <Typography variant="h6">{t("PASSENGERS")}</Typography>
      <Divider sx={{ width: "100%" }} />
      <Grid item xs={12} mt={2} mb={2}>
        <SearchFilter onSearchChange={() => {}} />
      </Grid>
      <Grid
        item
        xs={11}
        sm={12}
        container
        justifyContent={{ xs: "center", sm: "space-between" }}
        alignItems={"center"}
        pb={2}
        gap={{ xs: 2, sm: 0 }}
      >
        <Typography>
          {t("TOTAL_HOSTING")}: {totalPassengers || 0}
        </Typography>
        <Grid container item xs={12} md={8} justifyContent="flex-end">
          {numberPages >= 1 && (
            <Grid item xs={12} md={8} container justifyContent="flex-end">
              <Grid item>
                <SelectPerPage
                  text={t("HOSTING")}
                  change={changePerPage}
                  value={perPage}
                  total={totalPassengers || 0}
                />
              </Grid>
              <Pagination
                count={numberPages}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* PASSENGERS INFO */}
      <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
        <Table sx={{ whiteSpace: "nowrap" }}>
          <TableBody>
            {passangersInfo?.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  backgroundColor: row.has_arrived ? "var(--green-success)" : "",
                  whiteSpace: "nowrap",
                }}
              >
                <TableCell>
                  <Avatar alt={row.name} src={row.img_url} />
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  {t("ORDER")}:{row.order_id}
                </TableCell>
                <TableCell>
                  {t("SKU")}:{row.sku}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {row.has_arrived ? (
                      <Chip
                        variant="outlined"
                        label={t("HAS_ARRIVED")}
                        icon={<Check color="white" />}
                        sx={{ color: "var(--white)", borderColor: "var(--white)" }}
                      />
                    ) : (
                      <CheckCircleOutline sx={{ color: "gray" }} />
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {numberPages >= 1 && (
        <Grid container justifyContent="center">
          <Pagination
            count={numberPages}
            mr={3}
            sx={PAGINATION_STYLES}
            page={page}
            onChange={changePage}
          />
        </Grid>
      )}
    </>
  );
};
