import {
  AddCircleOutline,
  DeleteOutline,
  EditOutlined,
  ListAlt,
  ListAltOutlined,
  PersonAddOutlined,
} from "@mui/icons-material";
import { Button, Grid, IconButton, Paper, Popover, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { sanitizeBoolean } from "../../../constants/utils";
import { EVENTS_TYPE } from "../../../constants/variables";
import useUserRoles from "../../../hooks/useUserRoles";
import InfoToolTip from "../../shared/InfoToolTip";
import ScannerAssistant from "../scanner/ScannerAssistant";
import DeleteEventDialog from "./DeleteEventDialog";

const EventCardButtons = ({ event, setQrResponse, eventSales, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAdmin, isManager, isSuperAdmin } = useUserRoles();

  const [openDelete, setOpenDelete] = useState(false);
  const [anchorElAssistants, setAnchorElAssistants] = useState(null);
  const [anchorElDuplicate, setAnchorElDuplicate] = useState(null);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleEventClick = (id, tab) => {
    if (tab) {
      navigate(`/event/${id}?tab=${tab}`);
    } else {
      navigate(`/event/${id}`);
    }
  };

  const handleDuplicateEvent = () => {
    if (event.event_type === EVENTS_TYPE.TRIP_COMPOSITE) {
      navigate(`/trips/create_trip_plus/${event.id}`);
    } else if (event.event_type === EVENTS_TYPE.TRIP) {
      navigate(`/create_trip/${event.id}`);
    } else {
      navigate(`/create_event/${event.id}`);
    }
  };

  const handleCreateChildEvent = () => {
    navigate(`/trips/create_child_trip/${event.id}`);
  };

  const handleToggleAssistantsPopover = (event) => {
    setAnchorElAssistants(anchorElAssistants ? null : event.currentTarget);
  };

  const handleToggleDuplicatePopover = (event) => {
    setAnchorElDuplicate(anchorElDuplicate ? null : event.currentTarget);
  };

  const handleCloseAssistantsPopover = () => {
    setAnchorElAssistants(null);
  };

  const handleCloseDuplicatePopover = () => {
    setAnchorElDuplicate(null);
  };

  const openAssistants = Boolean(anchorElAssistants);
  const openDuplicate = Boolean(anchorElDuplicate);

  return isMobile ? (
    <Grid
      item
      xs={12}
      md={12}
      lg={3.2}
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
      mt={{ xs: 2, lg: 0 }}
    >
      <ScannerAssistant
        eventId={event.id}
        goBack={() => navigate(ROUTES.EVENTS)}
        eventName={event.name}
        totalTickets={eventSales?.sold_tickets}
        consumedTickets={eventSales?.consumed_tickets}
        isMobileButton
        setQrResponse={setQrResponse}
      />
      <Grid item xs={5} container justifyContent="center">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/event/${event.id}?tab=1`);
          }}
          className="oniria-btn"
          sx={{ borderRadius: "20%" }}
        >
          <ListAlt sx={{ fontSize: "3rem", color: "#fff" }} />
        </IconButton>
      </Grid>
    </Grid>
  ) : (
    <Grid
      item
      xs={12}
      md={12}
      lg={3}
      container
      alignItems="center"
      justifyContent="center"
      mt={{ xs: 2, lg: 0 }}
    >
      <InfoToolTip text={t("ADD_ASSISTANT")}>
        <IconButton
          onClick={() => navigate(`/event/${event.id}/add_assistant`)}
          className="oniria-icon-btn"
          sx={{ fontWeight: "bolder" }}
        >
          <PersonAddOutlined sx={{ fontSize: "2rem" }} />
        </IconButton>
      </InfoToolTip>

      <InfoToolTip text={t("EDIT_EVENT")}>
        <IconButton onClick={() => handleEventClick(event.id)} className="oniria-icon-btn">
          <EditOutlined sx={{ fontSize: "2rem" }} />
        </IconButton>
      </InfoToolTip>

      {sanitizeBoolean(event.is_b2b) && (
        <InfoToolTip text={t("B2B_SALES")}>
          <IconButton
            onClick={() => handleEventClick(event.id, 5)}
            className="oniria-icon-btn"
            sx={{ fontWeight: "bolder" }}
          >
            {t("B2B")}
          </IconButton>
        </InfoToolTip>
      )}

      <IconButton className="oniria-icon-btn" onClick={handleToggleAssistantsPopover}>
        <ListAltOutlined sx={{ fontSize: "2rem" }} />
      </IconButton>
      <Popover
        open={openAssistants}
        anchorEl={anchorElAssistants}
        onClose={handleCloseAssistantsPopover}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Paper sx={{ padding: 1, display: "flex", flexDirection: "column", textAlign: "center" }}>
          <Button onClick={() => handleEventClick(event.id, 1)} className="oniria-btn-reverse">
            {t("ASSISTANTS")}
          </Button>
          <Button onClick={() => handleEventClick(event.id, 2)} className="oniria-btn-reverse">
            {t("ORDERS")}
          </Button>
        </Paper>
      </Popover>

      <IconButton className="oniria-icon-btn" onClick={handleToggleDuplicatePopover}>
        <AddCircleOutline sx={{ fontSize: "2rem" }} />
      </IconButton>
      <Popover
        open={openDuplicate}
        anchorEl={anchorElDuplicate}
        onClose={handleCloseDuplicatePopover}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Paper sx={{ padding: 1, display: "flex", flexDirection: "column", textAlign: "center" }}>
          <Button onClick={handleCreateChildEvent} className="oniria-btn-reverse">
            {t("CREATE_CHILD")}
          </Button>
          <Button onClick={handleDuplicateEvent} className="oniria-btn-reverse">
            {t("DUPLICATE")}
          </Button>
        </Paper>
      </Popover>

      {(isSuperAdmin || isManager || isAdmin) && (
        <InfoToolTip text={t("DELETE_EVENT")}>
          <IconButton onClick={handleOpenDelete}>
            <DeleteOutline sx={{ fontSize: "2rem" }} />
          </IconButton>
        </InfoToolTip>
      )}

      <DeleteEventDialog
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        eventId={event.id}
        refetch={refetch}
      />
    </Grid>
  );
};

export default EventCardButtons;
