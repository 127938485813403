import { createContext, useContext, useState } from "react";

const TripLanguageContext = createContext();

export const TripLanguageProvider = ({ children, defaultLanguage, eventLanguages }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const languageIndex = eventLanguages?.findIndex((info) => info.language === selectedLanguage);

  // For general fields
  const getFieldName = (field) => {
    return languageIndex !== -1 ? `event_language_infos.${languageIndex}.${field}` : field;
  };

  // For itinerary fields
  const getItineraryFieldName = (field, itIndex) => {
    return languageIndex !== -1
      ? `itineraries.${itIndex}.itinerary_language_infos.${languageIndex}.${field}`
      : `itineraries.${itIndex}.${field}`;
  };

  //For tickets
  const getTicketFieldName = (field, ticketIndex) => {
    return languageIndex !== -1
      ? `tickets.${ticketIndex}.ticket_language_infos.${languageIndex}.${field}`
      : `tickets.${ticketIndex}.${field}`;
  };

  return (
    <TripLanguageContext.Provider
      value={{
        selectedLanguage,
        setSelectedLanguage,
        getFieldName,
        getItineraryFieldName,
        getTicketFieldName,
      }}
    >
      {children}
    </TripLanguageContext.Provider>
  );
};

export const useTripLanguage = () => {
  return useContext(TripLanguageContext);
};
