import { Add } from "@mui/icons-material";
import { Box, Button, Grid, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PAGINATION_STYLES } from "../../constants/styles";
import { useQueryB2bTrips } from "../../hooks/queries/b2b/useQueryB2bTrips";
import useDebounce from "../../hooks/useDebouncing";
import usePagination from "../../hooks/usePagination";
import useUserRoles from "../../hooks/useUserRoles";
import CardEvents from "../events/eventCard/CardEvents";
import SearchFilter from "../events/filters/filter/SearchFilter";
import CreateTripDialog from "../events/trip/CreateTripDialog";
import { Loading } from "../shared/Loading";
import SelectPerPage from "../shared/SelectPerPage";
import { ROLES } from "../../constants/variables";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

export const B2BTrips = ({ userType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { page, perPage, changePage, changePerPage } = usePagination();
  const { isSuperAdmin, isAdmin, isManager, isOfficeEditor } = useUserRoles();

  const [filters, setFilters] = useState({
    search: "",
    tag: "all",
  });
  const [openCreateTrip, setOpenCreateTrip] = useState(false);

  const { data, isLoading, isRefetching, refetch } = useQueryB2bTrips(
    page,
    perPage,
    filters,
    userType
  );
  const { total: totalTrips = 0, data: trips = [] } = data || {};

  const handleFilterChange = (value, key) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleSearchChange = (e) => {
    const search = e.target.value;
    handleFilterChange(search, "search");
  };
  const debounceSearch = useDebounce(handleSearchChange, 500);

  const numberPages = Math.ceil(parseFloat(totalTrips) / parseFloat(perPage));

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <SearchFilter onSearchChange={debounceSearch} />
          </Box>
        </Box>
        {userType === ROLES.B2B_SALES_AGENT ? (
          <Button
            onClick={() => navigate(ROUTES.B2B_CATALOGS)}
            className="oniria-btn"
            variant="contained"
            sx={{ fontSize: "12px", borderRadius: 3, height: "fit-content" }}
          >
            {t("SEARCH_MORE_PRODUCTS")}
          </Button>
        ) : (
          (isSuperAdmin || isAdmin || isManager || isOfficeEditor) && (
            <Button
              type="submit"
              onClick={() => setOpenCreateTrip(true)}
              className="oniria-btn"
              variant="contained"
              sx={{ fontSize: "12px", borderRadius: 3, height: "fit-content" }}
              startIcon={<Add />}
            >
              {t("ADD_TRIP")}
            </Button>
          )
        )}
      </Box>
      {totalTrips > 0 ? (
        <Grid container justifyContent={"center"} pb={2}>
          <Grid
            item
            xs={11}
            sm={12}
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography>
              {t("TRAVELS")} : {totalTrips}
            </Typography>
            {numberPages > 1 && (
              <Grid item xs={12} md={8} container justifyContent="flex-end">
                <Grid item>
                  <SelectPerPage
                    text={t("TRAVELS")}
                    change={changePerPage}
                    value={perPage}
                    total={totalTrips}
                  />
                </Grid>
                <Pagination
                  count={numberPages}
                  mr={3}
                  sx={PAGINATION_STYLES}
                  page={page}
                  onChange={changePage}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : null}
      {isLoading || isRefetching ? (
        <Loading />
      ) : trips && trips.length > 0 ? (
        <Grid
          container
          rowSpacing={2}
          sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mb: 20 }}
        >
          {trips?.map((trip, i) => (
            <CardEvents event={trip} key={i} refetch={refetch} isCatalogView userType={userType} />
          ))}
        </Grid>
      ) : (
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {t("NO_DATA_AVAILABLE")}
        </Typography>
      )}
      {numberPages > 1 && (
        <Pagination
          count={numberPages}
          sx={{
            mb: 20,
            ...PAGINATION_STYLES,
          }}
          page={page}
          onChange={changePage}
        />
      )}
      <CreateTripDialog isOpen={openCreateTrip} onClose={() => setOpenCreateTrip(false)} />
    </>
  );
};
