import { Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { sanitizeBoolean } from "../../../constants/utils";
import { IVA_OPTIONS } from "../../../constants/variables";
import { useTripLanguage } from "../../../context/TripLanguageContext";
import SelectHookForm from "../../shared/SelectHookForm";
import { StyledAccordion } from "../../shared/StyledAccordion";
import { focusColor } from "../../shared/textFieldStyle";
import TextEditor from "../../textEditor/TextEditor";
import TripTicketDiscounts from "./TripTicketDiscounts";
import TripTicketSales from "./TripTicketSales";

const TripTicketConfig = ({ index }) => {
  const { t } = useTranslation();
  const { getTicketFieldName } = useTripLanguage();

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();

  const ticketPolicy = getTicketFieldName("tickets_policy", index);
  const ticketDescription = getTicketFieldName("description", index);

  return (
    <Grid container spacing={2} alignItems="center">
      <TripTicketSales index={index} />
      <Grid item xs={12}>
        <Typography fontWeight="600">{t("IVA")}</Typography>
        <SelectHookForm
          name={`tickets.${index}.iva`}
          control={control}
          error={errors?.tickets?.[index]?.iva}
          defaultValue={""}
          placeholder="%"
          options={IVA_OPTIONS.map((iva) => (
            <MenuItem key={iva} value={iva}>
              {iva}%
            </MenuItem>
          ))}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="600">{t("POLICY")}</Typography>
        <TextField
          placeholder={t("POLICY")}
          key={ticketPolicy}
          {...register(ticketPolicy)}
          error={!!errors?.[ticketPolicy]}
          helperText={t(errors?.[ticketPolicy]?.message)}
          fullWidth
          sx={focusColor}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="600">
          {t("DESCRIPTION")} {t("TICKET")}
        </Typography>
        <TextEditor
          key={ticketDescription}
          data={watch(ticketDescription)}
          setData={(data) => setValue(ticketDescription, data)}
          id={`ticket-${index}-description-${ticketDescription}`}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              {...register(`tickets.${index}.only_in_app`)}
              checked={sanitizeBoolean(watch(`tickets.${index}.only_in_app`))}
              className="checkbox-oniria"
            />
          }
          label={t("ONLY_VISIBLE_APP")}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              {...register(`tickets.${index}.only_international`)}
              checked={sanitizeBoolean(watch(`tickets.${index}.only_international`))}
              className="checkbox-oniria"
            />
          }
          label={t("ONLY_FOR_MEMBERS")}
        />
      </Grid>
      <StyledAccordion title={t("TICKET_DISCOUNTS_OPTIONAL")}>
        <TripTicketDiscounts index={index} />
      </StyledAccordion>
    </Grid>
  );
};

export default TripTicketConfig;
