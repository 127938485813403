import { Close } from "@mui/icons-material";
import { IconButton, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { dateWithoutTZ, parseFloatOrNull } from "../../../constants/utils";
import { editB2bAgreements } from "../../../services/b2bServices";
import { focusColor } from "../../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";

export const CancelAgreementDialog = ({ isOpen, onClose, agreement }) => {
  const { t } = useTranslation();
  const { premises_id = [] } = useSelector((state) => state.userInfo);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [description, setDescription] = useState("");

  const handleAccept = async () => {
    try {
      setLoadingSubmit(true);
      const body = {
        cancellation_reason: description || "",
        commission_fixed: parseFloatOrNull(agreement.commission_fixed),
        commission_percentage: parseFloatOrNull(agreement.commission_percentage),
        cancelled_at: dateWithoutTZ(new Date().toISOString()),
        premise_operator_id: agreement.premise_operator_id || premises_id?.[0] || "",
        premise_agent_id: agreement.premise_agent_id ?? "",
        event_id: agreement.event_id ?? "",
      };

      await editB2bAgreements(agreement.id, body);
      toastMessageSuccess(t("ESPECIAL_COMMISSION_UPDATED_SUCCESS"));

      setDescription("");
      onClose();
    } catch (error) {
      const errorMessage = error.response?.data?.message || t("ERROR");
      toastMessageError(errorMessage);
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography sx={{ fontWeight: "bolder", color: "var(--oniria-red)" }}>
          {t("DECLINE_REQUEST")}
        </Typography>
        <Typography variant="body2">{t("DECLINE_REQUEST_DESCRIPTION")}</Typography>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "var(--secondary-color)",
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* DESCRIPTION */}
        <Typography>{t("REASON_OPTIONAL")}</Typography>
        <TextField
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={focusColor}
          fullWidth
        />
      </DialogContent>

      <DialogActions
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mb: 2 }}
      >
        <Button
          type="submit"
          className="oniria-border-btn"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
          disabled={loadingSubmit}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={handleAccept}
          disabled={loadingSubmit}
        >
          {loadingSubmit ? t("SAVING") : t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
