import { Settings } from "@mui/icons-material";
import { Tab, Tabs } from "@mui/material";
import { cloneElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { B2BConfiguration } from "../../components/b2b/B2BConfiguration";
import B2BSales from "../../components/b2b/B2BSales";
import { B2BTrips } from "../../components/b2b/B2BTrips";
import { B2bBAgents } from "../../components/b2b/sales/B2BAgents";
import Header from "../../components/Header";
import { DollarCoinIcon } from "../../components/icons/DollarCoinIcon";
import { MoneyCaseIcon } from "../../components/icons/MoneyCaseIcon";
import { PalmIcon } from "../../components/icons/PalmIcon";
import { ROUTES } from "../../constants/routes";
import { ROLES } from "../../constants/variables";

export const B2BPage = ({ userType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();

  const getLabel = (userType, operatorLabel, agentLabel) => {
    return userType === ROLES.B2B_OPERATOR ? operatorLabel : agentLabel;
  };

  const breadcrumbs = [
    {
      name: getLabel(userType, t("OPERATOR"), t("TRIPS_AGENT")),
      link: ROUTES.B2B_OPERATOR,
    },
  ];

  const tabs = [
    {
      label: getLabel(userType, t("MY_B2B_CATALOG"), t("PRODUCTS_IN_USE")),
      icon: <PalmIcon />,
      component: <B2BTrips userType={userType} />,
    },
    { label: t("B2B_SALES"), icon: <MoneyCaseIcon />, component: <B2BSales /> },
    ...(userType === ROLES.B2B_OPERATOR
      ? [
          {
            label: t("MY_B2B_AGENTS"),
            icon: <DollarCoinIcon />,
            component: <B2bBAgents />,
          },
        ]
      : []),
    {
      label: t("B2B_CONFIGURATION"),
      icon: <Settings />,
      component: <B2BConfiguration userType={userType} />,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(Number(searchParam.get("tab")) || 0);

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={""} />
      <Tabs
        value={selectedTab}
        onChange={(_, newValue) => {
          setSelectedTab(newValue);
          navigate(`/b2b/${userType}?tab=${newValue}`, { replace: true });
        }}
        TabIndicatorProps={{
          style: { display: "none" },
        }}
        sx={{
          mb: 2,
          backgroundColor: "var(--grey-background)",
          width: "fit-content",
          borderRadius: "8px",
          padding: "4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            iconPosition="start"
            label={tab.label}
            icon={cloneElement(tab.icon, { sx: { strokeColor: "red" } })}
            value={index}
            sx={{
              textTransform: "none",
              borderRadius: "4px",
              padding: "5px 5px",
              minHeight: "unset",
              minWidth: "unset",
              transition: "all 0.3s",
              margin: "0 4px",
              "&:hover": {
                backgroundColor: selectedTab === index ? "white" : "var(--grey-cancelled)",
              },
              "&.Mui-selected": {
                color: "var(--secondary-color)",
                backgroundColor: "white",
              },
            }}
          />
        ))}
      </Tabs>
      {tabs[selectedTab]?.component || null}
    </>
  );
};
