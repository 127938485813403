import { DEFAULT_EVENT_LANGUAGE } from "../constants/variables";

export const details = {
  additional_info: "",
  autovalidation: false,
  cities: "",
  countries: "",
  days: "",
  default_language: DEFAULT_EVENT_LANGUAGE,
  description: "",
  end_date_time: "",
  end_date: "",
  end_of_publication_date_time: "",
  end_of_publication_date: "",
  end_sale_date_time: "",
  end_sale_date: "",
  event_categories: [],
  event_language_infos: [],
  event_tag: "",
  event_type: "",
  first_payment_percentage: "",
  grouping_id: "",
  has_double_payment: false,
  id: "",
  image_url: "",
  is_international: false,
  is_visible: false,
  latitude: 0,
  limit_tickets_order: "",
  location: "",
  longitude: 0,
  min_age: "",
  multi_date: 0,
  name: "",
  nights: "",
  outfit: "",
  passport_required: false,
  premise_id: "",
  principal_url: "",
  publication_date_time: "",
  publication_date: "",
  rating: "",
  second_payment_end_date_time: "",
  second_payment_end_date: "",
  second_payment_start_date_time: "",
  second_payment_start_date: "",
  seo_name: "",
  short_description: "",
  sku: "",
  slider_urls: [],
  start_date_time: "",
  start_date: "",
  start_sale_date_time: "",
  start_sale_date: "",
  subtitle: "",
  total_capacity: "",
  tour_leader_ids: [],
  video_url: "",
  without_night: false,
  pensions_type: "",
  rooms_type: "",
  parent_event_id: "",
  is_b2b: false,
  is_catalogue_visible: false,
  agents_that_can_see_ids: [],
  pvp: "",
  app_contact_form: 0,
  event_payments: [],
};

export const moreInformation = {
  included_options: [],
  not_included_options: "",
  additional_info: "",
  blocks: [],
  itineraries: [],
};

export const block = {
  title: "",
  subtitle: "",
  description: "",
  images_urls: [],
  position: "",
  language: "",
};

export const itinerary = {
  title: "",
  subtitle: "",
  description: "",
  position: "",
  language: "",
  extra_ids: [],
};

export const ticket = {
  name: "",
  description: "",
  price: "",
  tickets_policy: "",
  is_visible: false, //boolean
  international_discount: "",
  early_payment_discount: "",
  early_payment_discount_date: "",
  early_payment_discount_date_time: "",
  group_discounts: [],
  initial_stock: "",
  promotional_code: "",
  promotional_code_discount: "",
  promotional_code_discounts: [],
  iva: "",
  only_in_app: "",
  ticket_language_infos: [],
  position: "",
  domain_names: [],
  start_date: "",
  end_date: "",
  only_international: "",
  second_payment_end_date: "",
  default_language: "",
};

export const ticketLanguageInfo = {
  id: "",
  ticket_id: "",
  language: "",
  name: "",
  description: "",
  tickets_policy: "",
};

export const groupDiscount = {
  min_size: "",
  max_size: "",
  discount: "",
};

export const extra = {
  id: "",
  event_id: "",
  name: "",
  description: "",
  description_design: "",
  short_description: "",
  images: "",
  price: 0, //decimal(10,2)
  is_visible: false, //boolean
  only_international: false, //boolean
  stock: "", //int
  stock_by_ticket_max: 0,
  initial_stock: 0,
  times_can_be_consumed: "",
  extra_language_infos: [],
  tickets_ids: [],
  no_after_sales: "",
  extra_template_id: "",
  domain_names: [],
  tags: "",
  hotel_id: "",
  default_language: "",
};

export const extraLanguageInfo = {
  id: "",
  extra_id: "",
  language: "",
  name: "",
  description: "",
  description_design: "",
  short_description: "",
};

export const eventLanguageInfo = {
  id: "",
  event_id: "",
  language: "",
  name: "",
  subtitle: "",
  description: "",
  short_description: "",
  outfit: "",
  additional_info: "",
  not_included_options: "",
};

export const eventLanguageErrors = {
  name: "empty",
  subtitle: "",
  description: "",
  short_description: "",
  outfit: "",
  additional_info: "",
  not_included_options: "",
};

export const eventLanguageErrorsAllOk = {
  name: "",
  subtitle: "",
  description: "",
  short_description: "",
  outfit: "",
  additional_info: "",
  not_included_options: "",
};

export const trip = {
  details,
  moreInformation,
  tickets: [ticket],
  extras: [extra],
};

export const detailsErrors = {
  name: "empty",
  premise_id: "empty",
  event_type: "",
  event_tag: "",
  subtitle: "",
  description: "empty",
  short_description: "",
  principal_url: "empty",
  slider_urls: "",
  video_url: "",
  location: "empty",
  latitude: "",
  longitude: "",

  start_date: "",
  start_date_time: "",
  end_date: "",
  end_date_time: "",

  start_sale_date: "",
  start_sale_date_time: "",
  end_sale_date: "",
  end_sale_date_time: "",

  publication_date: "",
  publication_date_time: "",
  end_of_publication_date: "",
  end_of_publication_date_time: "",

  min_age: "empty",
  limit_tickets_order: "empty",
  outfit: "",
  is_visible: "",
  autovalidation: "",
  is_international: "",
  has_double_payment: "",
  first_payment_percentage: "",
  second_payment_start_date: "",
  second_payment_end_date: "",
  second_payment_start_date_time: "",
  second_payment_end_date_time: "",
  event_categories: "",
  countries: "",
  cities: "",
  days: "",
  nights: "",
  rating: "",
  seo_name: "empty",

  event_language_infos: [],
  without_night: "",
  passport_required: "",
  sku: "",
  pensions_type: "",

  is_b2b: "",
  is_catalogue_visible: "",
  app_contact_form: "",
};

export const detailsErrorsAllOk = {
  name: "",
  premise_id: "",
  subtitle: "",
  description: "",
  short_description: "",
  principal_url: "",
  slider_urls: "",
  video_url: "",
  location: "",
  event_type: "",
  event_tag: "",
  latitude: "",
  longitude: "",

  start_date: "",
  start_date_time: "",
  end_date: "",
  end_date_time: "",

  start_sale_date: "",
  start_sale_date_time: "",
  end_sale_date: "",
  end_sale_date_time: "",

  publication_date: "",
  publication_date_time: "",
  end_of_publication_date: "",
  end_of_publication_date_time: "",

  min_age: "",
  total_capacity: "",
  limit_tickets_order: "",
  is_visible: "",
  outfit: "",
  has_double_payment: "",
  first_payment_percentage: "",
  second_payment_start_date: "",
  second_payment_end_date: "",
  second_payment_start_date_time: "",
  second_payment_end_date_time: "",
  event_categories: "",
  countries: "",
  cities: "",
  days: "",
  nights: "",
  rating: "",
  seo_name: "",
  event_language_infos: [],
  without_night: "",
  passport_required: "",
  sku: "",
  pensions_type: "",

  is_b2b: "",
  is_catalogue_visible: "",
  pvp: "",
};

export const moreInformationErrors = {
  included_options: "",
  not_included_options: "",
  additional_info: "",
  blocks: [],
  itineraries: [],
};

export const blockErrors = {
  title: "",
  subtitle: "",
  description: "",
  images_url: "",
  position: "",
  language: "",
};

export const blockErrorsAllOk = {
  title: "",
  subtitle: "",
  description: "",
  images_urls: "",
  position: "",
  language: "",
};

export const itineraryErrors = {
  title: "",
  subtitle: "",
  description: "",
  position: "",
  language: "",
};

export const itineraryErrorsAllOk = {
  title: "",
  subtitle: "",
  description: "",
  position: "",
  language: "",
};

export const ticketErrors = {
  name: "empty",
  description: "empty",
  stock: "",
  price: "empty",
  tickets_policy: "",
  is_visible: "", //boolean
  early_payment_discount: "",
  international_discount: "",
  early_payment_discount_date: "",
  early_payment_discount_date_time: "",
  group_discounts: [],
  initial_stock: "empty",
  promotional_code: "",
  promotional_code_discount: "",
  promotional_code_discounts: [],
  iva: "empty",
  only_in_app: "",
  ticket_language_infos: [],
  position: "",
  start_date: "",
  end_date: "",
  only_international: "",
  second_payment_end_date: "",
};

export const ticketErrorsAllOk = {
  name: "",
  description: "",
  stock: "",
  price: "",
  tickets_policy: "",
  is_visible: "", //boolean
  autovalidation: "", //boolean
  early_payment_discount: "",
  international_discount: "",
  early_payment_discount_date: "",
  early_payment_discount_date_time: "",
  group_discounts: [],
  initial_stock: "",
  promotional_code: "",
  promotional_code_discount: "",
  promotional_code_discounts: [],
  iva: "",
  only_in_app: "",
  ticket_language_infos: [],
  position: "",
  start_date: "",
  end_date: "",
  only_international: "",
  second_payment_end_date: "",
};

export const extraErrors = {
  name: "empty",
  description: "",
  short_description: "",
  description_design: "",
  price: "", //decimal(10,2)
  only_international: "", //boolean
  stock: "", //int
  stock_by_ticket_max: "empty",
  initial_stock: "empty",
  times_can_be_consumed: "",
  extra_language_infos: [],
  no_after_sales: "",
  extra_template_id: "",
};

export const extraErrorsAllOk = {
  name: "",
  description: "",
  short_description: "",
  description_design: "",
  price: "", //decimal(10,2)
  only_international: "", //boolean
  stock: "", //int
  stock_by_ticket_max: "",
  initial_stock: "",
  times_can_be_consumed: "",
  extra_language_infos: [],
  no_after_sales: "",
  extra_template_id: "",
};

export const tripErrors = {
  detailsErrors,
  moreInformationErrors,
  ticketErrors,
  extraErrors,
};

export const groupDiscountErrors = {
  min_size: "empty",
  max_size: "empty",
  discount: "empty",
};
export const groupDiscountErrorsAllOK = {
  min_size: "",
  max_size: "",
  discount: "",
};

export const promotionalCodeErrorsAllOk = {
  code: "",
  discount: "",
  is_active: "",
};

export const createTrip = { details, moreInformation, tickets: [ticket], extras: [] };
export const createTripErrors = {
  details: detailsErrors,
  moreInformation: moreInformationErrors,
  tickets: [ticketErrors],
  extras: [],
};
export const createTripErrorsAllOk = {
  details: detailsErrorsAllOk,
  moreInformation: moreInformationErrors,
  tickets: [],
  extras: [],
};

export const createEventFromTemplate = { details, tickets: [ticket], extras: [] };
export const createEventErrors = { details: detailsErrors, tickets: [ticketErrors], extras: [] };
