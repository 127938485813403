import { SwapVertOutlined } from "@mui/icons-material";
import { Button, FormControlLabel, Grid, Menu, Radio, RadioGroup, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useDebounce from "../../../hooks/useDebouncing";
import SearchFilter from "../../events/filters/filter/SearchFilter";
import { CustomSwitch } from "../../shared/switch/CustomSwitch";

export const B2BCatalogHeadFilters = ({ isClientView, setIsClientView, handleChangeFilter }) => {
  const { t } = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState("created_desc");

  const handleSearchChange = (e) => {
    const value = e.target.value;
    handleChangeFilter("search", value);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleOpenMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleSortChange = (event) => {
    const value = event.target.value;
    setSelectedOrder(value);

    const [field, order] = value.split("_");
    const updatedFilters = {
      asc: order === "asc" ? [field] : [],
      desc: order === "desc" ? [field] : [],
    };
    handleChangeFilter("order", updatedFilters);
    handleCloseMenu();
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{
        color: "var(--primary-color)",
        alignItems: "center",
      }}
    >
      <Grid item xs={12} sm={6}>
        <SearchFilter onSearchChange={debounceSearch} />
      </Grid>
      <Grid
        item
        container
        xs={6}
        sm={4}
        sx={{
          width: "fit-content",
          alignItems: "center",
          gap: 1,
          textTransform: "capitalize",
        }}
      >
        <Typography>{t("VIEW_FOR_CLIENTS")}</Typography>
        <CustomSwitch
          checked={isClientView}
          handleChange={(e) => setIsClientView(e.target.checked)}
        />
      </Grid>
      <Grid item xs={6} sm={2}>
        <Button
          variant="outlined"
          sx={{
            color: "var(--secondary-color)",
            borderColor: "var(--secondary-color)",
            "&:hover": {
              color: "var(--secondary-color)",
              borderColor: "var(--secondary-color)",
              backgroundColor: "var(--grey-cancelled)",
            },
          }}
          startIcon={<SwapVertOutlined />}
          onClick={handleOpenMenu}
        >
          {t("ORDER")}
        </Button>
        <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleCloseMenu}>
          <RadioGroup value={selectedOrder} onChange={handleSortChange} sx={{ padding: 1 }}>
            <FormControlLabel
              value="created_desc"
              control={<Radio className="radio-oniria" />}
              label={t("NEWS")}
            />
            <FormControlLabel
              value="name_asc"
              control={<Radio className="radio-oniria" />}
              label={t("NAME_A_Z")}
            />
            <FormControlLabel
              value="name_desc"
              control={<Radio className="radio-oniria" />}
              label={t("NAME_Z_A")}
            />
            <FormControlLabel
              value="price_asc"
              control={<Radio className="radio-oniria" />}
              label={t("PRICE_LOW_HIGH")}
            />
            <FormControlLabel
              value="price_desc"
              control={<Radio className="radio-oniria" />}
              label={t("PRICE_HIGH_LOW")}
            />
          </RadioGroup>
        </Menu>
      </Grid>
    </Grid>
  );
};
