import { ChevronLeft, ChevronRight, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  List,
  Typography,
  styled,
} from "@mui/material";
import { cloneElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LOGOS } from "../../constants/variables";
import MENU_CONFIG from "./menuConfig.jsx";
import WebBarItem from "./WebBarItem";

const DrawerHeaderSideBar = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const WebSideBar = ({ open, setOpen, isMobile }) => {
  const { t } = useTranslation();
  const { roles } = useSelector((state) => state.userInfo);

  const renderMenuItem = (item, key) => {
    const hasAccess =
      item.roles?.includes("ALL") || item.roles?.some((role) => roles?.includes(role));
    if (!hasAccess) return null;

    if (item.subItems) {
      return (
        <Accordion
          key={key}
          sx={{
            backgroundColor: "var(--secondary-color)",
            color: "#fff",
            border: 0,
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore sx={{ color: "#fff" }} />}>
            {item.icon && typeof item.icon === "string" && (
              <Box
                component="img"
                alt={t(item.title)}
                src={item.icon}
                sx={{ height: "25px", width: "25px", color: "#fff" }}
              />
            )}
            {item.icon && typeof item.icon !== "string" && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {cloneElement(item.icon, { sx: { height: "25px", width: "25px", color: "#fff" } })}
              </Box>
            )}
            {open && <Typography ml={2}>{t(item.title)}</Typography>}
          </AccordionSummary>
          <AccordionDetails>
            {item.subItems.map((subItem, subIndex) =>
              renderMenuItem(subItem, `${key}-${subIndex}`)
            )}
          </AccordionDetails>
        </Accordion>
      );
    }

    return <WebBarItem key={key} item={item} isOpen={open} />;
  };

  return (
    <>
      {!isMobile && (
        <DrawerHeaderSideBar
          sx={{
            p: 3,
            mr: 2,
            color: "white",
            maxHeight: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              mr: 2,
              color: "white",
              maxHeight: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "80%",
            }}
            component="img"
            alt="Logo"
            src={LOGOS.PRINCIPAL_WHITE}
          />
          <IconButton onClick={() => setOpen(!open)}>
            {open ? (
              <ChevronLeft sx={{ color: "white" }} />
            ) : (
              <ChevronRight sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeaderSideBar>
      )}
      <Divider />
      <List>{MENU_CONFIG.map((item, index) => renderMenuItem(item, `menu-item-${index}`))}</List>
    </>
  );
};

export default WebSideBar;
