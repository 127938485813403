import { TrendingDown, TrendingUp } from "@mui/icons-material";
import { Box, Card, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const SalesCard = ({
  title,
  subtitle,
  amount,
  percentage,
  icon,
  color = "#FFD700",
  iconUrl,
}) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        maxWidth: 400,
        borderRadius: 2,
        boxShadow: 3,
        paddingX: 5,
        paddingY: 2,
        backgroundColor: "#fff",
      }}
    >
      <Box display="flex" alignItems="center" mb={1}>
        <Box
          sx={{
            backgroundColor: color,
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            marginRight: 1.5,
          }}
        >
          {iconUrl && <img src={iconUrl} alt="icon" style={{ width: "80%", height: "80%" }} />}
          {icon}
        </Box>
        <Box>
          <Typography fontSize={20} fontWeight="bold">
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body2" color="textSecondary" mb={1}>
              {t("IN_THIS_MONTH")}
            </Typography>
          )}
        </Box>
      </Box>

      <Typography variant="h4" fontWeight="bold" color="textPrimary" textAlign="center">
        {amount}
      </Typography>

      {percentage && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            mt={1}
            display="flex"
            alignItems="center"
            sx={{
              backgroundColor: percentage > 0 ? "#4CAF50" : "#D32F2F",
              color: "var(--white)",
              padding: "4px 8px",
              borderRadius: 1,
              width: "fit-content",
            }}
          >
            {percentage > 0 ? (
              <TrendingUp sx={{ fontSize: 16, marginRight: 0.5 }} />
            ) : (
              <TrendingDown sx={{ fontSize: 16, marginRight: 0.5 }} />
            )}
            <Typography variant="body2" fontWeight="bold">
              {percentage > 0 ? "+" : ""}
              {percentage}%
            </Typography>
          </Box>
          <Typography variant="caption" color="textSecondary" mt={1} sx={{ marginTop: 1 }}>
            {t("SINCE_LAST_MONTH")}
          </Typography>
        </Box>
      )}
    </Card>
  );
};
