import { Add } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getTourPointCoordinates } from "../../../../../constants/utils";
import useQueryTourPointsList from "../../../../../hooks/queries/tourPoints/useQueryTourPointsList";
import CustomDialog from "../../../../shared/CustomDialog";
import RouteMap from "../../../../shared/Map/routeMap/RouteMap";
import { FormFieldHeader } from "../../../FormFieldHeader";
import TripItinerary from "./TripItinerary";

const TripItineraries = () => {
  const { t } = useTranslation();
  const { watch, setValue, getValues } = useFormContext();

  const [openDelete, setOpenDelete] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const itineraries = watch("itineraries", []);
  const { data: tourPoints = [], refetch: refetchTourPoints } = useQueryTourPointsList();

  const handleSetItineraryPositions = ({ destination, source }) => {
    if (!destination) return;
    const newItineraries = itineraries;
    const [removed] = newItineraries.splice(source.index, 1);
    newItineraries.splice(destination.index, 0, removed);

    const updatedItineraries = newItineraries.map((itinerary, index) => ({
      ...itinerary,
      position: index,
    }));

    setValue("itineraries", updatedItineraries);
  };

  const handleRemoveItinerary = () => {
    const itineraryToRemove = itineraries[selectedIndex];
    const transportToRemove = itineraryToRemove?.transport_ids || [];

    const updatedItineraries = itineraries
      ?.filter((_, idx) => idx !== selectedIndex)
      ?.map((iti, index) => ({
        ...iti,
        position: index,
        transport_ids: iti?.transport_ids?.filter(
          (transportId) => !transportToRemove.includes(transportId)
        ),
      }));

    setValue("itineraries", updatedItineraries);
    setSelectedIndex(null);
    setOpenDelete(false);
  };

  const handleAddItinerary = () => {
    const prev = getValues("itineraries") || [];
    const defaultLanguage = getValues("default_language");

    const eventLanguages = getValues("event_language_infos") || [];
    const itineraryLanguages = eventLanguages.map((language) => ({
      language: language.language,
      title: "",
      description: "",
      subtitle: "",
      itinerary_id: null,
      id: null,
    }));

    setValue("itineraries", [
      ...prev,
      {
        title: "",
        subtitle: "",
        description: "",
        position: prev.length,
        language: defaultLanguage,
        itinerary_language_infos: itineraryLanguages,
      },
    ]);
  };

  const handleOpenDelete = (e, index) => {
    e.stopPropagation();
    setSelectedIndex(index);
    setOpenDelete(true);
  };

  const itinerariesCoordinates = itineraries?.map((itinerary) =>
    getTourPointCoordinates(itinerary.tour_point_id, tourPoints)
  );

  return (
    <Grid item xs={12} container>
      <Grid item xs={8} pl={2} mb={2}>
        <Box mb={2}>
          <FormFieldHeader title={t("ROUTE")} description={t("ROUTE_DESCRIPTION")} />
        </Box>
        <DragDropContext onDragEnd={handleSetItineraryPositions}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%" }}
              >
                {itineraries.map((_, index) => (
                  <TripItinerary
                    key={`trip-itinerary-${index}`}
                    index={index}
                    handleOpenDelete={handleOpenDelete}
                    tourPoints={tourPoints}
                    refetchTourPoints={refetchTourPoints}
                    isSimpleView
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Button
          variant="outlined"
          size="medium"
          sx={{
            width: "fit-content",
            borderRadius: 3,
            mt: 2,
          }}
          className="oniria-border-btn"
          onClick={handleAddItinerary}
          startIcon={<Add sx={{ color: "var(--oniria-gold)" }} />}
        >
          {t("ADD_POINT")}
        </Button>
      </Grid>
      {/* MAP WITH THE ROUTES */}
      <Grid item xs={4} pl={2} mb={2}>
        <RouteMap coordinates={itinerariesCoordinates} />
      </Grid>
      <CustomDialog
        isOpen={openDelete}
        onClose={() => setOpenDelete(false)}
        title={t("DELETE_ITINERARY")}
        content={t("DELETE_ITINERARY_CONFIRM")}
        onAccept={handleRemoveItinerary}
      />
    </Grid>
  );
};

export default TripItineraries;
