import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatDate, getEventName } from "../../../constants/utils";
import { useQueryAllEventsList } from "../../../hooks/queries/events/useQueryAllEventsList";

export const RequestsTable = ({ data }) => {
  const { t } = useTranslation();

  const { data: events = [] } = useQueryAllEventsList();

  return (
    <>
      <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
        <Table sx={{ whiteSpace: "nowrap" }}>
          <TableHead>
            <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
              <TableCell>{t("NAME")}</TableCell>
              <TableCell>{t("PHONE")}</TableCell>
              <TableCell>{t("EMAIL")}</TableCell>
              <TableCell>{t("MESSAGE")}</TableCell>
              <TableCell>{t("EVENT")}</TableCell>
              <TableCell>{t("CREATED")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.message}</TableCell>
                <TableCell>{getEventName(row.event_id, events)}</TableCell>
                <TableCell>{formatDate(row.created_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
