import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Collapse, IconButton, Slider, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MUI_CUSTOM_SLIDER } from "../../../constants/styles";
import { B2B_FILTERS_VALUES } from "../../../constants/variables";
import { useQueryTagsList } from "../../../hooks/queries/tags/useQueryTagsList";
import useQueryTourPointsList from "../../../hooks/queries/tourPoints/useQueryTourPointsList";
import { useQueryPremisesList } from "../../../hooks/queries/useQueryPremisesList";
import SelectFilter from "../../events/filters/filter/SelectFilter";
import { DateRangePicker } from "../../shared/DateRangePicker";
import InfoToolTip from "../../shared/InfoToolTip";

export const B2BCatalogsFilter = ({ filters, handleChangeFilter, isCollapsed, toggleCollapse }) => {
  const { t } = useTranslation();
  const { catalogType, fromDate, toDate, tourPointId, premiseId } = filters;

  const { data: tourPoints } = useQueryTourPointsList();
  const tourPointsOptions =
    tourPoints?.map((tourPoint) => ({
      id: tourPoint.id,
      name: tourPoint.name,
    })) || [];

  const { data: premises } = useQueryPremisesList();
  const premisesOptions =
    premises?.map((premise) => ({
      id: premise.id,
      name: premise.name,
    })) || [];

  const { data: tags } = useQueryTagsList();

  const CATALOG_TYPES_OPTIONS = [
    { id: "all", name: t("CATALOG_ALL") },
    { id: "available", name: t("AVAILABLE") },
    { id: "contact", name: t("NEED_CONTACT") },
  ];
  const { MIN_PRICE, MAX_PRICE, MIN_DAYS, MAX_DAYS } = B2B_FILTERS_VALUES;

  const priceMarks = [
    {
      value: MIN_PRICE,
      label: "0€",
    },
    {
      value: MAX_PRICE,
      label: `${MAX_PRICE}€`,
    },
  ];
  const daysMarks = [
    {
      value: MIN_DAYS,
      label: "0",
    },
    {
      value: MAX_DAYS,
      label: `${MAX_DAYS}`,
    },
  ];

  // Show / hide all tags
  const [showAllTags, setShowAllTags] = useState(false);
  const displayedTags = showAllTags ? tags : tags?.slice(0, 5);

  const toggleShowAllTags = () => {
    setShowAllTags((prev) => !prev);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRight: isCollapsed ? "none" : "1px solid #ccc",
        transition: "width 0.3s ease",
        overflow: "hidden",
        padding: "0 5px",
        color: "var(--secondary-color)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "8px",
        }}
      >
        <InfoToolTip text={isCollapsed ? t("SHOW_FILTERS") : t("HIDE_FILTERS")}>
          <IconButton onClick={toggleCollapse} size="small">
            {isCollapsed ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
          </IconButton>
        </InfoToolTip>
      </Box>

      <Collapse
        orientation="vertical"
        in={!isCollapsed}
        sx={{
          flexGrow: 1,
          padding: isCollapsed ? "0" : "16px",
        }}
      >
        <Typography variant="h6" mb={2}>
          {t("ADVANCED_FILTER")}
        </Typography>

        <SelectFilter
          value={catalogType}
          onChange={(value) => handleChangeFilter("catalogType", value)}
          options={CATALOG_TYPES_OPTIONS}
          fullWidth
        />

        <DateRangePicker
          text={t("DEPARTURE_DATE")}
          endDate={toDate}
          startDate={fromDate}
          handleChangeStartDate={(value) => handleChangeFilter("fromDate", value)}
          handleChangeEndDate={(value) => handleChangeFilter("toDate", value)}
        />

        <SelectFilter
          value={tourPointId}
          onChange={(value) => handleChangeFilter("tourPointId", value)}
          options={[{ id: "all", name: t("TOUR_POINT") }, ...tourPointsOptions]}
          fullWidth
        />

        <Typography variant="body1" mb={4} mt={2}>
          {t("PRICE_WITHOUT")}:
        </Typography>
        <Slider
          defaultValue={[0, MAX_PRICE]}
          min={0}
          max={MAX_PRICE}
          sx={MUI_CUSTOM_SLIDER}
          marks={priceMarks}
          size="small"
          valueLabelDisplay="auto"
          onChange={(_, value) => handleChangeFilter("price", value)}
        />
        <Typography variant="body1" mb={4}>
          {t("DAYS_QUANTITY")}:
        </Typography>
        <Slider
          defaultValue={[0, MAX_DAYS]}
          min={0}
          max={MAX_DAYS}
          marks={daysMarks}
          sx={MUI_CUSTOM_SLIDER}
          size="small"
          valueLabelDisplay="auto"
          onChange={(_, value) => handleChangeFilter("days", value)}
        />

        <SelectFilter
          value={premiseId}
          onChange={(value) => handleChangeFilter("premiseId", value)}
          options={[{ id: "all", name: t("PREMISES_ALL") }, ...premisesOptions]}
          fullWidth
        />

        {/* <Typography variant="body1" mt={2}>
          Tags
        </Typography>
        <Box display="flex" flexDirection="column">
          {displayedTags?.map((tag) => (
            <FormControlLabel
              key={tag.id}
              control={
                <Checkbox
                  value={tag.id}
                  onChange={(e) => handleChangeFilter("tags", e.target.checked ? tag.id : null)}
                  className="checkbox-oniria"
                />
              }
              label={tag.name}
            />
          ))}
        </Box> */}

        {/* {tags && tags.length > 5 && (
          <Button
            variant="text"
            fullWidth
            onClick={toggleShowAllTags}
            sx={{
              mt: 2,
              color: "var(--oniria-gold)",
              textDecoration: "underline",
              textTransform: "capitalize",
            }}
          >
            {showAllTags ? t("SHOW_LESS_TAGS") : t("SHOW_ALL_TAGS")}
          </Button>
        )} */}
      </Collapse>
    </Box>
  );
};
