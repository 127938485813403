import { FormControl, MenuItem, Select } from "@mui/material";
import { focusColor } from "../../../shared/textFieldStyle";

const SelectFilter = (props) => {
  const { value, onChange, options, ...rest } = props;

  return (
    <FormControl sx={[focusColor, { minWidth: 120 }]} size="small" {...rest}>
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        sx={{ fontSize: "12px", borderRadius: 3 }}
      >
        {options?.map((option, index) => (
          <MenuItem key={`${option.id}-${index}`} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectFilter;
