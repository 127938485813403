import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Register from "../components/account/Register";
import Organization from "../components/account/Organization";
import Premise from "../components/account/Premise";
import LegalPerson from "../components/account/LegalPerson";
import Signature from "../components/account/Signature";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import { createAccount, createAccountErrors } from "../classes/createAccountClass";
import { serviceCreateAccount } from "../services/authenticationServices";
import { getBicFromIban } from "../services/utilsServices";
import { useNavigate } from "react-router-dom";
import { transformObjectWithUrls } from "../components/shared/FormsValidator";
import { isValidIBANNumber } from "../components/shared/FormsValidator";
import { LOGOS } from "../constants/variables";

const CreateAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const steps = [
    t("REGISTRATION"),
    t("ORGANIZATION"),
    t("LEGAL_PERSON"),
    t("SITE"),
    t("SIGNATURE"),
  ];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [formData, setFormData] = useState(createAccount);
  const [formParamsErrors, setFormParamsErrors] = useState(createAccountErrors);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const setFormParams = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    object[field] = e.target[atribute];
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };
  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };
  const setFormErrors = (objectName, field, value) => {
    let object = formParamsErrors[objectName];
    object[field] = value;
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };
  const checkIban = (classForm, e) => {
    let error = "";
    if (e.target.value === "") {
      error = "empty";
    } else {
      error = isValidIBANNumber(e.target.value) ? "" : t("INCORRECT");
      // BIC
      if (error === "") {
        getBicFromIban(e.target.value).then((result) => {
          // If the result returns a BIC...
          if (result.bankData.bic) {
            setFormParamsNotEvent("organization", "bic", result.bankData.bic);
          }
          // And if not IBAN as BIC
          else {
            setFormParamsNotEvent("organization", "bic", result.iban);
          }
        });
      }
    }
    setFormErrors(classForm, "iban", error);
    setFormParams(classForm, "iban", e);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleSubmit = async () => {
    const organizationToSend = await transformObjectWithUrls(formData.organization);
    const userToSend = await transformObjectWithUrls(formData.user);
    const premiseToSend = await transformObjectWithUrls(formData.premise);
    organizationToSend.vat_number = organizationToSend.country + organizationToSend.cif;
    // premiseToSend.is_international = (premiseToSend.is_international === "true")
    premiseToSend.latitude =
      !isNaN(premiseToSend.latitude) && premiseToSend.latitude !== "" ? premiseToSend.latitude : 0;
    premiseToSend.longitude =
      !isNaN(premiseToSend.longitude) && premiseToSend.longitude !== ""
        ? premiseToSend.longitude
        : 0;

    premiseToSend.vat_number = premiseToSend.country + premiseToSend.cif;
    premiseToSend.week_schedule = JSON.stringify(premiseToSend.week_schedule);
    const bodyToSend = {
      user: userToSend,
      organization: organizationToSend,
      premise: premiseToSend,
      grouping_name: "tikitup",
    };
    await serviceCreateAccount(bodyToSend);
    navigate("/account_success");
  };
  const isFirstStep = () => {
    return activeStep === 0;
  };
  const isLastStep = () => {
    return activeStep === 4;
  };
  const formController = {
    formData,
    formParams: setFormParams,
    setFormParamsNotEvent,
    next: handleNext,
    back: handleBack,
    validation: formParamsErrors,
    setFormErrors,
    isFirstStep,
    isLastStep,
    finish: handleSubmit,
    checkIban,
  };

  const addComponent = (activeStep) => {
    return activeStep === 0 ? (
      <Register formController={formController} />
    ) : activeStep === 1 ? (
      <Organization
        title={t("ORGANIZATION_TITLE")}
        description={t("ORGANIZATION_DESCRIPTION")}
        formController={formController}
      />
    ) : activeStep === 2 ? (
      <LegalPerson
        title={t("LEGAL_PERSON_TITLE")}
        description={t("LEGAL_PERSON_DESCRIPTION")}
        formController={formController}
      />
    ) : activeStep === 3 ? (
      <Premise
        title={t("CREATE_FIRST_PREMISE")}
        description={t("PREMISE_DESCRIPTION")}
        formController={formController}
      />
    ) : (
      <Signature formController={formController} />
    );
  };

  return (
    <>
      <Grid
        container
        sx={{ mt: 5, mb: 12, display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <Grid item xs={11} sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{ mb: "auto", cursor: "pointer", maxWidth: "150px" }}
            onClick={() => {
              navigate("/login");
            }}
            component="img"
            alt="Logo"
            src={LOGOS.PRINCIPAL_WHITE}
          />
          <Typography
            variant="subtitle1"
            sx={{
              mt: 5,
              ml: 1,
              cursor: "pointer",
              transition: "font-weight 0.3s, font-size 0.3s",
              "&:hover": {
                fontWeight: "bold",
                fontSize: "1.1rem",
              },
            }}
            onClick={() => {
              navigate("/login");
            }}
          >
            {t("GO_LOGIN")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7} md={9} lg={9}>
          <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Grid item xs={12} sm={9} md={6}>
              {!isMobile ? (
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ color: "white", m: 1 }}>
                      <ArrowBackIos />
                    </Button>
                  )}
                  <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      if (isStepSkipped(index)) {
                        stepProps.completed = false;
                      }
                      return (
                        <Step
                          sx={{
                            "& .MuiStepLabel-root .Mui-disabled": { color: "white" },
                            "& .MuiStepLabel-root .Mui-completed": {
                              color: "white",
                              opacity: "100%",
                              fontWeight: "bolder",
                            },
                            "& .MuiStepLabel-root .Mui-active": {
                              color: "white",
                              opacity: "100%",
                              fontWeight: "bolder",
                            },
                            "& .MuiSvgIcon-root": { color: "white" },
                            "& .MuiSvgIcon-root, .Mui-disabled": { opacity: "80%" },
                            "& .MuiStepIcon-text ": { fill: "var(--secondary-color)" },
                            mx: 4,
                          }}
                          key={label}
                          {...stepProps}
                        >
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
              ) : (
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ color: "white" }}>
                      <ArrowBackIos />
                    </Button>
                  )}
                  <Stepper connector activeStep={activeStep} sx={{ display: "block", mt: 0.5 }}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      if (isStepSkipped(index)) {
                        stepProps.completed = false;
                      }
                      return (
                        <Step
                          sx={{
                            "& .MuiStepLabel-root .Mui-disabled": { display: "none" },
                            "& .MuiStepLabel-root .Mui-completed": { display: "none" },
                            "& .MuiStepLabel-root .Mui-active": {
                              color: "white",
                              opacity: "100%",
                              fontWeight: "bolder",
                            },
                            "& .MuiSvgIcon-root, .Mui-disabled": { opacity: "80%" },
                            "& .MuiStepIcon-text ": { fill: "var(--secondary-color)" },
                            mx: 4,
                          }}
                          key={label}
                          {...stepProps}
                        >
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  {activeStep !== 4 && (
                    <Button sx={{ color: "white" }} onClick={handleNext}>
                      <ArrowForwardIosIcon />
                    </Button>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
          {addComponent(activeStep)}
        </Grid>
      </Grid>
    </>
  );
};

export default CreateAccount;
