import { Add, DeleteOutlineOutlined, DiscountOutlined } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { get, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { formatDate, hasDatePassed, sanitizeBoolean } from "../../../constants/utils";
import { useTripLanguage } from "../../../context/TripLanguageContext";
import { DEFAULT_TICKET } from "../../../schemas/trips";
import { deleteTicket } from "../../../services/eventsServices";
import CustomDialog from "../../shared/CustomDialog";
import { FormHeader } from "../../shared/FormHeader";
import { StyledAccordion } from "../../shared/StyledAccordion";
import { VisibleSwitch } from "../../shared/switch/VisibleSwitch";
import { focusColor } from "../../shared/textFieldStyle";
import { toastMessageError } from "../../shared/toastMessage";
import { TicketDiscountDialog } from "./TicketDiscountDialog";
import TripTicketConfig from "./TripTicketConfig";

const TripTickets = () => {
  const { t } = useTranslation();
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    index: null,
  });
  const [discountDialog, setDiscountDialog] = useState({
    open: false,
    index: null,
  });

  const {
    watch,
    setValue,
    getValues,
    formState: { errors },
    register,
    clearErrors,
  } = useFormContext();

  const dates = watch("dates", []);
  const tickets = watch("tickets", []);
  const { getTicketFieldName } = useTripLanguage();

  const handleOpenConfirm = (e, ticketIndex) => {
    e.stopPropagation();
    e.preventDefault();
    setConfirmDialog({ open: true, index: ticketIndex });
  };

  const handleCloseConfirm = () => {
    setConfirmDialog({ open: false, index: null });
  };

  const handleConfirmDelete = async () => {
    const { index } = confirmDialog;
    const tickets = getValues("tickets") || [];
    if (tickets[index]?.id) {
      try {
        await deleteTicket(tickets[index].id);
      } catch (error) {
        const errorMessage = error?.response?.data?.error || t("ERROR");
        toastMessageError(errorMessage);
        handleCloseConfirm();
        return;
      }
    }

    const updatedTickets = [...tickets]
      ?.filter((_, i) => i !== Number(index))
      ?.map((prev, i) => ({
        ...prev,
        position: i,
      }));
    setValue("tickets", updatedTickets);
    handleCloseConfirm();
  };

  const handleAddTicket = (index) => {
    const prev = getValues("tickets") || [];
    const defaultLanguage = getValues("default_language", "ES");
    const date = dates[index];
    const pvp = getValues("pvp") || 0;
    const ticketName = `${formatDate(date.start_date)}-${formatDate(date.end_date)}`;

    const eventLanguages = getValues("event_language_infos") || [];
    const ticketLanguages = eventLanguages.map((language) => ({
      language: language.language,
      id: null,
      ticket_id: null,
      name: ticketName,
      description: "",
      tickets_policy: "",
    }));

    setValue("tickets", [
      ...prev,
      {
        ...DEFAULT_TICKET,
        default_language: defaultLanguage,
        price: pvp,
        name: ticketName,
        start_date: `${date.start_date}T${date.start_date_time}Z`,
        end_date: `${date.end_date}T${date.end_date_time}Z`,
        position: prev.length,
        ticket_language_infos: ticketLanguages,
        initial_stock: 1,
      },
    ]);

    clearErrors("tickets");
  };

  const handleOpenDiscount = (e, index) => {
    e.stopPropagation();
    setDiscountDialog({ open: true, index: index });
  };

  const handleCloseDiscountDialog = () => {
    setDiscountDialog({ open: false, index: null });
  };

  useEffect(() => {
    if (!dates || dates.length === 0) return;

    const tickets = getValues("tickets") || [];
    const pvp = getValues("pvp") || 0;
    const eventLanguages = getValues("event_language_infos") || [];

    const ticketLanguages = eventLanguages.map((language) => ({
      language: language.language,
      id: null,
      ticket_id: null,
      name: "",
      description: "",
      tickets_policy: "",
    }));

    const updatedTickets = tickets.map((ticket) => ({
      ...ticket,
      start_date: ticket.start_date ?? `${dates[0].start_date}T${dates[0].start_date_time}Z`,
      end_date: ticket.end_date ?? `${dates[0].end_date}T${dates[0].end_date_time}Z`,
    }));

    const newTickets = dates
      .filter(
        (date) =>
          !updatedTickets.some((ticket) => ticket.start_date?.split("T")[0] === date.start_date)
      )
      .map((date, index) => {
        const ticketName = `${formatDate(date.start_date)}-${formatDate(date.end_date)}`;
        return {
          ...DEFAULT_TICKET,
          price: pvp,
          name: ticketName,
          start_date: `${date.start_date}T${date.start_date_time}Z`,
          end_date: `${date.end_date}T${date.end_date_time}Z`,
          position: updatedTickets.length + index,
          initial_stock: 1,
          ticket_language_infos: ticketLanguages.map((lang) => ({ ...lang, name: ticketName })),
        };
      });

    setValue("tickets", [...updatedTickets, ...newTickets]);
    clearErrors("tickets");
  }, [dates]);

  return sanitizeBoolean(watch("app_contact_form")) ? null : (
    <>
      {errors?.tickets && errors?.tickets?.message && (
        <Typography sx={{ color: "var(--red-error)", mb: 4 }}>
          *{t(errors?.tickets?.message)}
        </Typography>
      )}

      {dates
        .sort((a, b) => new Date(a.end_date) - new Date(b.end_date))
        .map((date, index) => (
          <FormHeader
            key={`tickets-${index}`}
            title={`${t("TICKETS")} ${formatDate(date.start_date)}-${formatDate(date.end_date)}`}
            description={t("TICKETS_DESCRIPTION")}
            isAccordion
            backgroundColor={
              hasDatePassed(date.end_date) ? "var(--red-error-light)" : "var(--grey-background)"
            }
            ticketPassedDate={hasDatePassed(date.end_date)}
          >
            {tickets
              .filter((ticket) => ticket.start_date?.split("T")[0] === date.start_date)
              .sort((a, b) => a.position - b.position)
              .map((ticket) => {
                const ticketIndex = ticket.position;
                const ticketName = getTicketFieldName("name", ticketIndex);

                return (
                  <Grid
                    key={`ticket-${ticketIndex}-${date.start_date}`}
                    sx={{
                      mt: 2,
                      border: "1px solid #E4E4E4",
                      width: "100%",
                      padding: 2,
                      borderRadius: 2,
                    }}
                  >
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography fontWeight="600">{t("NAME")}</Typography>
                        <TextField
                          key={ticketName}
                          {...register(ticketName)}
                          error={!!errors?.[ticketName]}
                          helperText={t(errors?.[ticketName]?.message)}
                          sx={focusColor}
                          placeholder={t("NAME")}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Typography fontWeight="600">{t("STOCK")}</Typography>
                        <TextField
                          placeholder={"00"}
                          {...register(`tickets.${ticketIndex}.initial_stock`)}
                          error={!!errors?.tickets?.[ticketIndex]?.initial_stock}
                          helperText={t(errors?.tickets?.[ticketIndex]?.initial_stock?.message)}
                          sx={focusColor}
                          type="number"
                          inputProps={{ min: 0 }}
                          required
                          onWheel={(e) => e.target.blur()}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Typography fontWeight="600">{t("PRICE")}</Typography>
                        <TextField
                          placeholder={t("PRICE")}
                          {...register(`tickets.${ticketIndex}.price`)}
                          error={!!errors?.tickets?.[ticketIndex]?.price}
                          helperText={t(errors?.tickets?.[ticketIndex]?.price?.message)}
                          sx={focusColor}
                          type="number"
                          inputProps={{ min: 0 }}
                          required
                          onWheel={(e) => e.target.blur()}
                        />
                      </Grid>
                      <Grid item xs={3} container justifyContent="flex-end">
                        <Box
                          sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
                        >
                          <Typography variant="caption">{t("TICKET_VISIBLE")}</Typography>
                          <VisibleSwitch
                            checked={watch(`tickets.${ticketIndex}.is_visible`)}
                            handleChange={(e) =>
                              setValue(`tickets.${ticketIndex}.is_visible`, e.target.checked)
                            }
                          />
                        </Box>
                        <IconButton
                          sx={{ mt: 2 }}
                          onClick={(e) => handleOpenDiscount(e, ticketIndex)}
                        >
                          <DiscountOutlined sx={{ color: "var(--oniria-gold)" }} />
                        </IconButton>
                        <IconButton
                          onClick={(e) => handleOpenConfirm(e, ticketIndex)}
                          sx={{ mt: 2 }}
                        >
                          <DeleteOutlineOutlined sx={{ color: "var(--oniria-red)" }} />
                        </IconButton>
                      </Grid>
                      <StyledAccordion title={t("ADVANCED_OPTIONS")}>
                        <TripTicketConfig index={ticketIndex} />
                      </StyledAccordion>
                    </Grid>
                  </Grid>
                );
              })}

            <Grid item xs={12}>
              <Button
                variant="outlined"
                size="small"
                className="oniria-border-btn"
                onClick={() => handleAddTicket(index)}
                startIcon={<Add />}
                sx={{ borderRadius: 3, textTransform: "capitalize" }}
              >
                {t("CREATE_NEW_TICKET")}
              </Button>
            </Grid>
          </FormHeader>
        ))}

      <TicketDiscountDialog
        isOpen={discountDialog.open}
        onClose={handleCloseDiscountDialog}
        index={discountDialog.index}
      />

      <CustomDialog
        isOpen={confirmDialog.open}
        onClose={handleCloseConfirm}
        title={t("CONFIRM_DELETE_TICKET")}
        content={t("")}
        onAccept={handleConfirmDelete}
      />
    </>
  );
};

export default TripTickets;
