import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createAccount, createAccountErrors } from "../../classes/createAccountClass";
import Header from "../../components/Header";
import LegalPerson from "../../components/account/LegalPerson";
import Organization from "../../components/account/Organization";
import Premise from "../../components/account/Premise";
import { isValidIBANNumber, transformObjectWithUrls } from "../../components/shared/FormsValidator";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { createOrganization } from "../../services/organizationsServices";
import { getBicFromIban } from "../../services/utilsServices";

const CreateOrganization = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("ENTITIES"),
      link: "/organizations",
    },
    {
      name: t("CREATE_ENTITY"),
      link: "/create_organization",
    },
  ];

  const steps = [t("ORGANIZATION"), t("LEGAL_PERSON"), t("SITE")];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(createAccount)));
  const [formParamsErrors, setFormParamsErrors] = useState(
    JSON.parse(JSON.stringify(createAccountErrors))
  );
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  function setFormParams(objectName, field, e, atribute = "value") {
    let object = formData[objectName];
    object[field] = e.target[atribute];
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  }

  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrors = (objectName, field, value) => {
    let object = formParamsErrors[objectName];
    object[field] = value;
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const checkIban = (classForm, e) => {
    let error = "";
    if (e.target.value === "") {
      error = "empty";
    } else {
      error = isValidIBANNumber(e.target.value) ? "" : t("INCORRECT");
      // BIC
      if (error === "") {
        getBicFromIban(e.target.value).then((result) => {
          // If the result returns a BIC...
          if (result.bankData.bic) {
            setFormParamsNotEvent(classForm, "bic", result.bankData.bic);
          }
          // And if not IBAN as BIC
          else {
            setFormParamsNotEvent(classForm, "bic", result.iban);
          }
        });
      }
    }
    setFormErrors(classForm, "iban", error);
    setFormParams(classForm, "iban", e);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    const organizationToSend = await transformObjectWithUrls(formData.organization);
    organizationToSend.grouping_id = userInfo.grouping_id ?? "";
    const userToSend = await transformObjectWithUrls(formData.user);
    const premiseToSend = await transformObjectWithUrls(formData.premise);
    organizationToSend.vat_number = organizationToSend.country + organizationToSend.cif;
    premiseToSend.is_international = premiseToSend.is_international === "true";
    premiseToSend.vat_number = premiseToSend.country + premiseToSend.cif;
    premiseToSend.week_schedule = JSON.stringify(premiseToSend.week_schedule);

    premiseToSend.latitude =
      !isNaN(premiseToSend.latitude) && premiseToSend.latitude !== "" ? premiseToSend.latitude : 0;
    premiseToSend.longitude =
      !isNaN(premiseToSend.longitude) && premiseToSend.longitude !== ""
        ? premiseToSend.longitude
        : 0;

    const bodyToSend = {
      user: userToSend,
      organization: organizationToSend,
      premise: premiseToSend,
    };

    try {
      const response = await createOrganization(bodyToSend);
      if (response === "success" && response !== undefined) {
        toastMessageSuccess(t("EDIT_SUCCESS"));
        setTimeout(() => {
          navigate("/organizations");
        }, 1500);
      }
    } catch (error) {
      toastMessageError(t("EDIT_ERROR") + error.response.data.error);
    }
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === 2;
  };
  const formController = {
    formData,
    formParams: setFormParams,
    next: handleNext,
    back: handleBack,
    validation: formParamsErrors,
    setFormErrors,
    isFirstStep,
    isLastStep,
    finish: handleSubmit,
    setFormParamsNotEvent,
    checkIban,
  };

  const addComponent = (activeStep) => {
    return activeStep === 0 ? (
      <Organization formController={formController} />
    ) : activeStep === 1 ? (
      <LegalPerson formController={formController} />
    ) : (
      <Premise
        title={t("CREATE_FIRST_PREMISE")}
        description={t("PREMISE_DESCRIPTION")}
        formController={formController}
      />
    );
  };
  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_CREATE_ORGANIZATION")} />
      <Grid
        item
        xs={11}
        sm={12}
        sx={{
          mb: 5,
          display: "flex",
          flexDirection: "row",
          justifyContent: "initial",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            {!isMobile ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ color: "var(--secondary-color)" }}>
                    <ArrowBackIos fontSize="small" />
                  </Button>
                )}
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step
                        sx={{
                          "& .MuiStepLabel-root .Mui-disabled": {
                            color: "var(--secondary-color)",
                          },
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "var(--secondary-color)",
                          },
                          "& .MuiSvgIcon-root, .Mui-disabled": {
                            opacity: "80%",
                          },
                          "& .MuiStepIcon-text ": { fill: "white" },
                          mx: 2,
                        }}
                        key={label}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ color: "var(--secondary-color)" }}>
                    <ArrowBackIos fontSize="small" sx={{ color: "var(--secondary-color)" }} />
                  </Button>
                )}
                <Stepper connector activeStep={activeStep} sx={{ display: "block" }}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step
                        sx={{
                          "& .MuiStepLabel-root .Mui-disabled": {
                            display: "none",
                          },
                          "& .MuiStepLabel-root .Mui-completed": {
                            display: "none",
                          },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiSvgIcon-root, .Mui-disabled": {
                            opacity: "80%",
                          },
                          "& .MuiStepIcon-text ": { fill: "white" },
                          mx: 4,
                        }}
                        key={label}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep !== 2 && (
                  <Button sx={{ color: "var(--secondary-color)" }} onClick={handleNext}>
                    <ArrowForwardIosIcon />
                  </Button>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      {addComponent(activeStep)}
    </>
  );
};

export default CreateOrganization;
