import { formatDate } from "./utils";
import { ORDER_STATUS_OPTIONS, ORDER_STATUS_TYPES, PAYMENT_OPTIONS } from "./variables";

export const CSV_ORDERS_HEADERS = [
  "Evento",
  "Sede",
  "Dominio",
  "Cantidad",
  "Gastos Bliss2B",
  "Gastos gestión ",
  "Total",
  "Fecha",
  "Email",
  "Tipo de pago",
];

export const PURCHASE_TYPES = [
  { id: "event", name: "EVENT" },
  { id: "trip", name: "TRIP" },
  { id: "card", name: "CARD" },
];

export const ORDER_STATUS = [
  { id: "failed", name: "ORDER_FAILED" }, //rechazado por pasarela
  { id: "cancel", name: "ORDER_CANCEL" }, //timeout o rechazado por user
  { id: "parcial", name: "ORDER_PARCIAL" },
  { id: "payed_succesfull", name: "ORDER_PAYED_SUCCESFULL" }, // todos los pagados exitosos
  { id: "payed", name: "ORDER_PAYED" }, //pagado pero no terminado el evento
  { id: "finish", name: "ORDER_FINISH" }, //pagado y terminado el evento
  { id: "refund", name: "ORDER_STATUS_REFUNDED" },
];

export const PURCHASE_TYPE = {
  CARD: "card",
  REFUND: "refunded",
};

export const PAYMENT_METHODS = [
  { id: "app", name: "APP" },
  { id: "office", name: "OFFICE" },
  { id: "all_app", name: "ALL_APP" },
  { id: "all_office", name: "ALL_OFFICE" },
  { id: "app_redsys", name: "REDSYS" },
  { id: "app_paypal", name: "PAYPAL" },
  { id: "app_free", name: "FREE" },
  { id: "office_cash", name: "OFFICE_CASH" },
  { id: "office_card", name: "OFFICE_CARD" },
  { id: "office_guest", name: "OFFICE_GUEST" },
  { id: "office_bank_transfer", name: "OFFICE_TRANSFER_BANK" },
];

export const getOrderAmount = (order) => {
  if (isMultiplePayments(order)) {
    return order.payments?.reduce(
      (acc, payment) => acc + (payment.payed_at ? parseFloat(payment.amount) || 0 : 0),
      0
    );
  }
  return (parseFloat(order.amount) || 0) + (parseFloat(order.first_payment_amount) || 0);
};

export const getOrderCommission = (order) => {
  if (isMultiplePayments(order)) {
    return order.payments?.reduce(
      (acc, payment) => acc + (payment.payed_at ? parseFloat(payment.commission) || 0 : 0),
      0
    );
  }

  return (parseFloat(order.commission) || 0) + (parseFloat(order.first_payment_commission) || 0);
};

export const getOrderPremiseCommission = (order) => {
  if (isMultiplePayments(order)) {
    return order.payments?.reduce(
      (acc, payment) => acc + (payment.payed_at ? parseFloat(payment.premise_commission) || 0 : 0),
      0
    );
  }
  return (
    (parseFloat(order.premise_commission) || 0) +
    (parseFloat(order.first_payment_premise_commission) || 0)
  );
};

export const getOrderRefundAmount = (order) => {
  return parseFloat(order.refund_amount ?? "0");
};

export const getOrderTotalAmount = (order) => {
  if (order.payment_type === PAYMENT_OPTIONS.OFFICE_GUEST) {
    return 0;
  }
  const total =
    getOrderAmount(order) +
    getOrderCommission(order) +
    getOrderPremiseCommission(order) +
    getOrderRefundAmount(order);
  return total.toFixed(2);
};

export const getOrderFirstPaymentTotalAmount = (order) => {
  const total =
    parseFloat(order.first_payment_amount || 0) +
    parseFloat(order.first_payment_commission || 0) +
    parseFloat(order.first_payment_premise_commission || 0);
  return total.toFixed(2);
};
export const getOrderSecondPaymentTotalAmount = (order) => {
  if (order.payment_type === PAYMENT_OPTIONS.OFFICE_GUEST) {
    return 0;
  }
  const total =
    parseFloat(order.amount || 0) +
    parseFloat(order.commission || 0) +
    parseFloat(order.premise_commission || 0) +
    parseFloat(order.refund_amount || 0);
  return total.toFixed(2);
};

export const isOrderStatus = (order) => {
  if (isMultiplePayments(order)) {
    const allPaid = order.payments?.every((payment) => payment.payed_at);
    const allRefunded = order.payments?.some((payment) => payment.refunded_at);

    if (order.refund_status === ORDER_STATUS_OPTIONS.REFUNDED) return ORDER_STATUS_TYPES.REFUNDED;

    if (allPaid) return ORDER_STATUS_TYPES.PAYED;
    if (allRefunded) return ORDER_STATUS_TYPES.REFUNDED;
    return ORDER_STATUS_TYPES.PARCIAL;
  }

  if (order.type === ORDER_STATUS_OPTIONS.REFUND) return ORDER_STATUS_TYPES.REFUND;

  const hasFailedPayment =
    order.payment_type?.includes("failed") || order.first_payment_type?.includes("failed");
  if (hasFailedPayment) return ORDER_STATUS_TYPES.FAILED;

  if (order.payed_at)
    return order.refunded_at ? ORDER_STATUS_TYPES.REFUNDED : ORDER_STATUS_TYPES.PAYED;
  if (order.first_payment_payed_at) return ORDER_STATUS_TYPES.PARCIAL;

  return ORDER_STATUS_TYPES.CANCEL;
};

export const orderStatusColors = {
  [ORDER_STATUS_TYPES.REFUND]: "var(--blue-refund)",
  [ORDER_STATUS_TYPES.REFUNDED]: "var(--blue-refund)",
  [ORDER_STATUS_TYPES.PAYED]: "var(--green-payed)",
  [ORDER_STATUS_TYPES.CANCEL]: "var(--orange-error)",
  [ORDER_STATUS_TYPES.FAILED]: "var(--orange-error)",
  [ORDER_STATUS_TYPES.PARCIAL]: "var(--orange-parcial)",
};

export const isOrderTotalPayed = (order) => {
  if (isMultiplePayments(order)) return order?.payments?.every((payment) => payment.payed_at);
  return order.payed_at;
};

export const isOrderFailed = (order) => {
  return (
    order.payment_type?.includes("failed") ||
    order.first_payment_type?.includes("failed") ||
    order.payment_type?.includes("timeout") ||
    order.first_payment_type?.includes("timeout")
  );
};

export const isOrderTimeout = (order) => {
  return order.payment_type?.includes("timeout") || order.first_payment_type?.includes("timeout");
};
export const isOrderCancelled = (order) => {
  return !order.first_payment_type && !order.payment_type;
};

export const getOneMonthAgo = () => {
  let today = new Date();

  today.setMonth(today.getMonth() - 1);
  let year = today.getFullYear();
  let month = String(today.getMonth() + 1).padStart(2, "0");
  let day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const checkDates = (firstDate, secondDate) => {
  if (!secondDate) return true;
  if (!firstDate) return false;

  const date1 = new Date(firstDate);
  const date2 = new Date(secondDate);

  const utcDate1 = Date.UTC(date1.getUTCFullYear(), date1.getUTCMonth(), date1.getUTCDate());
  const utcDate2 = Date.UTC(date2.getUTCFullYear(), date2.getUTCMonth(), date2.getUTCDate());
  return utcDate1 <= utcDate2;
};

export const getOrdersPayedAmount = (orders, end_date) => {
  if (!orders) return null;
  let total = 0;
  orders.forEach((order) => {
    if (isMultiplePayments(order)) {
      order.payments.forEach((payment) => {
        if (payment.payed_at && checkDates(payment.payed_at, end_date)) {
          total += parseFloat(payment.amount || "0");
        }
      });
    } else if (order.payment_type === PAYMENT_OPTIONS.OFFICE_GUEST) {
      return;
    }
    if (order.first_payment_payed_at && checkDates(order.first_payment_payed_at, end_date)) {
      total += parseFloat(order.first_payment_amount || "0");
    }
    if (order.payed_at && checkDates(order.payed_at, end_date)) {
      total += parseFloat(order.amount || "0");
    }

    //Refunds
    if (order.refunded_at && order.refund_status === PURCHASE_TYPE.REFUND) {
      total += parseFloat(order.refund_amount || "0");
    }
  });
  return total;
};

export const getOrdersPayedTotalCommission = (orders, end_date) => {
  if (!orders) return null;
  let total = 0;
  orders.forEach((order) => {
    if (isMultiplePayments(order)) {
      order.payments.forEach((payment) => {
        if (payment.payed_at && checkDates(payment.payed_at, end_date)) {
          total += parseFloat(payment.commission || "0");
        }
      });
    } else if (order.first_payment_payed_at && checkDates(order.first_payment_payed_at, end_date)) {
      total += parseFloat(order.first_payment_commission || "0");
    }
    if (order.payed_at && checkDates(order.payed_at, end_date)) {
      total += parseFloat(order.commission || "0");
    }
  });
  return total;
};

export const getOrdersPayedPremiseCommission = (orders, end_date) => {
  if (!orders) return null;
  let total = 0;
  orders.forEach((order) => {
    if (isMultiplePayments(order)) {
      order.payment?.forEach((payment) => {
        if (payment.payed_at && checkDates(payment.payed_at, end_date)) {
          total += parseFloat(payment.premise_commission || "0");
        }
      });
    } else if (order.first_payment_payed_at && checkDates(order.first_payment_payed_at, end_date)) {
      total += parseFloat(order.first_payment_premise_commission || "0");
    }
    if (order.payed_at && checkDates(order.payed_at, end_date)) {
      total += parseFloat(order.premise_commission || "0");
    }
  });
  return total;
};

export const getOrdersTotalPayed = (orders, end_date) => {
  if (!orders) return null;
  let total = 0;
  total += getOrdersPayedAmount(orders, end_date);
  total += getOrdersPayedTotalCommission(orders, end_date);
  total += getOrdersPayedPremiseCommission(orders, end_date);
  return total;
};

export const getOrderPaymentDate = (order) => {
  if (!order) return null;
  if (isMultiplePayments(order)) {
    const date = order.payments?.find((payment) => payment.payed_at)?.payed_at;
    return date ? formatDate(date) : "";
  }

  return order.payed_at
    ? formatDate(order.payed_at)
    : order.first_payment_payed_at
    ? formatDate(order.first_payment_payed_at)
    : "";
};

//MULTIPLE - PAYMENTS
export const isMultiplePayments = (order) => {
  return order?.payments?.length > 1;
};

export const getPaymentTotal = (payment) => {
  if (!payment) return null;
  return Number(payment.amount) + Number(payment.commission) + Number(payment.premise_commission);
};

export const getOrderPaymentType = (order) => {
  if (!order) return null;
  if (isMultiplePayments(order)) {
    return order.payments?.find((payment) => payment.payed_at)?.type;
  } else {
    return order.payment_type;
  }
};

export const getOrderTransactionId = (order) => {
  if (!order) return null;
  if (isMultiplePayments(order)) {
    return order.payments?.find((payment) => payment.payed_at)?.transaction_id;
  } else {
    return order.transaction_id;
  }
};

export const getOrderFirstPaymentDate = (order) => {
  if (!order) return null;
  if (isMultiplePayments(order)) {
    return order.payments?.find((payment) => payment.payed_at)?.payed_at;
  } else {
    return order.first_payment_payed_at || order.payed_at;
  }
};
