export const MoneyCaseIcon = (props) => {
  const { color = "currentColor" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        clipPath="url(#a)"
      >
        <path d="M20.755 6.429H3.245c-.879 0-1.592.712-1.592 1.591v12.735c0 .879.713 1.592 1.592 1.592h17.51c.879 0 1.591-.713 1.591-1.592V8.02c0-.88-.712-1.592-1.591-1.592Z" />
        <path d="M16.775 6.429V3.245a1.592 1.592 0 0 0-1.591-1.592H8.816a1.592 1.592 0 0 0-1.591 1.592V6.43M14.032 12.265a1.59 1.59 0 0 0-1.502-1.06h-1.232a1.42 1.42 0 0 0-.303 2.808l1.876.41a1.592 1.592 0 0 1-.34 3.148h-1.062a1.592 1.592 0 0 1-1.501-1.06M12 11.204V9.612M12 19.163v-1.592" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
