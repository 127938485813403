import { SERVICE } from "../constants/api";
import { http } from "../constants/axios";

export const getTourPoints = async (page, perPage, filters) => {
  try {
    const { asc, desc, search, premiseId, domainId, countryCode, regionCode } = filters;
    const params = new URLSearchParams();
    if (page) params.append("page", page);
    if (perPage) params.append("perpage", perPage);
    if (search) params.append("search", search);
    if (premiseId && premiseId !== "all") params.append("premise_id", premiseId);
    if (domainId && domainId !== "all") params.append("domain_id", domainId);
    if (countryCode && countryCode !== "all") params.append("country_code", countryCode);
    if (regionCode && regionCode !== "all") params.append("region_code", regionCode);

    if (asc.length > 0) {
      params.append("asc", asc.join(","));
    }

    if (desc.length > 0) {
      params.append("desc", desc.join(","));
    }

    const response = await http.get(`${SERVICE.TOUR_POINTS}?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tour points:", error);
    throw error;
  }
};

export const getTourPointsList = async () => {
  try {
    const response = await http.get(`${SERVICE.TOUR_POINTS}/list`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tour points:", error);
    throw error;
  }
};

export const getTourPointDetails = async (id) => {
  try {
    const response = await http.get(`${SERVICE.TOUR_POINTS}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tour point details:", error);
    throw error;
  }
};

export const createTourPoint = async (body) => {
  try {
    const response = await http.post(`${SERVICE.TOUR_POINTS}`, body);
    return response.data;
  } catch (error) {
    console.error("Error creating tour point:", error);
    throw error;
  }
};

export const editTourPoint = async (id, body) => {
  try {
    const response = await http.put(`${SERVICE.TOUR_POINTS}/${id}`, body);
    return response.data;
  } catch (error) {
    console.error("Error editing tour point:", error);
    throw error;
  }
};

export const deleteTourPoint = async (id) => {
  try {
    const response = await http.delete(`${SERVICE.TOUR_POINTS}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting tour point:", error);
    throw error;
  }
};

export const assignDomainsToTourPoint = async (id, domains) => {
  const body = {
    domains_ids: domains,
  };

  try {
    const response = await http.put(`${SERVICE.TOUR_POINTS}/domains/${id}`, body);
    return response.data;
  } catch (error) {
    console.error("Error assign domains:", error);
    throw error;
  }
};
