import { Check, Close, Visibility } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getOrderFirstPaymentTotalAmount,
  getOrderSecondPaymentTotalAmount,
  getOrdersPayedAmount,
  getOrdersPayedPremiseCommission,
  getOrdersPayedTotalCommission,
  getOrdersTotalPayed,
  getPaymentTotal,
  isMultiplePayments,
  isOrderStatus,
  orderStatusColors,
} from "../../constants/orders";
import { formatCurrency, formatDate, formatHours, getPremiseName } from "../../constants/utils";
import InfoToolTip from "../shared/InfoToolTip";
import { ROUTES } from "./../../constants/routes";

const OrdersTable = ({
  data,
  handleOpenDetail,
  premises,
  handleSelectOrders,
  selectedOrdersIds,
  fromCards,
  canSeeTotalPrice,
  filters = {},
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderPayments = (row, key) => {
    if (isMultiplePayments(row)) {
      return row.payments
        ?.sort((a, b) => Number(a.position) - Number(b.position))
        ?.map((payment, index) => <p key={index}>{formatCurrency(payment[key])}</p>);
    }
    return (
      <>
        {row[`first_payment_${key}`] && formatCurrency(row[`first_payment_${key}`])}
        {row[`first_payment_${key}`] && <br />}
        {formatCurrency(row[key])}
      </>
    );
  };

  const formatPaymentDate = (date) => (date ? `${formatDate(date)} ${formatHours(date)}` : "");

  const renderPaymentStatus = (payed_at) =>
    payed_at ? (
      <Check sx={{ color: "var(--green-success)", padding: 0 }} />
    ) : (
      <Close sx={{ color: "var(--oniria-red)" }} />
    );

  const formatFirstPaymentLabel = (row) => {
    const refundText = row.refund_amount ? ` (${formatCurrency(row.refund_amount)})` : "";
    return `${t("FIRST_PAYMENT")} / ${t(row.first_payment_type?.toUpperCase())}${refundText}`;
  };

  const formatOrderStatusLabel = (row) => {
    const refundText = row.refund_amount ? ` (${formatCurrency(row.refund_amount)})` : "";
    return `${t(isOrderStatus(row))}${refundText}`;
  };

  const formatTransactionChips = (row) => {
    const transactions = [];

    if (row.first_payment_transaction_id) {
      transactions.push({
        id: row.first_payment_transaction_id,
        type: row.first_payment_type,
      });
    }
    if (row.transaction_id) {
      transactions.push({
        id: row.transaction_id,
        type: row.payment_type,
      });
    }
    if (row.payments && row.payments.length > 0) {
      transactions.push(
        ...row.payments
          .filter((payment) => payment.transaction_id)
          .map((payment) => ({
            id: payment.transaction_id,
            type: payment.type,
          }))
      );
    }

    return transactions.map((tx) => (
      <Chip
        key={tx.id}
        label={`${t("TRANSACTION_ID")}: ${tx.id} / ${t(tx.type?.toUpperCase())}`}
        variant="outlined"
      />
    ));
  };

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
      <Table sx={{ whiteSpace: "nowrap" }}>
        <TableHead>
          <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
            <TableCell>
              <InfoToolTip text={t("ORDER_SELECT_DOWNLOAD")} />
            </TableCell>
            <TableCell>{fromCards ? t("CARD_NAME") : t("EVENT_NAME")}</TableCell>
            <TableCell>{t("PREMISE")}</TableCell>
            <TableCell>{t("AMOUNT")}</TableCell>
            <TableCell sx={{ maxWidth: "20px", whiteSpace: "wrap" }}>
              {t("COMMISION_SERVICE")} (oniria)
            </TableCell>
            <TableCell sx={{ maxWidth: "20px", whiteSpace: "wrap" }}>
              {t("PREMISE_COMMISSIONS")}
            </TableCell>
            {canSeeTotalPrice && <TableCell>{t("TOTAL_AMOUNT")}</TableCell>}
            <TableCell>{t("DATE")}</TableCell>
            <TableCell>{t("PAYMENT_TYPE")}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOrdersIds.includes(row.id)}
                      onChange={handleSelectOrders}
                      value={row.id}
                      className="checkbox-oniria"
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <Typography
                  className="oniria-colorText"
                  variant="subtitle2"
                  sx={{ fontWeight: "bolder", cursor: "pointer" }}
                  onClick={() => navigate(`/event/${row.event_id}`)}
                >
                  <p>{row.event_name || row.card_name}</p>
                  <span
                    className="oniria-colorText"
                    variant="subtitle2"
                    sx={{ fontWeight: "bolder", cursor: "pointer" }}
                    onClick={() => navigate(`${ROUTES.USERS}/info/${row.user_id}`)}
                  >
                    {row.email}
                  </span>
                </Typography>
              </TableCell>
              <TableCell>
                <p>{getPremiseName(row.event_premise_id || row.card_premise_id, premises)}</p>
                <p>{row.bought_in_domain}</p>
              </TableCell>
              <TableCell>{renderPayments(row, "amount")}</TableCell>
              <TableCell>{renderPayments(row, "commission")}</TableCell>
              <TableCell>{renderPayments(row, "premise_commission")}</TableCell>
              {canSeeTotalPrice && (
                <TableCell>
                  {isMultiplePayments(row) ? (
                    row.payments
                      ?.sort((a, b) => Number(a.position) - Number(b.position))
                      .map((payment, index) => (
                        <Box
                          key={`payment-${index}`}
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          {formatCurrency(getPaymentTotal(payment))}
                          {renderPaymentStatus(payment.payed_at)}
                        </Box>
                      ))
                  ) : (
                    <>
                      {row.first_payment_amount && (
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          {formatCurrency(getOrderFirstPaymentTotalAmount(row))}
                          {renderPaymentStatus(row.first_payment_payed_at)}
                        </Box>
                      )}
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {formatCurrency(getOrderSecondPaymentTotalAmount(row))}
                        {renderPaymentStatus(row.payed_at)}
                      </Box>
                    </>
                  )}
                </TableCell>
              )}
              <TableCell>
                {isMultiplePayments(row) ? (
                  row.payments
                    ?.filter((payment) => Boolean(payment.payed_at))
                    .sort((a, b) => Number(a.position) - Number(b.position))
                    .map((payment) => (
                      <p key={payment.id ?? `payment-${payment.position}`}>
                        {formatPaymentDate(payment.payed_at)}
                      </p>
                    ))
                ) : (
                  <>
                    {formatPaymentDate(row.first_payment_payed_at) && (
                      <p>{formatPaymentDate(row.first_payment_payed_at)}</p>
                    )}
                    {formatPaymentDate(row.payed_at) && <p>{formatPaymentDate(row.payed_at)}</p>}
                  </>
                )}
              </TableCell>
              <TableCell>
                <Stack spacing={1}>
                  {row?.first_payment_type && (
                    <Chip
                      label={formatFirstPaymentLabel(row)}
                      sx={{ backgroundColor: orderStatusColors[isOrderStatus(row)] }}
                    />
                  )}
                  <Chip
                    label={formatOrderStatusLabel(row)}
                    variant="outlined"
                    sx={{ backgroundColor: orderStatusColors[isOrderStatus(row)] }}
                  />
                  {formatTransactionChips(row)}
                </Stack>
              </TableCell>

              <TableCell>
                <Tooltip title={t("SEE")}>
                  <IconButton onClick={(e) => handleOpenDetail(e, row)}>
                    <Visibility sx={{ color: "var(--secondary-color)" }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {/* FOOTER */}
        <TableFooter>
          <TableRow sx={{ fontWeight: "bolder", backgroundColor: "#f5f5f5" }}>
            <TableCell colSpan={3} sx={{ textAlign: "right", fontWeight: "bold" }}>
              {t("TOTAL")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {formatCurrency(getOrdersPayedAmount(data, filters.toDate))}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {formatCurrency(getOrdersPayedTotalCommission(data, filters.toDate))}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {formatCurrency(getOrdersPayedPremiseCommission(data, filters.toDate))}
            </TableCell>
            {canSeeTotalPrice && (
              <TableCell sx={{ fontWeight: "bold" }}>
                {formatCurrency(getOrdersTotalPayed(data, filters.toDate))}
              </TableCell>
            )}
            <TableCell colSpan={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <InfoToolTip text={t("TOTAL_ORDERS_PAYED_INFO")} styles={{ textAlign: "left" }} />
                <Typography variant="body2" color="text.secondary">
                  **{t("TOTAL_ORDERS_PAYED_DESCRIPTION")}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default OrdersTable;
