import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { defaultExtra, defaultExtraError } from "../../classes/extrasClass";
import { ROUTES } from "../../constants/routes";
import { convertStringToObject, sanitizeBoolean, sanitizeForSending } from "../../constants/utils";
import {
  DEFAULT_EVENT_LANGUAGE,
  EXTRA_TYPES_OPTIONS,
  EXTRAS_TYPES,
} from "../../constants/variables";
import useQueryExtraDetail from "../../hooks/queries/extras/useQueryExtraDetail";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import { assignDomainsToExtra, createExtra, updateExtra } from "../../services/extrasServices";
import EmailsEditor from "../emailEditor/EmailEditor";
import LanguageTabs from "../events/languages/LanguageTabs";
import { handleSelectImage, transformObjectWithUrls } from "../shared/FormsValidator";
import ImageUpload from "../shared/ImageUpload";
import { Loading } from "../shared/Loading";
import { StyledAccordion } from "../shared/StyledAccordion";
import { focusColor } from "../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import { FormFieldHeader } from "../tripPlus/FormFieldHeader";
import ExtraLanguageSelect from "./ExtraLanguageSelect";

const ExtrasForm = ({ goBack, extraType }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(defaultExtra)));
  const [formErrors, setFormErrors] = useState(JSON.parse(JSON.stringify(defaultExtraError)));
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(DEFAULT_EVENT_LANGUAGE);
  const emailEditorRefs = useRef([]);

  const handlePremisesSuccess = (data) => {
    if (data.length === 1) {
      setFormData((prev) => ({ ...prev, owner_premise_id: data[0].id }));
    }
  };

  const handleDomainsSuccess = (data) => {
    if (data.length === 1) {
      setFormData((prev) => ({ ...prev, domains_ids: [data[0].domain_id] }));
    }
  };

  const { data: premises = [] } = useQueryPremisesList("", "", handlePremisesSuccess);
  const { data: domains = [] } = useQueryDomains(handleDomainsSuccess);

  const handleSetFormData = (data) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData.description_design = convertStringToObject(newData.description_design);
    newData.extra_template_language_infos =
      newData.extra_template_language_infos?.map((language) => ({
        ...language,
        description_design: convertStringToObject(language.description_design),
      })) || [];

    setFormData(newData);
  };

  const { isLoading } = useQueryExtraDetail(id, handleSetFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeLanguageParam = (value, key) => {
    const languageIndex = getLanguageIndex();
    setFormData((prev) => ({
      ...prev,
      extra_template_language_infos: prev.extra_template_language_infos.map((info, index) =>
        index === languageIndex ? { ...info, [key]: value } : info
      ),
    }));
  };

  const validateForm = () => {
    const errors = {};
    const requiredMessage = t("FIELD_REQUIRED");

    const requiredFields = ["name"];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = requiredMessage;
      }
    });

    if (formData.short_description && formData.short_description.length > 250) {
      errors.short_description = t("MAX_250");
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const errors = validateForm();
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }

      const formDataToSend = await transformObjectWithUrls(formData);
      formDataToSend.default_language = formDataToSend.default_language || DEFAULT_EVENT_LANGUAGE;
      formDataToSend.description = sanitizeForSending(formDataToSend.description);
      formDataToSend.description_design = sanitizeForSending(formDataToSend.description_design);

      if (extraType) {
        formDataToSend.type = extraType;
      }

      formDataToSend.extra_template_language_infos =
        formDataToSend?.extra_template_language_infos?.map((info) => ({
          ...info,
          extra_id: id,
          description: sanitizeForSending(info.description),
          description_design: sanitizeForSending(info.description_design),
        })) || [];

      delete formDataToSend.created_at;
      delete formDataToSend.last_modified_at;

      if (id) {
        formDataToSend.is_active = sanitizeBoolean(formDataToSend.is_active);
        await updateExtra(id, formDataToSend);
        if (formDataToSend.domains_ids && formDataToSend.domains_ids.length > 0) {
          await assignDomainsToExtra(id, formDataToSend.domains_ids);
        }
        toastMessageSuccess(t("EXTRA_UPDATED_SUCCESS"));
      } else {
        formDataToSend.is_active = true;
        const response = await createExtra(formDataToSend);
        if (formDataToSend.domains_ids && formDataToSend.domains_ids.length > 0 && response.id) {
          await assignDomainsToExtra(response.id, formDataToSend.domains_ids);
        }
        toastMessageSuccess(t("EXTRA_CREATED_SUCCESS"));
      }
      setTimeout(() => {
        handleGoBack();
      }, 1500);
    } catch (e) {
      toastMessageError(e?.response?.data?.error || t("ERROR_UPDATE_EXTRA"));
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigate(ROUTES.EXTRAS);
    }
  };

  const getLanguageIndex = () => {
    if (!formData || !formData.extra_template_language_infos) {
      return -1;
    }
    return formData.extra_template_language_infos.findIndex(
      (language) => language.language === selectedLanguage
    );
  };

  useEffect(() => {
    if (extraType) {
      setFormData((prev) => ({
        ...prev,
        tags: extraType,
      }));
    }
  }, [extraType]);

  return (
    <Grid mt={extraType ? 2 : 7} mb={5}>
      {!extraType && (
        <>
          <IconButton onClick={handleGoBack}>
            <ArrowBack />
          </IconButton>
          <Grid container justifyContent="center" mb={5} mt={2}>
            <Typography variant="h5" fontWeight="bold">
              {!!id ? t("EDIT_EXTRA") : t("CREATE_EXTRA")}
            </Typography>
          </Grid>
        </>
      )}

      {id && isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <ExtraLanguageSelect formData={formData} setFormData={setFormData} />
            {formData?.extra_template_language_infos?.length > 0 && (
              <LanguageTabs
                event_languages_info={formData?.extra_template_language_infos}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              />
            )}
            <Grid item xs={12}>
              <FormFieldHeader
                title={
                  extraType ? `${t("TITLE_OF")} ${t(extraType.toUpperCase())}` : t("EXTRA_NAME")
                }
              />
              {selectedLanguage === DEFAULT_EVENT_LANGUAGE ? (
                <TextField
                  fullWidth
                  label={t("NAME")}
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  sx={focusColor}
                  required
                  helperText={formErrors?.name}
                  error={formErrors?.name !== "" && formErrors?.name !== undefined}
                />
              ) : (
                <TextField
                  fullWidth
                  label={t("NAME")}
                  name="name"
                  value={formData.extra_template_language_infos[getLanguageIndex()].name}
                  onChange={(e) => handleChangeLanguageParam(e.target.value, "name")}
                  sx={focusColor}
                  required
                />
              )}
            </Grid>
            {extraType !== EXTRAS_TYPES.INSURANCE && (
              <Grid item xs={12}>
                <FormFieldHeader
                  title={t("EXTRA_IMAGES")}
                  description={t("EXTRA_IMAGES_DESCRIPTION")}
                />
                <ImageUpload
                  url={formData.images}
                  onImageSelect={(url) => handleSelectImage(url, setFormData, "images")}
                  multi={false}
                />
              </Grid>
            )}
            <Grid item xs={12} mt={2}>
              <FormFieldHeader
                title={t("EXTRA_DESCRIPTION")}
                description={t("EXTRA_DESCRIPTION_DESCRIPTION")}
              />
              {selectedLanguage === DEFAULT_EVENT_LANGUAGE ? (
                <EmailsEditor
                  ref={(el) => el && (emailEditorRefs.current[0] = el)}
                  initialDesign={formData?.description_design}
                  onExportHtml={(html) => setFormData((prev) => ({ ...prev, description: html }))}
                  onDesignChange={(design) => {
                    setFormData((prev) => ({
                      ...prev,
                      description_design: design,
                    }));
                  }}
                  key={`editor-extra-${DEFAULT_EVENT_LANGUAGE}`}
                />
              ) : (
                <EmailsEditor
                  ref={(el) => el && (emailEditorRefs.current[getLanguageIndex()] = el)}
                  initialDesign={
                    formData.extra_template_language_infos[getLanguageIndex()].description_design
                  }
                  onExportHtml={(html) => handleChangeLanguageParam(html, "description")}
                  onDesignChange={(design) =>
                    handleChangeLanguageParam(design, "description_design")
                  }
                  key={`editor-extra-${selectedLanguage}-${id}`}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ width: "100%" }} />
            </Grid>
            {domains && domains.length === 1 ? null : (
              <Grid item xs={12}>
                <FormFieldHeader title={t("DOMAINS")} />
                <FormControl fullWidth sx={focusColor}>
                  <InputLabel>{t("DOMAINS")}</InputLabel>
                  <Select
                    value={formData.domains_ids || []}
                    onChange={(e) =>
                      handleChange({ target: { name: "domains_ids", value: e.target.value } })
                    }
                    label={t("DOMAINS")}
                    multiple
                  >
                    {domains?.map((domain) => (
                      <MenuItem key={domain.domain_id} value={domain.domain_id}>
                        {domain.domain_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {premises && premises.length === 1 ? null : (
              <Grid item xs={12}>
                <FormFieldHeader title={t("PREMISE")} />
                <FormControl fullWidth sx={focusColor}>
                  <InputLabel>{t("PREMISE")}</InputLabel>
                  <Select
                    value={formData.owner_premise_id || ""}
                    onChange={(e) =>
                      handleChange({ target: { name: "owner_premise_id", value: e.target.value } })
                    }
                    label={t("PREMISE")}
                  >
                    {premises?.map((premise) => (
                      <MenuItem key={premise.id} value={premise.id}>
                        {premise.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {!extraType && (
              <Grid item xs={12}>
                <Typography>{t("TAGS")}</Typography>
                <FormControl fullWidth sx={focusColor}>
                  <InputLabel>{t("TAGS")}</InputLabel>
                  <Select
                    value={formData.tags || []}
                    onChange={(e) =>
                      handleChange({ target: { name: "tags", value: e.target.value } })
                    }
                    label={t("TAGS")}
                  >
                    {EXTRA_TYPES_OPTIONS?.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {t(type.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <StyledAccordion title={t("ADVANCED_OPTIONS")}>
                <Grid container gap={2}>
                  <Grid item xs={12}>
                    <Typography>{t("SKU_OPTIONAL")}</Typography>
                    <TextField
                      fullWidth
                      label={t("SKU")}
                      name="sku"
                      value={formData.sku}
                      onChange={handleChange}
                      sx={focusColor}
                      helperText={formErrors?.sku}
                      error={formErrors?.sku !== "" && formErrors?.sku !== undefined}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>{t("SHORT_DESCRIPTION_250")}</Typography>
                    {selectedLanguage === DEFAULT_EVENT_LANGUAGE ? (
                      <TextField
                        fullWidth
                        label={t("DESCRIPTION")}
                        name="short_description"
                        value={formData.short_description}
                        onChange={handleChange}
                        sx={focusColor}
                        helperText={formErrors?.short_description}
                        error={
                          formErrors?.short_description !== "" &&
                          formErrors?.short_description !== undefined
                        }
                      />
                    ) : (
                      <TextField
                        fullWidth
                        label={t("DESCRIPTION")}
                        name="short_description"
                        value={
                          formData.extra_template_language_infos[getLanguageIndex()]
                            .short_description
                        }
                        onChange={(e) =>
                          handleChangeLanguageParam(e.target.value, "short_description")
                        }
                        sx={focusColor}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {formData.is_active === 0 && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="is_active"
                            checked={formData.is_active === true || formData.is_active === 1}
                            onChange={(e) =>
                              setFormData({ ...formData, is_active: e.target.checked })
                            }
                            value="is_active"
                            className="checkbox-oniria"
                          />
                        }
                        label={t("ACTIVE")}
                      />
                    )}
                  </Grid>
                </Grid>
              </StyledAccordion>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Button type="submit" variant="contained" className="oniria-btn" disabled={loading}>
                {loading ? t("SAVING") : !!id ? t("UPDATE_EXTRA") : t("CREATE_EXTRA")}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Grid>
  );
};

export default ExtrasForm;
