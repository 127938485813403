export const PalmIcon = (props) => {
  const { color = "currentColor" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        clipPath="url(#a)"
      >
        <path d="M22.347 22.315h-.796a3.184 3.184 0 0 1-3.184-3.184 3.184 3.184 0 0 1-6.367 0 3.184 3.184 0 0 1-6.367 0 3.184 3.184 0 0 1-3.184 3.184h-.796M16.775 15.948a8.74 8.74 0 0 0-13.498 0" />
        <path d="M11.204 12.844c.096-3.598 1.194-6.877 3.582-8.055M10.026 1.765a4.107 4.107 0 0 1 4.776 3.024" />
        <path d="M20.612 5.378a4.425 4.425 0 0 0-5.826-.589" />
        <path d="M8.944 6.492a4.775 4.775 0 0 1 5.842-1.703 4.059 4.059 0 0 1 3.008 4.776" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
