import { Grid, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../../shared/textFieldStyle";

export const AgentContactFields = () => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid item xs={12} container spacing={2} mt={2}>
      <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%", ml: 2 }}>
        {t("CONTACT")}
      </Typography>
      <Grid item xs={12} sm={3}>
        <Controller
          name="phone_code"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("PHONE_CODE")}
              fullWidth
              error={!!errors.phone_code}
              helperText={errors.phone_code?.message}
              required
              sx={focusColor}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("PHONE")}
              fullWidth
              error={!!errors.phone}
              helperText={errors.phone?.message}
              required
              sx={focusColor}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("EMAIL")}
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
              required
              sx={focusColor}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
