import { DeleteOutlined, EditOutlined, ListAlt } from "@mui/icons-material";
import { CardMedia, Grid, IconButton, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { RQ_KEY } from "../../../constants/query";
import useUserRoles from "../../../hooks/useUserRoles";
import { getEventSales } from "../../../services/eventsServices";
import DeleteEventDialog from "./DeleteEventDialog";

const ChildEventCard = ({ event, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isManager, isSuperAdmin, isScanner, isAdmin } = useUserRoles();

  const [openDelete, setOpenDelete] = useState(false);

  //Principal image or video
  const isVideo = event.principal_url?.endsWith(".mp4") || event.principal_url?.endsWith(".webm");

  const { data: eventSales } = useQuery(
    [RQ_KEY.EVENT_SALES, event.id],
    () => getEventSales(event.id),
    {
      enabled: !!event.id,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Grid
      item
      xs={12}
      container
      component={Paper}
      elevation={3}
      sx={{
        border: "1px solid #E4E4E4",
        padding: 1,
        borderRadius: "15px",
        backgroundColor: event.is_active === 0 ? "var(--grey-cancelled)" : "inherit",
      }}
      columnSpacing={1}
    >
      <Grid item xs={2}>
        {isVideo ? (
          <CardMedia
            component={"video"}
            sx={{ height: 80, maxWidth: 130, objectFit: "fill" }}
            src={event.principal_url}
            alt="Video"
            autoPlay
            muted
          />
        ) : (
          <CardMedia
            component={"img"}
            sx={{ height: 80, maxWidth: 130, objectFit: "fill" }}
            image={event.principal_url === null ? "/media/events_null.svg" : event.principal_url}
            alt="Image"
          />
        )}
      </Grid>
      <Grid item xs={4} container alignItems="center" justifyContent="center">
        <Typography fontWeight="bold">{event.name}</Typography>
      </Grid>
      {/*  SALES */}
      <Grid item xs={4} container spacing={1} alignItems="center">
        {!isScanner && (
          <>
            <Grid item>
              <Typography variant="caption" mt={3.5} mb={1}>
                {t("TOTAL_SALES")}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                {eventSales?.amount ? eventSales?.amount + "€" : "0.00€"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" mt={3.5} mb={1}>
                {t("REFUNDS")}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                {eventSales?.total_refunded ? eventSales?.total_refunded + "€" : "0.00€"}
              </Typography>
            </Grid>
          </>
        )}
        {(isSuperAdmin || isManager) && (
          <Grid item>
            <Typography variant="caption" mt={3.5} mb={1}>
              {t("TOTAL_COMMISSIONS")}
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
              {eventSales?.commission ? eventSales?.commission + "€" : "0.00€"}
            </Typography>
          </Grid>
        )}
        {!!event.premise_commission && (
          <Grid item>
            <Typography variant="caption" mt={3.5} mb={1}>
              {t("PREMISE_COMMISSIONS")}
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
              {eventSales?.premise_commission ? eventSales?.premise_commission + "€" : "0.00€"}
            </Typography>
          </Grid>
        )}
      </Grid>
      {/* BUTTONS */}
      <Grid item xs={2} container alignItems="center">
        <Grid item>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/event/${event.id}?tab=1`);
            }}
            className="oniria-icon-btn"
          >
            <ListAlt sx={{ fontSize: "2rem" }} />
          </IconButton>
        </Grid>
        <Grid>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/event/${event.id}`);
            }}
            className="oniria-icon-btn "
          >
            <EditOutlined sx={{ fontSize: "2rem" }} />
          </IconButton>
        </Grid>
        {event.is_active === 0 ? (
          <Typography
            style={{
              color: "#ff0000",
              fontWeight: "600",
              marginBottom: "10px",
            }}
          >
            {t("CANCELED")}
          </Typography>
        ) : (
          <>
            <Grid item>
              {(isSuperAdmin || isManager || isAdmin) && (
                <IconButton onClick={() => setOpenDelete(true)} className="oniria-icon-btn ">
                  <DeleteOutlined sx={{ fontSize: "2rem" }} />
                </IconButton>
              )}
            </Grid>
          </>
        )}
      </Grid>

      <DeleteEventDialog
        openDelete={openDelete}
        handleCloseDelete={() => setOpenDelete(false)}
        eventId={event.id}
        refetch={refetch}
      />
    </Grid>
  );
};

export default ChildEventCard;
