import { formatCurrency } from "./utils";

const getTotal = (items = [], key) => {
  const total = items.reduce((sum, item) => sum + (parseFloat(item[key]) || 0), 0);
  return formatCurrency(total);
};

export const getSepasTotalAmount = (sepas) => getTotal(sepas, "total_amount");
export const getSepasTotalCommission = (sepas) => getTotal(sepas, "total_commission");
export const getPdfsTotalAmount = (pdfs) => getTotal(pdfs, "total_amount");
export const getPdfsTotalCommission = (pdfs) => getTotal(pdfs, "total_commission");
