import { Edit, FileDownloadOutlined } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PAGINATION_STYLES } from "../../../constants/styles";
import { formatCurrency } from "../../../constants/utils";
import { useQueryB2bSalesAgent } from "../../../hooks/queries/b2b/useQueryB2bSalesAgent";
import usePagination from "../../../hooks/usePagination";
import InfoToolTip from "../../shared/InfoToolTip";
import { Loading } from "../../shared/Loading";
import SelectPerPage from "../../shared/SelectPerPage";
import { AgreementFormDialog } from "../dialogs/AgreementFormDialog";
import { B2bSalesFilters } from "./B2bSalesFilters";

export const B2bBAgents = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [agreementFormDialog, setAgreementFormDialog] = useState({
    isOpen: false,
    agent: null,
  });
  const [filters, setFilters] = useState({
    search: "",
    order: {
      asc: [],
      desc: [],
    },
  });
  const { page, perPage, changePage, changePerPage } = usePagination();

  const { data, isLoading, isRefetching, refetch } = useQueryB2bSalesAgent(filters, page, perPage);
  const { amount: total = 0, data: agents = [] } = data || {};

  const handleSeeSales = (id) => {
    navigate(`/b2b/sales/${id}`);
  };
  const handleSeeCatalog = (id) => {
    navigate(`/b2b/catalog/${id}`);
  };

  const numberPages = Math.ceil(parseFloat(total) / parseFloat(perPage));

  return (
    <div>
      <B2bSalesFilters filters={filters} setFilters={setFilters} />
      {isLoading || isRefetching ? (
        <Loading />
      ) : agents && agents.length > 0 ? (
        <>
          <Grid
            item
            xs={11}
            sm={12}
            container
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems={"center"}
            pb={2}
            gap={{ xs: 2, sm: 0 }}
          >
            <Typography>
              {t("TOTAL_AGENTS")}: {total}
            </Typography>
            {numberPages >= 1 && (
              <Grid item xs={12} md={8} container justifyContent="flex-end">
                <Grid item>
                  <SelectPerPage text={t("AGENTS")} change={changePerPage} value={perPage} />
                </Grid>
                <Pagination
                  count={numberPages}
                  mr={3}
                  sx={PAGINATION_STYLES}
                  page={page}
                  onChange={changePage}
                />
              </Grid>
            )}
          </Grid>

          <div>
            <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
              <Table sx={{ whiteSpace: "nowrap" }}>
                <TableHead>
                  <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                    <TableCell align="center">{t("SALE_AGENT")}</TableCell>
                    <TableCell align="center">{t("COMMISSION_WORD")}</TableCell>
                    <TableCell align="center">{t("SALES")}</TableCell>
                    <TableCell align="center">{t("CATALOG_ACCESS")}</TableCell>
                    <TableCell align="center">{t("CONTRACT")}</TableCell>
                    <TableCell align="center">{t("CONTACT")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {agents?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">{`${row.name}, ${row.surname}`}</TableCell>
                      <TableCell align="center">
                        {row.commission_percentage && row.commission_percentage !== "0"
                          ? `${row.commission_percentage}%`
                          : row.commission_fixed
                          ? formatCurrency(row.commission_fixed)
                          : null}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => handleSeeSales(row.premise_id)}
                          variant="text"
                          className="underline-button"
                        >
                          {t("SEE_SALES")}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => handleSeeCatalog(row.id)}
                          variant="text"
                          className="underline-button"
                        >
                          {t("SEE_CATALOG")}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {row.pdf ? (
                          <IconButton component="a" href={row.pdf} download target="_blank">
                            <FileDownloadOutlined sx={{ color: "var(--oniria-gold)" }} />
                          </IconButton>
                        ) : null}
                        <InfoToolTip text={t("EDIT")}>
                          <IconButton
                            onClick={() => setAgreementFormDialog({ isOpen: true, agent: row })}
                          >
                            <Edit sx={{ color: "var(--oniria-gold)" }} />
                          </IconButton>
                        </InfoToolTip>
                      </TableCell>
                      {row.email && <TableCell align="center">{row.email}</TableCell>}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {numberPages >= 1 && (
            <Grid container justifyContent="center" mb={2}>
              <Pagination
                count={numberPages}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </>
      ) : (
        <Typography>{t("NO_DATA_AVAILABLE")}</Typography>
      )}
      <AgreementFormDialog
        isOpen={agreementFormDialog.isOpen}
        onClose={() => setAgreementFormDialog({ isOpen: false, agent: null })}
        agent={agreementFormDialog.agent}
        refetch={refetch}
        canEdit
      />
    </div>
  );
};
