import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { t } from "i18next";
import { focusColor } from "../../components/shared/textFieldStyle";
import { useDomain } from "../../context/DomainContext";
import { useQueryAssignDomains } from "../../hooks/queries/useQueryAssignDomains";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";

const SelectDomain = ({ eventCategories }) => {
  const { selectedDomain, setSelectedDomain } = useDomain();
  const { data: domainCategories = [] } = useQueryAssignDomains();
  const { data: userDomains = [] } = useQueryDomains();

  const handleChange = (event) => {
    setSelectedDomain(event.target.value);
  };

  // Filter domains by event categories
  const eventDomains = domainCategories.filter((domain) =>
    domain.categories.some((category) =>
      eventCategories?.some((eventCategory) => eventCategory?.category_id === category?.id)
    )
  );

  // Filter user domains based on event domains
  const filteredUserDomains = userDomains.filter((domain) =>
    eventDomains.some((eventDomain) => eventDomain.name === domain?.domain_name)
  );

  return (
    <FormControl sx={[focusColor, { minWidth: "170px" }]} size="small">
      <InputLabel id="domain-label">{t("ALL_DOMAINS")}</InputLabel>
      <Select
        labelId="domain-label"
        id="domain"
        value={selectedDomain || ""}
        label={t("ALL_DOMAINS")}
        onChange={handleChange}
      >
        <MenuItem value="">{t("ALL_DOMAINS")}</MenuItem>
        {filteredUserDomains.map((domain) => (
          <MenuItem key={domain?.domain_id} value={domain?.domain_name}>
            {domain?.domain_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectDomain;
