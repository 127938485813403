import { Box, Grid, Typography } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import AssignedTourLeaderCard from "../assignToTrip/AssignedTourLeaderCard";

export const TourLeadersAssignZone = ({ tripInfo = {}, handleRemove }) => {
  const { t } = useTranslation();

  const { name = "", tour_leader_ids = [] } = tripInfo;

  return (
    <Grid container>
      <Typography>{name}</Typography>
      <Grid item xs={12}>
        <Droppable droppableId={`extra-insurance`} direction="horizontal">
          {(provided, snapshot) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mb: 1,
                backgroundColor: snapshot.isDraggingOver ? "#e0e0e0" : "#fff",
                borderRadius: 2,
                padding: 2,
              }}
            >
              {tour_leader_ids.map((tourLeaderId, index) => (
                <AssignedTourLeaderCard
                  key={`tour-leader-${tourLeaderId || index}`}
                  tourLeaderId={tourLeaderId}
                  handleDelete={handleRemove}
                />
              ))}
              <Box
                sx={{
                  border: "1px dashed",
                  width: "100%",
                  textAlign: "center",
                  padding: 2,
                  borderRadius: 2,
                }}
              >
                <Typography variant="caption">{t("DRAG_TOUR_LEADERS_HERE")}</Typography>
              </Box>
              {provided.placeholder}{" "}
            </Box>
          )}
        </Droppable>
      </Grid>
    </Grid>
  );
};
