import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { focusColor } from "./textFieldStyle";

const SelectHookForm = ({
  control,
  label = "",
  error,
  options = [],
  disabled = false,
  name,
  required = false,
  multiple = false,
  defaultValue = multiple ? [] : "",
  placeholder = "",
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth sx={focusColor}>
      {!!label && <InputLabel className="form-input-label">{label}</InputLabel>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || multiple ? [] : ""}
        rules={{ required: required }}
        render={({ field }) => {
          const fieldValue = multiple ? field?.value || [] : field?.value || "";

          const isValidValue = multiple
            ? Array.isArray(fieldValue) &&
              fieldValue.every((v) => options.some((option) => option.props.value === v))
            : options.some((option) => option.props.value === fieldValue);

          return (
            <>
              <Select
                {...field}
                value={multiple ? field?.value || [] : field?.value || ""}
                label={label}
                className="input-select form-select-field"
                error={!!error}
                slotProps={{ input: { readOnly: disabled } }}
                multiple={multiple}
                required={required}
              >
                {placeholder && (
                  <MenuItem value="" disabled>
                    {placeholder}
                  </MenuItem>
                )}
                {options}
              </Select>
              {!isValidValue && field?.value && (
                <FormHelperText>{t("INVALID_SELECTION")}</FormHelperText>
              )}
            </>
          );
        }}
      />
      {!!error && <Typography className="form-input-error">{t(error.message)}</Typography>}
    </FormControl>
  );
};

export default SelectHookForm;
