import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryB2bSalesAgent } from "../../../hooks/queries/b2b/useQueryB2bSalesAgent";
import { FormHeader } from "../../shared/FormHeader";
import SelectHookForm from "../../shared/SelectHookForm";
import { CustomSwitch } from "../../shared/switch/CustomSwitch";

export const TripB2b = () => {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState("all");

  const { data: agents = [] } = useQueryB2bSalesAgent({}, "", "");

  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const isB2b = watch("is_b2b", false);
  const selectedAgents = watch("agents_that_can_see_ids", []) || [];

  const handleChangeVisibility = (e) => {
    const { value } = e.target;
    if (value === "all") {
      setValue("agents_that_can_see_ids", []);
    }
    setVisibility(value);
  };

  useEffect(() => {
    if (selectedAgents.length > 0) {
      setVisibility("selected");
    }
  }, []);

  return (
    <>
      <FormHeader title={t("B2B")} description={t("B2B_TRIP_DESCRIPTION")}>
        <Grid item xs={12} container alignItems="center" gap={2}>
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("PROVIDE_B2B_SALE")}
            </Typography>
            <Typography variant="body1">{t("PROVIDE_B2B_SALE_DESCRIPTION")}</Typography>
          </Grid>
          <CustomSwitch
            checked={isB2b}
            handleChange={(e) => {
              setValue("is_b2b", e.target.checked);
              setValue("is_catalogue_visible", e.target.checked);
            }}
          />
        </Grid>
        {isB2b && (
          <Grid item xs={12}>
            <Divider sx={{ marginBottom: 2 }} />
            <Typography variant="body1" mb={1}>
              {t("SHOW_IN_B2B_CATALOG")}
            </Typography>
            <RadioGroup value={visibility} onChange={handleChangeVisibility}>
              <FormControlLabel
                value="all"
                control={<Radio className="radio-oniria" />}
                label={t("VISIBLE_FOR_ALL_AGENTS")}
              />
              <FormControlLabel
                value="selected"
                control={<Radio className="radio-oniria" />}
                label={t("VISIBLE_FOR_SELECTED_AGENTS")}
              />
            </RadioGroup>
            {visibility === "selected" && (
              <>
                <SelectHookForm
                  name={"agents_that_can_see_ids"}
                  control={control}
                  label={t("AGENTS")}
                  error={errors.agents_that_can_see_ids}
                  options={[{ id: null, name: t("NONE") }, ...agents].map((agent) => (
                    <MenuItem key={agent.id} value={agent.id}>
                      {agent.name}
                    </MenuItem>
                  ))}
                  multiple
                  defaultValue={[]}
                />
                {selectedAgents.length > 0 && (
                  <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                    <Typography variant="subtitle2">{t("SELECTED_AGENTS")}:</Typography>
                    {selectedAgents.map((agent, index) => (
                      <Chip
                        key={`${agent}-${index}`}
                        label={agents.find((a) => a.id === agent)?.name}
                        onDelete={() =>
                          setValue(
                            "agents_that_can_see_ids",
                            selectedAgents.filter((a) => a !== agent) || []
                          )
                        }
                        sx={{ width: "fit-content" }}
                      />
                    ))}
                  </Box>
                )}
              </>
            )}
          </Grid>
        )}
      </FormHeader>
    </>
  );
};
