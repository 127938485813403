import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { findAllDomains } from "../../services/domainsServices";

export function useQueryDomains(onSuccess = () => {}) {
  return useQuery([RQ_KEY.DOMAINS], findAllDomains, {
    refetchOnWindowFocus: false,
    onSuccess: onSuccess ? onSuccess : null,
  });
}
