import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { formatDate, getRoutePointName, getTransportIconById } from "../../../constants/utils";
import useQueryTourPointsList from "../../../hooks/queries/tourPoints/useQueryTourPointsList";
import useQueryTransportDetails from "../../../hooks/queries/transports/useQueryTransportDetails";
import useQueryTransportOnTrips from "../../../hooks/queries/transports/useQueryTransportOnTrips";
import usePagination from "../../../hooks/usePagination";
import Header from "../../Header";
import { Loading } from "../../shared/Loading";
import { TransportUsers } from "./TransportUsers";

const passangersInfo = [
  {
    id: "1",
    img_url: "https://www.w3schools.com/w3images/avatar1.png",
    name: "John Doe",
    order_id: "1",
    sku: "sku-1",
    has_arrived: true,
  },
  {
    id: "2",
    img_url: "https://www.w3schools.com/w3images/avatar2.png",
    name: "Jane Doe",
    order_id: "2",
    sku: "sku-2",
    has_arrived: false,
  },
];

const TransportOnTrips = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: transportId } = useParams();
  const { page, perPage, changePage, changePerPage } = usePagination();

  const breadcrumbs = [
    { name: t("TRANSPORTS"), link: ROUTES.TRANSPORTS },
    { name: t("SEE_TRANSPORT_ASSIGNED") },
  ];

  const { data: tourPoints } = useQueryTourPointsList();
  const { data: transport, isLoading: loadingDetails } = useQueryTransportDetails(
    transportId,
    () => {},
    true
  );

  const { data: tripsInfo, isLoading: isLoadingTrips } = useQueryTransportOnTrips(transportId);

  const isLoadingData = loadingDetails;

  const handleSeeTrip = (tripId) => {
    navigate(`/event/${tripId}`);
  };

  console.log("transport", transport);

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("TRANSPORT_ON_TRIPS_DESCRIPTION")} />
      {isLoadingData ? (
        <Loading />
      ) : (
        <>
          {/* HEADER INFO */}
          <Grid container mt={5}>
            <Grid item xs={12} sm={7} container>
              <Grid item xs={12} container>
                <Grid xs={4} item container flexDirection="column" alignItems="center">
                  {getTransportIconById(transport?.type)}
                  <h4>{t(transport?.type?.toUpperCase())}</h4>
                </Grid>
                <Grid xs={8} item container flexDirection="column">
                  <h2>{transport?.name}</h2>
                  <Typography variant="body2">
                    {formatDate(transport?.start_date)} - {formatDate(transport?.end_date)}
                  </Typography>
                  <Typography variant="body2">
                    {t("SKU")}: {transport?.sku}
                  </Typography>
                </Grid>
              </Grid>
              {/* TRIPS INFO */}
              <Grid item xs={12} container>
                {isLoadingTrips ? (
                  <Loading />
                ) : tripsInfo && tripsInfo.length > 0 ? (
                  <Table style={{ borderCollapse: "collapse" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ borderBottom: "none" }}>{t("NAME_TRIP")}</TableCell>
                        <TableCell style={{ borderBottom: "none" }}>{t("ROUTE")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tripsInfo.map((trip) => (
                        <TableRow key={trip.trip_id}>
                          <TableCell style={{ borderBottom: "none" }}>
                            <Typography
                              fontWeight="bolder"
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleSeeTrip(trip.trip_id)}
                            >
                              {trip.trip_name}
                            </Typography>
                            {trip.trip_sku}
                          </TableCell>

                          {/* Itineraries */}
                          <TableCell
                            style={{ display: "flex", alignItems: "center", borderBottom: "none" }}
                          >
                            {trip.itineraries.map((itinerary, index) => (
                              <div
                                key={itinerary.id}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: index === trip.itineraries.length - 1 ? 0 : "20px",
                                }}
                              >
                                <div style={{ textAlign: "center", marginRight: "8px" }}>
                                  <div>
                                    {getRoutePointName(itinerary.tour_point_id, tourPoints)}
                                  </div>
                                  <div>{itinerary.date}</div>
                                </div>

                                {/* Line connecting points*/}
                                {index < trip.itineraries.length - 1 && (
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <div
                                      style={{
                                        height: "2px",
                                        backgroundColor: "var(--secondary-color)",
                                        width: "50px",
                                        margin: "0 8px",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            ))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography>{t("NO_TRIPS_ASSIGNED")}</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* PASSENGERS INFO */}
          <TransportUsers
            changePage={changePage}
            page={page}
            perPage={perPage}
            passangersInfo={passangersInfo}
            changePerPage={changePerPage}
          />
        </>
      )}
    </>
  );
};

export default TransportOnTrips;
