import axios from "axios";
import { API_URL, API_URL_2 } from "./api";

const http = axios.create({
  baseURL: API_URL,
  //timeout: 4000,
  headers: { Accept: "application/json" },
});

// API tikitup2
const http2 = axios.create({
  baseURL: API_URL_2,
  headers: { Accept: "application/json" },
});

// Set authorization token
export function setHttpBearerToken(token) {
  if (token === "" || token === null) {
    delete http.defaults.headers.common["Authorization"];
    delete http2.defaults.headers.common["Authorization"];
  } else {
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    http2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

const handleErrorResponse = (error) => {
  const errorMessage = error.response?.data?.error;
  if (errorMessage === "jwt expired") {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/login";
  }
  return Promise.reject(error);
};

http.interceptors.response.use((response) => response, handleErrorResponse);
http2.interceptors.response.use((response) => response, handleErrorResponse);

// Exportamos las instancias para usarlas en diferentes partes de la aplicación
export { http, http2 };
