import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PdfUpload from "../../../shared/PdfUpload";
import { focusColor } from "../../../shared/textFieldStyle";

export const AgentContractFields = () => {
  const { t } = useTranslation();
  const [commissionType, setCommissionType] = useState("");

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  return (
    <Grid item xs={12} container spacing={2} mt={2}>
      <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%", ml: 2 }}>
        {t("CONTRACT")}
      </Typography>
      <Grid item xs={12}>
        <FormControl fullWidth sx={[focusColor, { mt: 2 }]} error={!!errors.commission_type}>
          <InputLabel>{t("CHOOSE_COMMISSION_TYPE")}</InputLabel>
          <Select
            value={commissionType || ""}
            label={t("CHOOSE_COMMISSION_TYPE")}
            onChange={(e) => {
              setCommissionType(e.target.value);
              setValue("agreement.commission_fixed", 0);
              setValue("agreement.commission_percentage", 0);
            }}
          >
            <MenuItem value="fixed">{t("FIXED")}</MenuItem>
            <MenuItem value="percentage">{t("PERCENTAGE")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {commissionType === "fixed" && (
          <Controller
            name="agreement.commission_fixed"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={[focusColor, { mt: 2 }]}
                label={t("FIXED_COMMISSION")}
                type="number"
                error={!!errors?.agreement?.commission_fixed}
                helperText={
                  errors.commission_fixed?.message && t(errors.agreement.commission_fixed.message)
                }
                inputProps={{ min: 0 }}
                onWheel={(e) => e.target.blur()}
              />
            )}
          />
        )}
        {commissionType === "percentage" && (
          <Controller
            name="agreement.commission_percentage"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={[focusColor, { mt: 2 }]}
                label={t("PERCENTAGE_COMMISSION")}
                type="number"
                inputProps={{ min: 0, max: 100 }}
                error={!!errors?.agreement?.commission_percentage}
                helperText={
                  errors?.agreement?.commission_percentage?.message &&
                  t(errors.agreement.commission_percentage.message)
                }
                onWheel={(e) => e.target.blur()}
              />
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} mt={2}>
        <PdfUpload
          value={watch("agreement.pdf")}
          onChange={(value) => setValue("agreement.pdf", value)}
        />
      </Grid>
    </Grid>
  );
};
