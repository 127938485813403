import { TextField } from "@mui/material";
import { focusColor } from "../../shared/textFieldStyle";

export const AssistantTextField = ({
  label,
  fieldName,
  value,
  ticketIndex,
  type,
  errorProperty,
  errorText,
  regex,
  handleFieldChange,
  addClicked,
} = {}) => {
  return (
    <>
      <TextField
        sx={focusColor}
        margin="normal"
        required
        fullWidth
        id={fieldName}
        name={fieldName}
        autoComplete={fieldName}
        label={label}
        value={value}
        type={type ? type : "text"}
        onChange={(e) => handleFieldChange(e, ticketIndex, regex, errorText)}
        inputProps={
          fieldName === "client_document_expiry_date"
            ? {
                min: new Date().toISOString().split("T")[0],
                max: "2100-12-31",
              }
            : fieldName === "client_birth_date"
            ? {
                min: "1900-01-01",
                max: new Date().toISOString().split("T")[0],
              }
            : undefined
        }
        error={
          (errorProperty !== "" && errorProperty !== "empty") ||
          (errorProperty === "empty" && addClicked)
        }
        helperText={errorProperty === "empty" || errorProperty === "" ? "" : errorText}
      />
    </>
  );
};
