import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { useQueryPremisesList } from "../../../hooks/queries/useQueryPremisesList";
import useUserRoles from "../../../hooks/useUserRoles";
import { getCities } from "../../../services/eventsServices";
import { getAllGrouping, getOrganizations } from "../../../services/organizationsServices";
import ButtonsCreateEvent from "./ButtonsCreateEvent";
import SearchFilter from "./filter/SearchFilter";
import SelectFilter from "./filter/SelectFilter";

const EventFilters = (props) => {
  const { t } = useTranslation();
  const {
    isEventsPath,
    onSearchChange,
    grouping,
    onGroupingFilter,
    premise,
    onPremiseFilter,
    cityFilter,
    onCityFilter,
    dateFilter,
    onDateFilter,
    eventType,
    onEventFilter,
    organization,
    onOrganizationFilter,
  } = props;

  const { isSuperAdmin, isManager, isAdmin, isOfficeEditor } = useUserRoles();

  //Sedes
  const { data: premises = [] } = useQueryPremisesList(organization, grouping);

  //Ciudades
  const { data: cities = [] } = useQuery(RQ_KEY.ALL_CITIES, () => getCities(), {
    refetchOnWindowFocus: false,
  });

  const citiesNames = cities.map((city) => ({ id: city.city, name: city.city }));

  //Agrupaciones
  const { data: groups = [] } = useQuery(RQ_KEY.ALL_GROUPING, () => getAllGrouping(), {
    refetchOnWindowFocus: false,
    enabled: isSuperAdmin,
  });

  const eventTypes = [
    { id: "all", name: t("ALL_TYPES") },
    { id: "no-trip", name: t("EVENTS") },
    { id: "trip", name: t("TRAVELS") },
  ];

  const premisesFormat = premises?.map((premise) => ({
    id: premise.id,
    name: premise.name,
  }));

  //Organizations
  const { data: organizations } = useQuery(
    [RQ_KEY.ALL_ORGANIZATIONS, grouping],
    () => getOrganizations(grouping),
    {
      refetchOnWindowFocus: false,
    }
  );

  const organizationsFormat =
    organizations?.map((organization) => ({
      id: organization.id,
      name: organization.legal_name,
    })) || [];

  return (
    <Grid
      container
      spacing={1}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
    >
      <Grid item xs={12} md={3}>
        <SearchFilter onSearchChange={onSearchChange} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={11}
        md={9}
        columnGap={1}
        rowGap={1}
        justifyContent={{ xs: "flex-start", md: "flex-end" }}
      >
        {!isEventsPath && (
          <SelectFilter value={eventType} onChange={onEventFilter} options={eventTypes} />
        )}
        {isSuperAdmin && (
          <SelectFilter
            value={grouping}
            onChange={onGroupingFilter}
            options={[{ id: "all", name: t("ALL_GROUPS") }, ...groups]}
          />
        )}
        <SelectFilter
          value={organization}
          onChange={onOrganizationFilter}
          options={[{ id: "all", name: t("ALL_ORGANIZATIONS") }, ...organizationsFormat]}
        />
        <SelectFilter
          value={premise}
          onChange={onPremiseFilter}
          options={[{ id: "all", name: t("ALL_SITES") }, ...premisesFormat]}
        />
        <SelectFilter
          value={cityFilter}
          onChange={onCityFilter}
          options={[{ id: "all", name: t("ALLS_CITIES") }, ...citiesNames]}
        />
        <SelectFilter
          value={dateFilter}
          onChange={onDateFilter}
          options={[
            { id: "upcoming", name: t("UPCOMING") },
            { id: "pasts", name: t("PAST") },
            { id: "alls", name: t("ALLS") },
          ]}
        />
        {(isAdmin || isManager || isSuperAdmin || isOfficeEditor) && <ButtonsCreateEvent />}
      </Grid>
    </Grid>
  );
};

export default EventFilters;
