import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../constants/utils";

export const OrderTripDetail = ({ order }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    bought_in_domain,
    sku,
    organization_name,
    event_name,
    event_start_date,
    event_end_date,
    event_id,
  } = order || {};

  return (
    <Card sx={{ borderRadius: 3, width: "100%" }}>
      <CardHeader
        title={t("TRIP_DETAILS")}
        sx={{
          backgroundColor: "var(--grey-background)",
        }}
      />
      <CardContent>
        {/* Trip info */}
        <Grid item xs={12} container spacing={3}>
          <Grid item>
            <Typography variant="body2" fontWeight="600">
              {t("TITLE")}
            </Typography>
            <Typography variant="body2">{event_name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" fontWeight="600">
              {t("START_DATE")}
            </Typography>
            <Typography variant="body2">{formatDate(event_start_date)}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" fontWeight="600">
              {t("END_DATE")}
            </Typography>
            <Typography variant="body2">{formatDate(event_end_date)}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" fontWeight="600">
              {t("ORGANIZER")}
            </Typography>
            <Typography variant="body2">{organization_name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" fontWeight="600">
              {t("SKU")}
            </Typography>
            <Typography variant="body2">{sku}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" fontWeight="600">
              {t("BOUGHT_IN")}
            </Typography>
            <Typography variant="body2">{bought_in_domain}</Typography>
          </Grid>
          <Grid item>
            <Button
              className="underline-button"
              onClick={() => navigate(`/event/${event_id}?tab=0`)}
            >
              {t("SEE_TRIP_INFO")}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
