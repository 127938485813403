import { DialogTitle, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import TourLeaderForm from "../TourLeaderForm";
import { CloseDialogButton } from "../../shared/CloseDialogButton";
import { useTranslation } from "react-i18next";

function CreateTourLeaderDialog({ isOpen, onClose }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <CloseDialogButton onClose={onClose} />
      <DialogTitle sx={{ fontWeight: "bold", backgroundColor: "var(--grey-background)" }}>
        {t("CREATE")} {t("TOUR_LEADER")}
      </DialogTitle>
      <Grid container sx={{ padding: 2 }}>
        <TourLeaderForm isFromDialog />
      </Grid>
    </Dialog>
  );
}

export default CreateTourLeaderDialog;
