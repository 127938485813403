import {
  AccountBalanceOutlined,
  AirportShuttleOutlined,
  AttachMoneyOutlined,
  CampaignOutlined,
  CategoryOutlined,
  EventAvailableOutlined,
  GroupsOutlined,
  HandshakeOutlined,
  HotelOutlined,
  InboxOutlined,
  InfoOutlined,
  Inventory2Outlined,
  LanguageOutlined,
  MailOutlineOutlined,
  PaidOutlined,
  PeopleOutline,
  PointOfSaleOutlined,
  QueryStatsOutlined,
  SettingsOutlined,
  StarBorderOutlined,
  TagOutlined,
  TourOutlined,
  TravelExploreOutlined,
} from "@mui/icons-material";
import { ROUTES } from "../../constants/routes";
import { ROLES } from "../../constants/variables";

const {
  ADMIN,
  AFFILIATED,
  SUPER_ADMIN,
  MANAGER_ADMIN,
  OFFICE_ADMIN,
  OFFICE_ADMIN_CAN_EDIT,
  B2B_OPERATOR,
  B2B_SALES_AGENT,
  SCANNER,
} = ROLES;

const MENU_CONFIG = [
  {
    title: "HOME",
    icon: "/media/sidebarIcons/home.svg",
    route: ROUTES.HOME,
    roles: ["ALL"],
  },
  // B2B
  {
    title: "B2B",
    icon: <HandshakeOutlined />,
    route: ROUTES.B2B,
    roles: [SUPER_ADMIN, B2B_OPERATOR, B2B_SALES_AGENT],
    subItems: [
      {
        title: "OPERATOR",
        icon: "/media/sidebarIcons/plane_world.svg",
        route: ROUTES.B2B_OPERATOR,
        roles: [B2B_OPERATOR, SUPER_ADMIN],
      },
      {
        title: "TRIPS_AGENT",
        icon: "/media/sidebarIcons/trips_agent.svg",
        route: ROUTES.B2B_SALES_AGENT,
        roles: [B2B_SALES_AGENT, SUPER_ADMIN],
      },
      {
        title: "TRIPS_CATALOG",
        icon: <TravelExploreOutlined />,
        route: ROUTES.B2B_CATALOGS,
        roles: [B2B_SALES_AGENT, SUPER_ADMIN],
      },
    ],
  },
  // PRODUCTS
  {
    title: "PRODUCTS",
    icon: "/media/sidebarIcons/luggage.svg",
    roles: [SUPER_ADMIN, ADMIN, OFFICE_ADMIN, OFFICE_ADMIN_CAN_EDIT, SCANNER],
    subItems: [
      {
        title: "EVENT_MANAGER",
        icon: "/media/sidebarIcons/events.svg",
        route: "/events",
        roles: ["ALL"],
      },
      {
        title: "TRAVEL_MANAGER",
        icon: "/media/sidebarIcons/travels.svg",
        route: "/trips",
        roles: ["ALL"],
      },
      {
        title: "MEMBER_CARDS",
        icon: "/media/sidebarIcons/card.svg",
        route: ROUTES.CARDS,
        roles: ["ALL"],
      },
      {
        title: "RESOURCES",
        icon: <Inventory2Outlined />,
        route: "",
        roles: [SUPER_ADMIN, ADMIN, OFFICE_ADMIN, OFFICE_ADMIN_CAN_EDIT],
        subItems: [
          {
            title: "EXTRAS",
            icon: <StarBorderOutlined />,
            route: ROUTES.EXTRAS,
            roles: ["ALL"],
          },
          {
            title: "TOUR_POINTS",
            icon: "/media/sidebarIcons/style.svg",
            route: ROUTES.TOUR_POINTS,
            roles: ["ALL"],
          },
          {
            title: "HOTELS",
            icon: <HotelOutlined />,
            route: ROUTES.HOTELS,
            roles: ["ALL"],
          },
          {
            title: "TRANSPORTS",
            icon: <AirportShuttleOutlined />,
            route: ROUTES.TRANSPORTS,
            roles: ["ALL"],
          },
          {
            title: "TOUR_LEADERS",
            icon: <TourOutlined />,
            route: ROUTES.TOUR_LEADERS,
            roles: ["ALL"],
          },
        ],
      },
    ],
  },
  // MOVEMENTS
  {
    title: "MOVEMENTS",
    icon: <PaidOutlined />,
    roles: [SUPER_ADMIN, AFFILIATED, ADMIN, OFFICE_ADMIN, OFFICE_ADMIN_CAN_EDIT],
    subItems: [
      {
        title: "ORDERS",
        icon: "/media/sidebarIcons/orders.svg",
        route: ROUTES.ORDERS,
        roles: ["ALL"],
      },
      {
        title: "SALES",
        icon: <AttachMoneyOutlined />,
        route: ROUTES.SALES,
        roles: [SUPER_ADMIN],
      },
      {
        title: "BILLING",
        icon: <AccountBalanceOutlined />,
        route: ROUTES.BILLING,
        roles: [SUPER_ADMIN, MANAGER_ADMIN, ADMIN],
      },
      {
        title: "REFUNDS",
        icon: "/media/sidebarIcons/refund.svg",
        route: ROUTES.REFUNDS,
        roles: [SUPER_ADMIN, MANAGER_ADMIN, ADMIN],
      },
      {
        title: "EVENT_SALES",
        icon: <EventAvailableOutlined />,
        route: ROUTES.EVENT_STATISTICS,
        roles: [SUPER_ADMIN, MANAGER_ADMIN, ADMIN],
      },
    ],
  },
  // MARKETING
  {
    title: "MARKETING",
    icon: <CampaignOutlined />,
    roles: ["ALL"],
    subItems: [
      {
        title: "BLOG",
        icon: <GroupsOutlined />,
        route: ROUTES.BLOG,
        roles: ["ALL"],
      },
      {
        title: "TAGS",
        icon: <TagOutlined />,
        route: ROUTES.TAGS,
        roles: ["ALL"],
      },
    ],
  },
  //COMMUNITY
  {
    title: "COMMUNITY",
    icon: <PeopleOutline />,
    roles: [SUPER_ADMIN, MANAGER_ADMIN, ADMIN, AFFILIATED, OFFICE_ADMIN_CAN_EDIT],
    subItems: [
      {
        title: "CLIENTS",
        icon: "/media/sidebarIcons/users.svg",
        route: ROUTES.USERS,
        roles: ["ALL"],
      },
      {
        title: "ORGANIZATIONS",
        icon: "/media/sidebarIcons/organizations.svg",
        route: ROUTES.ORGANIZATIONS,
        roles: [SUPER_ADMIN, ADMIN, MANAGER_ADMIN],
      },
      {
        title: "PREMISES_SUCURSAL",
        icon: "/media/sidebarIcons/premise.svg",
        route: ROUTES.PREMISES,
        roles: ["ALL"],
      },
      {
        title: "ADMINS",
        icon: "/media/sidebarIcons/admin.svg",
        route: ROUTES.ADMINS,
        roles: [SUPER_ADMIN, ADMIN, MANAGER_ADMIN, AFFILIATED],
      },
      {
        title: "RRPP_MANAGER",
        icon: <PeopleOutline />,
        route: ROUTES.RRPPS,
        roles: [SUPER_ADMIN, ADMIN, MANAGER_ADMIN, AFFILIATED],
      },
    ],
  },
  // STATISTICS
  {
    title: "STATISTICS",
    icon: <QueryStatsOutlined />,
    route: ROUTES.STATISTICS,
    roles: [SUPER_ADMIN, MANAGER_ADMIN, ADMIN, AFFILIATED],
  },
  // CONFIGURATION
  {
    title: "CONFIGURATION",
    icon: <SettingsOutlined />,
    roles: [SUPER_ADMIN, MANAGER_ADMIN, ADMIN],
    subItems: [
      {
        title: "CATEGORIES",
        icon: <CategoryOutlined />,
        route: ROUTES.CATEGORIES,
        roles: ["ALL"],
      },
      {
        title: "DOMAINS",
        icon: <LanguageOutlined />,
        route: ROUTES.DOMAINS,
        roles: ["ALL"],
      },
      {
        title: "NOTIFICATION_MANAGER",
        icon: <MailOutlineOutlined />,
        route: ROUTES.MAILING,
        roles: [SUPER_ADMIN, MANAGER_ADMIN, ADMIN],
      },
      {
        title: "EVENT_REQUESTS",
        icon: <InboxOutlined />,
        route: ROUTES.EVENT_REQUESTS,
        roles: ["ALL"],
      },
      {
        title: "TPV",
        icon: <PointOfSaleOutlined />,
        route: ROUTES.TPV,
        roles: ["ALL"],
      },
    ],
  },
  // WIKIBLISS
  {
    title: "WIKIBLISS",
    icon: <InfoOutlined />,
    route: ROUTES.WIKIBLISS,
    roles: ["ALL"],
  },
];

export default MENU_CONFIG;
