import { TextField } from "@mui/material";
import { focusColor } from "../../../components/shared/textFieldStyle";
import { PAYMENT_OPTIONS } from "../../../constants/variables";

export const renderTextField = (
  label,
  fieldName,
  value,
  ticketIndex,
  type,
  errorProperty,
  errorText,
  regex,
  handleFieldChange,
  addClicked
) => (
  <>
    <TextField
      sx={focusColor}
      margin="normal"
      required
      fullWidth
      id={fieldName}
      name={fieldName}
      autoComplete={fieldName}
      label={label}
      value={value}
      type={type ? type : "text"}
      onChange={(e) => handleFieldChange(e, ticketIndex, regex, errorText)}
      inputProps={
        fieldName === "client_document_expiry_date"
          ? {
              min: new Date().toISOString().split("T")[0],
              max: "2100-12-31",
            }
          : fieldName === "client_birth_date"
          ? {
              min: "1900-01-01",
              max: new Date().toISOString().split("T")[0],
            }
          : undefined
      }
      error={
        (errorProperty !== "" && errorProperty !== "empty") ||
        (errorProperty === "empty" && addClicked)
      }
      helperText={errorProperty === "empty" || errorProperty === "" ? "" : errorText}
    />
  </>
);

export const getTicketsNearestDate = (tickets = []) => {
  if (tickets.length === 0) return "";

  const ticketsDates = tickets
    .filter((ticket) => ticket.start_date)
    .map((ticket) => new Date(ticket.start_date));

  const nearestDate = ticketsDates.reduce((acc, date) => {
    if (!acc || date < acc) return date;
    return acc;
  });

  return nearestDate.toISOString();
};

export const showSplitPayment = (eventData, selectedPayment = "") => {
  const hasDoublePayments = eventData?.has_double_payment === 1;
  const hasMultiplePayments = eventData?.event_payments?.length > 1;
  const paymentType = selectedPayment !== PAYMENT_OPTIONS.OFFICE_GUEST;

  return (hasDoublePayments || hasMultiplePayments) && paymentType;
};

const checkPaymentDate = (days, eventStartDate) => {
  const eventDate = new Date(eventStartDate);

  eventDate.setUTCDate(eventDate.getUTCDate() - Number(days));

  return eventDate > new Date();
};

export const uniquePaymentsNumbers = (eventPayments = [], eventStartDate, tickets = []) => {
  if (eventPayments?.length === 0) return 0;

  const ticketDate = getTicketsNearestDate(tickets);
  const date = ticketDate ? ticketDate : eventStartDate;

  const validPayments = eventPayments.filter((payment) =>
    checkPaymentDate(payment.days_before_start, date)
  );

  const payments = [
    ...new Set(
      eventPayments
        .map((payment) => payment.number_of_payments)
        .filter((num) => validPayments.some((p) => p.number_of_payments === num))
    ),
  ];

  return payments;
};
