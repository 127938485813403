import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { createAssistantUser, createAssistantUserErrors } from "../../classes/userClass";
import { AssistantInfo } from "../../components/events/createAssitant/AssistantInfo";
import { AssistantTickets } from "../../components/events/createAssitant/AssistantTickets";
import Header from "../../components/Header";
import { allFieldsOk } from "../../components/shared/FormsValidator";
import { Loading } from "../../components/shared/Loading";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { RQ_KEY } from "../../constants/query";
import { EVENTS_TYPE } from "../../constants/variables";
import {
  createOrder,
  createOrderAndUser,
  getEventById,
  getTicketsAndExtras,
} from "../../services/eventsServices";

const CreateAssistant = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { eventId } = useParams();

  const [formData, setFormData] = useState(createAssistantUser);
  const [formErrors, setFormErrors] = useState(createAssistantUserErrors);
  const [addClicked, setAddClicked] = useState(false);
  const [doublePayment, setDoublePayment] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [loading, setLoading] = useState(false);
  const [showNewUser, setShowNewUser] = useState(false);

  if (formData.event_id === "") {
    setFormData({
      ...formData,
      event_id: eventId,
    });
  }

  // Event Info
  const { isLoading, data: eventData = [] } = useQuery(
    [RQ_KEY.EVENT_DETAILS, eventId],
    () => getEventById(eventId),
    {
      enabled: !!eventId,
    }
  );

  const { data: ticketsExtrasData = [] } = useQuery(
    [RQ_KEY.TICKET_AND_EXTRAS, eventData?.id],
    () => getTicketsAndExtras(eventData.id),
    {
      enabled: !!eventData?.id,
    }
  );

  const isTrip =
    eventData.event_type === EVENTS_TYPE.TRIP ||
    eventData.event_type === EVENTS_TYPE.TRIP_COMPOSITE;

  // Breadcrumbs
  const breadcrumbs = [
    {
      name: isTrip ? t("TRAVELS") : t("EVENTS"),
      link: isTrip ? "/trips" : "/events",
    },
    {
      name: eventData.name ? eventData.name : "",
      link: `/event/${eventData.id}`,
    },
    {
      name: t("ADD_ASSISTANT"),
    },
  ];

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (formData.tickets.length > 0) {
      try {
        const bodyToSend = JSON.parse(JSON.stringify(formData));

        bodyToSend.two_time_payment = doublePayment && !selectedPayment;
        bodyToSend.cancellation_insurance = false;
        bodyToSend.number_of_payments = selectedPayment ? selectedPayment : null;

        for (let i = 0; i < bodyToSend.tickets.length; i++) {
          for (let j = 0; j < bodyToSend.tickets[i].extras.length; j++) {
            bodyToSend.tickets[i].extras[j].amount = parseInt(
              bodyToSend.tickets[i].extras[j].amount
            );
          }
        }

        if (showNewUser) {
          delete bodyToSend.user_id;
          await createOrderAndUser(bodyToSend);
        } else {
          delete bodyToSend.form_user_data;
          await createOrder(bodyToSend);
        }

        toastMessageSuccess(t("ASSISTANT_ADDED"));
        setTimeout(() => {
          navigate(`/event/${eventData.id}`);
        }, 1500);
      } catch (error) {
        toastMessageError(error.response?.data?.error || t("ERROR"));
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handleAddAssistant = (e) => {
    e.preventDefault();
    setAddClicked(true);
    // CHECK IF ALL FIELDS ARE FILLED & NO ERRORS
    const allFieldsFilled =
      formData.event_id && formData.payment_type && formData.tickets.length > 0;

    let userFieldsValid = true;
    let errors = { ...formErrors };

    if (showNewUser) {
      const requiredFields = ["name", "surname", "email", "phone", "phone_code"];
      userFieldsValid = requiredFields.every((field) => formData.form_user_data[field]);

      errors = {
        ...errors,
        user_id: "",
        form_user_data: requiredFields.reduce(
          (acc, field) => ({
            ...acc,
            [field]: formData.form_user_data[field] ? "" : t("REQUIRED_FIELD"),
          }),
          {}
        ),
      };
    } else {
      userFieldsValid = !!formData.user_id;
      errors.user_id = formData.user_id ? "" : t("REQUIRED_FIELD");
    }

    setFormErrors(errors);

    const noErrors = allFieldsOk(errors);

    if (allFieldsFilled && userFieldsValid && noErrors) {
      handleSubmit(e);
    } else {
      console.log("Errors in form", errors);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("ADD_NEW_ASSISTANT")} />
      <Grid
        container
        spacing={1}
        sx={{
          px: 1,
          color: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: "1200px",
          margin: "10px auto",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            py: 2,
            px: 3,
            width: "100%",
            maxWidth: "1200px",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <AssistantInfo
              formData={formData}
              formErrors={formErrors}
              addClicked={addClicked}
              setFormData={setFormData}
              setFormErrors={setFormErrors}
              eventData={eventData}
              doublePayment={doublePayment}
              setDoublePayment={setDoublePayment}
              setShowNewUser={setShowNewUser}
              showNewUser={showNewUser}
              ticketsExtrasData={ticketsExtrasData}
              selectedPayment={selectedPayment}
              setSelectedPayment={setSelectedPayment}
            />
            <Divider />
            <AssistantTickets
              formData={formData}
              setFormData={setFormData}
              eventData={eventData}
              setAddClicked={setAddClicked}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              doublePayment={doublePayment}
              addClicked={addClicked}
              selectedPayment={selectedPayment}
              ticketsExtrasData={ticketsExtrasData}
            />
            {formData.tickets.length === 0 && addClicked && (
              <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("MINIMUM_TICKET")}</Typography>
            )}
            {!allFieldsOk(formErrors) && addClicked && (
              <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("REQUIRED_FIELD")}</Typography>
            )}
            <Button
              type="submit"
              className="oniria-btn"
              fullWidth
              variant="contained"
              onClick={handleAddAssistant}
              disabled={loading}
              sx={{ mt: 3, mb: 2, fontSize: "20px", borderRadius: 3 }}
            >
              {loading ? t("SAVING") : t("ADD_ASSISTANT")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateAssistant;
