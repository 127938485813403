import { createContext, useContext, useState } from "react";

const DomainContext = createContext();

export const DomainProvider = ({ children }) => {
  const [selectedDomain, setSelectedDomain] = useState("");

  return (
    <DomainContext.Provider value={{ selectedDomain, setSelectedDomain }}>
      {children}
    </DomainContext.Provider>
  );
};

export const useDomain = () => {
  return useContext(DomainContext);
};
