import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ListAltIcon from "@mui/icons-material/ListAlt";
import QrCodeIcon from "@mui/icons-material/QrCode";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Box, Button, Grid, IconButton, Modal } from "@mui/material";
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ValidateAssistant from "../events/scanner/ValidateAssitant";
import { Loading } from "./Loading";

const qrConfig = {
  fps: 10,
  qrbox: { width: 200, height: 300 },
  rememberLastUsedCamera: true,
  aspectRatio: 1,
};

const Scanner = ({
  onResult,
  setQrResponse,
  isMobileButton,
  goBack,
  eventName,
  totalTickets,
  consumedTickets,
  eventId,
  openCheck,
  assistant,
  setOpenCheck,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [scanning, setScanning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const html5QrCode = useRef(null);

  const lastValue = useRef("");

  const qrCodeSuccessCallback = useCallback(
    async (decodedText) => {
      if (lastValue.current === decodedText) {
        return;
      }
      lastValue.current = decodedText;
      onResult(decodedText);

      setTimeout(() => {
        lastValue.current = "";
      }, 3000);
    },
    [onResult]
  );

  const startScanning = useCallback(async () => {
    setLoading(true);
    setQrResponse("");
    try {
      await html5QrCode.current.start(
        { facingMode: "environment" },
        qrConfig,
        qrCodeSuccessCallback
      );
      setScanning(true);
    } catch (err) {
      console.log("Error starting scanner", err);
      setModalOpen(false);
    } finally {
      setLoading(false);
    }
  }, [qrCodeSuccessCallback, setQrResponse]);

  const stopScanning = useCallback(() => {
    if (html5QrCode.current) {
      html5QrCode.current
        .stop()
        .then(() => {
          html5QrCode.current?.clear();
          setScanning(false);
          setModalOpen(false);
        })
        .catch((err) => {
          console.log("Error stopping scanner", err);
        });
    }
  }, []);

  // Wait for modal to open before starting the scanner
  useEffect(() => {
    if (modalOpen) {
      setTimeout(() => {
        if (!html5QrCode.current) {
          html5QrCode.current = new Html5Qrcode("reader", {
            formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
          });
        }
        startScanning();
      }, 300);
    }
  }, [modalOpen, startScanning]);

  const toggleScanning = useCallback(() => {
    if (scanning) {
      stopScanning();
    } else {
      setModalOpen(true);
    }
  }, [scanning, stopScanning]);

  const handleBack = () => {
    stopScanning();
    goBack();
  };

  const handleGoAssistants = (e) => {
    e.preventDefault();
    stopScanning();
    navigate(`/event/${eventId}?tab=1`);
  };

  const handleClearQrScanner = () => {
    if (html5QrCode.current && scanning) {
      html5QrCode.current
        .stop()
        .then(() => {
          return html5QrCode.current.clear();
        })
        .catch((err) => {
          console.error("Error stopping or clearing the scanner: ", err);
        });
    }
  };

  // Cleanup
  useEffect(() => {
    return () => {
      handleClearQrScanner();
    };
  }, []);

  return (
    <>
      {isMobileButton ? (
        <Grid item xs={5} container justifyContent="center">
          <IconButton onClick={toggleScanning} className="oniria-btn" sx={{ borderRadius: "20%" }}>
            <QrCodeScannerIcon sx={{ fontSize: "3rem", color: "#fff" }} />
          </IconButton>
        </Grid>
      ) : (
        <Button
          className="oniria-btn-qrs"
          onClick={toggleScanning}
          variant="contained"
          sx={{
            width: "fit-content",
            fontSize: "12px",
            borderRadius: 3,
            display: "flex",
            alignItems: "center",
            gap: "4px",
            alignSelf: "flex-end",
            marginBottom: "10px",
          }}
        >
          <QrCodeIcon />
          {scanning ? t("QR_BUTTON_STOP") : t("QR_BUTTON_START")}
        </Button>
      )}
      <Modal open={modalOpen} onClose={stopScanning}>
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 10000,
          }}
        >
          {/* EVENT INFO */}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2, width: "100%" }}
          >
            <Grid item xs={4}>
              <Button
                variant="text"
                onClick={handleBack}
                sx={{
                  color: "var(--color-title-grey)",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "capitalize",
                }}
              >
                <ArrowBackIosNewIcon /> {t("GO_BACK")}
              </Button>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Grid container justifyContent="center" alignItems="center">
                {eventName}
              </Grid>
              <Grid container alignItems="center" justifyContent="center">
                <CheckCircleIcon sx={{ color: "var(--green-success)", mr: 1 }} /> {consumedTickets}{" "}
                / {totalTickets}
              </Grid>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <IconButton
                onClick={handleGoAssistants}
                className="oniria-btn"
                sx={{ borderRadius: "20%" }}
              >
                <ListAltIcon sx={{ fontSize: "3rem", color: "#fff" }} />
              </IconButton>
            </Grid>
          </Grid>

          {!openCheck && (
            <Button className="oniria-btn" onClick={stopScanning} sx={{ color: "#fff", mb: 2 }}>
              <CloseIcon sx={{ mr: 1 }} /> {t("STOP_CAMERA")}
            </Button>
          )}

          {/* Scanner container */}
          <div id="reader" style={{ width: "100%", display: openCheck ? "none" : "block" }} />

          {/* Validate assistant checkbox*/}
          {openCheck && (
            <ValidateAssistant
              assistant={assistant}
              setQrResponse={setQrResponse}
              setOpenCheck={setOpenCheck}
              eventId={eventId}
            />
          )}

          {loading && <Loading />}
        </Box>
      </Modal>
    </>
  );
};

export default Scanner;
