import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DEFAULT_LANGUAGE } from "../../../../../constants/variables";
import { createTourPoint } from "../../../../../services/tourPoints";
import { prepareTourPointToSend } from "../../../../../utils/tourPoints";
import MapContent from "../../../../shared/Map/LeafletMap";
import { focusColor } from "../../../../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../../../shared/toastMessage";

const AddTourPointDialog = ({ isDialogOpen, handleCloseDialog, refetchTourPoints }) => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.userInfo);
  const { premises_id = [] } = userInfo;

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [tourPoint, setTourPoint] = useState({
    name: "",
    sku: "",
    default_language: DEFAULT_LANGUAGE,
    latitude: "",
    longitude: "",
  });

  const handleChangeLatLong = (lat, lng) => {
    setTourPoint((prev) => ({ ...prev, latitude: lat, longitude: lng }));
  };

  const handleCreateTourPoint = async () => {
    try {
      setLoadingSubmit(true);
      const bodyToSend = await prepareTourPointToSend(tourPoint);
      bodyToSend.owner_premise_id = premises_id?.[0] || "";
      await createTourPoint(bodyToSend);
      toastMessageSuccess(t("CREATED_SUCCESSFULLY"));
      setTimeout(() => {
        handleCloseDialog();
      }, 1500);
    } catch (e) {
      console.log("Error al crear tour point", e);
      toastMessageError(e?.response?.data?.error || t("ERROR"));
    } finally {
      setLoadingSubmit(false);
      refetchTourPoints();
    }
  };

  return (
    <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
      <DialogTitle>{t("CREATE_NEW_TOUR_POINT")}</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            placeholder={t("NAME")}
            value={tourPoint.name}
            onChange={(e) => setTourPoint((prev) => ({ ...prev, name: e.target.value }))}
            helperText={tourPoint.name?.message}
            fullWidth
            sx={[focusColor, { mb: 2 }]}
            required
          />
          <TextField
            placeholder={t("SKU")}
            value={tourPoint.sku}
            onChange={(e) => setTourPoint((prev) => ({ ...prev, sku: e.target.value }))}
            helperText={tourPoint.sku?.message}
            fullWidth
            sx={focusColor}
            required
          />
          <Grid item xs={12}>
            <Typography>{t("MAP_DESCRIPTION")}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>{t("MAP_DESCRIPTION_2")}</Typography>
          </Grid>
          <Box sx={{ height: "250px", width: "100%", minWidth: "500px", mt: 2 }}>
            <MapContent
              marker
              handleChangeLatLong={handleChangeLatLong}
              isDisabled={false}
              lat={tourPoint.latitude}
              lng={tourPoint.longitude}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", gap: 2 }}>
        <Button onClick={handleCloseDialog} className="oniria-btn-cancel">
          {t("CANCEL")}
        </Button>
        <Button
          onClick={handleCreateTourPoint}
          color="primary"
          variant="contained"
          className="oniria-btn"
          disabled={loadingSubmit}
        >
          {loadingSubmit ? t("SAVING") : t("CREATE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTourPointDialog;
