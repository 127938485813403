import { transformObjectWithUrls } from "../components/shared/FormsValidator";
import { SERVICE } from "../constants/api";
import { http2 } from "../constants/axios";
import { B2B_FILTERS_VALUES } from "../constants/variables";

export async function getB2bSales(filters, page, perPage) {
  try {
    const { search, premiseId, product, status, month } = filters;

    const params = new URLSearchParams({
      ...(page && { page }),
      ...(perPage && { perpage: perPage }),
      ...(search && { search }),
      ...(premiseId && premiseId !== "all" && { premiseid: premiseId }),
      ...(product && product !== "all" && { eventid: product }),
      ...(status && status !== "all" && { status }),
      ...(month && month !== "all" && { month }),
    });

    const { data } = await http2.get(`${SERVICE.B2B}/sales?${params}`);
    return data;
  } catch (error) {
    console.error("Error fetching b2b sales:", error);
    throw error;
  }
}

export const getB2bEventSales = async (eventId) => {
  try {
    const { data } = await http2.get(`${SERVICE.B2B}/sales/event/${eventId}`);
    return data;
  } catch (error) {
    console.error("Error fetching b2b event sales:", error);
    throw error;
  }
};

export const getB2bEventAgentSales = async (eventId, domain, page, perPage, filters) => {
  try {
    const params = new URLSearchParams();
    if (page) params.append("page", page);
    if (perPage) params.append("perpage", perPage);
    if (domain) params.append("domain", domain);
    if (filters.search) params.append("search", filters.search);
    if (filters.status) params.append("status", filters.status);

    if (filters.fromDate) params.append("fromDate", filters.fromDate);
    if (filters.toDate) params.append("toDate", filters.toDate);
    if (filters.order?.asc && filters.order.asc.length > 0) {
      params.append("asc", filters.order.asc.join(","));
    }
    if (filters.order?.desc && filters.order.desc.length > 0) {
      params.append("desc", filters.order.desc.join(","));
    }

    const { data } = await http2.get(
      `${SERVICE.B2B}/sales/event/agent/${eventId}?${params.toString()}`
    );
    return data;
  } catch (error) {
    console.error("Error fetching b2b event sales:", error);
    throw error;
  }
};

export async function getB2bCatalogs(filters, page, perPage) {
  try {
    const params = new URLSearchParams();

    const { search, catalogType, fromDate, toDate, tourPointId, premiseId, price, days, order } =
      filters;

    if (search) params.append("search", filters.search);
    if (page) params.append("page", page);
    if (perPage) params.append("perpage", perPage);
    if (fromDate) params.append("fromDate", fromDate);
    if (toDate) params.append("toDate", toDate);
    if (price && price[0] !== B2B_FILTERS_VALUES.MIN_PRICE) {
      params.append("fromPrice", price[0]);
    }
    if (price && price[1] !== B2B_FILTERS_VALUES.MAX_PRICE) {
      params.append("toPrice", price[1]);
    }
    if (days && days[0] !== B2B_FILTERS_VALUES.MIN_DAYS) {
      params.append("fromDays", days[0]);
    }
    if (days && days[1] !== B2B_FILTERS_VALUES.MAX_DAYS) {
      params.append("toDays", days[1]);
    }

    if (tourPointId && tourPointId !== "all") params.append("tour_point_id", tourPointId);
    if (premiseId && premiseId !== "all") params.append("premise_id", premiseId);
    if (catalogType && catalogType !== "all") params.append("catalogType", catalogType);

    if (order.asc && order.asc.length > 0) {
      params.append("asc", order.asc.join(","));
    }
    if (order.desc && order.desc.length > 0) {
      params.append("desc", order.desc.join(","));
    }

    const { data } = await http2.get(`${SERVICE.B2B}/catalogs?${params}`);
    return data;
  } catch (error) {
    console.error("Error fetching b2b catalogs:", error);
    throw error;
  }
}

export const createTripAgent = async (data) => {
  try {
    const newAgent = await transformObjectWithUrls(data);

    const { data: agent } = await http2.post(`${SERVICE.B2B}/agents`, newAgent);
    return agent;
  } catch (error) {
    console.error("Error creating trip agent:", error);
    throw error;
  }
};

export const getB2bSalesAgents = async (filters, page, perPage) => {
  try {
    const params = new URLSearchParams();
    const { search, order, hasCommission } = filters;

    if (search) params.append("search", filters.search);
    if (page) params.append("page", page);
    if (perPage) params.append("perpage", perPage);

    if (order?.asc && order.asc.length > 0) {
      params.append("asc", order.asc.join(","));
    }
    if (order?.desc && order.desc.length > 0) {
      params.append("desc", order.desc.join(","));
    }
    if (hasCommission) params.append("hasCommission", true);

    const { data } = await http2.get(`${SERVICE.B2B}/agents?${params}`);
    return data;
  } catch (error) {
    console.error("Error fetching b2b sales agents:", error);
    throw error;
  }
};

// AGREEMENTS
export const createB2bAgreements = async (body) => {
  try {
    const { data } = http2.post(`${SERVICE.B2B}/agreements`, body);
    return data;
  } catch (error) {
    console.error("Error creating b2b agreement:", error);
    throw error;
  }
};

export const editB2bAgreements = async (id, body) => {
  try {
    const { data } = await http2.put(`${SERVICE.B2B}/agreements/${id}`, body);
    return data;
  } catch (error) {
    console.error("Error editing b2b agreement:", error);
    throw error;
  }
};

export const getB2bAgreementsAgency = async (page, perPage) => {
  try {
    const params = new URLSearchParams();

    if (page) params.append("page", page);
    if (perPage) params.append("perpage", perPage);

    const { data } = await http2.get(`${SERVICE.B2B}/agreements/agency?${params}`);
    return data;
  } catch (error) {
    console.error("Error fetching b2b agreements agency:", error);
    throw error;
  }
};

export const getB2bAgreements = async (filters, page, perPage) => {
  try {
    const params = new URLSearchParams();
    const { search, status } = filters;

    if (search) params.append("search", filters.search);
    if (status) params.append("status", filters.status);
    if (page) params.append("page", page);
    if (perPage) params.append("perpage", perPage);

    const { data } = await http2.get(`${SERVICE.B2B}/agreements?${params}`);
    return data;
  } catch (error) {
    console.error("Error fetching b2b agreements:", error);
    throw error;
  }
};

export const deleteB2bAgreement = async (id, body) => {
  console.log("body", body);
  try {
    const { data } = await http2.delete(`${SERVICE.B2B}/agreements/${id}`, {
      data: body,
    });
    return data;
  } catch (error) {
    console.error("Error deleting b2b agreement:", error);
    throw error;
  }
};

// PAYMENTS
export const settleB2bPayment = async (body) => {
  try {
    const { data } = await http2.post(`${SERVICE.B2B}/payments/settle`, body);
    return data;
  } catch (error) {
    console.error("Error settle payment:", error);
    throw error;
  }
};

// CONTACT
export const contactForTrip = async (operatorId, eventId) => {
  try {
    const body = { event_id: eventId };
    const { data } = await http2.post(`${SERVICE.B2B}/contact/${operatorId}`, body);
    return data;
  } catch (error) {
    console.error("Error contact for trip:", error);
    throw error;
  }
};

export const getEventB2bSales = async (eventId) => {
  try {
    const { data } = await http2.get(`${SERVICE.B2B}/sales/event-resumed/${eventId}`);
    return data;
  } catch (error) {
    console.error("Error get event b2b sales:", error);
    throw error;
  }
};
