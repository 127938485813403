import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { ROLES } from "../../../constants/variables";
import { getB2bAgreementsAgency } from "../../../services/b2bServices";
import { getFilterTrips } from "../../../services/eventsServices";

export const useQueryB2bTrips = (page, perPage, filters, userType) => {
  const { search } = filters;

  return useQuery(
    [RQ_KEY.TRIPS_B2B, filters, page, perPage, userType],
    () => {
      if (userType === ROLES.B2B_OPERATOR) {
        return getFilterTrips("", search, "", page, perPage, "", "", "", true);
      } else {
        return getB2bAgreementsAgency(page, perPage);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};
