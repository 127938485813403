import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import useQueryTourLeaderDetails from "../../hooks/queries/tourLeaders/useQueryTourLeaderDetails";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import { tourLeaderSchema } from "../../schemas/tourLeaders";
import {
  assignDomainsToTourLeader,
  createTourLeader,
  editTourLeader,
} from "../../services/tourLeaders";
import { prepareTourLeaderToReceive, prepareTourLeaderToSend } from "../../utils/tourLeader";
import { handleSelectImageReactHook } from "../shared/FormsValidator";
import ImageUpload from "../shared/ImageUpload";
import { Loading } from "../shared/Loading";
import SelectHookForm from "../shared/SelectHookForm";
import { focusColor } from "../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import { FormFieldHeader } from "../tripPlus/FormFieldHeader";
import TourLeaderAddLanguages from "./TourLeaderAddLanguages";

const TourLeaderForm = ({ isFromDialog }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handlePremisesSuccess = (data) => {
    if (data.length === 1) {
      setValue("owner_premise_id", data[0].id);
    }
  };

  const handleDomainsSuccess = (data) => {
    if (data.length === 1) {
      setValue("domains_ids", [data[0].domain_id]);
    }
  };

  const { data: premises = [] } = useQueryPremisesList("", "", handlePremisesSuccess);
  const { data: domains = [] } = useQueryDomains(handleDomainsSuccess);

  const formMethods = useForm({
    resolver: zodResolver(tourLeaderSchema),
    mode: "onBlur",
  });

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = formMethods;

  const handleSetFormData = (data) => {
    const newData = prepareTourLeaderToReceive(data);
    reset(newData);
  };

  const { isLoading, isRefetching } = useQueryTourLeaderDetails(id, handleSetFormData);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    event.stopPropagation();

    setLoadingSubmit(true);
    try {
      const bodyToSend = await prepareTourLeaderToSend(data);
      if (id) {
        // EDIT
        await editTourLeader(id, bodyToSend);
        if (bodyToSend.domains_ids && bodyToSend.domains_ids.length > 0) {
          await assignDomainsToTourLeader(bodyToSend.tour_leader_id, bodyToSend.domains_ids);
        }
      } else {
        // NEW
        const response = await createTourLeader(bodyToSend);
        if (response.id && bodyToSend.domains_ids && bodyToSend.domains_ids.length > 0) {
          await assignDomainsToTourLeader(response.id, bodyToSend.domains_ids);
        }
      }
      setTimeout(() => {
        handleGoBack();
      }, 1500);
      toastMessageSuccess(t("CREATED_SUCCESSFULLY"));
    } catch (e) {
      toastMessageError(e?.response?.data?.error || t("ERROR"));
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onError = (errors) => {
    console.log("Errores formulario tour leader", errors);
  };

  const handleGoBack = () => {
    navigate(ROUTES.TOUR_LEADERS);
  };

  return (
    <Grid mt={isFromDialog ? 0 : 7} mb={5}>
      {!isFromDialog && (
        <>
          <IconButton onClick={handleGoBack}>
            <ArrowBack />
          </IconButton>
          <Grid container justifyContent="center" mb={5}>
            <Typography variant="h5">
              {!!id ? t("EDIT") : t("CREATE")} {t("TOUR_LEADER")}
            </Typography>
          </Grid>
        </>
      )}
      {isLoading || isRefetching ? (
        <Loading />
      ) : (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Grid container spacing={4}>
              {/* NAME */}
              <Grid container item spacing={3} xs={12}>
                <Grid item xs={12}>
                  <FormFieldHeader title={t("NAME")} />
                  <TextField
                    {...register("name")}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    required
                    fullWidth
                    sx={focusColor}
                    placeholder={t("NAME")}
                  />
                </Grid>
                {/* SURNAME */}
                <Grid item xs={12}>
                  <FormFieldHeader title={t("SURNAME")} />
                  <TextField
                    placeholder={t("SURNAME")}
                    {...register("surname")}
                    error={!!errors?.surname}
                    required
                    helperText={errors.surname?.message}
                    fullWidth
                    sx={focusColor}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={3} xs={12}>
                {/* EMAIL */}
                <Grid item xs={12}>
                  <FormFieldHeader title={t("EMAIL")} />
                  <TextField
                    placeholder={t("EMAIL")}
                    {...register("email")}
                    error={!!errors?.email}
                    required
                    helperText={errors.email?.message}
                    fullWidth
                    sx={focusColor}
                  />
                </Grid>
                {/* PHONE */}
                <Grid item xs={12}>
                  <FormFieldHeader title={t("PHONE")} />
                  <TextField
                    placeholder={t("PHONE")}
                    {...register("phone")}
                    error={!!errors?.phone}
                    helperText={errors.phone?.message}
                    fullWidth
                    sx={focusColor}
                  />
                </Grid>
                {/* BRITHDATE */}
                <Grid item xs={12}>
                  <FormFieldHeader title={t("BORN_AT")} />
                  <Controller
                    name="born_at"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="date"
                        {...field}
                        error={!!errors?.born_at}
                        helperText={errors.born_at?.message || ""}
                        fullWidth
                        inputProps={{
                          max: new Date().toISOString().split("T")[0],
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={focusColor}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ width: "100%" }} />
                </Grid>
                {/* TODO: neecsita un tooltip o explicacion de para que  */}
                {domains && domains.length === 1 ? null : (
                  <Grid item xs={12}>
                    <FormFieldHeader title={t("DOMAINS")} />
                    <SelectHookForm
                      name={"domains_ids"}
                      control={control}
                      label={t("DOMAINS")}
                      error={errors.domains_ids}
                      multiple
                      defaultValue={[]}
                      options={domains.map((domain) => (
                        <MenuItem key={domain.domain_id} value={domain.domain_id}>
                          {domain.domain_name}
                        </MenuItem>
                      ))}
                    />
                  </Grid>
                )}
                {premises && premises.length === 1 ? null : (
                  <Grid item xs={12}>
                    <FormFieldHeader title={t("PREMISE")} />
                    <SelectHookForm
                      name={"owner_premise_id"}
                      control={control}
                      label={t("PREMISE")}
                      error={errors.owner_premise_id}
                      options={premises.map((premise) => (
                        <MenuItem key={premise.id} value={premise.id}>
                          {premise.name}
                        </MenuItem>
                      ))}
                    />
                  </Grid>
                )}
                {domains && domains.length > 1 && premises && premises.length > 1 ? (
                  <Grid item xs={12}>
                    <Divider sx={{ width: "100%" }} />
                  </Grid>
                ) : null}

                <TourLeaderAddLanguages />
                {/* GENDER */}
                <Grid item xs={12}>
                  <FormFieldHeader title={t("GENDER")} />
                  <Controller
                    name="genre"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value="male"
                          control={<Radio className="radio-oniria" />}
                          label={t("MALE")}
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio className="radio-oniria" />}
                          label={t("FEMALE")}
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio className="radio-oniria" />}
                          label={t("OTHER")}
                        />
                      </RadioGroup>
                    )}
                  />
                  {errors?.gender && (
                    <Typography color="error" variant="caption">
                      {errors.gender?.message}
                    </Typography>
                  )}
                </Grid>
                {/* AVATAR */}
                <Grid item xs={12}>
                  <FormFieldHeader title={t("AVATAR_OPTIONAL_IMAGE")} />
                  <ImageUpload
                    onImageSelect={(e) => handleSelectImageReactHook(e, "avatar_url", setValue)}
                    url={watch("avatar_url")}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container justifyContent="center" gap={2}>
                <Button
                  onClick={handleGoBack}
                  className="oniria-btn-cancel"
                  variant="contained"
                  size="medium"
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  {t("CANCEL")}
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  className="oniria-btn"
                  disabled={loadingSubmit}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                  onClick={(e) => onSubmit(getValues(), e)}
                >
                  {loadingSubmit ? t("SAVING") : t("SAVE")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
    </Grid>
  );
};

export default TourLeaderForm;
