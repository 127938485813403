import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { Loading } from "../../components/shared/Loading";
import { ROUTES } from "../../constants/routes";
import useQueryHotelDetails from "../../hooks/queries/hotels/useQueryHotelDetails";

const HotelDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [hotelDetail, setHotelDetail] = useState({});
  const [open, setOpen] = useState(false); // Estado para el popup

  const breadcrumbs = [
    {
      name: t("HOTEL"),
      link: ROUTES.HOTELS,
    },
  ];

  const handleSetFormData = async (data) => {
    setHotelDetail(data);
  };

  const { isLoading, isRefetching } = useQueryHotelDetails(id, handleSetFormData, false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HOTEL_DETAIL")} />
      {isLoading || isRefetching ? (
        <Loading />
      ) : (
        <>
          <Grid>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                sx={{ height: 140 }}
                image={hotelDetail?.img_url || ""}
                title="Hotel principal img"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {hotelDetail?.name}
                </Typography>
                {hotelDetail?.short_description && (
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {hotelDetail.short_description}
                  </Typography>
                )}
              </CardContent>
              <CardActions>
                <Button size="small" onClick={handleClickOpen}>
                  {t("MORE")}
                </Button>
              </CardActions>
            </Card>

            {/* MAPA DE UBICACIÓN */}
            {/* TODO: tabla de asignaciones */}
            {/* Popup, deberá verse como en la web */}
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{hotelDetail?.name}</DialogTitle>
              <DialogContent>
                <Typography>
                  {hotelDetail?.long_description || "Descripción completa del hotel aquí"}
                </Typography>
                <Typography>{hotelDetail?.address || "Dirección del hotel"}</Typography>
                {/* Agrega más detalles si es necesario */}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t("CLOSE")}</Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </>
      )}
    </>
  );
};

export default HotelDetail;
