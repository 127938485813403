import { Grid, InputLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoToolTip from "../../../shared/InfoToolTip";
import { focusColor } from "../../../shared/textFieldStyle";

const TicketDates = ({
  checkTicketField,
  index,
  ticket,
  ticketErrors,
  buttonClicked,
  isDisabled,
  has_double_payment,
}) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} container columnSpacing={2}>
      <Grid item xs={12} container columnSpacing={2}>
        <Grid item xs={12}>
          <InputLabel sx={{ fontSize: "18px", color: "black" }}>
            <div
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                display: "flex",
              }}
            >
              {t("START_DATE")}
              <InfoToolTip text={t("INFO_TICKET_START_DATE")} />
            </div>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e) =>
              checkTicketField("start_date", e, /.*/, t("CHOOSE_OPTION"), true, index)
            }
            type="date"
            margin="normal"
            required
            fullWidth
            id="start_date"
            name="start_date"
            value={ticket.start_date || ""}
            autoComplete="start_date"
            error={
              (ticketErrors.start_date !== "" && ticketErrors.start_date !== "empty") ||
              (ticketErrors.start_date === "empty" && buttonClicked)
            }
            helperText={ticketErrors.start_date !== "empty" ? ticketErrors.start_date : ""}
            disabled={isDisabled}
            sx={focusColor}
            inputProps={{
              min: new Date().toISOString().split(" ")[0],
              max: "2100-12-31",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e) =>
              checkTicketField("start_date_time", e, /.*/, t("CHOOSE_OPTION"), true, index)
            }
            type="time"
            margin="normal"
            required
            fullWidth
            id="start_date_time"
            name="start_date_time"
            value={ticket.start_date_time || ""}
            autoComplete="start_date_time"
            disabled={isDisabled}
            sx={focusColor}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container columnSpacing={2}>
        <Grid item xs={12}>
          <InputLabel sx={{ fontSize: "18px", color: "black" }}>
            <div
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                display: "flex",
              }}
            >
              {t("END_DATE")}
              <InfoToolTip text={t("INFO_TICKET_END_DATE")} />
            </div>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e) => checkTicketField("end_date", e, /.*/, t("CHOOSE_OPTION"), true, index)}
            type="date"
            margin="normal"
            required
            fullWidth
            id="end_date"
            name="end_date"
            value={ticket.end_date || ""}
            autoComplete="end_date"
            error={
              (ticketErrors.end_date !== "" && ticketErrors.end_date !== "empty") ||
              (ticketErrors.end_date === "empty" && buttonClicked)
            }
            helperText={ticketErrors.end_date !== "empty" ? ticketErrors.end_date : ""}
            disabled={isDisabled}
            sx={focusColor}
            inputProps={{
              min: ticket.start_date,
              max: "2100-12-31",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e) =>
              checkTicketField("end_date_time", e, /.*/, t("CHOOSE_OPTION"), true, index)
            }
            type="time"
            margin="normal"
            required
            fullWidth
            id="end_date_time"
            name="end_date_time"
            value={ticket.end_date_time || ""}
            autoComplete="end_date_time"
            disabled={isDisabled}
            sx={focusColor}
          />
        </Grid>
      </Grid>
      {has_double_payment && (
        <Grid item xs={12} container columnSpacing={2}>
          <Grid item xs={12}>
            <InputLabel sx={{ fontSize: "18px", color: "black" }}>
              <div
                style={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  display: "flex",
                }}
              >
                {t("SECOND_PAYMENT_END_DATE")}
              </div>
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(e) =>
                checkTicketField(
                  "second_payment_end_date",
                  e,
                  /.*/,
                  t("CHOOSE_OPTION"),
                  true,
                  index
                )
              }
              type="date"
              margin="normal"
              required
              fullWidth
              id="second_payment_end_date"
              name="second_payment_end_date"
              value={ticket.second_payment_end_date || ""}
              autoComplete="second_payment_end_date"
              error={
                (ticketErrors.second_payment_end_date !== "" &&
                  ticketErrors.second_payment_end_date !== "empty") ||
                (ticketErrors.second_payment_end_date === "empty" && buttonClicked)
              }
              helperText={
                ticketErrors.second_payment_end_date !== "empty"
                  ? ticketErrors.second_payment_end_date
                  : ""
              }
              disabled={isDisabled}
              sx={focusColor}
              inputProps={{
                min: new Date().toISOString().split(" ")[0],
                max: ticket.end_date || "2100-12-31",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(e) =>
                checkTicketField(
                  "second_payment_end_date_time",
                  e,
                  /.*/,
                  t("CHOOSE_OPTION"),
                  true,
                  index
                )
              }
              type="time"
              margin="normal"
              required
              fullWidth
              id="second_payment_end_time"
              name="second_payment_end_time"
              value={ticket.second_payment_end_date_time || ""}
              autoComplete="second_payment_end_time"
              disabled={isDisabled}
              sx={focusColor}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TicketDates;
