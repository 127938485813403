import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { extra, ticketErrors, ticketErrorsOk } from "../../../classes/userClass";
import { EURO, formatCurrency, formatDate, formatHours } from "../../../constants/utils";
import { EVENTS_TYPE } from "../../../constants/variables";
import { useQueryUserCards } from "../../../hooks/queries/useQueryUserCards";
import { focusColor } from "../../shared/textFieldStyle";
import { AssistantData } from "./AssistantData";
import { AssistantTextField } from "./AssistantTextField";

export const AssistantTickets = ({
  formData,
  setFormData,
  eventData,
  setAddClicked,
  formErrors,
  setFormErrors,
  doublePayment,
  addClicked,
  selectedPayment,
  ticketsExtrasData,
}) => {
  const { t } = useTranslation();

  const [ticketError, setTicketError] = useState(false);
  const [ticketErrorMessage, setTicketErrorMessage] = useState("");

  // Get user cards to check if user is international
  const { data: userCards } = useQueryUserCards(formData.user_id);

  const addTicket = (index) => {
    setAddClicked(false);
    //
    if (formData.tickets.length >= eventData.limit_tickets_order) {
      setTicketError(true);
      setTicketErrorMessage(t("MAX_TICKETS_ORDER"));
      return;
    } else {
      setTicketError(false);
    }

    const ticketToAddId = ticketsExtrasData[index].id;
    const count = formData.tickets.reduce((accumulator, currentItem) => {
      if (currentItem.ticket_id === ticketToAddId) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);

    if (count > ticketsExtrasData[index].stock) {
      setTicketError(true);
      setTicketErrorMessage(t("NO_MORE_TICKETS"));
      return;
    }

    //
    let updatedTickets = JSON.parse(JSON.stringify(formData.tickets));
    const extrasData = ticketsExtrasData[index].extras;

    const newExtras = extrasData.map((extra) => ({
      id: extra.id,
      name: extra.name,
      price: extra.price,
      amount: 0,
    }));

    updatedTickets = [
      ...updatedTickets,
      {
        ticket_id: ticketsExtrasData[index].id,
        name: ticketsExtrasData[index].name,
        price: ticketsExtrasData[index].price,
        extras: newExtras,
        internationalDiscount: ticketsExtrasData[index].international_discount,
      },
    ];

    setFormData((prevUser) => ({
      ...prevUser,
      tickets: updatedTickets,
    }));

    const ticketError = JSON.parse(JSON.stringify(ticketErrors));
    const ticketErrorOk = JSON.parse(JSON.stringify(ticketErrorsOk));
    if (newExtras.length > 0) {
      for (let i = 0; i < newExtras.length; i++) {
        ticketError.extras.push(extra);
        ticketErrorOk.extras.push(extra);
      }
    }
    if (eventData.event_type === "trip") {
      const newFormErrors = JSON.parse(JSON.stringify(formErrors));
      newFormErrors.tickets.push(ticketError);
      setFormErrors(newFormErrors);
    } else {
      const newFormErrors = JSON.parse(JSON.stringify(formErrors));
      newFormErrors.tickets.push(ticketErrorOk);
      setFormErrors(newFormErrors);
    }
  };

  const handleExtraAmountChange = (ticketIndex, extraIndex, extraId, amount, regex) => {
    const ticketTemplate = ticketsExtrasData?.find(
      (item) => item.id === formData.tickets[ticketIndex].ticket_id
    );
    //Calculate stock of extra
    let orderStock = 0;
    for (let i = 0; i < formData.tickets.length; i++) {
      if (ticketIndex === i) {
        orderStock += parseInt(amount);
      } else {
        orderStock += parseInt(formData.tickets[i].extras[extraIndex].amount);
      }
    }
    let errorValue = "";
    // Check regex
    if (!regex.test(amount)) {
      errorValue = t("INVALID_AMOUNT");
      // Check de maximo por ticket
    } else if (amount > ticketTemplate.extras[extraIndex].stock_by_ticket_max) {
      errorValue = t("MAX_AMOUNT_BY_TICKET");
      // Check de stock
    } else if (orderStock > ticketTemplate.extras[extraIndex].stock) {
      errorValue = t("NO_MORE_EXTRA");
    } else {
      errorValue = "";
    }
    const newErrors = JSON.parse(JSON.stringify(formErrors));
    newErrors.tickets[ticketIndex].extras[extraIndex].amount = errorValue;
    setFormErrors(newErrors);

    // Procceed
    const updatedTickets = JSON.parse(JSON.stringify(formData.tickets));
    const updatedExtras = updatedTickets[ticketIndex].extras;
    updatedExtras[extraIndex].extra_id = extraId;
    updatedExtras[extraIndex].amount = amount;
    setFormData((prevUser) => ({
      ...prevUser,
      tickets: updatedTickets,
    }));
  };

  const handleFieldChange = (event, ticketIndex, regex, helperText) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    const updatedTickets = JSON.parse(JSON.stringify(formData.tickets));
    updatedTickets[ticketIndex][fieldName] = fieldValue;
    setFormData((prevUser) => ({
      ...prevUser,
      tickets: updatedTickets,
    }));
    let errorValue = "";
    if (regex) {
      if (regex.test(fieldValue)) {
        errorValue = "";
      } else {
        errorValue = helperText;
      }
    }

    setFormErrors((prevFormErrors) => {
      const newFormErrors = { ...prevFormErrors };
      newFormErrors.tickets = prevFormErrors.tickets.map((ticket, index) => {
        if (index !== ticketIndex) {
          return ticket;
        }
        return { ...ticket, [fieldName]: errorValue };
      });
      return newFormErrors;
    });
  };

  const removeTicket = (index) => {
    setTicketError(false);
    //
    const updatedTickets = formData.tickets.filter((_, ticketIndex) => ticketIndex !== index);
    setFormData((prev) => ({
      ...prev,
      tickets: updatedTickets,
    }));
    const updatedTicketsErrors = formErrors.tickets.filter(
      (_, ticketIndex) => ticketIndex !== index
    );
    setFormErrors({ ...formErrors, tickets: updatedTicketsErrors });
  };

  const ticketHasPromotionalCode = (index) => {
    const ticketTemplate = ticketsExtrasData?.find(
      (item) => item.id === formData.tickets[index].ticket_id
    );
    return (
      ticketTemplate.promotional_code &&
      ticketTemplate.promotional_code_discount &&
      ticketTemplate.promotional_code_discount > 0
    );
  };

  const getTotalPrice = (payment = 0) => {
    let totalPrice = 0;
    for (let i = 0; i < formData.tickets.length; i++) {
      totalPrice += parseFloat(formData.tickets[i].price);
      for (let j = 0; j < formData.tickets[i].extras.length; j++) {
        totalPrice += formData.tickets[i].extras[j].price * formData.tickets[i].extras[j].amount;
      }
      //International discount
      if (formData.tickets[i].internationalDiscount && userIsInternational()) {
        if (
          formData.tickets[i].internationalDiscount === ticketsExtrasData[0].international_discount
        ) {
          totalPrice -= ticketsExtrasData[i].international_discount;
        }
      }
      //Promotional code
      if (formData.tickets[i].promotional_code) {
        if (formData.tickets[i].promotional_code === ticketsExtrasData[0].promotional_code) {
          totalPrice -= ticketsExtrasData[i].promotional_code_discount;
        }
      }
    }

    //Payments split
    let splitPayment = 0;
    if (doublePayment && selectedPayment) {
      const percentage = eventData?.event_payments
        .sort((a, b) => Number(a.position) - Number(b.position))
        .filter((payment) => payment.number_of_payments === selectedPayment)[payment].percentage;

      totalPrice = (totalPrice * percentage) / 100;
    } else if (doublePayment) {
      splitPayment = (totalPrice * eventData?.first_payment_percentage) / 100;
    }

    return EURO.format(totalPrice - splitPayment);
  };

  const userIsInternational = () => {
    if (!userCards || userCards.length === 0) return false;
    return userCards.some((card) => card.expires_at === null || card.expires_at > new Date());
  };

  const isTrip =
    eventData.event_type === EVENTS_TYPE.TRIP ||
    eventData.event_type === EVENTS_TYPE.TRIP_COMPOSITE;

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={2}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        mt: 2,
      }}
    >
      <Grid item xs={12}>
        <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>{t("ENTRY_DATA")}</InputLabel>
        {formData?.tickets?.map((ticket, index) => (
          <div key={`${index}-${ticket.id}`}>
            <Box
              component={Paper}
              elevation={0}
              item={index}
              sx={{
                py: 2,
                px: 3,
                mb: 2,
                mt: 2,
                border: "1px solid #E4E4E4",
                borderRadius: "15px",
              }}
            >
              <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <IconButton onClick={() => removeTicket(index)}>
                  <Close fontSize="small" />
                </IconButton>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Grid item xs={8} sx={{ mt: 2 }}>
                  <Typography sx={{ fontSize: "18px" }}>
                    {t("TICKET")}: {ticket.name} {ticket?.start_date ? ticket.start_date : ""}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ mt: 2 }}>
                  <Typography>
                    {t("PRICE")} :{" "}
                    {ticket.internationalDiscount > 0 && userIsInternational() ? (
                      <>
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "red",
                          }}
                        >
                          {formatCurrency(ticket.price)}
                        </span>
                        <br />
                        <span>
                          {formatCurrency(ticket.price - ticket.internationalDiscount)} (
                          {t("INTERNATIONAL_DISCOUNT")})
                        </span>
                      </>
                    ) : (
                      formatCurrency(ticket.price)
                    )}
                  </Typography>
                </Grid>
              </Grid>
              {ticket.extras.length > 0 && (
                <Typography sx={{ fontSize: "18px", mt: 2 }}>{t("EXTRAS")}</Typography>
              )}
              {ticket.extras.length > 0 &&
                ticket.extras?.map((extra, extraIndex) => (
                  <Box
                    key={`${extraIndex}-${extra.id}`}
                    component={Paper}
                    elevation={0}
                    sx={{
                      py: 2,
                      px: 3,
                      mb: 2,
                      border: "1px solid #E4E4E4",
                      borderRadius: "15px",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Grid
                        item
                        xs={8}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography>
                          {extra.name + ":    " + extra.price * extra.amount}€
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          value={extra.amount}
                          onChange={(event) =>
                            handleExtraAmountChange(
                              index,
                              extraIndex,
                              extra.id,
                              event.target.value,
                              /^(?:[0-9]+)?$/
                            )
                          }
                          margin="normal"
                          required
                          type="number"
                          fullWidth
                          id="amount"
                          name="amount"
                          label={t("QUANTITY")}
                          autoComplete="amount"
                          sx={focusColor}
                          inputProps={{
                            min: 0,
                          }}
                          onWheel={(e) => e.target.blur()}
                          error={
                            (formErrors.tickets[index].extras[extraIndex].amount !== "" &&
                              formErrors.tickets[index].extras[extraIndex].amount !== "empty") ||
                            (formErrors.tickets[index].extras[extraIndex].amount === "empty" &&
                              addClicked)
                          }
                          helperText={
                            formErrors.tickets[index].extras[extraIndex].amount === ""
                              ? ""
                              : formErrors.tickets[index].extras[extraIndex].amount
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              {ticketHasPromotionalCode(index) && (
                <Grid item xs={12} md={6}>
                  <AssistantTextField
                    label={t("PROMOTIONAL_CODE")}
                    fieldName="promotional_code"
                    value={ticket.promotional_code}
                    ticketIndex={index}
                    type="text"
                    errorProperty={formErrors.tickets[index].promotional_code}
                    errorText={t("MINIMUM_CHARACTERS_ERROR")}
                    regex={/^[A-Za-z0-9]{4,}$|^$/}
                    handleFieldChange={handleFieldChange}
                    addClicked={addClicked}
                  />
                </Grid>
              )}
              {isTrip && (
                <AssistantData
                  formData={formData}
                  setFormErrors={setFormErrors}
                  ticket={ticket}
                  index={index}
                  formErrors={formErrors}
                  addClicked={addClicked}
                  handleFieldChange={handleFieldChange}
                />
              )}
            </Box>
          </div>
        ))}
        <InputLabel
          sx={{
            fontSize: "18px",
            color: "black",
            fontWeight: "bold",
            mb: 3,
            textAlign: "center",
          }}
        >
          {doublePayment ? t("TOTAL_PRICE_FIRST_PAYMENT") : t("TOTAL_PRICE")} {getTotalPrice()}
        </InputLabel>
        {doublePayment &&
          selectedPayment > 1 &&
          Array(selectedPayment - 1)
            .fill(1)
            .map((_, index) => (
              <InputLabel
                sx={{
                  fontSize: "15px",
                  color: "black",
                  fontWeight: "bold",
                  mb: 3,
                  textAlign: "center",
                }}
                key={`split-payment-${index}`}
              >
                {t("PRICE_WITHOUT")} {index + 2} {t("PAYMENT")} {getTotalPrice(index + 1)}
              </InputLabel>
            ))}
        {ticketError && (
          <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{ticketErrorMessage}</Typography>
        )}
        {ticketsExtrasData?.map((ticket, index) => (
          <Button
            fullWidth
            variant="outlined"
            size="large"
            key={`ticketsExtrasData-${index}`}
            sx={{
              mb: 2,
              color: "var(--secondary-color)",
              borderColor: "var(--secondary-color)",
              "&:hover": {
                color: "var(--secondary-color)",
                borderColor: "var(--secondary-color)",
                backgroundColor: "white",
              },
            }}
            onClick={() => addTicket(index)}
          >
            {t("ADD_NEW_TICKET") +
              ": " +
              ticket.name +
              "  " +
              (ticket.start_date
                ? formatDate(ticket.start_date) + " - " + formatHours(ticket.start_date)
                : "")}
          </Button>
        ))}
      </Grid>
    </Grid>
  );
};
