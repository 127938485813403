import { t } from "i18next";
import { toastMessageError } from "../../../components/shared/toastMessage";
import { convertStringToObject, generateAvailableSeoName } from "../../../constants/utils";
import { createHotel, getHotelDetails } from "../../../services/hotelServices";
import { createTransport, getTransportDetails } from "../../../services/transportServices";
import { prepareHotelToSend } from "../../../utils/hotels";
import { prepareTransportToSend } from "../../../utils/transports";

export default async function prepareDuplicateDetails(details, setIds, isDuplicateChild = false) {
  const newDetails = JSON.parse(JSON.stringify(details));

  newDetails.id = null;
  delete newDetails.last_modified_at;

  newDetails.included_options = convertStringToObject(newDetails.included_options) || [];
  newDetails.seo_name = await generateAvailableSeoName(newDetails.seo_name);

  //BLOCKS
  if (Array.isArray(newDetails.blocks) && newDetails.blocks.length > 0) {
    newDetails.blocks = newDetails.blocks.map((block) => {
      block.id = null;
      block.event_id = null;
      return block;
    });
  }
  //CATEGORIES
  if (Array.isArray(newDetails.event_categories) && newDetails.event_categories.length > 0) {
    newDetails.event_categories = newDetails.event_categories.map((cat) => {
      cat.id = null;
      delete cat.event_id;
      return cat;
    });
  }

  //LANGUAGES
  if (
    Array.isArray(newDetails.event_language_infos) &&
    newDetails.event_language_infos.length > 0
  ) {
    newDetails.event_language_infos = newDetails.event_language_infos.map((lang) => {
      lang.id = null;
      lang.event_id = null;
      return lang;
    });
  }

  //ITINERARIES
  if (
    Array.isArray(newDetails.itineraries) &&
    newDetails.itineraries.length > 0 &&
    !isDuplicateChild
  ) {
    newDetails.itineraries = await Promise.all(
      newDetails.itineraries?.map(async (itinerary) => {
        try {
          itinerary.id = null;
          itinerary.event_id = null;

          if (Array.isArray(itinerary.hotel_ids) && itinerary.hotel_ids.length > 0) {
            itinerary.hotel_ids = await Promise.all(
              itinerary.hotel_ids.map(async (hotelId) => {
                try {
                  const hotelData = await getHotelDetails(hotelId, true);
                  const hotelToSend = await prepareHotelToSend(hotelData, true);
                  delete hotelToSend.id;

                  const newHotel = await createHotel(hotelToSend, true);

                  setIds((prevIds) => ({
                    ...prevIds,
                    [hotelId]: newHotel.id,
                  }));

                  return newHotel.id;
                } catch (error) {
                  toastMessageError(t("ERROR"));
                  console.error(`Error procesando hotel ID ${hotelId}:`, error);
                  throw error;
                }
              })
            );
          }

          if (
            Array.isArray(itinerary.transport_ids) &&
            itinerary.transport_ids.length > 0 &&
            !isDuplicateChild
          ) {
            itinerary.transport_ids = await Promise.all(
              itinerary.transport_ids.map(async (transportId) => {
                try {
                  const transportData = await getTransportDetails(transportId, true);
                  const transportToSend = await prepareTransportToSend(transportData);
                  delete transportToSend.id;
                  const newTransport = await createTransport(transportToSend, true);

                  setIds((prevIds) => ({
                    ...prevIds,
                    [transportId]: newTransport.id,
                  }));

                  return newTransport.id;
                } catch (error) {
                  console.error(`Error procesando transporte ID ${transportId}:`, error);
                  throw error;
                }
              })
            );
          }
          return itinerary;
        } catch (error) {
          console.error(`Error procesando itinerario:`, error);
          throw error;
        }
      })
    );
  }

  return newDetails;
}
