import { Close } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../shared/textFieldStyle";

export const B2BAddToMyCatalog = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    price: null,
    percentage: null,
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontWeight: "bolder" }}>
        {t("ADD_TO_MY_CATALOG")}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "var(--secondary-color)",
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body1">{t("PVP")}</Typography>
          <TextField
            value={data.price}
            onChange={(e) => setData({ ...data, price: e.target.value })}
            type="number"
            variant="outlined"
            size="small"
            placeholder="0€"
            sx={[{ width: "100%", borderRadius: 3 }, focusColor]}
            disabled
          />
          <Typography variant="body1" sx={{ mt: 2 }}>
            {t("PERCENTAGE_WIN_PER_TRIP")}:
          </Typography>
          <TextField
            value={data.percentage}
            onChange={(e) => setData({ ...data, percentage: e.target.value })}
            type="number"
            variant="outlined"
            size="small"
            placeholder="0%"
            sx={[{ width: "100%", borderRadius: 3 }, focusColor]}
            disabled
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
