import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { Loading } from "../../components/shared/Loading";
import UserForm from "../../components/users/UserForm";
import useUserRoles from "../../hooks/useUserRoles";
import { getUserById } from "../../services/authenticationServices";
import { getCards } from "../../services/premisesServices";
import BuyCardDialog from "./BuyCardDialog";

const UserDetail = () => {
  const { t } = useTranslation();
  const { userId } = useParams();

  const [showCardBuyConfirmation, setShowCardBuyConfirmation] = useState(false);
  const [cardExists, setCardExist] = useState("");

  const { isSuperAdmin, isAdmin, isManager } = useUserRoles();
  const canBuyCard = isSuperAdmin || isAdmin || isManager;

  const handleBuyClose = () => {
    setShowCardBuyConfirmation(false);
  };

  const {
    data: userData = {},
    isLoading,
    isRefetching,
    refetch: refetchUserData,
  } = useQuery(["user", userId], () => getUserById(userId), {
    refetchOnWindowFocus: false,
    enabled: !!userId,
  });

  const breadcrumbs = [
    {
      name: t("USERS"),
      link: "/users",
    },
    {
      name: userData ? `${userData?.name}, ${userData.surname}` : "",
      link: `/users/${userId}`,
    },
  ];

  const { data: cards = [] } = useQuery(["cards"], () => getCards(), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (userData?.premise && cards) {
      const foundCard = cards.find((card) => card.premise_id === userData?.premise);
      setCardExist(foundCard);
    }
  }, [userData]);

  if (isLoading || isRefetching) {
    return <Loading />;
  }

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        description={t("HEADER_TEXT_USER_DETAIL") + `${userData?.name}, ${userData.surname}`}
      />
      {userData.affiliated_status === "pending_payment" && cardExists && canBuyCard && (
        <Grid
          item
          xs={11}
          sm={12}
          sx={{ mb: 5, ml: 3, display: "flex", flexDirection: "row", justifyContent: "end" }}
        >
          <Button
            onClick={() => setShowCardBuyConfirmation(true)}
            className="oniria-btn"
            variant="contained"
            sx={{ fontSize: "12px", borderRadius: 3, height: "fit-content" }}
          >
            {t("BUY_CARD")}
          </Button>
        </Grid>
      )}
      <UserForm formData={userData} imageDisable refetchUserData={refetchUserData} />
      <BuyCardDialog open={showCardBuyConfirmation} onClose={handleBuyClose} data={userData} />
    </>
  );
};

export default UserDetail;
