import { Box, Button, Grid, Pagination } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PAGINATION_STYLES } from "../../../../constants/styles";
import { ASSIGN_TRIP_TYPES } from "../../../../constants/variables";
import useQueryHotelTemplates from "../../../../hooks/queries/hotels/useQueryHotels";
import useDebounce from "../../../../hooks/useDebouncing";
import usePagination from "../../../../hooks/usePagination";
import { createHotel, getHotelDetails } from "../../../../services/hotelServices";
import { prepareHotelToSend, TEMPLATES_TAB } from "../../../../utils/hotels";
import SearchFilter from "../../../events/filters/filter/SearchFilter";
import CreateHotelDialog from "../../../hotels/createDialog/CreateHotelDialog";
import AssignComponent from "../../../shared/AssignComponent";
import { toastMessageError } from "../../../shared/toastMessage";
import { Add } from "@mui/icons-material";
import SelectFilter from "../../../events/filters/filter/SelectFilter";
import useQueryTourPointsList from "../../../../hooks/queries/tourPoints/useQueryTourPointsList";

const AddHosting = () => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();
  const { page, changePage, perPage } = usePagination(6);
  const { data: tourPoints = [] } = useQueryTourPointsList();

  const [filters, setFilters] = useState({
    search: "",
    isActive: true,
    tourPointId: "all",
  });
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const tripInfo = getValues();

  const { data } = useQueryHotelTemplates(page, perPage, filters, TEMPLATES_TAB, () => {});
  const hotels = data?.data;

  const totalPages = Math.ceil((data?.total || 0) / perPage);

  const handleSearchChange = (event) => {
    setFilters((prev) => ({ ...prev, search: event.target.value }));
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleAssignHotel = async (hotelId, itineraryId) => {
    try {
      // 1 - Create a hotel with the info of the template
      const hotelInfo = await getHotelDetails(hotelId, false);
      hotelInfo.id = null;
      const hotelToSend = await prepareHotelToSend(hotelInfo, true);
      const newHotel = await createHotel(hotelToSend, true);
      // 2 - Add the hotel to the itinerary
      const itId = itineraryId.split("-")[1];
      addHotelToItinerary(newHotel.id, itId);
    } catch (e) {
      toastMessageError(e?.response?.data?.error || t("ERROR"));
    }
  };

  const addHotelToItinerary = (hotelId, itineraryId) => {
    const prevItineraries = getValues("itineraries");
    const newItineraries = prevItineraries.map((itinerary, index) => {
      if (
        itineraryId.length > 4 ? itinerary.id?.includes(itineraryId) : index === Number(itineraryId)
      ) {
        if (!itinerary.hotel_ids) {
          itinerary.hotel_ids = [hotelId];
        } else {
          itinerary.hotel_ids = [...itinerary?.hotel_ids, hotelId];
        }
      }
      return itinerary;
    });
    setValue("itineraries", newItineraries);
  };

  const handleRemoveHotel = (itemId, itineraryId) => {
    const prevItineraries = getValues("itineraries");
    const newItineraries = prevItineraries.map((itinerary, index) => {
      if (
        itineraryId.length > 4 ? itinerary.id?.includes(itineraryId) : index === Number(itineraryId)
      ) {
        if (itinerary.hotel_ids) {
          itinerary.hotel_ids = itinerary?.hotel_ids.filter((hotelId) => hotelId !== itemId);
        }
      }
      return itinerary;
    });

    // Delete the extras with the deleted hotel
    const extras = getValues("extras") || [];
    const newExtras = extras.filter((extra) => extra.hotel_id !== itemId);

    setValue("extras", newExtras);
    setValue("itineraries", newItineraries);
  };

  //Tour point filter
  const handleChangeTourPoint = (value) => {
    setFilters((prev) => ({ ...prev, tourPointId: value }));
  };
  const tourPointsOptions = [
    { id: "all", name: t("TOUR_POINTS") },
    ...tourPoints
      .sort((a, b) => a.name.localeCompare(b.name))
      ?.map((tourPoint) => ({
        id: tourPoint.id,
        name: tourPoint.name,
      })),
  ];

  return (
    <Grid container paddingLeft={2} sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <Grid container spacing={2} sx={{ width: "45%", justifyContent: "start" }}>
          <Grid item xs={12} sm={6}>
            <SearchFilter onSearchChange={debounceSearch} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectFilter
              value={filters.tourPointId}
              onChange={handleChangeTourPoint}
              options={tourPointsOptions}
            />
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="flex-end">
            <Button
              className="oniria-border-btn"
              onClick={() => setOpenCreateDialog(true)}
              startIcon={<Add />}
              size="small"
              sx={{ borderRadius: 3 }}
            >
              {t("CREATE_HOTEL")}
            </Button>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <Pagination
              count={totalPages}
              page={page}
              onChange={changePage}
              sx={PAGINATION_STYLES}
            />
          </Grid>
        </Grid>
      </Box>
      <AssignComponent
        tripInfo={tripInfo}
        itemsToAssign={hotels}
        handleAssign={handleAssignHotel}
        handleRemove={handleRemoveHotel}
        type={ASSIGN_TRIP_TYPES.HOSTING}
      />

      <CreateHotelDialog isOpen={openCreateDialog} onClose={() => setOpenCreateDialog(false)} />
    </Grid>
  );
};

export default AddHosting;
