import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryAssignDomains } from "../../../hooks/queries/useQueryAssignDomains";
import { focusColor } from "../../shared/textFieldStyle";

const SelectDomainsAdmin = ({ selectedDomains, setSelectedDomains }) => {
  const { t } = useTranslation();
  const { data: domains } = useQueryAssignDomains();

  const handleChangeDomain = (event) => {
    setSelectedDomains(event.target.value);
  };

  return (
    <>
      <Typography>{t("DOMAINS_ADMIN_INFO")}</Typography>
      <FormControl fullWidth variant="outlined" sx={focusColor}>
        <InputLabel>{t("DOMAIN")}</InputLabel>
        <Select
          multiple
          label={t("DOMAIN")}
          value={selectedDomains || []}
          onChange={handleChangeDomain}
        >
          {domains?.map((domain) => (
            <MenuItem key={domain.id} value={domain.id}>
              {domain.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectDomainsAdmin;
