import { CurrencyExchange, ExpandLess, ExpandMore, Visibility } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import UserAvatar from "../../components/UserAvatar";
import OrderDetail from "../../components/events/orderDetails/OrderDetail";
import { focusColor } from "../../components/shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import useDebounce from "../../hooks/useDebouncing";
import { getAllEvents } from "../../services/eventsServices";
import { applyRefund, getRefunds } from "../../services/refundsServices";
import { formatCurrency } from "../../constants/utils";

let perPage = "10";
let refundsAmount = "";

const RefundList = () => {
  const { t } = useTranslation();
  const [refunds, setRefunds] = useState([]);
  const [openRefund, setOpenRefund] = useState(false);
  const [selectedRefund, setSelectedRefund] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState({ asc: [], desc: ["requested_at"] });
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [showDetailOrder, setShowDetailOrder] = useState(false);
  const [events, setEvents] = useState([""]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedPremise, setSelectedPremise] = useState("");

  const userInfo = useSelector((state) => state.userInfo);
  //GET REFUNDS FROM API
  const handleQuerySuccess = (data) => {
    refundsAmount = data?.amount;
    setRefunds(data.data);
  };

  const { isLoading, isRefetching, refetch } = useQuery(
    ["refunds", search, page, perPage, order, selectedStatus, selectedEvent, selectedPremise],
    () => getRefunds(search, page, perPage, order, selectedStatus, selectedPremise, selectedEvent),
    {
      onSuccess: handleQuerySuccess,
      refetchOnWindowFocus: false,
    }
  );

  const dateFormat = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString("en-ES");
  };
  const handleChangeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };

  //manejar la búsqueda de refunds
  const handleSearchChange = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  //dialog
  const handleOpenRefund = (refund) => {
    setSelectedRefund(refund);
    setOpenRefund((prev) => !prev);
  };

  const handleApplyRefund = async (e) => {
    e.preventDefault();
    let body = {
      user_id: userInfo.id,
      order_id: selectedRefund?.order_id,
      transaction_id: selectedRefund?.transaction_id,
    };
    try {
      await applyRefund(selectedRefund.id, body);
      toastMessageSuccess(t("REFUND_OK"));
      refetch();
    } catch (error) {
      toastMessageError(error?.response?.data?.error ?? "Something went wrong");
    }
    setOpenRefund((prev) => !prev);
  };
  const handleCloseRefund = () => {
    setOpenRefund(false);
  };

  //Change page
  const handleChangePage = (_, value) => {
    setPage(value);
  };
  const numberPages = Math.ceil(refundsAmount / perPage);

  const statusOptions = { refunded: "refunded", pending: "pending" };

  //filtros
  const handleSortRefunds = (id, orderType) => {
    setOrder((prevOrder) => {
      const oppositeOrderType = orderType === "asc" ? "desc" : "asc";
      return {
        ...prevOrder,
        [orderType]: prevOrder[orderType].includes(id)
          ? prevOrder[orderType].filter((columnId) => columnId !== id)
          : [...prevOrder[orderType], id],
        [oppositeOrderType]: prevOrder[oppositeOrderType].filter((columnId) => columnId !== id),
      };
    });
  };
  //SHORT LIST
  const renderArrowButtons = (id) => {
    return (
      <ButtonGroup sx={{ fontSize: "5px" }}>
        <IconButton
          aria-label="sort"
          size="small"
          name={"asc"}
          onClick={() => handleSortRefunds(id, "asc")}
          className={order["asc"].includes(id) ? "oniria-btn-check-validated" : "oniria-btn-check"}
          sx={{ height: "20px", width: "20px" }}
        >
          <ExpandLess />
        </IconButton>
        <IconButton
          aria-label="sort"
          size="small"
          name={"desc"}
          onClick={() => handleSortRefunds(id, "desc")}
          className={order["desc"].includes(id) ? "oniria-btn-check-validated" : "oniria-btn-check"}
          sx={{ height: "20px", width: "20px" }}
        >
          <ExpandMore />
        </IconButton>
      </ButtonGroup>
    );
  };

  //ver detalle del pedido
  const handleShowOrder = (refund) => {
    setSelectedRefund(refund);
    setShowDetailOrder(true);
  };

  //llamada de sedes
  const { data: premises } = useQueryPremisesList();

  useEffect(() => {
    if (premises && premises?.length > 0) {
      setSelectedPremise(premises[0].id);
    }
  }, [premises]);

  const handleChangePremise = (e) => {
    setPage(1);
    setSelectedPremise(e.target.value);
    setSelectedEvent("");
  };
  const renderPremisesOptions = () => {
    return premises?.map((premise, index) => (
      <MenuItem key={index} value={premise.id}>
        {premise.name}
      </MenuItem>
    ));
  };
  //FIN SEDES

  //llamada de eventos
  const handleQueryEvents = (data) => {
    setEvents(data);
  };
  useQuery(["allEvents", selectedPremise], () => getAllEvents(selectedPremise), {
    onSuccess: handleQueryEvents,
  });
  const handleChangeEvent = (_, value) => {
    setPage(1);
    setSelectedEvent(value?.id);
  };

  return (
    <Grid paddingTop={{ xs: 10, sm: 2 }}>
      <Grid container item xs={12} alignItems="center">
        <Grid item sm={8} sx={{ paddingLeft: { xs: 2, sm: 0 } }}>
          <Typography
            key="2"
            className="oniria-colorText"
            variant="h5"
            sx={{ fontWeight: "bolder" }}
          >
            {t("REFUNDS_LIST")}
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <UserAvatar />
        </Grid>
      </Grid>
      {showDetailOrder ? (
        <OrderDetail
          order={selectedRefund?.order_id}
          transactionId={selectedRefund?.transaction_id}
          refunds={true}
          onBack={() => setShowDetailOrder(false)}
        />
      ) : (
        <>
          <Grid
            component={Paper}
            item
            container
            xs={12}
            sm={8}
            lg={5}
            padding={2}
            elevation={3}
            borderRadius={3}
            rowGap={2}
          >
            <Grid
              item
              container
              justifyContent="space-between"
              sx={{ height: "fit-content", flexWrap: "nowrap" }}
            >
              <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
              <TextField
                label={t("SEARCH")}
                onChange={(event) => debounceSearch(event)}
                size="small"
                sx={[focusColor, { flexGrow: "1" }]}
              />
            </Grid>
            <Grid
              container
              item
              flexDirection="row"
              wrap="nowrap"
              alignItems="center"
              columnGap={1}
            >
              <FormControl sx={focusColor} fullWidth>
                <InputLabel id="demo-simple-select-label">{t("STATUS")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedStatus}
                  label={t("STATUS")}
                  onChange={handleChangeStatus}
                >
                  <MenuItem value="">{t("ALL")}</MenuItem>
                  {Object.values(statusOptions)?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {t(option.toUpperCase())}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* PREMISE OR PREMISES */}
            <Grid
              container
              item
              flexDirection="row"
              wrap="nowrap"
              alignItems="center"
              columnGap={1}
            >
              <FormControl sx={focusColor} fullWidth>
                <InputLabel id="demo-simple-select-label">{t("PREMISES")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedPremise}
                  label={t("PREMISE")}
                  onChange={handleChangePremise}
                >
                  <MenuItem value="">{t("PREMISES")}</MenuItem>
                  {premises?.length > 0 && renderPremisesOptions()}
                </Select>
              </FormControl>
            </Grid>
            {/* EVENTOS */}
            <Grid
              container
              item
              flexDirection="row"
              wrap="nowrap"
              alignItems="center"
              columnGap={1}
            >
              <FormControl sx={focusColor} fullWidth>
                <Autocomplete
                  id="auto-complete"
                  options={events}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name} ({option.premise_name})
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={t("EVENTS")} variant="outlined" />
                  )}
                  onChange={handleChangeEvent}
                  value={events.find((event) => event.id === selectedEvent) || null}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent={{ xs: "center", sm: "space-between" }}
            paddingTop={3}
            alignItems={"center"}
            rowGap={2}
          >
            <Typography>
              {refundsAmount} {t("LAST_MOVEMENTS")}
            </Typography>
            {numberPages > 1 && (
              <Pagination
                count={numberPages}
                mr={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bolder",
                  "& .MuiPaginationItem-root": {
                    "&.Mui-selected": {
                      background: "#3D3B38",
                      color: "#fff",
                      fontWeight: "bolder",
                    },
                    fontWeight: "600",
                  },
                }}
                page={page}
                onChange={handleChangePage}
              />
            )}
          </Grid>
          <TableContainer sx={{ marginBottom: numberPages <= 1 ? 25 : 4 }}>
            <Table>
              <TableHead>
                <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                  <TableCell>
                    <Grid
                      container
                      flexDirection="row"
                      wrap="nowrap"
                      alignItems="center"
                      columnGap={1}
                      whiteSpace="nowrap"
                    >
                      {t("REQUESTED_AT")}
                      {renderArrowButtons("requested_at")}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid
                      container
                      flexDirection="row"
                      wrap="nowrap"
                      alignItems="center"
                      columnGap={1}
                      whiteSpace="nowrap"
                    >
                      {t("EVENT_NAME")}
                      {renderArrowButtons("event_name")}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid
                      container
                      flexDirection="row"
                      wrap="nowrap"
                      alignItems="center"
                      columnGap={1}
                    >
                      {t("NAME")}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid
                      container
                      flexDirection="row"
                      wrap="nowrap"
                      alignItems="center"
                      columnGap={1}
                    >
                      {t("Surname")}
                    </Grid>
                  </TableCell>
                  <TableCell>{t("DESCRIPTION")}</TableCell>
                  <TableCell>{t("TRANSACTION_ID")}</TableCell>
                  <TableCell>
                    <Grid
                      container
                      flexDirection="row"
                      wrap="nowrap"
                      alignItems="center"
                      columnGap={1}
                    >
                      {t("AMOUNT")}
                      {renderArrowButtons("amount")}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid
                      container
                      flexDirection="row"
                      wrap="nowrap"
                      alignItems="center"
                      columnGap={1}
                      whiteSpace="nowrap"
                    >
                      {t("REFUNDED_AT")}:
                    </Grid>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {isLoading || isRefetching ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} sx={{ border: "none" }}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{ height: "50vh" }}
                      >
                        <CircularProgress sx={{ color: "var(--secondary-color)" }} />
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {refunds?.map((refund, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        {refund.requested_at ? dateFormat(refund.requested_at) : ""}
                      </TableCell>
                      <TableCell>{refund.event_name}</TableCell>
                      <TableCell>{refund.client_name}</TableCell>
                      <TableCell>{refund.client_surname}</TableCell>
                      <Tooltip
                        title={
                          t("REQUESTER_REFUND") +
                          refund.applicant_name +
                          " " +
                          refund.applicant_surname
                        }
                      >
                        <TableCell>{refund.description}</TableCell>
                      </Tooltip>
                      <TableCell>
                        {refund.transaction_id}
                        {refund?.payments && refund.payments.length > 0
                          ? refund.payments.map((payment) => <p>{payment.transaction_id}</p>)
                          : null}
                      </TableCell>
                      <TableCell>
                        {refund?.payments && refund.payments.length > 0
                          ? refund.payments
                              .filter((payment) => payment.payed_at)
                              .map((payment) => <p>{formatCurrency(payment.amount)}</p>)
                          : formatCurrency(refund.amount)}
                      </TableCell>
                      <TableCell>
                        {refund.refund_status === statusOptions.refunded
                          ? dateFormat(refund.refunded_at)
                          : refund.refund_status}
                      </TableCell>
                      <TableCell>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          flexWrap="nowrap"
                          columnGap={1}
                        >
                          <Tooltip title={t("SEE")}>
                            <IconButton onClick={() => handleShowOrder(refund)}>
                              <Visibility sx={{ color: "var(--secondary-color)" }} />
                            </IconButton>
                          </Tooltip>

                          {refund.refund_status === statusOptions.pending && (
                            <Tooltip title={t("REFUND")}>
                              <IconButton onClick={() => handleOpenRefund(refund)}>
                                <CurrencyExchange sx={{ color: "var(--secondary-color)" }} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {numberPages > 1 && (
            <Grid item xs={12}>
              <Pagination
                count={numberPages}
                sx={{
                  mb: 30,
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bolder",
                  "& .MuiPaginationItem-root": {
                    "&.Mui-selected": {
                      background: "#3D3B38",
                      color: "#fff",
                      fontWeight: "bolder",
                    },
                    fontWeight: "600",
                  },
                }}
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          )}
          <Dialog
            open={openRefund}
            onClose={handleCloseRefund}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)" }}>
              {t("ORDER_REFUND")}:
            </DialogTitle>
            <DialogContent>
              <Typography marginTop={2} textAlign="center">
                {t("CONFIRM_REFUND_PUT")}
              </Typography>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                paddingBottom: 2,
              }}
            >
              <Button
                type="submit"
                className="oniria-btn-cancel"
                variant="contained"
                size="medium"
                sx={{ fontSize: "12px", borderRadius: 3 }}
                onClick={handleCloseRefund}
              >
                {t("CANCEL")}
              </Button>
              <Button
                type="submit"
                className="oniria-btn"
                variant="contained"
                size="medium"
                sx={{ fontSize: "12px", borderRadius: 3 }}
                onClick={handleApplyRefund}
              >
                {t("ACCEPT")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Grid>
  );
};
export default RefundList;
