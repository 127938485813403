export const DollarCoinIcon = (props) => {
  const { color = "currentColor" } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.466 7.605a1.62 1.62 0 0 0-1.53-1.082h-1.255a1.448 1.448 0 0 0-.31 2.864l1.912.418a1.623 1.623 0 0 1-.347 3.208h-1.081a1.623 1.623 0 0 1-1.53-1.081M14.395 6.523V4.901M14.395 14.636v-1.622"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.61 9.768a7.785 7.785 0 1 0 15.571 0 7.785 7.785 0 0 0-15.57 0ZM2.772 10.621a7.785 7.785 0 0 0 10.246 10.712"
      />
    </svg>
  );
};
