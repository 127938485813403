import { Grid, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RequestsTable } from "../components/events/requests/RequestsTable";
import Header from "../components/Header";
import { Loading } from "../components/shared/Loading";
import SelectPerPage from "../components/shared/SelectPerPage";
import { ROUTES } from "../constants/routes";
import { PAGINATION_STYLES } from "../constants/styles";
import { useQueryEventsRequests } from "../hooks/queries/events/useQueryEventsRequests";
import useDebounce from "../hooks/useDebouncing";
import usePagination from "../hooks/usePagination";

export const EventRequests = () => {
  const { t } = useTranslation();
  const breadcrumbs = [{ name: t("EVENT_REQUESTS"), link: ROUTES.EVENT_REQUESTS }];
  const { page, perPage, changePage, changePerPage } = usePagination();

  const [filters, setFilters] = useState({
    search: "",
    eventId: "",
    order: { asc: [], desc: ["created_at"] },
  });

  const {
    data: { data: requests = [], total: totalRequests = 0 } = {},
    isLoading,
    isRefetching,
    refetch,
  } = useQueryEventsRequests(filters, page, perPage);

  const updateFilter = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    changePage(null, 1);
  };

  const handleSearchChange = (event) => {
    updateFilter("search", event.target.value);
  };
  const debounceSearch = useDebounce(handleSearchChange, 300);

  const numberPages = Math.ceil(parseFloat(totalRequests || "1") / parseFloat(perPage));

  return (
    <>
      <Header breadcrumbs={breadcrumbs} />

      <Grid
        item
        xs={11}
        sm={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "initial",
        }}
      >
        {/* <MailingFilters
          onSearchChange={debounceSearch}
          filters={filters}
          updateFilter={updateFilter}
          setOpenMailDialog={setOpenMailDialog}
        /> */}
      </Grid>
      {totalRequests > 0 && (
        <Grid
          item
          xs={11}
          sm={12}
          container
          justifyContent={{ xs: "center", sm: "space-between" }}
          alignItems={"center"}
          pb={2}
          gap={{ xs: 2, sm: 0 }}
          mt={3}
        >
          <Typography>
            {t("TOTAL_REQUESTS")}: {totalRequests}
          </Typography>
          {numberPages >= 1 && (
            <Grid item xs={12} md={8} container justifyContent="flex-end">
              <Grid item>
                <SelectPerPage text={t("REQUESTS")} change={changePerPage} value={perPage} />
              </Grid>
              <Pagination
                count={numberPages}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </Grid>
      )}
      {isRefetching || isLoading ? (
        <Loading />
      ) : requests?.length === 0 ? (
        <Grid ml={3} mt={3}>
          {t("NO_DATA_AVAILABLE")}
        </Grid>
      ) : (
        <RequestsTable data={requests} />
      )}
      {numberPages >= 1 && (
        <Grid mb={2}>
          <Pagination
            count={numberPages}
            sx={PAGINATION_STYLES}
            page={page}
            onChange={changePage}
          />
        </Grid>
      )}
    </>
  );
};
