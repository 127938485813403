import { Box, Divider } from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import { LeftDragArea } from "./dragDrop/LeftDragArea";
import RightDragArea from "./dragDrop/RigthDragArea";

const AssignComponent = ({ tripInfo, itemsToAssign = [], handleAssign, handleRemove, type }) => {
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const destinationId = destination.droppableId;
    const assignedItemId = itemsToAssign[source.index].id || itemsToAssign[source.index].user_id;

    handleAssign(assignedItemId, destinationId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box display="flex" justifyContent="space-between" width="100%" mb={2}>
        <LeftDragArea itemsToAssign={itemsToAssign} type={type} />

        <Divider orientation="vertical" flexItem />

        <RightDragArea tripInfo={tripInfo} handleRemove={handleRemove} type={type} />
      </Box>
    </DragDropContext>
  );
};

export default AssignComponent;
