import { Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../shared/textFieldStyle";
import { Controller, useFormContext } from "react-hook-form";

export const TripPaymentItem = ({ index, payment, groupKey }) => {
  const { t } = useTranslation();
  const {
    register,
    getValues,
    setValue,
    clearErrors,
    setError,
    watch,
    control,
    formState: { errors },
  } = useFormContext();

  const pvp = watch("pvp", 0);

  // Change percentage of payment
  const handleChangePercentage = (e, i) => {
    const prev = getValues("event_payments") || [];
    let newPayments = [...prev];

    newPayments[i].percentage = Number(e.target.value);
    setValue("event_payments", newPayments);

    // Check group percentages
    const groupPayments = newPayments.filter(
      (payment) => Number(payment.number_of_payments) === Number(groupKey)
    );
    const totalPercentage = groupPayments.reduce((acc, p) => acc + Number(p.percentage), 0);

    if (totalPercentage !== 100) {
      setError(`event_payments.${groupKey}`, {
        type: "manual",
        message: "PERCENTAGE_ERROR",
      });
    } else {
      clearErrors(`event_payments.${groupKey}`);
    }
  };

  const handleChangeDays = (e, i) => {
    const prev = getValues("event_payments") || [];
    const newPayments = [...prev];
    newPayments[i].days_before_start = Number(e.target.value);
    setValue("event_payments", newPayments);
  };

  return (
    <Grid container alignItems="center" spacing={2} sx={{ pb: 2 }}>
      <Grid item xs={0.5} container alignItems="center" justifyContent="center">
        %
      </Grid>
      {/* Item Quantity  */}
      <Grid item xs={2.5}>
        <Typography variant="body1" fontWeight={600}>
          {`${t("QUANTITY")} ${index + 1}. ${t("PAYMENT")}`}
        </Typography>

        <Controller
          name={`event_payments.${payment.position}.percentage`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="percentage-input"
              placeholder="%"
              fullWidth
              type="number"
              inputProps={{ min: 0, max: 100, step: 0.1 }}
              onChange={(e) => handleChangePercentage(e, payment.position)}
              sx={focusColor}
              onWheel={(e) => e.target.blur()}
              error={!!errors?.event_payments?.[payment.position]?.percentage}
              helperText={t(errors?.event_payments?.[payment.position]?.percentage?.message)}
            />
          )}
        />
      </Grid>

      {/* Percentage over pvp */}
      <Grid item xs={2}>
        <Typography variant="body1" fontWeight={600}>
          ({((payment.percentage / 100) * pvp).toFixed(2)} €)
        </Typography>
      </Grid>

      {index !== 0 ? (
        <>
          <Grid item xs={2} textAlign="center">
            <Typography variant="body1">{t("MUST_REALIZED")}</Typography>
          </Grid>

          <Grid item xs={2}>
            <TextField
              {...register(`event_payments.${payment.position}.days_before_start`)}
              className="days-input"
              placeholder="Días"
              fullWidth
              type="number"
              inputProps={{ min: 0, step: 0.1 }}
              value={payment.days_before_start}
              onChange={(e) => handleChangeDays(e, payment.position)}
              sx={focusColor}
              onWheel={(e) => e.target.blur()}
              error={!!errors?.event_payments?.[payment.position]?.days_before_start}
              helperText={t(errors?.event_payments?.[payment.position]?.days_before_start?.message)}
            />
          </Grid>

          <Grid item xs={3}>
            <Typography variant="body1">{t("DAYS_BEFORE_TRIP_START")}</Typography>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};
