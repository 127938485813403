import { z } from "zod";

export const tripAgent = z.object({
  id: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  description: z
    .union([z.string(), z.record(z.any())])
    .nullable()
    .optional(),

  // Address
  address: z.string().nullable().optional(),
  postal_code: z.string().nullable().optional(),
  country: z.string().nullable().optional(),
  state: z.string().nullable().optional(),
  city: z.string().nullable().optional(),

  phone_code: z.string().nullable().optional(),
  phone: z.union([z.string(), z.number()]).nullable().optional(),
  email: z.string().nullable().optional(),

  // Fiscal
  fiscal_name: z.string().nullable().optional(),
  cif: z.string().nullable().optional(),
  iban: z.string().nullable().optional(),

  // Fiscal Address
  fiscal_address: z.string().nullable().optional(),
  fiscal_postal_code: z.string().nullable().optional(),
  fiscal_country: z.string().nullable().optional(),
  fiscal_state: z.string().nullable().optional(),
  fiscal_city: z.string().nullable().optional(),

  // Domain styles
  styles: z.object({
    principal_color: z.string().nullable().optional(),
    text_color: z.string().nullable().optional(),
    logo_url: z.any(),
  }),

  // Admin info
  admin: z.object({
    name: z.string().nullable().optional(),
    surname: z.string().nullable().optional(),
    email: z.string().nullable().optional(),
  }),

  //Contract
  agreement: z.object({
    commission_fixed: z.union([z.number(), z.string()]).nullable().optional(),
    commission_percentage: z.union([z.number(), z.string()]).nullable().optional(),
    premise_operator_id: z.string().nullable().optional(),
    pdf: z.string().nullable().optional(),
  }),
});

export const DEFAULT_TRIP_AGENT = {
  // Organization & Premise
  id: null,
  name: "",
  description: "",
  // Address
  address: "",
  postal_code: "",
  country: "",
  state: "",
  city: "",
  // Contact
  phone_code: "",
  phone: "",
  email: "",
  // Billing
  fiscal_name: "",
  cif: "",
  iban: "",
  // Fiscal address
  fiscal_address: "",
  fiscal_postal_code: "",
  fiscal_country: "",
  fiscal_state: "",
  fiscal_city: "",

  // Domain styles
  styles: {
    principal_color: "#FFFFFF",
    text_color: "#000000",
    logo_url: "",
  },
  // Admin info
  admin: {
    name: "",
    surname: "",
    email: "",
  },
  //Contract
  agreement: {
    commission_fixed: 0,
    commission_percentage: 0,
    premise_operator_id: "",
    pdf: "",
  },
};
