import { useTranslation } from "react-i18next";
import { formatCurrency, formatDate } from "../constants/utils";
import { useQueryB2bSales } from "./queries/b2b/useQueryB2bSales";

export const useDownloadB2bSales = (filters, enabledDownload) => {
  const { t } = useTranslation();

  const { data = [], isSuccess } = useQueryB2bSales("", "", filters, enabledDownload);

  //CSV download
  const tableHeadersCsv = [
    t("SALE_AGENT"),
    t("PRODUCT"),
    t("RESERVE_DATE"),
    t("NET_PRICE"),
    t("COMMISSION"),
    t("PVP"),
    t("MARGIN"),
    t("STATUS"),
  ];

  // Transform data to table
  const tableDataCsv = data?.map((item) => {
    return [
      item.premise_name,
      item.event_name,
      formatDate(item.created_at),
      formatCurrency(item.amount),
      formatCurrency(item.commission),
      formatCurrency(item.net_price),
      item.commission_percentage
        ? `${item.commission_percentage}%`
        : item.commission_fixed
        ? formatCurrency(item.commission_fixed)
        : null,
      t(item.status?.toUpperCase()),
    ];
  });

  return {
    tableHeadersCsv,
    tableDataCsv,
    isSuccess,
  };
};
