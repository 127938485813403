import { Box, Grid, Card, CardContent, Typography, Avatar, CardActions } from "@mui/material";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { ASSIGN_TRIP_TYPES, getTransportIconById } from "../../../constants/variables";

export const LeftDragArea = ({ itemsToAssign, type }) => {
  return (
    <Droppable droppableId="left" direction="vertical">
      {(provided) => (
        <Box ref={provided.innerRef} {...provided.droppableProps} width="45%" p={2}>
          <Grid container spacing={2}>
            {itemsToAssign?.map((item, index) => (
              <Grid key={`${item.id || item.user_id}-${index}-assign`} item xs={6}>
                <Draggable
                  draggableId={`${item.id || item.user_id}`}
                  index={index}
                  component={Grid}
                  item
                  xs={6}
                >
                  {(provided, snapshot) => (
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        borderRadius: "20px",
                        backgroundColor: snapshot.isDragging ? "#e0e0e0" : "transparent",
                        transform: snapshot.isDragging ? "scale(1.1)" : "scale(1)",
                        transition: "transform 0.2s ease, background-color 0.2s ease",
                      }}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexGrow: 1,
                          padding: 1,
                          alignItems: "center",
                        }}
                      >
                        {type === ASSIGN_TRIP_TYPES.HOSTING && (
                          <Avatar
                            alt={item.name}
                            src={item.img_url}
                            variant="square"
                            sx={{ width: 90, height: "auto", borderRadius: "8px" }}
                          />
                        )}
                        {type === ASSIGN_TRIP_TYPES.TRANSPORTS && (
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: 2,
                            }}
                          >
                            {getTransportIconById(item?.type, 30)}
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              sx={{ marginLeft: 1 }}
                            >
                              {item?.type}
                            </Typography>
                          </Grid>
                        )}
                        <CardContent sx={{ flex: 1, paddingLeft: 2 }}>
                          <Typography variant="h6" component="div">
                            {item.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" mb={1}>
                            {item.sku}
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardActions
                        disableSpacing
                        sx={{ justifyContent: "center", gap: 2, paddingTop: 1 }}
                      />
                    </Card>
                  )}
                </Draggable>
              </Grid>
            ))}
            {provided.placeholder}
          </Grid>
        </Box>
      )}
    </Droppable>
  );
};
