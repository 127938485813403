import { Divider, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EXTRAS_TYPES } from "../../../constants/variables";
import useQueryExtraDetail from "../../../hooks/queries/extras/useQueryExtraDetail";
import { deleteExtra } from "../../../services/eventsServices";
import ExtraTemplateDialog from "../../events/extras/ExtraTemplateDialog";
import { FormHeader } from "../../shared/FormHeader";
import { TripAdditionalExtras } from "./TripAdditionalExtras";
import { TripExtraDialog } from "./TripExtraDialog";
import { TripExtrasInsurance } from "./TripExtraInsurances";
import { TripExtrasActivities } from "./TripExtrasActivities";

const TripExtras = ({ extraTemplateId }) => {
  const { t } = useTranslation();
  // Add extra template
  const [openAddExtra, setOpenAddExtra] = useState({
    isOpen: false,
    extraTemplateId: null,
  });
  // Edit extra
  const [openExtraDialog, setOpenExtraDialog] = useState({
    isOpen: false,
    extraIndex: null,
  });

  const { watch, setValue, getValues } = useFormContext();

  const extras = watch("extras", []);
  const eventCategories = watch("event_categories", []);

  const handleRemoveExtra = async (e, i) => {
    e?.stopPropagation();
    try {
      const index = i !== -1 ? i : 0;
      if (extras[index]?.id) {
        await deleteExtra(extras[index].id);
      }
      // Remove extra from itineraries
      if (extras[index]?.extra_template_id) {
        const itineraries = getValues("itineraries") || [];
        const newItineraries = itineraries.map((itinerary) => {
          if (itinerary.extra_ids?.includes(extras[index].extra_template_id)) {
            itinerary.extra_ids = itinerary?.extra_ids?.filter(
              (extraId) => extraId !== extras[index].extra_template_id
            );
          }
          return itinerary;
        });
        setValue("itineraries", newItineraries);
      }
      const updatedExtras = extras
        ?.filter((_, i) => i !== index)
        ?.map((extra, index) => ({
          ...extra,
          position: index,
        }));
      setValue("extras", updatedExtras);
    } catch (error) {
      console.error("Error deleting extra:", error);
      throw error;
    }
  };

  const handleAddExtraTemplate = (extraTemplate) => {
    const prev = getValues("extras") || [];
    const newExtra = {
      name: extraTemplate.name || "",
      description: extraTemplate.description || "",
      description_design: extraTemplate.description_design || "",
      extra_language_infos: extraTemplate.extra_template_language_infos || [],
      extra_template_id: extraTemplate.id,
      short_description: extraTemplate.short_description || "",
      images: extraTemplate.images || "",
      position: prev.length || 0,
      price: 0,
      tags: extraTemplate.tags,
      no_after_sales: extraTemplate?.tags?.includes(EXTRAS_TYPES.INSURANCE) || false,
      is_visible: true,
    };
    setValue("extras", [...prev, newExtra]);

    //Open dialog to edit the new extra
    setOpenExtraDialog({ isOpen: true, extraIndex: prev.length });
  };

  // FOR EXTRA TEMPLATE from url
  const { data: extraTemplate } = useQueryExtraDetail(extraTemplateId);
  const checkExtraTemplate = () => {
    if (extras.some((extra) => extra.extra_template_id === extraTemplateId)) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (!!extraTemplateId && extraTemplate && checkExtraTemplate()) {
      handleAddExtraTemplate(extraTemplate);
    }
  }, [extraTemplateId, extraTemplate]);

  return (
    <>
      <FormHeader title={t("EXTRAS")} description={t("EXTRAS_DESCRIPTION")}>
        <Grid container padding={2} gap={2}>
          <TripExtrasInsurance
            handleAddExtraTemplate={handleAddExtraTemplate}
            handleRemoveExtra={handleRemoveExtra}
          />
          <Divider sx={{ width: "100%" }} />
          <TripExtrasActivities handleRemoveExtra={handleRemoveExtra} />
          <Divider sx={{ width: "100%" }} />
          <TripAdditionalExtras
            handleAddExtraTemplate={handleAddExtraTemplate}
            handleRemoveExtra={handleRemoveExtra}
          />
        </Grid>
      </FormHeader>
      <ExtraTemplateDialog
        isOpen={openAddExtra.isOpen}
        onClose={() => setOpenAddExtra({ isOpen: false, extraTemplateId: null })}
        onAccept={handleAddExtraTemplate}
        event_categories={eventCategories}
      />

      <TripExtraDialog
        isOpen={openExtraDialog.isOpen}
        onClose={() => setOpenExtraDialog({ isOpen: false, extraIndex: null })}
        extraIndex={openExtraDialog.extraIndex}
      />
    </>
  );
};

export default TripExtras;
