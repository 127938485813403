import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getB2bSales } from "../../../services/b2bServices";

export function useQueryB2bSales(page, perPage, filters, enabledDownload) {
  return useQuery(
    [RQ_KEY.B2B_SALES, page, perPage, filters],
    () => getB2bSales(filters, page, perPage),
    {
      refetchOnWindowFocus: false,
      enabled: enabledDownload,
    }
  );
}
