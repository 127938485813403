import { useQuery } from "react-query";
import { getPremisesList } from "../../services/premisesServices";
import { RQ_KEY } from "../../constants/query";

export function useQueryPremisesList(organization_id, group_id, onSuccess = () => {}) {
  return useQuery(
    [RQ_KEY.PREMISE_LIST, organization_id, group_id],
    () => getPremisesList(organization_id, group_id),
    {
      refetchOnWindowFocus: false,
      onSuccess: onSuccess ? onSuccess : null,
    }
  );
}
