import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const CloseDialogButton = ({ onClose }) => {
  return (
    <IconButton
      onClick={onClose}
      sx={{
        position: "absolute",
        top: 8,
        right: 8,
        color: "var(--secondary-color)",
      }}
    >
      <Close />
    </IconButton>
  );
};
