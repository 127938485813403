import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { Fragment } from "react";

const InfoToolTip = ({ text, styles = {}, children, ...props }) => {
  return (
    <Tooltip
      title={
        <span>
          {text.split("\n").map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
        </span>
      }
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            backgroundColor: "var(--grey-blue)",
            fontSize: "1rem",
            whiteSpace: "normal",
            wordWrap: "break-word",
            textAlign: "center",
            ...styles,
          },
        },
      }}
      {...props}
    >
      {children ? (
        children
      ) : (
        <IconButton sx={{ height: 25, width: 35 }} aria-label={text || "Información"}>
          <InfoOutlinedIcon sx={{ color: "var(--grey-dark)" }} />
        </IconButton>
      )}
    </Tooltip>
  );
};

export default InfoToolTip;
