import { Close, EditOutlined } from "@mui/icons-material";
import { Card, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TripExtraDialog } from "../../tripPlus/extras/TripExtraDialog";

export const AssignedExtraCard = ({ extra = {}, handleClose }) => {
  const { t } = useTranslation();
  const [openEditExtra, setOpenEditExtra] = useState(false);

  const { name = "", position } = extra;

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: 1,
          gap: 2,
          position: "relative",
        }}
      >
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Grid>

        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={t("EDIT_EXTRA")}>
            <IconButton onClick={() => setOpenEditExtra(true)}>
              <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("DELETE_EXTRA")}>
            <IconButton onClick={handleClose} size="small">
              <Close fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Card>
      <TripExtraDialog
        isOpen={openEditExtra}
        onClose={() => setOpenEditExtra(false)}
        extraIndex={position}
      />
    </>
  );
};
