import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../../shared/textFieldStyle";

export const AgentFiscalAddressFields = ({ countries = [], cities = [], regions = [] }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid item xs={12} container spacing={2} mt={2}>
      <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%", ml: 2 }}>
        {t("FISCAL_ADDRESS")}
      </Typography>
      <Grid item xs={12} sm={9}>
        <Controller
          name="fiscal_address"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("ADDRESS")}
              fullWidth
              error={!!errors.fiscal_address}
              helperText={errors.fiscal_address?.message}
              required
              sx={focusColor}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="fiscal_postal_code"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("POSTAL_CODE")}
              fullWidth
              error={!!errors.fiscal_postal_code}
              helperText={errors.fiscal_postal_code?.message}
              required
              sx={focusColor}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="fiscal_country"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("COUNTRY")}
              fullWidth
              error={!!errors.fiscal_country}
              helperText={errors.fiscal_country?.message}
              required
              sx={focusColor}
            >
              {countries.map((country) => (
                <MenuItem key={country.country_code} value={country.country_code}>
                  {country.official_name_en}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="fiscal_state"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("STATE")}
              required
              fullWidth
              error={!!errors.fiscal_state}
              helperText={errors.fiscal_state?.message}
              sx={focusColor}
              select
            >
              {regions.map((state) => (
                <MenuItem key={state.code} value={state.code}>
                  {state.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {cities.length > 0 ? (
          <Controller
            name="fiscal_city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("CITY")}
                fullWidth
                error={!!errors.fiscal_city}
                helperText={errors.fiscal_city?.message}
                sx={focusColor}
                select
              >
                {cities.map((city, index) => (
                  <MenuItem key={`${city.name}-${index}`} value={city.name}>
                    {city.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        ) : (
          <Controller
            name="fiscal_city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("CITY")}
                fullWidth
                error={!!errors.fiscal_city}
                helperText={errors.fiscal_city?.message}
                sx={focusColor}
              />
            )}
          />
        )}
      </Grid>
    </Grid>
  );
};
