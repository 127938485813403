export const AgentsIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_38_2712)">
        <path
          d="M15.4213 1.35588C15.4753 1.24873 15.558 1.15867 15.6603 1.09575C15.7624 1.03283 15.88 0.999512 16 0.999512C16.12 0.999512 16.2376 1.03283 16.3399 1.09575C16.442 1.15867 16.5247 1.24873 16.5787 1.35588L17.924 4.06788C17.9705 4.16084 18.0388 4.24118 18.1231 4.302C18.2073 4.36284 18.3052 4.40236 18.408 4.41722L21.4267 4.85322C21.5445 4.86978 21.6556 4.91884 21.7472 4.9949C21.8388 5.07096 21.9073 5.17103 21.9453 5.28388C21.9825 5.3963 21.9872 5.51696 21.9585 5.63188C21.93 5.7468 21.8695 5.85128 21.784 5.93322L19.6 8.05855C19.5263 8.12971 19.4709 8.21777 19.4389 8.31512C19.4068 8.41245 19.3989 8.51615 19.416 8.61722L19.9333 11.6132C19.9529 11.7304 19.9389 11.8508 19.8932 11.9604C19.8475 12.0701 19.7717 12.1646 19.6747 12.2332C19.5769 12.303 19.4617 12.3443 19.342 12.3525C19.2221 12.3608 19.1024 12.3356 18.996 12.2799L16.3013 10.8745C16.2085 10.8253 16.1051 10.7995 16 10.7995C15.8949 10.7995 15.7915 10.8253 15.6987 10.8745L13.0107 12.2799C12.9043 12.3356 12.7846 12.3608 12.6647 12.3525C12.5449 12.3443 12.4298 12.303 12.332 12.2332C12.235 12.1646 12.1592 12.0701 12.1135 11.9604C12.0677 11.8508 12.0538 11.7304 12.0733 11.6132L12.5907 8.61722C12.6077 8.51615 12.5999 8.41245 12.5678 8.31512C12.5357 8.21777 12.4804 8.12971 12.4067 8.05855L10.216 5.93322C10.1305 5.85128 10.07 5.7468 10.0414 5.63188C10.0129 5.51696 10.0175 5.3963 10.0547 5.28388C10.0926 5.17103 10.1613 5.07096 10.2529 4.9949C10.3445 4.91884 10.4554 4.86978 10.5733 4.85322L13.592 4.41722C13.6949 4.40236 13.7927 4.36284 13.8769 4.302C13.9612 4.24118 14.0295 4.16084 14.076 4.06788L15.4213 1.35588Z"
          stroke="#11B33C"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.42948 13.4668L7.27882 12.8669C7.18558 12.8183 7.08198 12.7929 6.97682 12.7929C6.87167 12.7929 6.76807 12.8183 6.67482 12.8669L3.98815 14.2668C3.88158 14.3224 3.76174 14.3476 3.64179 14.3396C3.52184 14.3316 3.40642 14.2908 3.30815 14.2215C3.21112 14.1527 3.13551 14.0579 3.08998 13.9479C3.04446 13.838 3.03088 13.7175 3.05082 13.6001L3.56682 10.6055C3.58426 10.5046 3.57672 10.4009 3.54488 10.3035C3.51304 10.2061 3.45786 10.118 3.38415 10.0469L1.19215 7.92552C1.10716 7.84327 1.04707 7.73872 1.01877 7.62387C0.990467 7.50902 0.995105 7.38852 1.03215 7.27619C1.07011 7.16334 1.13873 7.06327 1.23033 6.98722C1.32194 6.91115 1.43291 6.86208 1.55082 6.84552L4.56815 6.40952C4.67127 6.39488 4.7693 6.35544 4.85383 6.2946C4.93835 6.23376 5.00687 6.15332 5.05348 6.06019L6.40015 3.34819C6.45396 3.24091 6.53662 3.15076 6.63884 3.08788C6.74107 3.02499 6.8588 2.99186 6.97882 2.99219"
          stroke="#11B33C"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.5708 13.4668L24.7215 12.8655C24.8147 12.8169 24.9183 12.7915 25.0235 12.7915C25.1287 12.7915 25.2323 12.8169 25.3255 12.8655L28.0121 14.2668C28.1187 14.3224 28.2385 14.3476 28.3585 14.3396C28.4784 14.3316 28.5939 14.2908 28.6921 14.2215C28.7892 14.1527 28.8648 14.0579 28.9103 13.9479C28.9559 13.838 28.9695 13.7175 28.9495 13.6001L28.4335 10.6055C28.416 10.5046 28.4236 10.4009 28.4555 10.3035C28.4872 10.2061 28.5424 10.118 28.6161 10.0469L30.8081 7.92019C30.8932 7.83794 30.9532 7.73339 30.9815 7.61854C31.0099 7.5037 31.0052 7.38319 30.9681 7.27086C30.9301 7.158 30.8616 7.05794 30.77 6.98188C30.6784 6.90582 30.5673 6.85675 30.4495 6.84019L27.4321 6.40419C27.3291 6.38955 27.2311 6.35011 27.1465 6.28927C27.062 6.22843 26.9935 6.14799 26.9468 6.05486L25.6001 3.34819C25.5464 3.24091 25.4637 3.15076 25.3615 3.08788C25.2592 3.02499 25.1415 2.99186 25.0215 2.99219"
          stroke="#11B33C"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5026 20.5957V21.929C10.5541 23.464 10.1555 24.9804 9.35596 26.2917"
          stroke="#11B33C"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.4986 20.5957V21.929C21.4469 23.4638 21.8449 24.9802 22.6439 26.2917"
          stroke="#11B33C"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5171 20.887C11.5586 20.8799 12.5865 20.6503 13.5322 20.2136C14.4776 19.7768 15.319 19.1432 15.9998 18.355C16.6804 19.1435 17.5216 19.7774 18.4672 20.2143C19.4128 20.6512 20.4408 20.881 21.4824 20.8883"
          stroke="#11B33C"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 20.4478C10.5 21.9066 11.0795 23.3055 12.1109 24.337C13.1424 25.3683 14.5413 25.9478 16 25.9478C17.4587 25.9478 18.8576 25.3683 19.8891 24.337C20.9205 23.3055 21.5 21.9066 21.5 20.4478C21.5 18.9891 20.9205 17.5902 19.8891 16.5587C18.8576 15.5272 17.4587 14.9478 16 14.9478C14.5413 14.9478 13.1424 15.5272 12.1109 16.5587C11.0795 17.5902 10.5 18.9891 10.5 20.4478Z"
          stroke="#11B33C"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.7295 31.0001C21.8024 29.9478 20.6428 29.1261 19.3428 28.6001L16.0002 30.9481L12.6575 28.6054C11.3645 29.1273 10.21 29.9414 9.28418 30.9841"
          stroke="#11B33C"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_38_2712">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
