export const OrderIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_38_2747)">
        <path
          d="M27.5652 31.0002L23.6185 28.6629C23.2712 28.4154 23.0005 28.0753 22.8374 27.6814C22.6742 27.2874 22.6252 26.8554 22.6958 26.4349V24.0029L21.2212 22.5269C21.0361 22.3422 20.8942 22.1186 20.806 21.8723C20.7176 21.6262 20.685 21.3634 20.7106 21.1031C20.7361 20.8429 20.819 20.5914 20.9534 20.367C21.0878 20.1427 21.2704 19.9509 21.4878 19.8055C21.828 19.5793 22.2361 19.4775 22.6426 19.5179C23.0493 19.5583 23.4294 19.7381 23.7185 20.0269L25.7985 22.1082"
          stroke="#E14875"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.0293 12.5332L28.5786 17.4439C29.3054 18.4617 29.6961 19.6812 29.696 20.9319V23.8399C29.6961 24.6751 30.028 25.476 30.6186 26.0665L31 26.4479"
          stroke="#E14875"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4188 31L14.6535 27.024C14.9298 26.6819 15.0622 26.2457 15.0226 25.8077C14.983 25.3697 14.7747 24.9644 14.4415 24.6773C14.2783 24.5368 14.0886 24.4307 13.8834 24.3653C13.6782 24.2999 13.4619 24.2765 13.2475 24.2968C13.0332 24.3169 12.8251 24.3801 12.6357 24.4827C12.4463 24.5852 12.2796 24.7248 12.1455 24.8933L11.0295 25.848V18C11.0295 17.4696 10.8188 16.9608 10.4437 16.5857C10.0686 16.2107 9.55994 16 9.02951 16C8.49907 16 7.99036 16.2107 7.6153 16.5857C7.24022 16.9608 7.02951 17.4696 7.02951 18V23H5.37751C4.21216 23.0137 3.09986 23.4892 2.28471 24.3221C1.46955 25.1551 1.01813 26.2773 1.02951 27.4427V30.9987"
          stroke="#E14875"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.0293 19.6169V6.01417C23.0293 5.74894 22.924 5.4946 22.7364 5.30706C22.5489 5.11952 22.2945 5.01417 22.0293 5.01417H16.0293C16.0293 3.9533 15.6079 2.93589 14.8577 2.18574C14.1076 1.4356 13.0902 1.01416 12.0293 1.01416C10.9684 1.01416 9.95101 1.4356 9.20087 2.18574C8.45072 2.93589 8.02929 3.9533 8.02929 5.01417H2.02929C1.76408 5.01417 1.50972 5.11952 1.32219 5.30706C1.13465 5.4946 1.0293 5.74894 1.0293 6.01417V19.0129"
          stroke="#E14875"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0293 5.51514C11.7532 5.51514 11.5293 5.29127 11.5293 5.01514C11.5293 4.73899 11.7532 4.51514 12.0293 4.51514"
          stroke="#E14875"
          strokeWidth="2.5"
        />
        <path
          d="M12.0293 5.51514C12.3054 5.51514 12.5293 5.29127 12.5293 5.01514C12.5293 4.73899 12.3054 4.51514 12.0293 4.51514"
          stroke="#E14875"
          strokeWidth="2.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_38_2747">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
