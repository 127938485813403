import { SERVICE } from "../constants/api";
import { http } from "../constants/axios";

export async function uploadImage(body) {
  const { data } = await http.post(`${SERVICE.IMAGE}`, body);
  return data;
}
export async function getCnae() {
  const { data } = await http.get(`${SERVICE.CNAE}`);
  return data;
}
export async function getCountries() {
  const { data } = await http.get(`${SERVICE.COUNTRIES}`);
  return data;
}
export async function getRegions(code) {
  const { data } = await http.get(`${SERVICE.REGIONS}${code}`);
  return data;
}
export async function getCitiesByRegion(regionCode) {
  const { data } = await http.get(`${SERVICE.UTILS}/cities?region=${regionCode}`);
  return data;
}

export async function getHome() {
  const { data } = await http.get(`${SERVICE.HOME}`);
  return data;
}

export async function getHomeNextEvents() {
  const params = new URLSearchParams();
  params.append("desc", "start_date");
  const { data } = await http.get(`${SERVICE.HOME}/next-events?${params}`);
  return data;
}

export async function getHomeStatistics() {
  const { data } = await http.get(`${SERVICE.HOME}/statistics`);
  return data;
}

export async function getBicFromIban(iban) {
  const { data } = await http.get(`https://openiban.com/validate/${iban}?getBIC=true`);
  return data;
}
export async function deleteUser(id) {
  const { data } = await http.delete(`${SERVICE.USER}${id}`);
  return data;
}

// START GENERAL STATISTICS
export async function getMonthStatisticsGeneralTotal(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/general-total-sales?${params}`);
  return data;
}

export async function getMonthStatisticsGeneralPremise(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/general-premise-commissions?${params}`);
  return data;
}

export async function getMonthStatisticsGeneralNet(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/general-net-income?${params}`);
  return data;
}

export async function getMonthStatisticsGeneralOrders(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/general-number-orders?${params}`);
  return data;
}

export async function getMonthStatisticsGeneralRefunded(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/general-refunded-orders?${params}`);
  return data;
}

export async function getMonthStatisticsGeneralTickets(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/general-total-tickets?${params}`);
  return data;
}
// END GENERAL STATISTICS

// START USERS STATISTICS
export async function getMonthStatisticsUsersGender(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/users-gender-sales?${params}`);
  return data;
}
export async function getMonthStatisticsUsersCard(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/users-card-sales?${params}`);
  return data;
}
export async function getMonthStatisticsUsersAge(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/users-age-tickets?${params}`);
  return data;
}
export async function getMonthStatisticsUsersNationality(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/users-assitants-nationality?${params}`);
  return data;
}
// END USERS STATISTICS

// START SALES STATISTICS
export async function getMonthStatisticsSalesTickets(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/sales-ticket-sales?${params}`);
  return data;
}
export async function getMonthStatisticsSalesExtras(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/sales-extra-sales?${params}`);
  return data;
}
export async function getMonthStatisticsSalesMethod(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/sales-payment-method?${params}`);
  return data;
}
export async function getMonthStatisticsSalesPromo(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/sales-promo-code?${params}`);
  return data;
}
export async function getMonthStatisticsSalesHour(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/sales-hour-purchases?${params}`);
  return data;
}
export async function getMonthStatisticsSalesWeek(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/sales-weekday-purchases?${params}`);
  return data;
}
export async function getMonthStatisticsSalesTicketCons(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/sales-ticket-consumitions?${params}`);
  return data;
}
export async function getMonthStatisticsSalesExtrasCons(month, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (month) params.append("month", month);

  const { data } = await http.get(`${SERVICE.STATISTICS}/sales-extra-consumitions?${params}`);
  return data;
}
// END SALES STATISTICS

export async function getMonthEventStatistics(page, perPage, filters) {
  const { month: date, search, premiseId, asc, desc } = filters;
  const params = new URLSearchParams();

  if (date) {
    const [year, month] = date.split("-");
    const formattedValue = `${month}/${year}`;
    params.append("month", formattedValue);
  }
  if (page) params.append("page", page);
  if (perPage) params.append("perpage", perPage);
  if (search) params.append("search", search);
  if (premiseId && premiseId !== "all") params.append("premise_id", premiseId);
  if (asc && asc.length > 0) params.append("asc", asc.join(";"));
  if (desc && desc.length > 0) params.append("desc", desc.join(";"));

  const { data } = await http.get(`${SERVICE.UTILS}/month-events-statistics?${params}`);
  return data;
}

export async function generateStatistics(filters) {
  const params = new URLSearchParams();
  const { month: date } = filters;
  if (date) {
    const [year, month] = date.split("-");
    const formattedValue = `${month}/${year}`;
    params.append("month", formattedValue);
  }

  const { data } = await http.post(`${SERVICE.UTILS}/month-events-statistics?${params}`);
  return data;
}

export async function getEventsRequests(filters, page, perPage) {
  const params = new URLSearchParams();

  if (page) params.append("page", page);
  if (perPage) params.append("perpage", perPage);

  const { data } = await http.get(`${SERVICE.EVENTS}contact-requests?${params}`);
  return data;
}
