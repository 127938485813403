import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getTripsOfTransport } from "../../../services/transportServices";

const useQueryTransportOnTrips = (id) => {
  return useQuery([RQ_KEY.TRANSPORT_ON_TRIPS, id], () => getTripsOfTransport(id), {
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export default useQueryTransportOnTrips;
