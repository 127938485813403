import { DeleteOutline, DragIndicator, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ABECEDARY } from "../../../../../constants/variables";
import { useTripLanguage } from "../../../../../context/TripLanguageContext";
import { AssignedActivityCard } from "../../../../shared/assignToTrip/AssignedActivityCard";
import AssignedHotelCard from "../../../../shared/assignToTrip/AssignedHotelCard";
import SelectHookForm from "../../../../shared/SelectHookForm";
import { focusColor } from "../../../../shared/textFieldStyle";
import TextEditor from "../../../../textEditor/TextEditor";
import AddTourPointDialog from "./AddTourPointDialog";

const TripItinerary = ({
  index,
  handleOpenDelete,
  tourPoints,
  refetchTourPoints,
  isSimpleView,
}) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    register,
    formState: { errors },
    control,
    clearErrors,
    getValues,
  } = useFormContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { getItineraryFieldName } = useTripLanguage();

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
    setValue(`itineraries[${index}].tour_point_id`, null);
  };

  const itinerary = watch(`itineraries[${index}]`, []);
  const itineraryTourPoint = watch(`itineraries[${index}].tour_point_id`);

  const itineraryFieldTitle = getItineraryFieldName("title", index);
  const itineraryFieldDescription = getItineraryFieldName("description", index);

  const orderTourPoints = tourPoints?.sort((a, b) => a?.name?.localeCompare(b?.name));

  const itineraryHasSomeError = Object.values(errors?.itineraries?.[index] || {}).some(
    (error) => !!error
  );

  // Set itinerary title if tour point is selected
  useEffect(() => {
    const title = getValues(`itineraries[${index}].title`);
    if (itineraryTourPoint && !title) {
      const tourPoint = tourPoints?.find((point) => point.id === itineraryTourPoint);
      setValue(`itineraries[${index}].title`, tourPoint?.name);
      clearErrors(`itineraries[${index}].title`);

      if (itinerary?.itinerary_language_infos && itinerary?.itinerary_language_infos?.length > 0) {
        itinerary?.itinerary_language_infos.forEach((_, langIndex) => {
          setValue(
            `itineraries[${index}].itinerary_language_infos.${langIndex}.title`,
            tourPoint?.name
          );
        });
      }
    }
  }, [itineraryTourPoint]);

  return (
    <>
      <Draggable draggableId={`draggable-itinerary-${index}`} index={index}>
        {(provided, snapshot) => (
          <Accordion
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{
              backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
              display: "flex",
              flexDirection: "column",
              border: "1px solid var(--field-grey)",
              boxShadow: "none",
              borderColor: itineraryHasSomeError ? "var(--red-error)" : "",
              width: "100%",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Grid container flexDirection="row" justifyContent="space-between">
                <Grid item xs={6} container alignItems="center">
                  {isSimpleView && <DragIndicator sx={{ marginRight: 2 }} />}
                  <Typography>
                    {t("POINT")} {ABECEDARY[index]} -{" "}
                    {itinerary.title ? itinerary.title : t("TITLE")}
                    {!isSimpleView && itinerary?.nights ? (
                      <>
                        - {itinerary?.nights} {t("NIGHTS")}
                      </>
                    ) : null}
                  </Typography>
                </Grid>
                {isSimpleView && (
                  <Grid item marginRight={2}>
                    <IconButton
                      onClick={(e) => handleOpenDelete(e, index)}
                      className="oniria-icon-btn-delete"
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {!isSimpleView && (
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: "600" }}>{t("TITLE")}</Typography>
                    <TextField
                      key={itineraryFieldTitle}
                      {...register(itineraryFieldTitle)}
                      error={!!errors?.[itineraryFieldTitle]}
                      helperText={t(errors?.[itineraryFieldTitle]?.message)}
                      fullWidth
                      sx={focusColor}
                      placeholder={t("TITLE")}
                    />
                  </Grid>
                )}
                {!isSimpleView && (
                  <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                    <Typography sx={{ fontWeight: "600" }}>{t("DESCRIPTION")}</Typography>
                    <TextEditor
                      key={itineraryFieldDescription}
                      data={watch(itineraryFieldDescription)}
                      setData={(data) => setValue(itineraryFieldDescription, data)}
                      id={`itinerary-${index}-description-${itineraryFieldDescription}`}
                    />
                  </Grid>
                )}
                {isSimpleView && (
                  <Grid item xs={12}>
                    <Typography>{t("DESTINATION")}</Typography>
                    <SelectHookForm
                      name={`itineraries[${index}].tour_point_id`}
                      control={control}
                      defaultValue=""
                      options={[
                        { id: "create", name: `+ ${t("CREATE_NEW_TOUR_POINT")}` },
                        { id: null, name: t("NONE") },
                        ...orderTourPoints,
                      ].map((point) => (
                        <MenuItem
                          key={point.id}
                          value={point.id}
                          onClick={point.id === "create" ? handleOpenDialog : undefined}
                          className={point.id === "create" ? "oniria-border-btn" : ""}
                          sx={{
                            margin: point.id === "create" ? "10px" : "",
                          }}
                        >
                          {point.name}
                        </MenuItem>
                      ))}
                      placeholder={t("DESTINATION")}
                      error={errors?.itineraries?.[index]?.tour_point_id}
                    />
                  </Grid>
                )}
                {isSimpleView && (
                  <Grid item xs={12}>
                    <Typography>{t("NIGHTS_DURATION")}</Typography>
                    <TextField
                      placeholder={"00"}
                      {...register(`itineraries[${index}].nights`)}
                      error={!!errors?.itineraries?.[index]?.nights}
                      helperText={t(errors?.itineraries?.[index]?.nights?.message)}
                      fullWidth
                      sx={focusColor}
                      type="number"
                      inputProps={{ min: 0 }}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Grid>
                )}
                <Grid item xs={12} container gap={2}>
                  {!isSimpleView && itinerary?.hotel_ids && itinerary?.hotel_ids?.length > 0
                    ? itinerary.hotel_ids?.map((hotelId) => (
                        <AssignedHotelCard key={hotelId} hotelId={hotelId} isSimple />
                      ))
                    : null}
                </Grid>
                <Grid item xs={12} container gap={2}>
                  {!isSimpleView && itinerary?.extra_ids && itinerary?.extra_ids?.length > 0
                    ? itinerary.extra_ids?.map((extraId) => (
                        <AssignedActivityCard key={extraId} extraId={extraId} isSimple />
                      ))
                    : null}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Draggable>
      {itineraryHasSomeError && (
        <Typography variant="caption" color="error">
          {t("PLEASE_FIX_ERRORS")}
        </Typography>
      )}
      <AddTourPointDialog
        isDialogOpen={isDialogOpen}
        handleCloseDialog={() => setIsDialogOpen(false)}
        refetchTourPoints={refetchTourPoints}
      />
    </>
  );
};

export default TripItinerary;
